import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('CRAZY_SLOT', fileName)
};
export default {
  code: "CRAZY_SLOT",
  type: ["EGAMING"],
  play_method: "URL",
  subGames: [
    {
      subCode: "CRAZY_SLOT_emoji-riches",
      thumb: {
        img: getImg("CRAZY_SLOT_emoji-riches.png"),
        subGame: getImg("CRAZY_SLOT_emoji-riches.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_farm-invaders",
      thumb: {
        img: getImg("CRAZY_SLOT_farm-invaders.png"),
        subGame: getImg("CRAZY_SLOT_farm-invaders.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_spirit-wonder",
      thumb: {
        img: getImg("CRAZY_SLOT_spirit-wonder.png"),
        subGame: getImg("CRAZY_SLOT_spirit-wonder.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_lgd-monkey-kg",
      thumb: {
        img: getImg("CRAZY_SLOT_lgd-monkey-kg.png"),
        subGame: getImg("CRAZY_SLOT_lgd-monkey-kg.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_buffalo-win",
      thumb: {
        img: getImg("CRAZY_SLOT_buffalo-win.png"),
        subGame: getImg("CRAZY_SLOT_buffalo-win.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_mahjong-ways2",
      thumb: {
        img: getImg("CRAZY_SLOT_mahjong-ways2.png"),
        subGame: getImg("CRAZY_SLOT_mahjong-ways2.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_bali-vacation",
      thumb: {
        img: getImg("CRAZY_SLOT_bali-vacation.png"),
        subGame: getImg("CRAZY_SLOT_bali-vacation.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_bikini-paradise",
      thumb: {
        img: getImg("CRAZY_SLOT_bikini-paradise.png"),
        subGame: getImg("CRAZY_SLOT_bikini-paradise.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_sprmkt-spree",
      thumb: {
        img: getImg("CRAZY_SLOT_sprmkt-spree.png"),
        subGame: getImg("CRAZY_SLOT_sprmkt-spree.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_crypt-fortune",
      thumb: {
        img: getImg("CRAZY_SLOT_crypt-fortune.png"),
        subGame: getImg("CRAZY_SLOT_crypt-fortune.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_groundhog",
      thumb: {
        img: getImg("CRAZY_SLOT_groundhog.png"),
        subGame: getImg("CRAZY_SLOT_groundhog.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_mermaid-riches",
      thumb: {
        img: getImg("CRAZY_SLOT_mermaid-riches.png"),
        subGame: getImg("CRAZY_SLOT_mermaid-riches.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_sushi-oishi",
      thumb: {
        img: getImg("CRAZY_SLOT_sushi-oishi.png"),
        subGame: getImg("CRAZY_SLOT_sushi-oishi.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_heist-stakes",
      thumb: {
        img: getImg("CRAZY_SLOT_heist-stakes.png"),
        subGame: getImg("CRAZY_SLOT_heist-stakes.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_wild-bandito",
      thumb: {
        img: getImg("CRAZY_SLOT_wild-bandito.png"),
        subGame: getImg("CRAZY_SLOT_wild-bandito.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_majestic-ts",
      thumb: {
        img: getImg("CRAZY_SLOT_majestic-ts.png"),
        subGame: getImg("CRAZY_SLOT_majestic-ts.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_fortune-ox",
      thumb: {
        img: getImg("CRAZY_SLOT_fortune-ox.png"),
        subGame: getImg("CRAZY_SLOT_fortune-ox.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_gdn-ice-fire",
      thumb: {
        img: getImg("CRAZY_SLOT_gdn-ice-fire.png"),
        subGame: getImg("CRAZY_SLOT_gdn-ice-fire.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_jack-frosts",
      thumb: {
        img: getImg("CRAZY_SLOT_jack-frosts.png"),
        subGame: getImg("CRAZY_SLOT_jack-frosts.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_jewels-prosper",
      thumb: {
        img: getImg("CRAZY_SLOT_jewels-prosper.png"),
        subGame: getImg("CRAZY_SLOT_jewels-prosper.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_vampires-charm",
      thumb: {
        img: getImg("CRAZY_SLOT_vampires-charm.png"),
        subGame: getImg("CRAZY_SLOT_vampires-charm.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_wild-fireworks",
      thumb: {
        img: getImg("CRAZY_SLOT_wild-fireworks.png"),
        subGame: getImg("CRAZY_SLOT_wild-fireworks.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_phoenix-rises",
      thumb: {
        img: getImg("CRAZY_SLOT_phoenix-rises.png"),
        subGame: getImg("CRAZY_SLOT_phoenix-rises.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_candy-burst",
      thumb: {
        img: getImg("CRAZY_SLOT_candy-burst.png"),
        subGame: getImg("CRAZY_SLOT_candy-burst.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_reel-love",
      thumb: {
        img: getImg("CRAZY_SLOT_reel-love.png"),
        subGame: getImg("CRAZY_SLOT_reel-love.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_fortune-mouse",
      thumb: {
        img: getImg("CRAZY_SLOT_fortune-mouse.png"),
        subGame: getImg("CRAZY_SLOT_fortune-mouse.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_dragon-hatch",
      thumb: {
        img: getImg("CRAZY_SLOT_dragon-hatch.png"),
        subGame: getImg("CRAZY_SLOT_dragon-hatch.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_galactic-gems",
      thumb: {
        img: getImg("CRAZY_SLOT_galactic-gems.png"),
        subGame: getImg("CRAZY_SLOT_galactic-gems.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_Monopoly_GJM",
      thumb: {
        img: getImg("CRAZY_SLOT_Monopoly_GJM.png"),
        subGame: getImg("CRAZY_SLOT_Monopoly_GJM.png"),
      },
    },
    {
      subCode: "CRAZY_SLOT_Bells",
      thumb: {
        img: getImg("CRAZY_SLOT_Bells.jpg"),
        subGame: getImg("CRAZY_SLOT_Bells.jpg"),
      },
    },
    {
      subCode: "CRAZY_SLOT_RulerOfTheDead",
      thumb: {
        img: getImg("CRAZY_SLOT_RulerOfTheDead.jpg"),
        subGame: getImg("CRAZY_SLOT_RulerOfTheDead.jpg"),
      },
    },
    {
      subCode: "CRAZY_SLOT_WonderWarriors",
      thumb: {
        img: getImg("CRAZY_SLOT_WonderWarriors.jpg"),
        subGame: getImg("CRAZY_SLOT_WonderWarriors.jpg"),
      },
    },
  ],
} as LIBRARY_GAME;
