import { AxiosResponse } from "axios";
import $http from "../../../FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "casinoAccount/front-member-tradeAccount/doModify";

export namespace typeDoModify {
  export interface request {
    p17_member_trade_account: p17_member_trade_account[];
  }
  /** 會員交易帳號 */
  export type p17_member_trade_account = {
    /** 會員交易帳號ID 有ID就編輯沒有就是新增  */
    p17_mta_id?: number;
    /** 類型 有資料就必填;BANK,USDT  */
    p17_mta_type: "BANK" | "USDT";
    /** 資料 json 有類型就必填  */
    p17_mta_data: string;
    /** 會員交易帳號圖檔 陣列  */
    p17_member_trade_account_file: number[];
  };
  export interface AxiosResponse extends _AxiosResponse {}
}
interface _AxiosResponse extends AxiosResponse<responseData> {}

interface responseData extends BackendResponse {
  response: any;
}

/** 交易帳號上傳 */
export default async (request: typeDoModify.request) => {
  const getter = await $http.put(API, preformat(request));
  if (getter.data.code !== 0) throw getter;
  return getter;
};

/** 送出前的強制格式化，去除額外的參數 */
function preformat(request: typeDoModify.request) {
  return {
    p17_member_trade_account: request.p17_member_trade_account,
  };
}
