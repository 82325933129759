import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('GTAG_BOOONGO', fileName)
};

const ZH_SUBGAMES = [
  {
    subCode: "GTAG_BOOONGO_BNG_14",
    thumb: {
      img: getImg("14_zh.webp"),
      subGame: getImg("14_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_86",
    thumb: {
      img: getImg("86_zh.webp"),
      subGame: getImg("86_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_139",
    thumb: {
      img: getImg("139_zh.webp"),
      subGame: getImg("139_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_148",
    thumb: {
      img: getImg("148_zh.webp"),
      subGame: getImg("148_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_151",
    thumb: {
      img: getImg("151_zh.webp"),
      subGame: getImg("151_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_157",
    thumb: {
      img: getImg("157_zh.webp"),
      subGame: getImg("157_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_166",
    thumb: {
      img: getImg("166_zh.webp"),
      subGame: getImg("166_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_168",
    thumb: {
      img: getImg("168_zh.webp"),
      subGame: getImg("168_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_173",
    thumb: {
      img: getImg("173_zh.webp"),
      subGame: getImg("173_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_175",
    thumb: {
      img: getImg("175_zh.webp"),
      subGame: getImg("175_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_178",
    thumb: {
      img: getImg("178_zh.webp"),
      subGame: getImg("178_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_181",
    thumb: {
      img: getImg("181_zh.webp"),
      subGame: getImg("181_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_183",
    thumb: {
      img: getImg("183.webp"),
      subGame: getImg("183.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_184",
    thumb: {
      img: getImg("184_zh.webp"),
      subGame: getImg("184_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_187",
    thumb: {
      img: getImg("187_zh.webp"),
      subGame: getImg("187_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_189",
    thumb: {
      img: getImg("189_zh.webp"),
      subGame: getImg("189_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_190",
    thumb: {
      img: getImg("190_zh.webp"),
      subGame: getImg("190_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_197",
    thumb: {
      img: getImg("197_zh.webp"),
      subGame: getImg("197_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_200",
    thumb: {
      img: getImg("200_zh.webp"),
      subGame: getImg("200_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_201",
    thumb: {
      img: getImg("201_zh.webp"),
      subGame: getImg("201_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_202",
    thumb: {
      img: getImg("202_zh.webp"),
      subGame: getImg("202_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_204",
    thumb: {
      img: getImg("204_zh.webp"),
      subGame: getImg("204_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_209",
    thumb: {
      img: getImg("209_zh.webp"),
      subGame: getImg("209_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_212",
    thumb: {
      img: getImg("212_zh.webp"),
      subGame: getImg("212_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_216",
    thumb: {
      img: getImg("216_zh.webp"),
      subGame: getImg("216_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_217",
    thumb: {
      img: getImg("217_zh.webp"),
      subGame: getImg("217_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_219",
    thumb: {
      img: getImg("219_zh.webp"),
      subGame: getImg("219_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_223",
    thumb: {
      img: getImg("223_zh.webp"),
      subGame: getImg("223_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_228",
    thumb: {
      img: getImg("228_zh.webp"),
      subGame: getImg("228_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_231",
    thumb: {
      img: getImg("231_zh.webp"),
      subGame: getImg("231_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_232",
    thumb: {
      img: getImg("232_zh.webp"),
      subGame: getImg("232_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_233",
    thumb: {
      img: getImg("233_zh.webp"),
      subGame: getImg("233_zh.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_236",
    thumb: {
      img: getImg("236.webp"),
      subGame: getImg("236.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_237",
    thumb: {
      img: getImg("237.webp"),
      subGame: getImg("237.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_240",
    thumb: {
      img: getImg("240.webp"),
      subGame: getImg("240.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_242",
    thumb: {
      img: getImg("242.webp"),
      subGame: getImg("242.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_244",
    thumb: {
      img: getImg("244.webp"),
      subGame: getImg("244.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_245",
    thumb: {
      img: getImg("245.webp"),
      subGame: getImg("245.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_249",
    thumb: {
      img: getImg("249.webp"),
      subGame: getImg("249.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_250",
    thumb: {
      img: getImg("250.webp"),
      subGame: getImg("250.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_252",
    thumb: {
      img: getImg("252.webp"),
      subGame: getImg("252.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_254",
    thumb: {
      img: getImg("254.webp"),
      subGame: getImg("254.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_255",
    thumb: {
      img: getImg("255.webp"),
      subGame: getImg("255.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_256",
    thumb: {
      img: getImg("256.webp"),
      subGame: getImg("256.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_259",
    thumb: {
      img: getImg("259.webp"),
      subGame: getImg("259.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_261",
    thumb: {
      img: getImg("261.webp"),
      subGame: getImg("261.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_262",
    thumb: {
      img: getImg("262.webp"),
      subGame: getImg("262.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_265",
    thumb: {
      img: getImg("265.webp"),
      subGame: getImg("265.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_266",
    thumb: {
      img: getImg("266.webp"),
      subGame: getImg("266.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_267",
    thumb: {
      img: getImg("267.webp"),
      subGame: getImg("267.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_269",
    thumb: {
      img: getImg("269.webp"),
      subGame: getImg("269.webp"),
    },
  },
  {
    subCode: "GTAG_BOOONGO_BNG_270",
    thumb: {
      img: getImg("270.webp"),
      subGame: getImg("270.webp"),
    },
  },
];

export default {
  code: "GTAG_BOOONGO",
  type: ["EGAMING"],
  play_method: "URL",
  subGames: [
    {
      subCode: "GTAG_BOOONGO_BNG_14",
      thumb: {
        img: getImg("14.webp"),
        subGame: getImg("14.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_86",
      thumb: {
        img: getImg("86.webp"),
        subGame: getImg("86.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_139",
      thumb: {
        img: getImg("139.webp"),
        subGame: getImg("139.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_148",
      thumb: {
        img: getImg("148.webp"),
        subGame: getImg("148.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_151",
      thumb: {
        img: getImg("151.webp"),
        subGame: getImg("151.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_157",
      thumb: {
        img: getImg("157.webp"),
        subGame: getImg("157.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_166",
      thumb: {
        img: getImg("166.webp"),
        subGame: getImg("166.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_168",
      thumb: {
        img: getImg("168.webp"),
        subGame: getImg("168.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_173",
      thumb: {
        img: getImg("173.webp"),
        subGame: getImg("173.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_175",
      thumb: {
        img: getImg("175.webp"),
        subGame: getImg("175.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_178",
      thumb: {
        img: getImg("178.webp"),
        subGame: getImg("178.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_181",
      thumb: {
        img: getImg("181.webp"),
        subGame: getImg("181.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_183",
      thumb: {
        img: getImg("183.webp"),
        subGame: getImg("183.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_184",
      thumb: {
        img: getImg("184.webp"),
        subGame: getImg("184.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_187",
      thumb: {
        img: getImg("187.webp"),
        subGame: getImg("187.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_189",
      thumb: {
        img: getImg("189.webp"),
        subGame: getImg("189.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_190",
      thumb: {
        img: getImg("190.webp"),
        subGame: getImg("190.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_197",
      thumb: {
        img: getImg("197.webp"),
        subGame: getImg("197.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_200",
      thumb: {
        img: getImg("200.webp"),
        subGame: getImg("200.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_201",
      thumb: {
        img: getImg("201.webp"),
        subGame: getImg("201.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_202",
      thumb: {
        img: getImg("202.webp"),
        subGame: getImg("202.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_204",
      thumb: {
        img: getImg("204.webp"),
        subGame: getImg("204.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_209",
      thumb: {
        img: getImg("209.webp"),
        subGame: getImg("209.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_212",
      thumb: {
        img: getImg("212.webp"),
        subGame: getImg("212.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_216",
      thumb: {
        img: getImg("216.webp"),
        subGame: getImg("216.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_217",
      thumb: {
        img: getImg("217.webp"),
        subGame: getImg("217.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_219",
      thumb: {
        img: getImg("219.webp"),
        subGame: getImg("219.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_223",
      thumb: {
        img: getImg("223.webp"),
        subGame: getImg("223.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_228",
      thumb: {
        img: getImg("228.webp"),
        subGame: getImg("228.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_231",
      thumb: {
        img: getImg("231.webp"),
        subGame: getImg("231.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_232",
      thumb: {
        img: getImg("232.webp"),
        subGame: getImg("232.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_233",
      thumb: {
        img: getImg("233.webp"),
        subGame: getImg("233.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_236",
      thumb: {
        img: getImg("236.webp"),
        subGame: getImg("236.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_237",
      thumb: {
        img: getImg("237.webp"),
        subGame: getImg("237.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_240",
      thumb: {
        img: getImg("240.webp"),
        subGame: getImg("240.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_242",
      thumb: {
        img: getImg("242.webp"),
        subGame: getImg("242.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_244",
      thumb: {
        img: getImg("244.webp"),
        subGame: getImg("244.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_245",
      thumb: {
        img: getImg("245.webp"),
        subGame: getImg("245.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_249",
      thumb: {
        img: getImg("249.webp"),
        subGame: getImg("249.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_250",
      thumb: {
        img: getImg("250.webp"),
        subGame: getImg("250.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_252",
      thumb: {
        img: getImg("252.webp"),
        subGame: getImg("252.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_254",
      thumb: {
        img: getImg("254.webp"),
        subGame: getImg("254.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_255",
      thumb: {
        img: getImg("255.webp"),
        subGame: getImg("255.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_256",
      thumb: {
        img: getImg("256.webp"),
        subGame: getImg("256.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_259",
      thumb: {
        img: getImg("259.webp"),
        subGame: getImg("259.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_261",
      thumb: {
        img: getImg("261.webp"),
        subGame: getImg("261.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_262",
      thumb: {
        img: getImg("262.webp"),
        subGame: getImg("262.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_265",
      thumb: {
        img: getImg("265.webp"),
        subGame: getImg("265.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_266",
      thumb: {
        img: getImg("266.webp"),
        subGame: getImg("266.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_267",
      thumb: {
        img: getImg("267.webp"),
        subGame: getImg("267.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_269",
      thumb: {
        img: getImg("269.webp"),
        subGame: getImg("269.webp"),
      },
    },
    {
      subCode: "GTAG_BOOONGO_BNG_270",
      thumb: {
        img: getImg("270.webp"),
        subGame: getImg("270.webp"),
      },
    },
  ],
  localeSetting: {
    zh_CN: {
      subGames: ZH_SUBGAMES,
    },
    zh_TW: {
      subGames: ZH_SUBGAMES,
    },
  },
} as LIBRARY_GAME;
