type AutoComponent = Record<string, any>; // 可以根据需要更改类型

/**
 * 自动注册组件
 * @param components 需要引入的路径，例如 import.meta.glob('./*.vue') 取得的对象
 * @returns 一串组件设置，可用 components: { ...AUTO_COMPONENTS } 使其注册
 */
const GET_COMPONENTS = (components: Record<string, any>) => {
  const AUTO_COMPONENT: AutoComponent = {};
  for (const fileName in components) {
    // 加载组件
    try {
      const reqCom = components[fileName];
      // 提取组件名称，移除文件夹名
      const reqComName = fileName
        .replace(/.*\//, "") // 移除路径到最后一个斜杠
        .replace(/\.\w+$/, ""); // 移除文件扩展名
      // 组件挂载
      AUTO_COMPONENT[reqComName] = reqCom.default || reqCom;
    } catch (e) {
      console.error(`Error loading component ${fileName}:`, e);
    }
  }

  return AUTO_COMPONENT;
};

export default GET_COMPONENTS;
