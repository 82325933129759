import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('GTAG_NOLIMITCITY_ASIA', fileName)
};

export default {
  code: "GTAG_NOLIMITCITY_ASIA",
  type: ["EGAMING", "CASINO"],
  play_method: "URL",
  subGames: [
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_WHACKEDDX2",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("whackeddx2.webp"),
        subGame: getImg("whackeddx2.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_WHACKEDDX4",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("whackeddx4.webp"),
        subGame: getImg("whackeddx4.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_WHACKEDDX1",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("whackeddx1.webp"),
        subGame: getImg("whackeddx1.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_WHACKED",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("whacked.webp"),
        subGame: getImg("whacked.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_DEADCANARY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("deadcanary.webp"),
        subGame: getImg("deadcanary.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_TESLAJOLT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("teslajolt.webp"),
        subGame: getImg("teslajolt.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_MENTAL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mental.webp"),
        subGame: getImg("mental.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_COINSOFFORTUNE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("coinsoffortune.webp"),
        subGame: getImg("coinsoffortune.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_WIXX",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wixx.webp"),
        subGame: getImg("wixx.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_INFECTIOUS5XWAYS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("infectious5xways.webp"),
        subGame: getImg("infectious5xways.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_BUSHIDOWAYSXNUDGE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bushidowaysxnudge.webp"),
        subGame: getImg("bushidowaysxnudge.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_BARBARIANFURY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("barbarianfury.webp"),
        subGame: getImg("barbarianfury.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_CASINOWINSPIN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("casinowinspin.webp"),
        subGame: getImg("casinowinspin.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_SERIAL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("serial.webp"),
        subGame: getImg("serial.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_TOMBOFAKHENATEN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tombofakhenaten.webp"),
        subGame: getImg("tombofakhenaten.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_THEBORDER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("theborder.webp"),
        subGame: getImg("theborder.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_TOMBSTONE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tombstone.webp"),
        subGame: getImg("tombstone.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_TRUEGRITREDEMPTION",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("truegritredemption.webp"),
        subGame: getImg("truegritredemption.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_DISTURBEDDX4",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("disturbeddx4.webp"),
        subGame: getImg("disturbeddx4.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_XWAYSHOARDERXSPLIT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("xwayshoarderxsplit.webp"),
        subGame: getImg("xwayshoarderxsplit.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_GLUTTONYDX1",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gluttonydx1.webp"),
        subGame: getImg("gluttonydx1.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_THERAVE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("therave.webp"),
        subGame: getImg("therave.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_DISTURBEDDX1",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("disturbeddx1.webp"),
        subGame: getImg("disturbeddx1.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_BOOKOFSHADOWS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bookofshadows.webp"),
        subGame: getImg("bookofshadows.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_KISSMYCHAINSAW",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("kissmychainsaw.webp"),
        subGame: getImg("kissmychainsaw.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_PUNKTOILET",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("punktoilet.webp"),
        subGame: getImg("punktoilet.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_KISSMYCHAINSAWDX4",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("kissmychainsawdx4.webp"),
        subGame: getImg("kissmychainsawdx4.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_ROCKBOTTOM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("rockbottom.webp"),
        subGame: getImg("rockbottom.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_THECREEPYCARNIVAL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thecreepycarnival.webp"),
        subGame: getImg("thecreepycarnival.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_DISTURBED",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("disturbed.webp"),
        subGame: getImg("disturbed.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_STARSTRUCK",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("starstruck.webp"),
        subGame: getImg("starstruck.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_PIXIESVSPIRATES",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pixiesvspirates.webp"),
        subGame: getImg("pixiesvspirates.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_TOMBSTONERIP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tombstonerip.webp"),
        subGame: getImg("tombstonerip.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_GLUTTONYDX2",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gluttonydx2.webp"),
        subGame: getImg("gluttonydx2.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_HARLEQUINCARNIVAL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("harlequincarnival.webp"),
        subGame: getImg("harlequincarnival.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_MAYANMAGICWILDFIRE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mayanmagicwildfire.webp"),
        subGame: getImg("mayanmagicwildfire.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_EVILGOBLINSXBOMB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("evilgoblinsxbomb.webp"),
        subGame: getImg("evilgoblinsxbomb.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_GAELICGOLD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gaelicgold.webp"),
        subGame: getImg("gaelicgold.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_LEGIONX",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("legionx.webp"),
        subGame: getImg("legionx.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_DUNGEONQUEST",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dungeonquest.webp"),
        subGame: getImg("dungeonquest.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_SANQUENTINXWAYS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sanquentinxways.webp"),
        subGame: getImg("sanquentinxways.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_MANHATTANGOESWILD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("manhattangoeswild.webp"),
        subGame: getImg("manhattangoeswild.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_KISSMYCHAINSAWDX1",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("kissmychainsawdx1.webp"),
        subGame: getImg("kissmychainsawdx1.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_REMEMBERGULAG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("remembergulag.webp"),
        subGame: getImg("remembergulag.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_FRUITS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fruits.webp"),
        subGame: getImg("fruits.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_DISTURBEDDX2",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("disturbeddx2.webp"),
        subGame: getImg("disturbeddx2.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_MONKEYSGOLDXPAYS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("monkeysgoldxpays.webp"),
        subGame: getImg("monkeysgoldxpays.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_POISONEVE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("poisoneve.webp"),
        subGame: getImg("poisoneve.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_TRACTORBEAM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tractorbeam.webp"),
        subGame: getImg("tractorbeam.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_MISERYMINING",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("miserymining.webp"),
        subGame: getImg("miserymining.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_OWLS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("owls.webp"),
        subGame: getImg("owls.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_EASTCOASTVSWESTCOAST",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("eastcoastvswestcoast.webp"),
        subGame: getImg("eastcoastvswestcoast.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_MILKYWAYS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("milkyways.webp"),
        subGame: getImg("milkyways.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_PEARLHARBOR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pearlharbor.webp"),
        subGame: getImg("pearlharbor.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_KITCHENDRAMABBQFRENZY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("kitchendramabbqfrenzy.webp"),
        subGame: getImg("kitchendramabbqfrenzy.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_GG&TWWD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldengenieandthewalkingwilds.webp"),
        subGame: getImg("goldengenieandthewalkingwilds.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_TOMBOFNEFERTITI",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tombofnefertiti.webp"),
        subGame: getImg("tombofnefertiti.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_LITTLEBIGHORN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("littlebighorn.webp"),
        subGame: getImg("littlebighorn.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_IMMORTALFRUITS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("immortalfruits.webp"),
        subGame: getImg("immortalfruits.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_BUFFALOHUNTER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("buffalohunter.webp"),
        subGame: getImg("buffalohunter.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_PUNKROCKER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("punkrocker.webp"),
        subGame: getImg("punkrocker.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_THORHAMMERTIME",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thorhammertime.webp"),
        subGame: getImg("thorhammertime.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_GLUTTONYDX4",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gluttonydx4.webp"),
        subGame: getImg("gluttonydx4.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_GLUTTONY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gluttony.webp"),
        subGame: getImg("gluttony.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_BONUSBUNNIES",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bonusbunnies.webp"),
        subGame: getImg("bonusbunnies.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_DRAGONTRIBE",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragontribe.webp"),
        subGame: getImg("dragontribe.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_DEADWOODXNUDGE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("deadwoodxnudge.webp"),
        subGame: getImg("deadwoodxnudge.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_HOT4CASH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hot4cash.webp"),
        subGame: getImg("hot4cash.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_ICEICEYETI",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("iceiceyeti.webp"),
        subGame: getImg("iceiceyeti.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_FIREINTHEHOLEXBOMB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fireintheholexbomb.webp"),
        subGame: getImg("fireintheholexbomb.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_WARRIORGRAVEYARDXNUDGE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("warriorgraveyardxnudge.webp"),
        subGame: getImg("warriorgraveyardxnudge.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_FOLSOMPRISON",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("folsomprison.webp"),
        subGame: getImg("folsomprison.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_ELPASOGUNFIGHTXNUDGE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("elpasogunfightxnudge.webp"),
        subGame: getImg("elpasogunfightxnudge.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_HOTNUDGE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hotnudge.webp"),
        subGame: getImg("hotnudge.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_KARENMANEATER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("karenmaneater.webp"),
        subGame: getImg("karenmaneater.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_DASXBOOT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dasxboot.webp"),
        subGame: getImg("dasxboot.webp"),
      },
    },
    {
      subCode: "GTAG_NOLIMITCITY_ASIA_NLCA_ROADRAGE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("roadrage.webp"),
        subGame: getImg("roadrage.webp"),
      },
    },
  ],
} as LIBRARY_GAME;
