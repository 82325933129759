import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('GOLDEN_F_JILI_EGAME', fileName)
};
const subGames = [
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_1",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_1_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_1_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_10",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_10_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_10_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_61",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_61_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_61_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_5",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_5_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_5_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_2",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_2_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_2_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_9",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_9_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_9_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_6",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_6_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_6_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_17",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_17_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_17_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_21",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_21_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_21_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_4",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_4_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_4_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_27",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_27_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_27_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_35",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_35_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_35_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_30",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_30_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_30_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_36",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_36_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_36_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_40",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_40_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_40_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_43",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_43_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_43_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_33",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_33_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_33_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_23",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_23_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_23_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_38",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_38_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_38_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_45",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_45_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_45_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_48",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_48_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_48_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_46",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_46_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_46_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_47",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_47_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_47_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_49",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_49_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_49_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_58",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_58_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_58_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_51",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_51_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_51_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_16",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_16_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_16_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_77",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_77_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_77_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_78",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_78_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_78_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_85",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_85_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_85_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_91",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_91_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_91_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_100",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_100_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_100_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_102",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_102_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_102_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_103",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_103_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_103_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_109",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_109_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_109_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_92",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_92_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_92_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_76",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_76_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_76_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_108",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_108_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_108_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_115",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_115_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_115_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_106",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_106_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_106_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_110",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_110_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_110_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_134",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_134_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_134_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_89",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_89_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_89_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_101",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_101_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_101_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_87",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_87_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_87_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_130",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_130_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_130_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_20",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_20_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_20_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_42",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_42_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_42_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_32",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_32_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_32_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_60",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_60_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_60_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_74",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_74_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_74_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_71",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_71_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_71_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_82",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_82_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_82_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_119",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_119_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_119_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_64",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_64_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_64_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_62",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_62_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_62_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_63",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_63_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_63_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_66",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_66_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_66_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_111",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_111_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_111_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_118",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_118_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_118_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_112",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_112_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_112_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_113",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_113_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_113_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_123",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_123_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_123_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_122",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_122_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_122_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_124",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_124_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_124_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_152",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_152_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_152_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_139",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_139_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_139_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_161",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_161_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_161_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_116",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_116_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_116_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_137",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_137_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_137_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_125",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_125_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_125_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_151",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_151_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_151_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_135",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_135_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_135_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_153",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_153_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_153_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_126",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_126_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_126_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_148",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_148_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_148_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_144",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_144_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_144_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_150",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_150_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_150_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_142",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_142_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_142_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_174",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_174_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_174_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_197",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_197_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_197_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_199",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_199_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_199_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_163",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_163_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_163_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_149",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_149_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_149_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_145",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_145_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_145_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_164",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_164_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_164_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_166",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_166_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_166_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_183",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_183_CN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_183_CN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_224",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_224_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_224_EN.webp"),
    },
  },
  {
    subCode: "GOLDEN_F_JILI_EGAME_JILI_229",
    thumb: {
      img: getImg("GOLDEN_F_JILI_EGAME_JILI_229_EN.webp"),
      subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_229_EN.webp"),
    },
  },
];
export default {
  code: "GOLDEN_F_JILI_EGAME",
  type: ["EGAMING"],
  play_method: "URL",
  subGames: [
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_1",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_1_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_1_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_10",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_10_CN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_10_CN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_61",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_61_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_61_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_5",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_5_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_5_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_2",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_2_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_2_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_9",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_9_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_9_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_6",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_6_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_6_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_17",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_17_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_17_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_21",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_21_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_21_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_4",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_4_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_4_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_27",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_27_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_27_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_35",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_35_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_35_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_30",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_30_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_30_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_36",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_36_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_36_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_40",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_40_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_40_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_43",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_43_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_43_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_33",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_33_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_33_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_23",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_23_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_23_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_38",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_38_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_38_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_45",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_45_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_45_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_48",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_48_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_48_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_46",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_46_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_46_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_47",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_47_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_47_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_49",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_49_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_49_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_58",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_58_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_58_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_51",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_51_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_51_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_16",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_16_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_16_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_77",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_77_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_77_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_78",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_78_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_78_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_85",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_85_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_85_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_91",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_91_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_91_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_100",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_100_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_100_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_102",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_102_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_102_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_103",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_103_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_103_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_109",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_109_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_109_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_92",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_92_CN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_92_CN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_76",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_76_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_76_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_108",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_108_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_108_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_115",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_115_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_115_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_106",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_106_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_106_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_110",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_110_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_110_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_134",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_134_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_134_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_89",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_89_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_89_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_101",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_101_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_101_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_87",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_87_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_87_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_130",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_130_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_130_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_20",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_20_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_20_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_42",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_42_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_42_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_32",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_32_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_32_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_60",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_60_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_60_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_74",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_74_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_74_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_71",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_71_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_71_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_82",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_82_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_82_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_119",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_119_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_119_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_64",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_64_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_64_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_62",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_62_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_62_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_63",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_63_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_63_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_66",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_66_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_66_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_111",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_111_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_111_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_118",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_118_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_118_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_112",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_112_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_112_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_113",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_113_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_113_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_123",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_123_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_123_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_122",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_122_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_122_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_124",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_124_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_124_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_152",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_152_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_152_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_139",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_139_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_139_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_161",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_161_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_161_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_116",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_116_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_116_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_137",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_137_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_137_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_125",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_125_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_125_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_151",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_151_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_151_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_135",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_135_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_135_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_153",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_153_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_153_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_126",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_126_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_126_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_148",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_148_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_148_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_144",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_144_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_144_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_150",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_150_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_150_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_142",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_142_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_142_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_174",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_174_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_174_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_197",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_197_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_197_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_199",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_199_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_199_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_163",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_163_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_163_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_149",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_149_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_149_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_145",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_145_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_145_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_164",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_164_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_164_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_166",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_166_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_166_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_183",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_183_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_183_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_224",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_224_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_224_EN.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_JILI_EGAME_JILI_229",
      thumb: {
        img: getImg("GOLDEN_F_JILI_EGAME_JILI_229_EN.webp"),
        subGame: getImg("GOLDEN_F_JILI_EGAME_JILI_229_EN.webp"),
      },
    },
  ],
  localeSetting: {
    zh_CN: { subGames },
    zh_TW: { subGames },
  },
} as LIBRARY_GAME;
