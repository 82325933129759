/**
 * 檢查用戶端登入的狀態
 * 確認 auth 過期時，會清空用戶資訊
 */
export default function isCilentLogined() {
  if (window.localStorage.getItem("loging")) {
    const token = window.localStorage.getItem("token");
    /** token存活時間 30分鐘 */
    const tokenLife = 30 * 60 * 1000;
    /** token誕生日 */
    const tokenBirth = Number(token);
    /** token死期 */
    const tokenEnd = tokenBirth + tokenLife;
    /** 現在時間 */
    const now = Date.now();
    if (tokenEnd < now) {
      window.localStorage.removeItem("x-authorisation");
      window.localStorage.removeItem("loging");
      window.localStorage.removeItem("token");
      return false;
    }
    return true;
  }
  return false;
}
