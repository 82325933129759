import { AxiosResponse } from "axios";
import $http from "../../../../FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "casinoPaymentFlow/front-auction/create";

export namespace typeCreate {
  export interface request {
    /** 通道方法 必填,參考 */
    p23_bmd_method: number;
    /** 金額 必填,數值,min:0.01 */
    p27_aon_amount: string;
  }
  export type AxiosResponse = _AxiosResponse;
  export type response = type_form;

  export type type_form = {
    code: "MyCash";
    result: {
      form_data: Record<string, string | number>;
      url: string;
    };
  };
}
type _AxiosResponse = AxiosResponse<responseData>;

interface responseData extends BackendResponse {
  response: typeCreate.response;
}

/**
 * 送出提領單
 * https://redmine.mirfak.tw/projects/pkg-270000-casinopaymentflow/repository/55/revisions/master/entry/_md/api/Front/Auction/%E6%96%B0%E5%A2%9E.md
 */
export default async (request: typeCreate.request) => {
  const getter = await $http.post(API, preformater(request));
  if (getter.data.code !== 0) throw getter;
  return getter;
};

function preformater(req: typeCreate.request): typeCreate.request {
  return {
    p23_bmd_method: req.p23_bmd_method,
    p27_aon_amount: req.p27_aon_amount,
  };
}
