/* eslint-disable no-param-reassign */
import List, { typeLists } from "../service/detail/lists";
import Info, { typeInfo } from "../service/detail/info";
import { typeLists as typeGame } from "../service/casinoGames/lists";
import { setNamePath } from "../library/GAME/index";

// TODO: 調閱遊戲名稱、ID、namePath 做參考
// TODO: 輸出遊戲名稱列表ID做搜尋
// TODO: merging account data
// TODO: 搜尋相關
// TODO: PAGINATOR 相關

type state = {
  /** raw Data */
  rawData: typeLists.response;
  betDetail: null | typeInfo.response;
};

const state: state = {
  betDetail: null,
  rawData: {
    account: [],
    list: [],
    paginator: {
      sort_type: "ASC",
      count: 0,
      page: 0,
      page_num: 0,
      perpage: 0,
    },
  },
};

export default {
  namespaced: true,
  state,
  mutations: {
    setList: (st: state, data: typeLists.response) => {
      st.rawData = data;
    },
    setInfo: (st: state, data: typeInfo.response) => {
      st.betDetail = data;
    },
    clean: (st: state) => {
      st.rawData = {
        account: [],
        list: [],
        paginator: {
          sort_type: "ASC",
          count: 0,
          page: 0,
          page_num: 0,
          perpage: 0,
        },
      };
    },
  },
  actions: {
    async getList({ commit }: any, params?: typeLists.request) {
      const res: typeLists.response = await (await List(params)).data.response;
      commit("setList", res);
    },
    async getInfo({ commit }: any, id: string) {
      const params: typeInfo.request = {
        p28_glr_vendor_id: id,
      };
      const res: typeLists.response = await (await Info(params)).data.response;
      commit("setInfo", res);
    },
  },
  getters: {
    list(st: state, ARG: any, root: any) {
      const GAMES: typeGame.listItem[] = root.game.rawData;
      const ACC = st.rawData.account;

      return st.rawData.list.map(mergeAcc(ACC)).map(mergeGame(GAMES));
    },
    betDetail(st: state) {
      return st.betDetail;
    },
  },
};

/** 加入遊戲資訊與名稱 */
const mergeGame = (games: typeGame.listItem[]) => (
  report: typeLists.listItem,
) => {
  const CUR_GAME = games.find(G => G.p26_ges_id === report.p28_glr_p26_ges_id);
  if (CUR_GAME)
    return { ...report, ...CUR_GAME, name: setNamePath(CUR_GAME.p26_ges_code) };
  return report;
};

/** 加入會員資訊 */
const mergeAcc = (ACC: typeLists.account[]) => (report: typeLists.listItem) => {
  const CUR_ACC = ACC.find(A => A.p3_ant_id === report.p28_glr_p3_ant_id);
  if (CUR_ACC) return { ...report, ...CUR_ACC };
  return report;
};
