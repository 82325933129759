/* eslint-disable no-param-reassign */
import List, { typeLists } from "../service/ledger/lists";
import { typeLists as typeGame } from "../service/casinoGames/lists";
import { setNamePath } from "../library/GAME/index";
import day from "dayjs";
// TODO: 調閱遊戲名稱、ID、namePath 做參考
// TODO: 輸出遊戲名稱列表ID做搜尋
// TODO: merging account data
// TODO: 搜尋相關
// TODO: PAGINATOR 相關

type state = {
  /** raw Data */
  rawData: typeLists.response;
};

const state: state = {
  rawData: {
    account: [],
    list: [],
    paginator: {
      sort_type: "ASC",
      count: 0,
      page: 0,
      page_num: 0,
      perpage: 0,
    },
  },
};

export default {
  namespaced: true,
  state,
  mutations: {
    setList: (st: state, data: typeLists.response) => {
      st.rawData = data;
    },
    clean: (st: state) => {
      st.rawData = {
        account: [],
        list: [],
        paginator: {
          sort_type: "ASC",
          count: 0,
          page: 0,
          page_num: 0,
          perpage: 0,
        },
      };
    },
  },
  actions: {
    async getList({ commit }: any, params?: typeLists.request) {
      const req = requestFormater(params as any);
      const res: typeLists.response = await (await List(req)).data.response;
      commit("setList", res);
    },
  },
  getters: {
    list(st: state, ARG: any, root: any) {
      const GAMES: typeGame.listItem[] = root.game.rawData;
      const ACC = st.rawData.account;

      return st.rawData.list.map(mergeAcc(ACC)).map(mergeGame(GAMES));
    },
  },
};

/** 加入遊戲資訊與名稱 */
const mergeGame = (games: typeGame.listItem[]) => (
  report: typeLists.listItem,
) => {
  const CUR_GAME = games.find(
    G => G.p26_ges_id === report.p28_glcbte_p26_ges_id,
  );
  if (CUR_GAME)
    return { ...report, ...CUR_GAME, name: setNamePath(CUR_GAME.p26_ges_code) };
  return report;
};

/** 加入會員資訊 */
const mergeAcc = (ACC: typeLists.account[]) => (report: typeLists.listItem) => {
  const CUR_ACC = ACC.find(A => A.p3_ant_id === report.p28_glcbte_layer_ant_id);
  if (CUR_ACC) return { ...report, ...CUR_ACC };
  return report;
};

const requestFormater = (req?: {
  search_start_time: number;
  search_end_time: number;
}) => {
  const start = req?.search_start_time;
  const end = req?.search_end_time;
  const search_start_time = start
    ? day(start * 1000).format("YYYY-MM-DD 00:00:00")
    : undefined;
  const search_end_time = end
    ? day(end * 1000).format("YYYY-MM-DD 23:59:59")
    : undefined;

  return { ...req, search_start_time, search_end_time };
};
