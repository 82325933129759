/**
 * 設定 HTML META 標籤
 * @param name meta標籤的name
 * @param content meta標籤的內文
 */
function setMeta(name: string, content: string) {
  const target = document.querySelector(`meta[name=${name}]`);
  if (target) target.setAttribute("content", content);
}

export default setMeta;
