import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('U151', fileName)
};
export default {
  code: "U151_LOTTERY",
  type: ["LOTTERY"],
  play_method: "URL",
  thumb: { img: getImg("img.png") },
} as LIBRARY_GAME;
