import { AxiosResponse } from "axios";
import $http from "../../../FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "promotion/member-promotion/create";

export namespace typeCreate {
  export interface request {
    p47_pon_id: number;
  }
  export type AxiosResponse = _AxiosResponse;
}
type _AxiosResponse = AxiosResponse<responseData>;

interface responseData extends BackendResponse {
  response: any;
}

/** 優惠申請 */
export default async (request: typeCreate.request) => {
  const getter = await $http.post(API, request);
  if (getter.data.code !== 0) throw getter;
  return getter;
};
