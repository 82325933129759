import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('GR', fileName)
};
const getLogo = () => {
  return getGameImg('GR_CHESS', 'logo.png')
};
export default {
  code: "GR_CHESS",
  type: ["CHESS"],
  play_method: "URL",
  thumb: {
    img: getLogo(),
  },
  subGames: [
    {
      subCode: "GR_CHESS_101",
      thumb: {
        img: getImg("101.png"),
        subGame: getImg("101.png"),
      },
    },
    {
      subCode: "GR_CHESS_102",
      thumb: {
        img: getImg("102.png"),
        subGame: getImg("102.png"),
      },
    },
    {
      subCode: "GR_CHESS_103",
      thumb: {
        img: getImg("103.png"),
        subGame: getImg("103.png"),
      },
    },
    {
      subCode: "GR_CHESS_104",
      thumb: {
        img: getImg("104.png"),
        subGame: getImg("104.png"),
      },
    },
    {
      subCode: "GR_CHESS_105",
      thumb: {
        img: getImg("105.png"),
        subGame: getImg("105.png"),
      },
    },
    {
      subCode: "GR_CHESS_106",
      thumb: {
        img: getImg("106.png"),
        subGame: getImg("106.png"),
      },
    },
    {
      subCode: "GR_CHESS_107",
      thumb: {
        img: getImg("107.png"),
        subGame: getImg("107.png"),
      },
    },
    {
      subCode: "GR_CHESS_108",
      thumb: {
        img: getImg("108.png"),
        subGame: getImg("108.png"),
      },
    },
    {
      subCode: "GR_CHESS_109",
      thumb: {
        img: getImg("109.png"),
        subGame: getImg("109.png"),
      },
    },
    {
      subCode: "GR_CHESS_110",
      thumb: {
        img: getImg("110.png"),
        subGame: getImg("110.png"),
      },
    },
    {
      subCode: "GR_CHESS_111",
      thumb: {
        img: getImg("111.png"),
        subGame: getImg("111.png"),
      },
    },
    {
      subCode: "GR_CHESS_112",
      thumb: {
        img: getImg("112.png"),
        subGame: getImg("112.png"),
      },
    },
    {
      subCode: "GR_CHESS_113",
      thumb: {
        img: getImg("113.png"),
        subGame: getImg("113.png"),
      },
    },
    {
      subCode: "GR_CHESS_114",
      thumb: {
        img: getImg("114.png"),
        subGame: getImg("114.png"),
      },
    },
    {
      subCode: "GR_CHESS_115",
      thumb: {
        img: getImg("115.png"),
        subGame: getImg("115.png"),
      },
    },
    {
      subCode: "GR_CHESS_116",
      thumb: {
        img: getImg("116.png"),
        subGame: getImg("116.png"),
      },
    },
    {
      subCode: "GR_CHESS_119",
      thumb: {
        img: getImg("119.png"),
        subGame: getImg("119.png"),
      },
    },
    {
      subCode: "GR_CHESS_120",
      thumb: {
        img: getImg("120.png"),
        subGame: getImg("120.png"),
      },
    },
    {
      subCode: "GR_CHESS_121",
      thumb: {
        img: getImg("121.png"),
        subGame: getImg("121.png"),
      },
    },
    {
      subCode: "GR_CHESS_122",
      thumb: {
        img: getImg("122.png"),
        subGame: getImg("122.png"),
      },
    },
    {
      subCode: "GR_CHESS_124",
      thumb: {
        img: getImg("124.png"),
        subGame: getImg("124.png"),
      },
    },
    {
      subCode: "GR_CHESS_125",
      thumb: {
        img: getImg("125.png"),
        subGame: getImg("125.png"),
      },
    },
    {
      subCode: "GR_CHESS_126",
      thumb: {
        img: getImg("126.png"),
        subGame: getImg("126.png"),
      },
    },
    {
      subCode: "GR_CHESS_301",
      thumb: {
        img: getImg("301.png"),
        subGame: getImg("301.png"),
      },
    },
    {
      subCode: "GR_CHESS_302",
      thumb: {
        img: getImg("302.png"),
        subGame: getImg("302.png"),
      },
    },
    {
      subCode: "GR_CHESS_303",
      thumb: {
        img: getImg("303.png"),
        subGame: getImg("303.png"),
      },
    },
    {
      subCode: "GR_CHESS_304",
      thumb: {
        img: getImg("304.png"),
        subGame: getImg("304.png"),
      },
    },
    {
      subCode: "GR_CHESS_305",
      thumb: {
        img: getImg("305.png"),
        subGame: getImg("305.png"),
      },
    },
    {
      subCode: "GR_CHESS_306",
      thumb: {
        img: getImg("306.png"),
        subGame: getImg("306.png"),
      },
    },
    {
      subCode: "GR_CHESS_307",
      thumb: {
        img: getImg("307.png"),
        subGame: getImg("307.png"),
      },
    },
    {
      subCode: "GR_CHESS_308",
      thumb: {
        img: getImg("308.png"),
        subGame: getImg("308.png"),
      },
    },
    {
      subCode: "GR_CHESS_309",
      thumb: {
        img: getImg("309.png"),
        subGame: getImg("309.png"),
      },
    },
    {
      subCode: "GR_CHESS_310",
      thumb: {
        img: getImg("310.png"),
        subGame: getImg("310.png"),
      },
    },
    {
      subCode: "GR_CHESS_311",
      thumb: {
        img: getImg("311.png"),
        subGame: getImg("311.png"),
      },
    },
    {
      subCode: "GR_CHESS_312",
      thumb: {
        img: getImg("312.png"),
        subGame: getImg("312.png"),
      },
    },
    {
      subCode: "GR_CHESS_313",
      thumb: {
        img: getImg("313.png"),
        subGame: getImg("313.png"),
      },
    },
    {
      subCode: "GR_CHESS_314",
      thumb: {
        img: getImg("314.png"),
        subGame: getImg("314.png"),
      },
    },
    {
      subCode: "GR_CHESS_501",
      thumb: {
        img: getImg("501.png"),
        subGame: getImg("501.png"),
      },
    },
    {
      subCode: "GR_CHESS_502",
      thumb: {
        img: getImg("502.png"),
        subGame: getImg("502.png"),
      },
    },
    {
      subCode: "GR_CHESS_10001",
      thumb: {
        img: getImg("10001.png"),
        subGame: getImg("10001.png"),
      },
    },
    {
      subCode: "GR_CHESS_10002",
      thumb: {
        img: getImg("10002.png"),
        subGame: getImg("10002.png"),
      },
    },
    {
      subCode: "GR_CHESS_10003",
      thumb: {
        img: getImg("10003.png"),
        subGame: getImg("10003.png"),
      },
    },
    {
      subCode: "GR_CHESS_10004",
      thumb: {
        img: getImg("10004.png"),
        subGame: getImg("10004.png"),
      },
    },
    {
      subCode: "GR_CHESS_10005",
      thumb: {
        img: getImg("10005.png"),
        subGame: getImg("10005.png"),
      },
    },
    {
      subCode: "GR_CHESS_10006",
      thumb: {
        img: getImg("10006.png"),
        subGame: getImg("10006.png"),
      },
    },
    {
      subCode: "GR_CHESS_10007",
      thumb: {
        img: getImg("10007.png"),
        subGame: getImg("10007.png"),
      },
    },
    {
      subCode: "GR_CHESS_10008",
      thumb: {
        img: getImg("10008.png"),
        subGame: getImg("10008.png"),
      },
    },
    {
      subCode: "GR_CHESS_10010",
      thumb: {
        img: getImg("10010.png"),
        subGame: getImg("10010.png"),
      },
    },
    {
      subCode: "GR_CHESS_10012",
      thumb: {
        img: getImg("10012.png"),
        subGame: getImg("10012.png"),
      },
    },
    {
      subCode: "GR_CHESS_10013",
      thumb: {
        img: getImg("10013.png"),
        subGame: getImg("10013.png"),
      },
    },
    {
      subCode: "GR_CHESS_10014",
      thumb: {
        img: getImg("10014.png"),
        subGame: getImg("10014.png"),
      },
    },
    {
      subCode: "GR_CHESS_10015",
      thumb: {
        img: getImg("10015.png"),
        subGame: getImg("10015.png"),
      },
    },
    {
      subCode: "GR_CHESS_10016",
      thumb: {
        img: getImg("10016.png"),
        subGame: getImg("10016.png"),
      },
    },
    {
      subCode: "GR_CHESS_10017",
      thumb: {
        img: getImg("10017.png"),
        subGame: getImg("10017.png"),
      },
    },
    {
      subCode: "GR_CHESS_10018",
      thumb: {
        img: getImg("10018.png"),
        subGame: getImg("10018.png"),
      },
    },
    {
      subCode: "GR_CHESS_20002",
      thumb: {
        img: getImg("20002.png"),
        subGame: getImg("20002.png"),
      },
    },
  ],
} as LIBRARY_GAME;
