import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('BE_ELECTRONIC', fileName)
};
export default {
  code: "BE_ELECTRONIC",
  type: ["EGAMING"],
  play_method: "URL",
  subGames: [
    {
      subCode: "BE_ELECTRONIC_GB5001",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5001.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5001.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5003",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5003.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5003.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5006",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5006.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5006.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5010",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5010.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5010.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5011",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5011.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5011.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5019",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5019.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5019.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5028",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5028.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5028.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5035",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5035.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5035.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5015",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5015.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5015.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5023",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5023.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5023.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5036",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5036.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5036.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_TA1",
      thumb: {
        img: getImg("BE_ELECTRONIC_TA1.png"),
        subGame: getImg("BE_ELECTRONIC_TA1.png"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_TA2",
      thumb: {
        img: getImg("BE_ELECTRONIC_TA2.jpg"),
        subGame: getImg("BE_ELECTRONIC_TA2.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_TA3",
      thumb: {
        img: getImg("BE_ELECTRONIC_TA3.jpg"),
        subGame: getImg("BE_ELECTRONIC_TA3.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_TA4",
      thumb: {
        img: getImg("BE_ELECTRONIC_TA4.jpg"),
        subGame: getImg("BE_ELECTRONIC_TA4.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_TA5",
      thumb: {
        img: getImg("BE_ELECTRONIC_TA5.jpg"),
        subGame: getImg("BE_ELECTRONIC_TA5.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_TA6",
      thumb: {
        img: getImg("BE_ELECTRONIC_TA6.jpg"),
        subGame: getImg("BE_ELECTRONIC_TA6.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_TA7",
      thumb: {
        img: getImg("BE_ELECTRONIC_TA7.jpg"),
        subGame: getImg("BE_ELECTRONIC_TA7.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_TA8",
      thumb: {
        img: getImg("BE_ELECTRONIC_TA8.jpg"),
        subGame: getImg("BE_ELECTRONIC_TA8.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_TA9",
      thumb: {
        img: getImg("BE_ELECTRONIC_TA9.jpg"),
        subGame: getImg("BE_ELECTRONIC_TA9.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_TA10",
      thumb: {
        img: getImg("BE_ELECTRONIC_TA10.jpg"),
        subGame: getImg("BE_ELECTRONIC_TA10.jpg"),
      },
    },
    // {
    //   subCode: "BE_ELECTRONIC_SBK01",
    //   thumb: {
    //
    //       img: getImg("BE_ELECTRONIC_SBK01.jpg"),
    //       subGame: getImg("BE_ELECTRONIC_SBK01.jpg"),

    //   },
    // },
    {
      subCode: "BE_ELECTRONIC_GB5038",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5038.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5038.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_TA11",
      thumb: {
        img: getImg("BE_ELECTRONIC_TA11.jpg"),
        subGame: getImg("BE_ELECTRONIC_TA11.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_TA12",
      thumb: {
        img: getImg("BE_ELECTRONIC_TA12.jpg"),
        subGame: getImg("BE_ELECTRONIC_TA12.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_TA13",
      thumb: {
        img: getImg("BE_ELECTRONIC_TA13.jpg"),
        subGame: getImg("BE_ELECTRONIC_TA13.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5002",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5002.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5002.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5005",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5005.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5005.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5012",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5012.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5012.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5014",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5014.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5014.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5017",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5017.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5017.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5018",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5018.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5018.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5022",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5022.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5022.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5031",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5031.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5031.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_GB5032",
      thumb: {
        img: getImg("BE_ELECTRONIC_GB5032.jpg"),
        subGame: getImg("BE_ELECTRONIC_GB5032.jpg"),
      },
    },
    {
      subCode: "BE_ELECTRONIC_TB1",
      thumb: {
        img: getImg("BE_ELECTRONIC_TB1.jpg"),
        subGame: getImg("BE_ELECTRONIC_TB1.jpg"),
      },
    },
    // {
    //   subCode: "BE_ELECTRONIC_GINKGO01",
    //   thumb: {
    //
    //       img: getImg("BE_ELECTRONIC_GINKGO01.jpg"),
    //       subGame: getImg("BE_ELECTRONIC_GINKGO01.jpg"),

    //   },
    // },
  ],
} as LIBRARY_GAME;
