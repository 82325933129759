import { AxiosResponse } from "axios";
import $http from "@/modules/FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "casinoReward/member/lists";
// https://redmine.mirfak.tw/projects/pkg-320000-casinoreward/repository/71/revisions/master/entry/_md/api/Member%20%E6%9C%83%E5%93%A1%E8%BF%94%E6%B0%B4%E7%B4%80%E9%8C%84/%E5%88%97%E8%A1%A8.md

export namespace typeLists {
  export type request = pageinator & {
    /** 發放日期 */
    search_p32_crgrd_completed_datetime?: string;
  };

  export type response = {
    list: listItem[];
    paginator: pageinator;
  };

  export type listItem = {
    /** ID  */
    p32_crgrd_id: number;
    /** 建立時間 unixtime */
    p32_crgrd_created_at: number;
    /** 計算時間區間(起) unixtime */
    p32_crgrd_completed_time_range_start: number;
    /** 計算時間區間(迄) unixtime */
    p32_crgrd_completed_time_range_end: number;
    /** 計算日期 unixtime(format:yyyy-mm-dd) */
    p32_crgrd_completed_datetime: number;
    /** 返水點數 小數下四位,null */
    p32_crgrd_grant_point: number;
    /** 是否發放 y.是 n.未發 */
    p32_crgrd_is_grant: number;
    /** 發放時間 unixtime */
    p32_crgrd_grant_time: number;
    /** 發放狀態 1.未發 2.已發 3.補發 4.不發 */
    p32_crgrd_grant_status: number;
    /** 操作者帳號ID null為系統操作 */
    p32_crgrd_operator_p3_ant_id: number;
    /** 會員ID  */
    p32_crgrd_member_p3_ant_id: number;
    /** 會員的階層1ID  */
    p32_crgrd_p16_lbc_layer_1: number;
    /** 會員的階層2ID  */
    p32_crgrd_p16_lbc_layer_2: number;
    /** 會員的階層3ID  */
    p32_crgrd_p16_lbc_layer_3: number;
    /** 會員的階層4ID  */
    p32_crgrd_p16_lbc_layer_4: number;
    /** 會員的階層5ID  */
    p32_crgrd_p16_lbc_layer_5: number;
    /** .. 會員的階層...ID 依此類推 */
    p32_crgrd_p16_lbc_layer_: number;
    /** 更新時間 unixtime */
    p32_crgrd_updated_at: number;
    /** 遊戲ID  */
    p32_crgrd_p26_ges_id: number;
    /** 遊戲資訊 */
    game: {
      /** 遊戲代碼 */
      p26_ges_code: string;
    };
    /** 帳號資訊 */
    account: {};
  };

  export interface AxiosResponse extends _AxiosResponse {}
}
interface _AxiosResponse extends AxiosResponse<responseData> {}

interface responseData extends BackendResponse {
  response: any;
}

/** 明細 */
export default async (params?: typeLists.request) => {
  const getter = await $http.get(API, { params });
  if (getter.data.code !== 0) throw getter;
  return getter;
};
