import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('ZG', fileName)
};
export default {
  code: "ZG_ELECTRONIC",
  type: ["EGAMING"],
  play_method: "URL",
  thumb: { img: getImg("ZEBRA.png") },
  subGames: [
    {
      subCode: "ZG_ELECTRONIC_1001",
      type: ["EGAMING"],
      // type: ["FISHING"],
      thumb: {
        img: getImg("1001_hk_web_200.png"),
        subGame: getImg("1001_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_1002",
      type: ["EGAMING"],
      // type: ["FISHING"],
      thumb: {
        img: getImg("1002_hk_web_200.png"),
        subGame: getImg("1002_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_1003",
      type: ["EGAMING"],
      // type: ["FISHING"],
      thumb: {
        img: getImg("1003_hk_web_200.png"),
        subGame: getImg("1003_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_1004",
      type: ["EGAMING"],
      // type: ["FISHING"],
      thumb: {
        img: getImg("1004_hk_web_200.png"),
        subGame: getImg("1004_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_1006",
      type: ["EGAMING"],
      // type: ["FISHING"],
      thumb: {
        img: getImg("1006_hk_web_200.png"),
        subGame: getImg("1006_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_1007",
      type: ["EGAMING"],
      // type: ["FISHING"],
      thumb: {
        img: getImg("1007_hk_web_200.png"),
        subGame: getImg("1007_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_1009",
      type: ["EGAMING"],
      // type: ["FISHING"],
      thumb: {
        img: getImg("1009_hk_web_200.png"),
        subGame: getImg("1009_hk_web_200.png"),
      },
    },

    {
      subCode: "ZG_ELECTRONIC_1001",
      type: ["EGAMING"],
      thumb: {
        img: getImg("1001_hk_web_200.png"),
        subGame: getImg("1001_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_1002",
      type: ["EGAMING"],
      thumb: {
        img: getImg("1002_hk_web_200.png"),
        subGame: getImg("1002_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_1003",
      type: ["EGAMING"],
      thumb: {
        img: getImg("1003_hk_web_200.png"),
        subGame: getImg("1003_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_1004",
      type: ["EGAMING"],
      thumb: {
        img: getImg("1004_hk_web_200.png"),
        subGame: getImg("1004_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_1006",
      type: ["EGAMING"],
      thumb: {
        img: getImg("1006_hk_web_200.png"),
        subGame: getImg("1006_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_1007",
      type: ["EGAMING"],
      thumb: {
        img: getImg("1007_hk_web_200.png"),
        subGame: getImg("1007_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_1009",
      type: ["EGAMING"],
      thumb: {
        img: getImg("1009_hk_web_200.png"),
        subGame: getImg("1009_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3001",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3001_hk_web_200.png"),
        subGame: getImg("3001_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3002",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3002_hk_web_200.png"),
        subGame: getImg("3002_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3003",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3003_hk_web_200.png"),
        subGame: getImg("3003_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3004",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3004_hk_web_200.png"),
        subGame: getImg("3004_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3005",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3005_hk_web_200.png"),
        subGame: getImg("3005_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3006",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3006_hk_web_200.png"),
        subGame: getImg("3006_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3007",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3007_hk_web_200.png"),
        subGame: getImg("3007_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3008",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3008_hk_web_200.png"),
        subGame: getImg("3008_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3009",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3009_hk_web_200.png"),
        subGame: getImg("3009_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3010",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3010_hk_web_200.png"),
        subGame: getImg("3010_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3011",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3011_hk_web_200.png"),
        subGame: getImg("3011_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3012",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3012_hk_web_200.png"),
        subGame: getImg("3012_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3013",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3013_hk_web_200.png"),
        subGame: getImg("3013_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3014",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3014_hk_web_200.png"),
        subGame: getImg("3014_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3015",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3015_hk_web_200.png"),
        subGame: getImg("3015_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3016",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3016_hk_web_200.png"),
        subGame: getImg("3016_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3017",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3017_hk_web_200.png"),
        subGame: getImg("3017_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3018",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3018_hk_web_200.png"),
        subGame: getImg("3018_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3019",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3019_hk_web_200.png"),
        subGame: getImg("3019_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3020",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3020_hk_web_200.png"),
        subGame: getImg("3020_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3021",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3021_hk_web_200.png"),
        subGame: getImg("3021_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3022",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3022_hk_web_200.png"),
        subGame: getImg("3022_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3023",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3023_hk_web_200.png"),
        subGame: getImg("3023_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3024",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3024_hk_web_200.png"),
        subGame: getImg("3024_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3025",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3025_hk_web_200.png"),
        subGame: getImg("3025_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3026",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3026_hk_web_200.png"),
        subGame: getImg("3026_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3027",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3027_hk_web_200.png"),
        subGame: getImg("3027_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3028",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3028_hk_web_200.png"),
        subGame: getImg("3028_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3029",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3029_hk_web_200.png"),
        subGame: getImg("3029_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3030",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3030_hk_web_200.png"),
        subGame: getImg("3030_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3031",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3031_hk_web_200.png"),
        subGame: getImg("3031_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3032",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3032_hk_web_200.png"),
        subGame: getImg("3032_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3033",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3033_hk_web_200.png"),
        subGame: getImg("3033_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3034",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3034_hk_web_200.png"),
        subGame: getImg("3034_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3035",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3035_hk_web_200.png"),
        subGame: getImg("3035_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3036",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3036_hk_web_200.png"),
        subGame: getImg("3036_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3037",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3037_hk_web_200.png"),
        subGame: getImg("3037_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3038",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3038_hk_web_200.png"),
        subGame: getImg("3038_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3039",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3039_hk_web_200.png"),
        subGame: getImg("3039_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3040",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3040_hk_web_200.png"),
        subGame: getImg("3040_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3041",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3041_hk_web_200.png"),
        subGame: getImg("3041_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3042",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3042_hk_web_200.png"),
        subGame: getImg("3042_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3043",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3043_hk_web_200.png"),
        subGame: getImg("3043_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3044",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3044_hk_web_200.png"),
        subGame: getImg("3044_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3045",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3045_hk_web_200.png"),
        subGame: getImg("3045_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3046",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3046_hk_web_200.png"),
        subGame: getImg("3046_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3047",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3047_hk_web_200.png"),
        subGame: getImg("3047_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3048",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3048_hk_web_200.png"),
        subGame: getImg("3048_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3049",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3049_hk_web_200.png"),
        subGame: getImg("3049_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3050",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3050_hk_web_200.png"),
        subGame: getImg("3050_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3051",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3051_hk_web_200.png"),
        subGame: getImg("3051_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3052",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3052_hk_web_200.png"),
        subGame: getImg("3052_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3054",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3054_hk_web_200.png"),
        subGame: getImg("3054_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3055",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3055_hk_web_200.png"),
        subGame: getImg("3055_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3056",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3056_hk_web_200.png"),
        subGame: getImg("3056_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3057",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3057_hk_web_200.png"),
        subGame: getImg("3057_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3058",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3058_hk_web_200.png"),
        subGame: getImg("3058_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3059",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3059_hk_web_200.png"),
        subGame: getImg("3059_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3060",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3060_hk_web_200.png"),
        subGame: getImg("3060_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3061",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3061_hk_web_200.png"),
        subGame: getImg("3061_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_3062",
      type: ["EGAMING"],
      thumb: {
        img: getImg("3062_hk_web_200.png"),
        subGame: getImg("3062_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_4001",
      type: ["EGAMING"],
      thumb: {
        img: getImg("4001_hk_web_200.png"),
        subGame: getImg("4001_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_4002",
      type: ["EGAMING"],
      thumb: {
        img: getImg("4002_hk_web_200.png"),
        subGame: getImg("4002_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_4003",
      type: ["EGAMING"],
      thumb: {
        img: getImg("4003_hk_web_200.png"),
        subGame: getImg("4003_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_4004",
      type: ["EGAMING"],
      thumb: {
        img: getImg("4004_hk_web_200.png"),
        subGame: getImg("4004_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_4005",
      type: ["EGAMING"],
      thumb: {
        img: getImg("4005_hk_web_200.png"),
        subGame: getImg("4005_hk_web_200.png"),
      },
    },
    {
      subCode: "ZG_ELECTRONIC_4009",
      type: ["EGAMING"],
      thumb: {
        img: getImg("4009_hk_web_200.png"),
        subGame: getImg("4009_hk_web_200.png"),
      },
    },
  ],
} as LIBRARY_GAME;
