/* eslint-disable no-param-reassign */

import Lists, { typeLists } from "../../service/promotion/lists";
import Applicant from "../../service/promotion/create";

import FilesFormater from "@/modules/FPKG-220000-CasinoCore/converter/responseFileFormater";

function fileFormater(file: p7_file) {
  if (!file) return null;
  const item = new FilesFormater(file).renderImage;
  return item;
}

type state = {
  /** 優惠列表 */
  promotion: typeLists.response;
};

const state: state = {
  promotion: [],
};

export default {
  namespaced: true,
  state,
  mutations: {
    setList: (st: state, data: typeLists.response) => {
      st.promotion = data.map(o => {
        return {
          ...o,
          title_file_f: fileFormater(o.title_file),
          contentfile_f: fileFormater(o.contentfile),
        };
      });
    },
    cleanList(st: state) {
      st.promotion = [];
    },
  },
  actions: {
    async getList({ commit }: any, request: typeLists.request) {
      const response = await (await Lists(request)).data.response;
      commit("setList", response);
    },
    async applicant({ commit }: any, p47_pon_id: number) {
      await (await Applicant({ p47_pon_id })).data.response;
    },
  },
  getters: {
    list(st: state) {
      return st.promotion;
    },
  },
};
