/* eslint-disable @typescript-eslint/no-var-requires */
import SET_AUTO from "@/modules/FPKG-20000-Util/library/AUTO_COMPONENTS";
import { getGameImg } from '@/modules/FPKG-260000-CasinoFrontGames/util/gameImg'
import BRANCH from '@branch';  
const THEME_NAME = BRANCH.p8_theme; 
/** 本地端預設遊戲庫 */

const LOCAL_GAMES =SET_AUTO(import.meta.glob('./library/*.ts', {eager:true}));
/** 取得主題名稱 */

const getSampleImg = () => {
  return getGameImg('','sample.webp')
};
const img = getSampleImg();

type arg = { code: string; subCode?: string };
type MERGED_SUB_GAME = LIBRARY_GAME & SUB_GAME;

/**
 * 依遊戲內容建立遊戲資訊
 * 在此階段會引入 通用 與 客製 的本地端遊戲設定
 * 會引入遊戲資訊、再覆蓋子遊戲資訊，而通用與客製設定各進行一次
 */
export default class CreateGame {
  private code = "";
  private subCode: string | undefined;
  private GAME: arg

  /** 輸出結果，預設為初始值 */
  public result:
    | LIBRARY_GAME
    | (MERGED_SUB_GAME & { name: string; subName?: string }) = {
    code: "",
    type: ["EGAMING"],
    play_method: "URL",
    thumb: { img },
  };

  constructor(GAME: arg) {
    this.code = GAME.code;
    this.subCode = GAME.subCode;
    this.GAME = GAME
  }

  async initGame() {
    const lib =await this.getLib(this.GAME); // 取得預設與客制遊戲庫設定
    const mege = this.mergeLib(lib); // 合併設定
    return  {  
      name: setNamePath(this.GAME.code),
      subName: setSubNamePath(this.code, this.subCode),
      ...this.result,
      ...mege,
      code: this.code,
      subCode: this.subCode,
    };
  }

  /**
   * 取得遊戲庫設定
   * 調出該遊戲於系統、主題、客戶的設定
   * @param GAME code
   * @returns LIBRARY_GAME[]
   */
  private async getLib(GAME: arg): LIBRARY_GAME[] {
    const LOCAL = ((LOCAL_GAMES[GAME.code] as unknown) || {}) as LIBRARY_GAME; // 系統基本設定
    /** 主題基本與客制  */
    const THEME_DEFAULT =await GET_THEME_GAME("default"); // 'default' 為基本設定保留名
    const THEME_GAME =await GET_THEME_GAME(this.code);

    /** 客戶基本與客制 */
    const CUST_DEFAULT = GET_CFPKG_GAME("default"); // default' 為基本設定保留名
    const CUST_GAME = GET_CFPKG_GAME(this.code);

    return [LOCAL, THEME_DEFAULT, THEME_GAME, CUST_DEFAULT, CUST_GAME]
      .filter(g => g.code)
      .map(customizeGameWithLocale);
  }

  /** 合併遊戲 */
  private mergeLib(lib: LIBRARY_GAME[]) {
    return lib.reduce((cur, nex) => {
      /** 子遊戲合併 */
      if (this.subCode) {
        const OVERWRITE = GET_SUB_GAME(this.subCode, nex);
        return {
          ...cur,
          ...OVERWRITE,
          thumb: { ...cur.thumb, ...OVERWRITE.thumb },
          typeSetting: mergingTypes(cur, OVERWRITE),
        };
      }

      /** 一般合併 */
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { subGames, ...OVERWRITE } = nex;
      return {
        ...cur,
        ...OVERWRITE,
        thumb: {
          ...cur.thumb,
          ...OVERWRITE.thumb,
        },
        typeSetting: mergingTypes(cur, OVERWRITE),
      };
    }, {} as LIBRARY_GAME);
  }
}

export const setNamePath = (code: string) => `CasinoFrontGames.${code}.name`;

const setSubNamePath = (code: string, subCode?: string) => {
  if (!subCode) return undefined;
  return `CasinoFrontGames.${code}.subGames.${subCode}`;
};

/**
 * 取得子遊戲，並且合併主遊戲資訊
 * @param subCode string
 * @param game LIBRARY_GAME
 * @returns LIBRARY_GAME
 */
function GET_SUB_GAME(
  subCode: string,
  game: LIBRARY_GAME,
): LIBRARY_GAME | MERGED_SUB_GAME {
  const { subGames, ...MAIN_GAME } = game;

  if (subCode && Array.isArray(subGames) && subGames.length) {
    const SUB_GAME = subGames.find(G => G.subCode === subCode);
    if (!SUB_GAME) return game;

    return {
      ...MAIN_GAME,
      ...SUB_GAME,
      /** 圖檔合併 */
      thumb: {
        img,
        ...MAIN_GAME.thumb,
        ...SUB_GAME.thumb,
      },
    };
  }
  return game;
}

/**
 * 取得客戶端客制遊戲設定
 * @param code 遊戲代碼
 * @returns
 */
async function GET_CFPKG_GAME(code: string): LIBRARY_GAME {
  const noGame = {} as LIBRARY_GAME;
  if (!code) return noGame;
  try {
    const REQURED_GAME = await import(`@branch/GAME_REFS/${code}.ts`).then(module => module.default);
    if (REQURED_GAME) return REQURED_GAME;
    return noGame;
  } catch (_) {
    /* do nothing */
    return noGame;
  }
}

async function GET_THEME_GAME(code: string): Promise<LIBRARY_GAME> {  
  const noGame = {} as LIBRARY_GAME;  
  if (!code) return noGame;  
  
  try {  
    const REQURED_GAME = (await import(`@/modules/THEMES/${THEME_NAME}/GAME_REFS/${code}.ts`)).default;  
    if (REQURED_GAME) return REQURED_GAME;  
    return noGame;  
  } catch (_) {  
    /* do nothing */  
    return noGame;  
  }  
}  

/**
 * 合併遊戲的語系額外設定
 */
function customizeGameWithLocale(game: LIBRARY_GAME) {
  const htmlElement = document.querySelector("html") as HTMLHtmlElement;
  const htmlLang = htmlElement.getAttribute("lang");
  const LOCALE = setLocaleString(
    localStorage.getItem("lang") || htmlLang,
  ) as string;
  if (!game.localeSetting || !game.localeSetting[LOCALE]) return game;

  const LOCALE_GAME = game.localeSetting[LOCALE];
  return {
    ...game,
    ...LOCALE_GAME,
    thumb: {
      ...(game.thumb || {}),
      ...(LOCALE_GAME.thumb || {}),
    },
  };
  /** 將html顯示語系統一為 i18n 資料夾取名規範 */
  function setLocaleString(locale?: string | null) {
    switch (locale) {
      case "zh-Hant":
        return "zh_TW";
      case "zh-Hans":
        return "zh_CN";
      case "ja":
        return "jp";
      default:
        return locale;
    }
  }
}

/** 合併多類別遊戲的設定 */
function mergingTypes(cur: LIBRARY_GAME, nex: LIBRARY_GAME) {
  if (cur.typeSetting || nex.typeSetting) {
    Object.keys(nex.typeSetting || {}).forEach(str => {
      const type = str as game_type;
      const curTypeSetting = cur.typeSetting?.[type];
      const nexTypeSetting = nex.typeSetting?.[type];

      if (cur.typeSetting && curTypeSetting) {
        cur.typeSetting[type] = {
          ...curTypeSetting,
          ...nexTypeSetting,
        };
      } else if (nex.typeSetting) {
        if (typeof cur.typeSetting !== "object") cur.typeSetting = {};
        cur.typeSetting[type] = { ...nexTypeSetting };
      }
    });
  }

  return cur.typeSetting;
}
