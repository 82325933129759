/* eslint-disable no-param-reassign */
import { typeLists } from "../service/casinoGames/lists";
import Balance from "../service/casinoGames/balance";
import Transfer, {
  typeDoModify as typeTransfer,
} from "../service/casinoWallet/wallet/transfer";
import CreateGame from "../library/GAME/index";
import { ElMessage } from "element-plus";
import $i18n from "@/modules/FPKG-20000-Util/lang";
import { Commit } from "vuex";

type flatGame = Omit<typeLists.listItem, "sub_games"> & {
  p26_sgs_code?: string;
  p26_sgs_name?: string;
};

type State = {
  /** 啟用中的主題資料夾名稱 */
  THEME_PKG: string;
  /** raw Data */
  rawData: typeLists.listItem[];
  /** 扁平化的 子/主 遊戲列表 */
  flatGames: flatGame[];
  /** 遊戲的啟動資訊 */
  launcher?: Record<string, any>;
  games: any[];
};

const state: State = {
  // THEME_PKG: "THEME-10000-CASINO1",
  THEME_PKG: "",
  rawData: [],
  flatGames: [],
  launcher: {},
  games: [],
};

export default {
  namespaced: true,
  state,
  mutations: {
    setGames(state: State, games: any[]) {
      state.games = games; // 將物件陣列儲存到 state 中
    },
  },
  actions: {
    async getBalance({ commit }: any, game: { code: string }) {
      const p26_ges_code = game.code;
      const result = await (await Balance({ p26_ges_code })).data.response;
      return convertToNumber(result);
      // const res =
      // commit("setBalance", res);
      /** 可能會回傳 string number */
      function convertToNumber(value: string) {
        const numberValue = Number(value);
        if (!isNaN(numberValue)) {
          return numberValue;
        } else {
          return value;
        }
      }
    },

    async transfer({ commit }: any, req: typeTransfer.request) {
      if (Number(req.amount) < 1) {
        const msg = $i18n.global.t("CasinoFrontGames.noPoint");
        ElMessage.warning(msg);
        throw new Error("Not enough point,in state transfer");
      }
      await (
        await Transfer(req)
      ).data.response;
    },

    async fetchGames({ commit, rootState }: { commit: Commit; rootState: any }) {  
    const transGames = await Promise.all(  
      (rootState.game.rawData as GameItem[])  
        .filter((G) => G.p26_gaio_transfer_type !== 1)  
        .map(async (G) => {  
          /** 取得客製化資訊 */  
          const settedGame = await new CreateGame({ code: G.p26_ges_code }).initGame();  
          return { ...G, ...settedGame };  
        })  
    );  

    commit('setGames', transGames); // 提交 mutation 更新遊戲資料  
  }  
  },
  getters: {
    list(st: State) {
      return st.games;
    },
  },
};
