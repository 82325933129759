/**
 * 新增 google 標籤程式碼
 * 可應用於其他需要貼在內部的程式碼
 *  */

/**
 * 新增標籤並且啟用標籤於head
 * @param src 程式碼片段
 * @returns void
 */
export function appendHead(src: string | null) {
  if (!src || !src.includes("<script>")) return;
  const head = document.head;
  const scriptEl = document.createRange().createContextualFragment(src);
  head.append(scriptEl);
}

/**
 * 新增標籤並且啟用標籤於body
 * @param src 程式碼片段
 * @returns void
 */
export function appendBody(src: string | null) {
  if (!src || !src.includes("script")) return;
  const body = document.body;
  const scriptEl = document.createRange().createContextualFragment(src);
  body.prepend(scriptEl);
}

export default appendHead;
