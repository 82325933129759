import { AxiosResponse } from "axios";
import $http from "../../../FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "casinoGames/member/balance";

export namespace typeInfo {
  export interface request {
    p26_ges_code: string;
  }
  export type response = number;

  export interface AxiosResponse extends _AxiosResponse {}
}
interface _AxiosResponse extends AxiosResponse<responseData> {}

interface responseData extends BackendResponse {
  response: number;
}

/** 取得遊戲餘額 */
export default async (request: typeInfo.request) => {
  const params = preformater(request);
  const getter = await $http.get(API, { params });
  if (getter.data.code !== 0) throw getter;
  return getter;
};

function preformater(req: typeInfo.request) {
  return {
    p26_ges_code: req.p26_ges_code,
  };
}
