import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('GOLDEN_F_PM', fileName)
};
export default {
  code: "GOLDEN_FPF_PM_KENO",
  type: ["LOTTERY"],
  play_method: "URL",
  subGames: [
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_12",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_12",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_SICBO31",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_SICBO31.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_SICBO31.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_BAIJIALE13",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE13.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE13.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_SICBO32",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_SICBO32.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_SICBO32.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_BAIJIALE19",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE19.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE19.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_SICBO33",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_SICBO33.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_SICBO33.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_BAIJIALE21",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE21.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE21.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_SICBO34",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_SICBO34.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_SICBO34.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_BAIJIALE22",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE22.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE22.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_TESE25",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_BAIJIALE23",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE23.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE23.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_TESE28",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_10.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_10.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_10",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_10.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_10.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_BAIJIALE24",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE24.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE24.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_10",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_10.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_10.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_BAIJIALE74",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE74.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE74.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_2",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_2",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_SICBO29",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_SICBO29.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_SICBO29.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_7",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_7.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_7.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_7",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_7.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_7.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_SICBO30",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_SICBO30.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_SICBO30.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_83",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_11",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_14",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_15",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_4",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_46",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_5",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_58",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_11",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_14",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_15",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_4",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_46",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_5",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_58",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_83",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_205",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_205",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_276",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_276",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_48",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_54",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_68",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_48",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_54",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_68",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_TESE25",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_TESE35",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_69",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_69",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_35",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_35",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE25.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YAKENO_H5_269",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_10.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_10.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_269",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_10.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_10.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_67",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE28.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE28.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_67",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE28.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE28.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_TESE91",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE28.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE28.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_91",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE28.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE28.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_91",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE28.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_TESE28.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_27",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_27",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_36",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_36",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_204",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_204",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_279",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_279",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_273",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_273",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_45",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_55",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_56",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_57",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_59",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_45",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_55",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_56",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_57",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_59",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_37",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_37",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_166",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_167",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_168",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_166",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_167",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_168",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_200",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_200",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_271",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_271",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_272",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_272",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_274",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_274",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_3",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_8",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_9",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_3",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_8",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_9",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_80",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_80",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_206",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_207",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_208",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_209",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_216",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_231",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_206",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_207",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_208",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_209",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_216",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_231",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_18",
      thumb: {
        img: getImg("pk10.webp"),
        subGame: getImg("pk10.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_18",
      thumb: {
        img: getImg("pk10.webp"),
        subGame: getImg("pk10.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_81",
      thumb: {
        img: getImg("pk10.webp"),
        subGame: getImg("pk10.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_81",
      thumb: {
        img: getImg("pk10.webp"),
        subGame: getImg("pk10.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_201",
      thumb: {
        img: getImg("pk10.webp"),
        subGame: getImg("pk10.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_201",
      thumb: {
        img: getImg("pk10.webp"),
        subGame: getImg("pk10.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_275",
      thumb: {
        img: getImg("pk10.webp"),
        subGame: getImg("pk10.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_275",
      thumb: {
        img: getImg("pk10.webp"),
        subGame: getImg("pk10.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_20",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_41",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_42",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_43",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_44",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_50",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_89",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_20",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_41",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_42",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_43",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_44",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_50",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_89",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_82",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_82",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_203",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_203",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_277",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_277",
      thumb: {
        img: getImg("super3.webp"),
        subGame: getImg("super3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_278",
      thumb: {
        img: getImg("super8.webp"),
        subGame: getImg("super8.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_278",
      thumb: {
        img: getImg("super8.webp"),
        subGame: getImg("super8.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_26",
      thumb: {
        img: getImg("super8.webp"),
        subGame: getImg("super8.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_47",
      thumb: {
        img: getImg("super8.webp"),
        subGame: getImg("super8.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_26",
      thumb: {
        img: getImg("super8.webp"),
        subGame: getImg("super8.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_47",
      thumb: {
        img: getImg("super8.webp"),
        subGame: getImg("super8.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_PC_202",
      thumb: {
        img: getImg("super8.webp"),
        subGame: getImg("super8.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_KENO_YBKENO_H5_202",
      thumb: {
        img: getImg("super8.webp"),
        subGame: getImg("super8.webp"),
      },
    },
  ],
} as LIBRARY_GAME;
