/* eslint-disable no-use-before-define, camelcase */
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import FilesFormater, {
  img,
} from "@/modules/FPKG-220000-CasinoCore/converter/responseFileFormater";
import FileUploader from "@/modules/FPKG-220000-CasinoCore/library/fileUpload";
import ExpError from "@/modules/FPKG-20000-Util/library/ExpError";

export type IMG = img;

const API = "casinoAccount/front-member/upload";
// http://redmine.mirfak.tw/projects/pkg-170000-casinoaccount/repository/37/revisions/master/entry/_md/api/Front/Member/%E4%B8%8A%E5%82%B3.md

class Render {
  public basic_RPC: any;

  constructor(files: File[]) {
    this.basic_RPC = this.uploadPreview(files);
  }

  async uploadPreview(params: File[]): Promise<AxiosResponse> {
    const getter = new FileUploader(API);
    const response: any = getter.parseFile(params, "file_upload").upload();
    return response.then((res: AxiosResponse[]) => {
      const errors = res.filter(
        (x: AxiosResponse): boolean => x.data.code !== 0,
      );
      
      if (errors.length) {
        throw new ExpError("We got some ErrorResponses in RPC", 10001);
      }
      return res;
    });
  }

  public async action(): Promise<img[]> {
    const view_data = this.basic_RPC;
    /* render to view */
    return view_data.then((res: AxiosResponse[]) => res.map(showImages));
  }
}

export default Render;

/* format data */
const showImages = (file: AxiosResponse) => {
  const inputFile = file.data.response;
  const formater = new FilesFormater(inputFile);
  return formater.renderImage;
};
