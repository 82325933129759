/* eslint-disable no-use-before-define, no-param-reassign, max-len, @typescript-eslint/no-var-requires */
import _BRANCH from "@branch";
import _BRANCH_IMG from "@branch/img";
import _BRANCH_CUSTOMIZED from "@/modules/CFPKG-130000-777online/customized"

/** 站台客制化設定，不一定會有 */
let customized: customized  = _BRANCH_CUSTOMIZED


interface State {
  branch: typeof _BRANCH;
  logo?: string;
  img: Record<string, string>;
  customized?: customized;
}

/** Vuex State */
const state: State = {
  branch: _BRANCH,
  logo: _BRANCH_IMG.logo,
  img: _BRANCH_IMG,
  customized: customized,
};

/** 取得前台的站台資訊(存在CFPKG的靜態資料，動態部分全部都在 member) */
const branchModule = {
  namespaced: true,
  state,
  getters: {
    logo(st: State) {
      return st.logo;
    },
    branch(st: State) {
      return st.branch;
    },
    img(st: State) {
      return st.img;
    },
    customized(st: State) {
      return st.customized || {};
    },
  },
};

export default branchModule;

interface IconHTMLElement extends HTMLElement {
  rel: string;
  href: string;
  type: string;
}
