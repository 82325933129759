/** salesmartly */

/**
 * 根據客服連結不同，決定為連結類型或是其他的顯示模式
 * 若為客服程式連結，就回傳空字串
 */
export default (url: string | null): string | "" => {
  const salesmartly = RegExp("https://assets.salesmartly.com/js/*");
  if (!url) return "";
  /** 當客服連結為salesmartly, 進行salesmartly視窗初始化 */
  if (salesmartly.test(url)) {
    setScript(url);
    return "";
  }

  /** 沒有符合條件，回傳原有連結 */
  return url;
};

function setScript(url: string) {
  (function() {
    const s1 = document.createElement("script");
    const s0 = document.getElementsByTagName("script")[0];
    s1.src = url;
    if (s0.parentNode) {
      s0.parentNode.insertBefore(s1, s0);
    }
  })();
}
