/** TODO: safarifix new place */
import { createApp } from "vue";
import ElementPlus from "element-plus";
import status from "@/modules/FPKG-20000-Util/components/b-status-option/index.vue"; // 狀態顯示組件
import icon from "@/modules/FPKG-20000-Util/components/b-icon.vue"; // 狀態顯示組件
import formatNumber from "@/modules/FPKG-20000-Util/prototypes/Format_Number";
import store  from "@/modules/FPKG-240000-CasinoFrontApi/store/index";
import $i18n from "@/modules/FPKG-20000-Util/lang"; // 引入所有語言檔進行整合
import App from "./App.vue";
import router from "./vueRouter";
import errorHandler from "./erorHandler";
import 'element-plus/dist/index.css'
import 'swiper/css';

const app = createApp(App);
app.config.errorHandler = (err, instance, info) => {
  console.error('Global error handler:', err, info);
};

app.config.globalProperties.$i18n = $i18n;
app.config.globalProperties.$Format_Number = formatNumber;
app.config.globalProperties;
app.config.errorHandler = errorHandler;

app.component("status", status);
app.component("icon", icon);

app
  .use(router)
  .use(store)
  .use(ElementPlus, {
    // i18n: $i18n.global.t,
  })
  .use($i18n)
  .mount("#app");

