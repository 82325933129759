import { AxiosResponse } from "axios";
import $http from "../../../FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "site/locale/set";

export namespace typeDoModify {
  export interface request {
    locale: string;
  }
  export interface AxiosResponse extends _AxiosResponse {}
}
interface _AxiosResponse extends AxiosResponse<responseData> {}

interface responseData extends BackendResponse {
  response: any;
}

/** 語系變更 */
export default async (request: typeDoModify.request) => {
  const getter = await $http.put(API, request);
  if (getter.data.code !== 0) throw getter;
  return getter;
};
