import { PAY_INFO, AUCTION_INFO } from "./type";
import { ElMessageBox } from "element-plus";
import GET_COMPONENTS from "@/modules/FPKG-20000-Util/library/AUTO_COMPONENTS";
import { ERROR_CODES } from "../service/casinoPaymentFlow/stored/create";
/** 支付方法 */
const LOCAL_PAYS =GET_COMPONENTS(import.meta.glob('./library/*.ts',{eager:true}));

/**
 * 取得支付方法組件
 * 綜合回傳與送出的資料
 * 在金流庫中取得相應的組件
 *  */
export default (data: PAY_INFO | AUCTION_INFO) => {
  const CODE = data.response.code;
  if (CODE) {
    const PAYMENT_FLOW = LOCAL_PAYS[CODE] as any;
    if (PAYMENT_FLOW) return PAYMENT_FLOW(data);
    ElMessageBox.alert(CODE + " is not ready yet.");
    throw new Error(CODE);
  }

  /** 需要經錯誤碼取得下一步交易的的金流 */
  const { code: ERROR_CODE } = data as PAY_INFO;
  const PAY = ERROR_CODES.find((C) => C.code === ERROR_CODE)?.component;
  if (PAY) {
    const PAYMENT_FLOW = LOCAL_PAYS[PAY] as any;
    if (PAYMENT_FLOW) return PAYMENT_FLOW(data);
    ElMessageBox.alert(CODE + " is not ready yet.");
    throw new Error(CODE);
  }
};
