import { AxiosResponse } from "axios";
import $http from "../../../FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "casinoAccount/front-member/password";

export namespace typeDoModify {
  export interface request {
    /** 密碼 必填 */
    p3_ant_password: string;
    /** 密碼確認 必填,需與 p3_ant_password 一致 */
    p3_ant_password_confirm: string;
  }
  export interface AxiosResponse extends _AxiosResponse {}
}
interface _AxiosResponse extends AxiosResponse<responseData> {}

interface responseData extends BackendResponse {
  response: any;
}

/** 修改密碼 */
export default async (request: typeDoModify.request) => {
  const getter = await $http.put(API, request);
  if (getter.data.code !== 0) throw getter;
  return getter;
};
