import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('WG_LOTTERY', fileName)
};
export default {
  code: "WG_LOTTERY",
  type: ["LOTTERY"],
  play_method: "WG_LOTTERY",
  thumb: { img: getImg("logo.jpg") },
} as LIBRARY_GAME;
