import { createI18n } from "vue-i18n";
import setElementLang from "@/modules/FPKG-20000-Util/library/setElementLocale"; // 設定element的語言
import langs from "./const/htmlLangs";

/**
 * 進行掃描，將所有語言檔進行整合，並且依 i18n messages 的格式輸出
 */
// 用于收集所有语言包的函数  
async function allLocale() {  
  const files = import.meta.glob('@/modules/**/*locale/*.json', { eager: true, as: 'raw' });  
  const acc = {};  

  for (const filePath in files) {  
    const modulePath = filePath.split("/"); // 将路径转为数组  
    const moduleName = modulePath[modulePath.indexOf("locale") - 1].split("-")[2];
    const match = filePath.match(/locale\/(\S*).json/);
    const locale = match ? match[1] : undefined;

    // 初始化语言对象，如果不存在就创建一个  
    if (typeof acc[locale] !== "object") {  
      acc[locale] = {};  
    }   

    // 将语言包格式化，按 [地区][模块简写] 进行设置  
    acc[locale][moduleName] = JSON.parse(files[filePath]); // JSON.parse 用来解析 .json 文件内容  
  }  

  return acc;  
}  

/**
 * 將語言包依i18n messages格式輸出
 */
const messagesG = await allLocale(); 
/** 設定element多國語言 */
const messages: any = setElementLang(messagesG);

const defaultLocale = "zh_TW";
const localLocale: string = localStorage.getItem("lang") || defaultLocale; // 取得local儲存的語言設定

const i18n = createI18n({
  legacy: false,
  locale: localLocale, // 语言标识
  fallbackLocale: {
    default: [defaultLocale],
    id: ["en", defaultLocale],
  }, // 沒有可用語言時，使用該回退語言
  messages,
  silentTranslationWarn: true, // 停止找不到語言路徑時的錯誤警告
  silentFallbackWarn: true, // 停止回退語言所產生的錯誤警告
});

// setTimeout(() => {
//   i18n.locale = localStorage.getItem('lang') || '';
// }, 2000);

export default i18n;
/** 重要: 假如後台回傳的語言又變動(例: US => en_US)，需要修改的項目包含 {lang}.json,以及 elementLocale內的項目 */

// elementLocale.i18n((key: any, value: any) => i18n.t(key, value)); // element修改語言
// const app = createApp(App)

// app.use(ElementPlus, {
//   i18n: i18n.global.t,
// })
// app.use(i18n)

/** 改變 html 上的語言標記 */
export const changeHtmlLang = (lang: string) => {
  const html = document.documentElement; // returns the html tag

  const htmlLang = langs[lang];
  if (htmlLang) {
    html.setAttribute("lang", htmlLang);
    return;
  }
  html.setAttribute("lang", lang);
};

/** 暫時移除，改由 ./API/langChanger INIT_LANG 進行html標籤的lang替換 */
// changeHtmlLang(localLocale);
