import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('GOLDEN_F_PM', fileName)
};

export default {
  code: "GOLDEN_FPF_PM_LIVE",
  type: ["CASINO"],
  play_method: "URL",
  subGames: [
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2001_1",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE23.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE23.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2001_2",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE23.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE23.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2002_1",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE19.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE19.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2002_2",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE19.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE19.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2003_1",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE23.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE23.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2003_2",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE23.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE23.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2004_1",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE24.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE24.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2004_2",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE24.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE24.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2005_1",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE23.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE23.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2005_2",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE23.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE23.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2014_1",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE74.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE74.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2014_2",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE74.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE74.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2016_2",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE23.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE23.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2016_1",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE23.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE23.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2017_1",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE19.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE19.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2017_2",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE19.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_H5_BAIJIALE19.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2007_2",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2007_1",
      thumb: {
        img: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
        subGame: getImg("GOLDEN_F_PM_KENO_YBKENO_PC_83.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2006_1",
      thumb: {
        img: getImg("dragonTiger.webp"),
        subGame: getImg("dragonTiger.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2006_2",
      thumb: {
        img: getImg("dragonTiger.webp"),
        subGame: getImg("dragonTiger.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2008_1",
      thumb: {
        img: getImg("sicbo.webp"),
        subGame: getImg("sicbo.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2008_2",
      thumb: {
        img: getImg("sicbo.webp"),
        subGame: getImg("sicbo.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2010_1",
      thumb: {
        img: getImg("flower.webp"),
        subGame: getImg("flower.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2010_2",
      thumb: {
        img: getImg("flower.webp"),
        subGame: getImg("flower.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2009_1",
      thumb: {
        img: getImg("ox.webp"),
        subGame: getImg("ox.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2009_2",
      thumb: {
        img: getImg("ox.webp"),
        subGame: getImg("ox.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2011_1",
      thumb: {
        img: getImg("three.webp"),
        subGame: getImg("three.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2011_2",
      thumb: {
        img: getImg("three.webp"),
        subGame: getImg("three.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2015_1",
      thumb: {
        img: getImg("ox2.webp"),
        subGame: getImg("ox2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2015_2",
      thumb: {
        img: getImg("ox2.webp"),
        subGame: getImg("ox2.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2019_2",
      thumb: {
        img: getImg("poker.webp"),
        subGame: getImg("poker.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2019_1",
      thumb: {
        img: getImg("poker.webp"),
        subGame: getImg("poker.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2021_1",
      thumb: {
        img: getImg("21.webp"),
        subGame: getImg("21.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2021_2",
      thumb: {
        img: getImg("21.webp"),
        subGame: getImg("21.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2012_2",
      thumb: {
        img: getImg("21.webp"),
        subGame: getImg("21.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2012_1",
      thumb: {
        img: getImg("21.webp"),
        subGame: getImg("21.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2025_2",
      thumb: {
        img: getImg("andar_bahar.webp"),
        subGame: getImg("andar_bahar.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2025_1",
      thumb: {
        img: getImg("andar_bahar.webp"),
        subGame: getImg("andar_bahar.webp"),
      },
    },

    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2023_2",
      thumb: {
        img: getImg("poker2.webp"),
        subGame: getImg("poker2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_LIVE_YBLIVE_2023_1",
      thumb: {
        img: getImg("poker2.webp"),
        subGame: getImg("poker2.webp"),
      },
    },
  ],
} as LIBRARY_GAME;
