/* eslint-disable no-param-reassign */
import Lists, { typeLists } from "../../service/reward/lists";

type state = {
  /** 反水紀錄 */
  record: typeLists.response;
};

const state: state = {
  record: {
    list: [],
    paginator: {
      sort_type: "ASC",
      count: 0,
      page: 0,
      page_num: 0,
      perpage: 0,
    },
  },
};

export default {
  namespaced: true,
  state,
  mutations: {
    setRecord: (st: state, data: typeLists.response) => {
      st.record = data;
    },
    async cleanRecord(st: state) {
      st.record = {
        list: [],
        paginator: {
          sort_type: "ASC",
          count: 0,
          page: 0,
          page_num: 0,
          perpage: 0,
        },
      };
    },
  },
  actions: {
    /** 取得紀錄 */
    async getRecord({ commit }: any, request: typeLists.request) {
      const response = await (await Lists(request)).data.response;
      commit("setRecord", response);
    },
  },
  getters: {
    record(st: state) {
      return st.record;
    },
  },
};
