import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('EG_ELECTRONIC', fileName)
};
/** TODO: 圖片位於本地端，待S3正常後上傳，並解鎖多語系遊戲圖片 */
export default {
  code: "EG_ELECTRONIC",
  type: ["EGAMING"],
  play_method: "URL",
  thumb: {
    // EAZYGAMING_Logo.svg
    // EAZYGAMING_Logo1.svg
    // game: getImg("EAZYGAMING_Logo1.svg"),
    // submenu: getImg("EAZYGAMING_Logo.svg"),
  },
  subGames: [
    {
      subCode: "EG_ELECTRONIC_ADVENTUREOFSINBAD",
      thumb: {
        img: getImg("adventureofsinbad_tw.webp"),
        subGame: getImg("adventureofsinbad_tw.webp"),
      },
    },
    {
      subCode: "EG_ELECTRONIC_GOALLINEBABY",
      thumb: {
        img: getImg("goallinebaby_tw.webp"),
        subGame: getImg("goallinebaby_tw.webp"),
      },
    },
    {
      subCode: "EG_ELECTRONIC_SICBOVIDEO",
      thumb: {
        img: getImg("sicbovideo_tw.webp"),
        subGame: getImg("sicbovideo_tw.webp"),
      },
    },
    {
      subCode: "EG_ELECTRONIC_HILOP",
      thumb: {
        img: getImg("hilop_tw.webp"),
        subGame: getImg("hilop_tw.webp"),
      },
    },
    {
      subCode: "EG_ELECTRONIC_KITSUNESISTER",
      thumb: {
        img: getImg("kitsunesister_tw.webp"),
        subGame: getImg("kitsunesister_tw.webp"),
      },
    },
    {
      subCode: "EG_ELECTRONIC_NEKOMAID",
      thumb: {
        img: getImg("nekomaid_tw.webp"),
        subGame: getImg("nekomaid_tw.webp"),
      },
    },
    {
      subCode: "EG_ELECTRONIC_XOCDIAVIDEO",
      thumb: {
        img: getImg("xocdiavideo_tw.webp"),
        subGame: getImg("xocdiavideo_tw.webp"),
      },
    },
    {
      subCode: "EG_ELECTRONIC_BLASTXP",
      thumb: {
        img: getImg("blastxp_tw.webp"),
        subGame: getImg("blastxp_tw.webp"),
      },
    },
    {
      subCode: "EG_ELECTRONIC_PLINKOP",
      thumb: {
        img: getImg("plinkop_tw.webp"),
        subGame: getImg("plinkop_tw.webp"),
      },
    },
    {
      subCode: "EG_ELECTRONIC_WHEELP",
      thumb: {
        img: getImg("wheelp_tw.webp"),
        subGame: getImg("wheelp_tw.webp"),
      },
    },
    {
      subCode: "EG_ELECTRONIC_GIDOL",
      thumb: {
        img: getImg("gidol_tw.webp"),
        subGame: getImg("gidol_tw.webp"),
      },
    },
    {
      subCode: "EG_ELECTRONIC_DICEP",
      thumb: {
        img: getImg("dicep_tw.webp"),
        subGame: getImg("dicep_tw.webp"),
      },
    },
    {
      subCode: "EG_ELECTRONIC_MISSHOLMESCOLDCASE",
      thumb: {
        img: getImg("missholmescoldcase_tw.webp"),
        subGame: getImg("missholmescoldcase_tw.webp"),
      },
    },
    {
      subCode: "EG_ELECTRONIC_HOOHEYHOWVIDEO",
      thumb: {
        img: getImg("hooheyhowvideo_tw.webp"),
        subGame: getImg("hooheyhowvideo_tw.webp"),
      },
    },
    {
      subCode: "EG_ELECTRONIC_WITCHLOVE",
      thumb: {
        img: getImg("witchlove_tw.webp"),
        subGame: getImg("witchlove_tw.webp"),
      },
    },
  ],
  // subGames: [
  //   {
  //     subCode: "EG_ELECTRONIC_ADVENTUREOFSINBAD",
  //     thumb: {
  //       img: getImg("adventureofsinbad.webp"),
  //       subGame: getImg("adventureofsinbad.webp"),
  //     },
  //   },
  //   {
  //     subCode: "EG_ELECTRONIC_GOALLINEBABY",
  //     thumb: {
  //       img: getImg("goallinebaby.webp"),
  //       subGame: getImg("goallinebaby.webp"),
  //     },
  //   },
  //   {
  //     subCode: "EG_ELECTRONIC_SICBOVIDEO",
  //     thumb: {
  //       img: getImg("sicbovideo.webp"),
  //       subGame: getImg("sicbovideo.webp"),
  //     },
  //   },
  //   {
  //     subCode: "EG_ELECTRONIC_HILOP",
  //     thumb: {
  //       img: getImg("hilop.webp"),
  //       subGame: getImg("hilop.webp"),
  //     },
  //   },
  //   {
  //     subCode: "EG_ELECTRONIC_KITSUNESISTER",
  //     thumb: {
  //       img: getImg("kitsunesister.webp"),
  //       subGame: getImg("kitsunesister.webp"),
  //     },
  //   },
  //   {
  //     subCode: "EG_ELECTRONIC_NEKOMAID",
  //     thumb: {
  //       img: getImg("nekomaid.webp"),
  //       subGame: getImg("nekomaid.webp"),
  //     },
  //   },
  //   {
  //     subCode: "EG_ELECTRONIC_XOCDIAVIDEO",
  //     thumb: {
  //       img: getImg("xocdiavideo.webp"),
  //       subGame: getImg("xocdiavideo.webp"),
  //     },
  //   },
  //   {
  //     subCode: "EG_ELECTRONIC_BLASTXP",
  //     thumb: {
  //       img: getImg("blastxp.webp"),
  //       subGame: getImg("blastxp.webp"),
  //     },
  //   },
  //   {
  //     subCode: "EG_ELECTRONIC_PLINKOP",
  //     thumb: {
  //       img: getImg("plinkop.webp"),
  //       subGame: getImg("plinkop.webp"),
  //     },
  //   },
  //   {
  //     subCode: "EG_ELECTRONIC_WHEELP",
  //     thumb: {
  //       img: getImg("wheelp.webp"),
  //       subGame: getImg("wheelp.webp"),
  //     },
  //   },
  //   {
  //     subCode: "EG_ELECTRONIC_GIDOL",
  //     thumb: {
  //       img: getImg("gidol.webp"),
  //       subGame: getImg("gidol.webp"),
  //     },
  //   },
  //   {
  //     subCode: "EG_ELECTRONIC_DICEP",
  //     thumb: {
  //       img: getImg("dicep.webp"),
  //       subGame: getImg("dicep.webp"),
  //     },
  //   },
  //   {
  //     subCode: "EG_ELECTRONIC_MISSHOLMESCOLDCASE",
  //     thumb: {
  //       img: getImg("missholmescoldcase.webp"),
  //       subGame: getImg("missholmescoldcase.webp"),
  //     },
  //   },
  //   {
  //     subCode: "EG_ELECTRONIC_HOOHEYHOWVIDEO",
  //     thumb: {
  //       img: getImg("hooheyhowvideo.webp"),
  //       subGame: getImg("hooheyhowvideo.webp"),
  //     },
  //   },
  //   {
  //     subCode: "EG_ELECTRONIC_WITCHLOVE",
  //     thumb: {
  //       img: getImg("witchlove.webp"),
  //       subGame: getImg("witchlove.webp"),
  //     },
  //   },
  // ],
  // localeSetting: {
  //   zh_CN: {
  //     subGames: [
  //       {
  //         subCode: "EG_ELECTRONIC_ADVENTUREOFSINBAD",
  //         thumb: {
  //           img: getImg("adventureofsinbad_cn.webp"),
  //           subGame: getImg("adventureofsinbad_cn.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_GOALLINEBABY",
  //         thumb: {
  //           img: getImg("goallinebaby_cn.webp"),
  //           subGame: getImg("goallinebaby_cn.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_SICBOVIDEO",
  //         thumb: {
  //           img: getImg("sicbovideo_cn.webp"),
  //           subGame: getImg("sicbovideo_cn.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_HILOP",
  //         thumb: {
  //           img: getImg("hilop_cn.webp"),
  //           subGame: getImg("hilop_cn.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_KITSUNESISTER",
  //         thumb: {
  //           img: getImg("kitsunesister_cn.webp"),
  //           subGame: getImg("kitsunesister_cn.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_NEKOMAID",
  //         thumb: {
  //           img: getImg("nekomaid_cn.webp"),
  //           subGame: getImg("nekomaid_cn.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_XOCDIAVIDEO",
  //         thumb: {
  //           img: getImg("xocdiavideo_cn.webp"),
  //           subGame: getImg("xocdiavideo_cn.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_BLASTXP",
  //         thumb: {
  //           img: getImg("blastxp_cn.webp"),
  //           subGame: getImg("blastxp_cn.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_PLINKOP",
  //         thumb: {
  //           img: getImg("plinkop_cn.webp"),
  //           subGame: getImg("plinkop_cn.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_WHEELP",
  //         thumb: {
  //           img: getImg("wheelp_cn.webp"),
  //           subGame: getImg("wheelp_cn.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_GIDOL",
  //         thumb: {
  //           img: getImg("gidol_cn.webp"),
  //           subGame: getImg("gidol_cn.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_DICEP",
  //         thumb: {
  //           img: getImg("dicep_cn.webp"),
  //           subGame: getImg("dicep_cn.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_MISSHOLMESCOLDCASE",
  //         thumb: {
  //           img: getImg("missholmescoldcase_cn.webp"),
  //           subGame: getImg("missholmescoldcase_cn.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_HOOHEYHOWVIDEO",
  //         thumb: {
  //           img: getImg("hooheyhowvideo_cn.webp"),
  //           subGame: getImg("hooheyhowvideo_cn.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_WITCHLOVE",
  //         thumb: {
  //           img: getImg("witchlove_cn.webp"),
  //           subGame: getImg("witchlove_cn.webp"),
  //         },
  //       },
  //     ],
  //   },
  //   zh_TW: {
  //     subGames: [
  //       {
  //         subCode: "EG_ELECTRONIC_ADVENTUREOFSINBAD",
  //         thumb: {
  //           img: getImg("adventureofsinbad_tw.webp"),
  //           subGame: getImg("adventureofsinbad_tw.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_GOALLINEBABY",
  //         thumb: {
  //           img: getImg("goallinebaby_tw.webp"),
  //           subGame: getImg("goallinebaby_tw.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_SICBOVIDEO",
  //         thumb: {
  //           img: getImg("sicbovideo_tw.webp"),
  //           subGame: getImg("sicbovideo_tw.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_HILOP",
  //         thumb: {
  //           img: getImg("hilop_tw.webp"),
  //           subGame: getImg("hilop_tw.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_KITSUNESISTER",
  //         thumb: {
  //           img: getImg("kitsunesister_tw.webp"),
  //           subGame: getImg("kitsunesister_tw.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_NEKOMAID",
  //         thumb: {
  //           img: getImg("nekomaid_tw.webp"),
  //           subGame: getImg("nekomaid_tw.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_XOCDIAVIDEO",
  //         thumb: {
  //           img: getImg("xocdiavideo_tw.webp"),
  //           subGame: getImg("xocdiavideo_tw.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_BLASTXP",
  //         thumb: {
  //           img: getImg("blastxp_tw.webp"),
  //           subGame: getImg("blastxp_tw.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_PLINKOP",
  //         thumb: {
  //           img: getImg("plinkop_tw.webp"),
  //           subGame: getImg("plinkop_tw.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_WHEELP",
  //         thumb: {
  //           img: getImg("wheelp_tw.webp"),
  //           subGame: getImg("wheelp_tw.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_GIDOL",
  //         thumb: {
  //           img: getImg("gidol_tw.webp"),
  //           subGame: getImg("gidol_tw.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_DICEP",
  //         thumb: {
  //           img: getImg("dicep_tw.webp"),
  //           subGame: getImg("dicep_tw.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_MISSHOLMESCOLDCASE",
  //         thumb: {
  //           img: getImg("missholmescoldcase_tw.webp"),
  //           subGame: getImg("missholmescoldcase_tw.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_HOOHEYHOWVIDEO",
  //         thumb: {
  //           img: getImg("hooheyhowvideo_tw.webp"),
  //           subGame: getImg("hooheyhowvideo_tw.webp"),
  //         },
  //       },
  //       {
  //         subCode: "EG_ELECTRONIC_WITCHLOVE",
  //         thumb: {
  //           img: getImg("witchlove_tw.webp"),
  //           subGame: getImg("witchlove_tw.webp"),
  //         },
  //       },
  //     ],
  //   },
  // },
} as LIBRARY_GAME;
