/* eslint-disable no-param-reassign */
import Prospects, {
  typeInfo,
  fileFormater,
  jsonFormater,
  getAD,
  getCMS,
} from "../../service/frontendAdapter/prospects";
import Member, {
  typeInfo as typeMember,
} from "../../service/casinoAccount/member";
import setTawk from "@/modules/FPKG-220000-CasinoCore/library/tawk";
import setSalesmartly from "@/modules/FPKG-220000-CasinoCore/library/salesmartly";
import {
  appendHead,
  appendBody,
} from "@/modules/FPKG-220000-CasinoCore/library/googleTag";
import setMeta from "@/modules/FPKG-220000-CasinoCore/library/setMeta";

type state = {
  member: typeMember.datum;
  comm: typeMember.p17_communication_software[];
  wallet: string;
  tradeAccounts: any[];
  name: string;
  rawData: typeInfo.response | {};
  AD: typeInfo.p37_ad_advertise_block[];
  CMS: typeInfo.p36_cms_customization_block[];
  custService: typeInfo.p38_casino_customer_service;
  branch: typeInfo.p11_site_config_info;
};

const state: state = {
  /** 會員進階資訊 需有登入權限 */
  member: {} as typeMember.datum,
  comm: [],
  wallet: "0",
  tradeAccounts: [],
  name: "",
  rawData: {},
  AD: [],
  CMS: [],
  custService: {} as typeInfo.p38_casino_customer_service,
  branch: {
    p11_scg_description: "",
    p11_scg_google_tag_manager_head: "",
    p11_scg_google_tag_manager_noscript: "",
    p11_scg_keyword: "",
    p11_scg_title: "",
  } as typeInfo.p11_site_config_info,
};

export default {
  namespaced: true,
  state,
  mutations: {
    /** 設置使用者資訊 */
    setMember(st: state, res: typeMember.response) {
      st.member = res.datum || {};
    },
    /** 設置通訊軟體 */
    setComm(st: state, res: typeMember.p17_communication_software[]) {
      st.comm = res;
    },

    setInfo(st: state, info: typeInfo.response) {
      st.rawData = info;
    },
    /** 設定客服 */
    setService(st: state, service: typeInfo.p38_casino_customer_service) {
      // service不存在，不動作維持空obj
      if (!service) return;
      st.custService = {
        ...service,
        /** tawk 比較特殊，因為若為程式型態，連結不會出現在用戶面前 */
        p38_ccse_tawk: setTawk(service.p38_ccse_tawk),
        /** salesmartly 同上 */
        p38_ccse_salesmartly: setSalesmartly(service.p38_ccse_salesmartly),
      };
    },
    /** 設定錢包 */
    setWallet(st: state, wallet: string) {
      st.wallet = wallet;
    },
    setTradeAccount(st: state, TA: typeInfo.p17_member_trade_account[]) {
      st.tradeAccounts = (TA || []).map(T => ({
        ...T,
        ...jsonFormater(T.p17_mta_data),
        image: T.p7_file.map(fileFormater),
      }));
    },
    setName(st: state, res: typeInfo.p17_member) {
      st.name = res?.p3_ape_name || "";
    },

    setAD(st: state, AD: typeInfo.p37_ad_advertise_block[]) {
      st.AD = [];
      if (AD)
        st.AD = AD.map(B => ({
          ...B,
          content: B.content
            .filter(I => I.p37_abct_status !== "-2")
            .map(I => ({
              ...I,
              title_file_f: fileFormater(I.title_file),
            })),
        }));
    },
    setCMS(st: state, CMS: typeInfo.p36_cms_customization_block[]) {
      st.CMS = [];
      if (CMS) st.CMS = CMS;
    },
    setBranch(st: state, BCH: typeInfo.p11_site_config_info) {
      st.branch = {
        p11_scg_description: "",
        p11_scg_google_tag_manager_head: "",
        p11_scg_google_tag_manager_noscript: "",
        p11_scg_keyword: "",
        p11_scg_title: "",
      };

      if (BCH) {
        /** DOM 僅在初次設定，之後使用者取資料都不會更動 */
        if (!st.branch.p11_scg_title) settingDOM(BCH);
        st.branch = BCH;
      }
    },
  },
  actions: {
    async getInfo({ dispatch, commit }: any) {
      const res = (await Prospects()).data.response;
      if (res) {
        commit("setWallet", res.p25_wallet?.p24_wet_point);
        commit("setTradeAccount", res.p17_member_trade_account);
        commit("setName", res.p17_member);
        commit("setInfo", res);
        commit("setAD", res.p37_ad_advertise_block);
        commit("setCMS", res.p36_cms_customization_block);
        commit("setService", res.p38_casino_customer_service);
        commit("setBranch", res.p11_site_config_info);

        /** 進行登入狀態檢測，並將值經由root傳遞至其他模組 */
        // const isLogin = !!(res.p25_wallet && res.p17_member);
        // dispatch("account/setLogin", isLogin, { root: true });
      }
    },
    /** 取得使用者個人資訊 */
    async getMember({ dispatch, commit }: any) {
      const res = (await Member()).data.response;
      if (res) {
        commit("setMember", res);
        commit("setComm", res.p17_communication_software || []);
      }
    },
  },
  getters: {
    wallet(st: state) {
      return st.wallet;
    },
    tradeAccounts(st: state) {
      return st.tradeAccounts;
    },
    name(st: state) {
      return st.name;
    },
    /* 取得指定廣告區塊內的 content
    opt 的參數有裝置類型、區塊序號、以及廣告ID
    廣告 ID 為選填項目
    */
    AD: (st: state) => (opt: {
      type: "PC" | "MOBILE";
      block: number;
      id?: number | string;
    }) => {
      return getAD(st.AD, opt);
    },
    CMS: (st: state) => (opt: { type: "PC" | "MOBILE"; block: number }) => {
      return getCMS(st.CMS, opt);
    },
    service: (st: state) => {
      return st.custService;
    },
    /** 整理並匯出通訊軟體名單 */
    custServiceList: (st: state) => setCustServiceLinks(st.custService),
    /** 會員進階資訊 */
    info: (st: state) => st.member,
    /** 站台資訊 */
    branch: (st: state) => st.branch,
    /** 通訊軟體 */
    commSoft: (st: state) => st.comm,
  },
};

/** 進行 HTML DOM 的設定 */
function settingDOM(BCH: typeInfo.p11_site_config_info) {
  /** title 初始化設置 */
  if (document.title !== BCH.p11_scg_title) {
    document.title = BCH.p11_scg_title;
  }
  setMeta("description", BCH.p11_scg_description);
  setMeta("keyword", BCH.p11_scg_keyword);
  appendHead(BCH.p11_scg_google_tag_manager_head);
  appendBody(BCH.p11_scg_google_tag_manager_noscript);
}

function setCustServiceLinks(
  data: typeInfo.p38_casino_customer_service,
): {
  code: string;
  name: string;
  icon: string;
  iconStyle?: Record<string, string>;
  id?: string | null;
  href?: string | null;
}[] {
  const result = [];
  if (data?.p38_ccse_line_id || data?.p38_ccse_line_link) {
    result.push({
      code: "line",
      name: "LINE",
      icon: "fab fa-line",
      iconStyle: { color: "#06c755" },
      id: data.p38_ccse_line_id,
      href:
        data.p38_ccse_line_link ||
        `https://line.me/R/ti/p/${data?.p38_ccse_line_id}`,
    });
  }
  if (data?.p38_ccse_tawk) {
    result.push({
      code: "tawk",
      name: "TAWK",
      iconStyle: { color: "#03a84e" },
      icon: "fas fa-headset",
      href: data.p38_ccse_tawk,
    });
  }
  if (data?.p38_ccse_salesmartly) {
    result.push({
      code: "salesmartly",
      name: "Salesmartly",
      iconStyle: { color: "#03a84e" },
      icon: "fas fa-headset",
      href: data.p38_ccse_salesmartly,
    });
  }
  if (data?.p38_ccse_telegram) {
    result.push({
      code: "telegram",
      name: "TELEGRAM",
      iconStyle: { color: "#0088cc" },
      icon: "fab fa-telegram",
      href: formatId(data.p38_ccse_telegram, "https://t.me"),
    });
  }
  if (data?.p38_ccse_instagram) {
    result.push({
      code: "instagram",
      name: "INSTAGRAM",
      iconStyle: { color: "#e1306c" },
      icon: "fab fa-instagram",
      href: formatId(data.p38_ccse_instagram, "https://www.instagram.com"),
    });
  }
  if (data?.p38_ccse_messenger) {
    result.push({
      code: "messenger",
      name: "MESSENGER",
      iconStyle: { color: "#0088cc" },
      icon: "fab fa-facebook-messenger",
      href: formatId(data.p38_ccse_messenger, "https://m.me"),
    });
  }
  if (data?.p38_ccse_facebook) {
    result.push({
      code: "facebook",
      name: "FACEBOOK",
      iconStyle: { color: "#3b5998" },
      icon: "fab fa-facebook-f",
      href: formatId(data.p38_ccse_facebook, "https://www.facebook.com"),
    });
  }

  if (data?.p38_ccse_viber) {
    result.push({
      code: "viber",
      name: "VIBER",
      iconStyle: { color: "#7360F2" },
      icon: "fab fa-viber",
      href: formatId(data.p38_ccse_viber, "https://chats.viber.com"),
    });
  }
  if (data?.p38_ccse_whatsapp) {
    result.push({
      code: "whatsapp",
      name: "WHATSAPP",
      iconStyle: { color: "#25d366" },
      icon: "fab fa-whatsapp",
      href: formatId(data.p38_ccse_whatsapp, "https://wa.me"),
    });
  }
  return result;

  /** 檢查id是否帶有連結，沒帶連結就用預設的網址 */
  function formatId(id: string, prefix: string) {
    if (
      id.includes("http://") ||
      id.includes("https://") ||
      id.includes("www.")
    ) {
      return id;
    }
    return `${prefix}/${id}`;
  }
}
