/** TODO: IOS / safari */
import isMobile, { deviceName } from "@/modules/FPKG-20000-Util/library/mobileDetective";

type state = {
  /** 裝置寬度 */
  screenWidth: number;
  /** 判斷是否為行動裝置 */
  isMobile: boolean;
  deviceName: "Android" | "iOS" | "unknown";
};

/**
 * 裝置/瀏覽器資訊
 */
/* eslint-disable no-param-reassign */
const state: state = {
  screenWidth: 0,
    isMobile: false,
    deviceName: "unknown",
};

const store = {
  namespaced: true,
  state,
  mutations: {
    getScreenWidth: (st: state) => {
      st.screenWidth =
        window.innerWidth || document.documentElement.clientWidth;
    },
    setDeviceState: (st: state) => {
      st.deviceName = deviceName;
      st.isMobile = isMobile;
    },
  },
  actions: {},
  getters: {},
};

export default store;