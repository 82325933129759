import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('GOLDEN_F_PM_SLOT', fileName)
};

export default {
  code: "GOLDEN_FPF_PM_SLOT",
  type: ["EGAMING"],
  play_method: "URL",
  thumb: {
    bg: getImg("bg.svg"),
  },
  subGames: [
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_31",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_31.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_31.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_30",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_30.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_30.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_28",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_28.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_28.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_27",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_27.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_27.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_26",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_26.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_26.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_25",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_25.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_25.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_32",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_32.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_32.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_33",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_33.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_33.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_34",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_34.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_34.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_35",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_35.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_35.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_1",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_1.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_1.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_2",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_2.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_3",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_3.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_3.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_4",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_4.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_4.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_5",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_5.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_5.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_6",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_6.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_6.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_7",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_7.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_7.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_8",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_8.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_8.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_9",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_9.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_9.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_10",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_10.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_10.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_11",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_11.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_11.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_12",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_12.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_12.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_13",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_13.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_13.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_14",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_14.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_14.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_15",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_15.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_15.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_16",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_16.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_16.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_17",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_17.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_17.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_18",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_18.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_18.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_19",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_19.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_19.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_20",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_20.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_20.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_29",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_29.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_29.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_42",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_42.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_42.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_43",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_43.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_43.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_37",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_37.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_37.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_39",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_39.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_39.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_40",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_40.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_40.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_41",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_41.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_41.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_44",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_44.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_44.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_38",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_38.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_38.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_36",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_36.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_36.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_46",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_46.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_46.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_48",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_48.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_48.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_47",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_47.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_47.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_45",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_45.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_45.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_49",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_49.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_49.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_50",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_50.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_50.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_51",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_51.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_51.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_52",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_52.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_52.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_63",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_63.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_63.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_62",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_62.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_62.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_61",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_61.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_61.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_60",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_60.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_60.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_59",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_59.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_59.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_58",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_58.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_58.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_57",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_57.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_57.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_56",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_56.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_56.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_54",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_54.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_54.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_55",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_55.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_55.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_53",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_53.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_53.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_65",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_65.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_65.webp"),
      },
    },
    {
      subCode: "GOLDEN_FPF_PM_SLOT_OBSLOT_66",
      thumb: {
        img: getImg("GOLDEN_F_PM_SLOT_OBSLOT_66.webp"),
        subGame: getImg("GOLDEN_F_PM_SLOT_OBSLOT_66.webp"),
      },
    },
  ],
} as LIBRARY_GAME;
