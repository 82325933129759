import { AxiosResponse } from "axios";
import $http from "../../../FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "casinoAccount/front/captcha";
// http://redmine.mirfak.tw/projects/pkg-170000-casinoaccount/repository/37/revisions/master/entry/_md/api/Front/%E9%A9%97%E8%AD%89%E7%A2%BC.md
export namespace typeInfo {
  // export interface request {  }
  export type AxiosResponse = _AxiosResponse;
  export type response = string;
}
type _AxiosResponse = AxiosResponse<responseData>;

interface responseData extends BackendResponse {
  response: typeInfo.response;
}

export default async () => {
  const getter = await $http(API);
  if (getter.data.code !== 0) throw getter;
  return getter;
};
