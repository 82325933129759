import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('GTAG_PRAGMATICPLAY', fileName)
};

export default {
  code: "GTAG_PRAGMATICPLAY",
  type: ["EGAMING", "CASINO"],
  play_method: "URL",
  subGames: [
    {
      subCode: "GTAG_PRAGMATICPLAY_PP_449",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedbaccarat2.webp"),
        subGame: getImg("speedbaccarat2.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_PP_442",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("megabaccarat.webp"),
        subGame: getImg("megabaccarat.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_904",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("oneblackjack5turkish.webp"),
        subGame: getImg("oneblackjack5turkish.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_903",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("oneblackjack3dutch.webp"),
        subGame: getImg("oneblackjack3dutch.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_205",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedroulette2.webp"),
        subGame: getImg("speedroulette2.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_240",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("poweruproulette.webp"),
        subGame: getImg("poweruproulette.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_439",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("nocommspeedbaccarat2.webp"),
        subGame: getImg("nocommspeedbaccarat2.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_1101",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sweetbonanzacandyland.webp"),
        subGame: getImg("sweetbonanzacandyland.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_1001",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragontiger.webp"),
        subGame: getImg("dragontiger.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_108",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragontigerlobby.webp"),
        subGame: getImg("dragontigerlobby.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_902",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("oneblackjack2ruby.webp"),
        subGame: getImg("oneblackjack2ruby.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_901",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("oneblackjack.webp"),
        subGame: getImg("oneblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_103",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjacklobby.webp"),
        subGame: getImg("blackjacklobby.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_204",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("megaroulette.webp"),
        subGame: getImg("megaroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_105",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gameshows.webp"),
        subGame: getImg("gameshows.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_801",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("megawheel.webp"),
        subGame: getImg("megawheel.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_234",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("roulette14spanish.webp"),
        subGame: getImg("roulette14spanish.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_230",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("roulette10ruby.webp"),
        subGame: getImg("roulette10ruby.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_545",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("roulette9indigo.webp"),
        subGame: getImg("roulette9indigo.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_229",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("roulette8indian.webp"),
        subGame: getImg("roulette8indian.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_201",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("roulette2.webp"),
        subGame: getImg("roulette2.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_102",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("roulettelobby.webp"),
        subGame: getImg("roulettelobby.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_203",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedroulette1.webp"),
        subGame: getImg("speedroulette1.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_701",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("megasicbo.webp"),
        subGame: getImg("megasicbo.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_107",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("megasicbolobby.webp"),
        subGame: getImg("megasicbolobby.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_435",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("nocommspeedbaccarat1.webp"),
        subGame: getImg("nocommspeedbaccarat1.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_402",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedbaccarat1.webp"),
        subGame: getImg("speedbaccarat1.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_436",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("nocommbaccarat1.webp"),
        subGame: getImg("nocommbaccarat1.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_1401",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("boomcity.webp"),
        subGame: getImg("boomcity.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_1301",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("spaceman.webp"),
        subGame: getImg("spaceman.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_570",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedblackjack5ruby.webp"),
        subGame: getImg("speedblackjack5ruby.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_569",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedblackjack4ruby.webp"),
        subGame: getImg("speedblackjack4ruby.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_550",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("vipblackjack5ruby.webp"),
        subGame: getImg("vipblackjack5ruby.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_549",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("vipblackjack4ruby.webp"),
        subGame: getImg("vipblackjack4ruby.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_548",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("vipblackjack3ruby.webp"),
        subGame: getImg("vipblackjack3ruby.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_547",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("vipblackjack2ruby.webp"),
        subGame: getImg("vipblackjack2ruby.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_546",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("vipblackjack1ruby.webp"),
        subGame: getImg("vipblackjack1ruby.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_544",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack36theclub.webp"),
        subGame: getImg("blackjack36theclub.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_543",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack35theclub.webp"),
        subGame: getImg("blackjack35theclub.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_542",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack34theclub.webp"),
        subGame: getImg("blackjack34theclub.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_541",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack33theclub.webp"),
        subGame: getImg("blackjack33theclub.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_540",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack32azure.webp"),
        subGame: getImg("blackjack32azure.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_536",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack30azure.webp"),
        subGame: getImg("blackjack30azure.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_530",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack26azure.webp"),
        subGame: getImg("blackjack26azure.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_529",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack25azure.webp"),
        subGame: getImg("blackjack25azure.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_305",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack16.webp"),
        subGame: getImg("blackjack16.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_304",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack15.webp"),
        subGame: getImg("blackjack15.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_302",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack12.webp"),
        subGame: getImg("blackjack12.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_301",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack11.webp"),
        subGame: getImg("blackjack11.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_520",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack8azure.webp"),
        subGame: getImg("blackjack8azure.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_512",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack6azure.webp"),
        subGame: getImg("blackjack6azure.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_511",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack3azure.webp"),
        subGame: getImg("blackjack3azure.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_519",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack2azure.webp"),
        subGame: getImg("blackjack2azure.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_233",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("roulette12romanian.webp"),
        subGame: getImg("roulette12romanian.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_235",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("roulette11dutch.webp"),
        subGame: getImg("roulette11dutch.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_223",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("roulette7italian.webp"),
        subGame: getImg("roulette7italian.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_224",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("roulette6turkish.webp"),
        subGame: getImg("roulette6turkish.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_222",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("roulette5german.webp"),
        subGame: getImg("roulette5german.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_221",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("roulette4russian.webp"),
        subGame: getImg("roulette4russian.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_206",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("roulette3macao.webp"),
        subGame: getImg("roulette3macao.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_227",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("roulette1azure.webp"),
        subGame: getImg("roulette1azure.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_434",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fortune6baccarat.webp"),
        subGame: getImg("fortune6baccarat.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_433",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("super8baccarat.webp"),
        subGame: getImg("super8baccarat.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_427",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedbaccarat15.webp"),
        subGame: getImg("speedbaccarat15.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_405",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedbaccarat14.webp"),
        subGame: getImg("speedbaccarat14.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_438",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedbaccarat13.webp"),
        subGame: getImg("speedbaccarat13.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_421",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedbaccarat12.webp"),
        subGame: getImg("speedbaccarat12.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_424",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedbaccarat11.webp"),
        subGame: getImg("speedbaccarat11.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_428",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedbaccarat10.webp"),
        subGame: getImg("speedbaccarat10.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_430",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedbaccarat9.webp"),
        subGame: getImg("speedbaccarat9.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_432",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedbaccarat8.webp"),
        subGame: getImg("speedbaccarat8.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_431",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedbaccarat7.webp"),
        subGame: getImg("speedbaccarat7.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_415",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedbaccarat6.webp"),
        subGame: getImg("speedbaccarat6.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_414",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedbaccarat5.webp"),
        subGame: getImg("speedbaccarat5.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_412",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedbaccarat3.webp"),
        subGame: getImg("speedbaccarat3.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_403",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedbaccarat2.webp"),
        subGame: getImg("speedbaccarat2.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_426",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("baccarat8.webp"),
        subGame: getImg("baccarat8.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_425",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("baccarat7.webp"),
        subGame: getImg("baccarat7.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_413",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("baccarat6.webp"),
        subGame: getImg("baccarat6.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_411",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("baccarat5.webp"),
        subGame: getImg("baccarat5.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_422",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("baccarat3.webp"),
        subGame: getImg("baccarat3.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_404",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("baccarat2.webp"),
        subGame: getImg("baccarat2.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_104",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("baccaratlobby.webp"),
        subGame: getImg("baccaratlobby.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_1024",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("liveandarbahar.webp"),
        subGame: getImg("liveandarbahar.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_303",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack14.webp"),
        subGame: getImg("blackjack14.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_401",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("baccarat1.webp"),
        subGame: getImg("baccarat1.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_225",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("autoroulette1.webp"),
        subGame: getImg("autoroulette1.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_SCDIAMOND",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("diamondstrike100000.webp"),
        subGame: getImg("diamondstrike100000.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_SC7PIGGIES",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("7piggies5000.webp"),
        subGame: getImg("7piggies5000.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_SCGOLDRUSH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldrush250000.webp"),
        subGame: getImg("goldrush250000.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_SCSAFARI",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hotsafari50000.webp"),
        subGame: getImg("hotsafari50000.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_SCPANDA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pandagold10000.webp"),
        subGame: getImg("pandagold10000.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_SCQOG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("queenofgold100000.webp"),
        subGame: getImg("queenofgold100000.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_SCWOLFGOLD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wolfgold1000000.webp"),
        subGame: getImg("wolfgold1000000.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_RLA",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("roulette.webp"),
        subGame: getImg("roulette.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_BNDT",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragontiger.webp"),
        subGame: getImg("dragontiger.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_BCA",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("baccarat.webp"),
        subGame: getImg("baccarat.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_BJMA",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("multihandblackjack.webp"),
        subGame: getImg("multihandblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_BJMB",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("americanblackjack.webp"),
        subGame: getImg("americanblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_PP_1601",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("snakesandladderslive.webp"),
        subGame: getImg("snakesandladderslive.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_PP_226",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("speedautoroulette.webp"),
        subGame: getImg("speedautoroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20DRGBLESS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragonhero.webp"),
        subGame: getImg("dragonhero.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSWWHEX",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildwildbananas.webp"),
        subGame: getImg("wildwildbananas.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20LTNG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pinupgirls.webp"),
        subGame: getImg("pinupgirls.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25SPGLDWAYS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("secretcitygold.webp"),
        subGame: getImg("secretcitygold.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20MAMMOTH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mammothgoldmegaways.webp"),
        subGame: getImg("mammothgoldmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10FISHEYE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fisheye.webp"),
        subGame: getImg("fisheye.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20SUPERLANCHE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("monstersuperlanche.webp"),
        subGame: getImg("monstersuperlanche.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25ARCHER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("firearcher.webp"),
        subGame: getImg("firearcher.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS12TROPICANA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("clubtropicana.webp"),
        subGame: getImg("clubtropicana.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10POWERLINES",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("peakpower.webp"),
        subGame: getImg("peakpower.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSWWRICHES",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildwildrichesmegaways.webp"),
        subGame: getImg("wildwildrichesmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSMORIENT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mysteryoftheorient.webp"),
        subGame: getImg("mysteryoftheorient.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20PISTOLS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildwestduels.webp"),
        subGame: getImg("wildwestduels.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20DOGHOUSEMH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thedoghousemultihold.webp"),
        subGame: getImg("thedoghousemultihold.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSULTRCOIN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("cowboycoins.webp"),
        subGame: getImg("cowboycoins.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20MOCHIMON",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mochimon.webp"),
        subGame: getImg("mochimon.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20MVWILD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jasminedreams.webp"),
        subGame: getImg("jasminedreams.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20SKNIGHTS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("theknightking.webp"),
        subGame: getImg("theknightking.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20GOLDCLUST",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("rabbitgarden.webp"),
        subGame: getImg("rabbitgarden.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10BBHAS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bigbassbonanzaholdspinner.webp"),
        subGame: getImg("bigbassbonanzaholdspinner.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSREDQUEEN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("theredqueen.webp"),
        subGame: getImg("theredqueen.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10GIZAGODS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("godsofgiza.webp"),
        subGame: getImg("godsofgiza.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20HOTZONE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("africanelephant.webp"),
        subGame: getImg("africanelephant.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSRSM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildcelebritybusmegaways.webp"),
        subGame: getImg("wildcelebritybusmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSMONKEY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("3dancingmonkeys.webp"),
        subGame: getImg("3dancingmonkeys.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10JNMNTZMA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("janehunterandthemaskofmontezuma.webp"),
        subGame: getImg("janehunterandthemaskofmontezuma.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10KINGOFDTH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("kingdomofthedead.webp"),
        subGame: getImg("kingdomofthedead.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20EXCALIBUR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("excaliburunleashed.webp"),
        subGame: getImg("excaliburunleashed.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20STICKYWILD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildbisoncharge.webp"),
        subGame: getImg("wildbisoncharge.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25SPOTZ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("knighthotspotz.webp"),
        subGame: getImg("knighthotspotz.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSETERNITY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("diamondsofegypt.webp"),
        subGame: getImg("diamondsofegypt.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20LAMPINF",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("lampofinfinity.webp"),
        subGame: getImg("lampofinfinity.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20JEWELPARTY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jewelrush.webp"),
        subGame: getImg("jewelrush.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS15GODSOFWAR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("zeusvshadesgodsofwar.webp"),
        subGame: getImg("zeusvshadesgodsofwar.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20CLUSTWILD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("stickybees.webp"),
        subGame: getImg("stickybees.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS9OUTLAW",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("piratespub.webp"),
        subGame: getImg("piratespub.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20SUGARRUSH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sugarrush.webp"),
        subGame: getImg("sugarrush.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_PP_VS20DUGEMS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hotpepper.webp"),
        subGame: getImg("hotpepper.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10BBKIR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bigbassbonanzakeepingitreel.webp"),
        subGame: getImg("bigbassbonanzakeepingitreel.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20SCHRISTMAS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("starlightchristmas.webp"),
        subGame: getImg("starlightchristmas.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25RLBANK",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("reelbanks.webp"),
        subGame: getImg("reelbanks.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20CLSPWRNDG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sweetpowernudge.webp"),
        subGame: getImg("sweetpowernudge.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSPIZZA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pizzapizzapizza.webp"),
        subGame: getImg("pizzapizzapizza.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20BERMUDA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bermudariches.webp"),
        subGame: getImg("bermudariches.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10AMM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("amazingmoneymachine.webp"),
        subGame: getImg("amazingmoneymachine.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10BOOKOFTUT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bookoftut.webp"),
        subGame: getImg("bookoftut.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS7776SECRETS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("aztectreasure.webp"),
        subGame: getImg("aztectreasure.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20SPARTA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("shieldofsparta.webp"),
        subGame: getImg("shieldofsparta.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20LCOUNT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gemsofserengeti.webp"),
        subGame: getImg("gemsofserengeti.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10SNAKEEYES",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("snakesladderssnakeeyes.webp"),
        subGame: getImg("snakesladderssnakeeyes.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20PORBS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("santasgreatgifts.webp"),
        subGame: getImg("santasgreatgifts.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS12BBBXMAS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("biggerbassblizzardchristmascatch.webp"),
        subGame: getImg("biggerbassblizzardchristmascatch.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSFURYODIN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("furyofodinmegaways.webp"),
        subGame: getImg("furyofodinmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10CROWNFIRE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("crownoffire.webp"),
        subGame: getImg("crownoffire.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20MPARTY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildhopdrop.webp"),
        subGame: getImg("wildhopdrop.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSFLTDRG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("floatingdragonmegaways.webp"),
        subGame: getImg("floatingdragonmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25KFRUIT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("aztecblaze.webp"),
        subGame: getImg("aztecblaze.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10TUT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("johnhunterandthebookoftutrespin.webp"),
        subGame: getImg("johnhunterandthebookoftutrespin.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20MTREASURE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pirategoldenage.webp"),
        subGame: getImg("pirategoldenage.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSCONCOLL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("firebirdspirit.webp"),
        subGame: getImg("firebirdspirit.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20MUERTOS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("muertosmultipliermegaways.webp"),
        subGame: getImg("muertosmultipliermegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSSTRWILD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("candystars.webp"),
        subGame: getImg("candystars.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20SWORDOFARES",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("swordofares.webp"),
        subGame: getImg("swordofares.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20KRAKEN2",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("releasethekraken2.webp"),
        subGame: getImg("releasethekraken2.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSFRYWLD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("spinscoremegaways.webp"),
        subGame: getImg("spinscoremegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20THEIGHTS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("toweringfortunes.webp"),
        subGame: getImg("toweringfortunes.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS100SH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("shininghot100.webp"),
        subGame: getImg("shininghot100.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS40SH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("shininghot40.webp"),
        subGame: getImg("shininghot40.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20SH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("shininghot20.webp"),
        subGame: getImg("shininghot20.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS5SH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("shininghot5.webp"),
        subGame: getImg("shininghot5.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS5FIREHOT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("firehot5.webp"),
        subGame: getImg("firehot5.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20FH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("firehot20.webp"),
        subGame: getImg("firehot20.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS40FIREHOT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("firehot40.webp"),
        subGame: getImg("firehot40.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS100FIREHOT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("firehot100.webp"),
        subGame: getImg("firehot100.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20OCTOBEER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("octobeerfortunes.webp"),
        subGame: getImg("octobeerfortunes.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS40MSTRWILD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("happyhooves.webp"),
        subGame: getImg("happyhooves.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS5STRH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("strikinghot5.webp"),
        subGame: getImg("strikinghot5.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSBOOK",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bookofgoldensands.webp"),
        subGame: getImg("bookofgoldensands.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20UNDERGROUND",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("downtherails.webp"),
        subGame: getImg("downtherails.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25DWARVES",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dwarvengold.webp"),
        subGame: getImg("dwarvengold.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20ROME",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gloriousrome.webp"),
        subGame: getImg("gloriousrome.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20EGYPT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("talesofegypt.webp"),
        subGame: getImg("talesofegypt.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS13LADYOFMOON",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ladyofthemoon.webp"),
        subGame: getImg("ladyofthemoon.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS50AMT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("aladdinstreasure.webp"),
        subGame: getImg("aladdinstreasure.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS243CRYSTALCAVE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("magiccrystals.webp"),
        subGame: getImg("magiccrystals.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS1024ATLANTIS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("queenofatlantis.webp"),
        subGame: getImg("queenofatlantis.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25PANTHERQUEEN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pantherqueen.webp"),
        subGame: getImg("pantherqueen.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS4096JURASSIC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jurassicgiants.webp"),
        subGame: getImg("jurassicgiants.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25CHAMP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thechampions.webp"),
        subGame: getImg("thechampions.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS5TRJOKERS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("triplejokers.webp"),
        subGame: getImg("triplejokers.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS40HOTBURNX",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hottoburnextreme.webp"),
        subGame: getImg("hottoburnextreme.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25ROMEOANDJULIET",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("romeoandjuliet.webp"),
        subGame: getImg("romeoandjuliet.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20HOCKEY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hockeyleague.webp"),
        subGame: getImg("hockeyleague.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS9CATZ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thecatfather.webp"),
        subGame: getImg("thecatfather.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS30CATZ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thecatfatherpartii.webp"),
        subGame: getImg("thecatfatherpartii.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS9HOCKEY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hockeyleaguewildmatch.webp"),
        subGame: getImg("hockeyleaguewildmatch.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10BOOKVIKING",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bookofvikings.webp"),
        subGame: getImg("bookofvikings.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS50NORTHGARD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("northguardians.webp"),
        subGame: getImg("northguardians.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20GOBNUDGE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goblinheistpowernudge.webp"),
        subGame: getImg("goblinheistpowernudge.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS5LITTLEGEM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("littlegem.webp"),
        subGame: getImg("littlegem.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20CLEOCATRA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("cleocatra.webp"),
        subGame: getImg("cleocatra.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10EGRICH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("queenofgods.webp"),
        subGame: getImg("queenofgods.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25BOMB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bombbonanza.webp"),
        subGame: getImg("bombbonanza.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS40COSMICCASH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("cosmiccash.webp"),
        subGame: getImg("cosmiccash.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSJKRDROP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tropicaltiki.webp"),
        subGame: getImg("tropicaltiki.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10MMM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("magicmoneymaze.webp"),
        subGame: getImg("magicmoneymaze.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20WOLFIE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("greedywolf.webp"),
        subGame: getImg("greedywolf.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20TRSWILD2",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackbull.webp"),
        subGame: getImg("blackbull.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10TXBIGBASS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bigbasssplash.webp"),
        subGame: getImg("bigbasssplash.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSZOMBCARN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("zombiecarnival.webp"),
        subGame: getImg("zombiecarnival.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20STICKYSYMBOL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thegreatstickup.webp"),
        subGame: getImg("thegreatstickup.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS40CLEOEYE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("eyeofcleopatra.webp"),
        subGame: getImg("eyeofcleopatra.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10FIRESTRIKE2",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("firestrike2.webp"),
        subGame: getImg("firestrike2.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10SPIRITADV",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("spiritofadventure.webp"),
        subGame: getImg("spiritofadventure.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20DRTGOLD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("drillthatgold.webp"),
        subGame: getImg("drillthatgold.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20MUSTANGGLD2",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("clovergold.webp"),
        subGame: getImg("clovergold.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSWILDWEST",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildwestgoldmegaways.webp"),
        subGame: getImg("wildwestgoldmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10CHKCHASE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("chickenchase.webp"),
        subGame: getImg("chickenchase.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20FARMFEST",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("barnfestival.webp"),
        subGame: getImg("barnfestival.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS243QUEENIE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("queenie.webp"),
        subGame: getImg("queenie.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10TICTAC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tictactake.webp"),
        subGame: getImg("tictactake.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20RAINBOWG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("rainbowgold.webp"),
        subGame: getImg("rainbowgold.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10SNAKELADD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("snakesandladdersmegadice.webp"),
        subGame: getImg("snakesandladdersmegadice.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS50MIGHTRA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mightofra.webp"),
        subGame: getImg("mightofra.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20BCHPRTY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildbeachparty.webp"),
        subGame: getImg("wildbeachparty.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20ULTIM5",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("theultimate5.webp"),
        subGame: getImg("theultimate5.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20COLCASHZONE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("colossalcashzone.webp"),
        subGame: getImg("colossalcashzone.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25COPSROBBERS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("cashpatrol.webp"),
        subGame: getImg("cashpatrol.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20ROCKVEGAS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("rockvegas.webp"),
        subGame: getImg("rockvegas.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSELEMENTS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("elementalgemsmegaways.webp"),
        subGame: getImg("elementalgemsmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS5SPJOKER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("superjoker.webp"),
        subGame: getImg("superjoker.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS5SUPER7",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("super7s.webp"),
        subGame: getImg("super7s.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS40STREETRACER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("streetracer.webp"),
        subGame: getImg("streetracer.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS117649STARZ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("starzmegaways.webp"),
        subGame: getImg("starzmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20STARLIGHT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("starlightprincess.webp"),
        subGame: getImg("starlightprincess.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10STARPIRATE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("starpiratescode.webp"),
        subGame: getImg("starpiratescode.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS40WANDERW",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wilddepths.webp"),
        subGame: getImg("wilddepths.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSXJUICY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("extrajuicymegaways.webp"),
        subGame: getImg("extrajuicymegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSYUMYUM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("yumyumpowerways.webp"),
        subGame: getImg("yumyumpowerways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25WOLFGOLD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wolfgold.webp"),
        subGame: getImg("wolfgold.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS576TREASURES",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildwildriches.webp"),
        subGame: getImg("wildwildriches.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25WALKER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildwalker.webp"),
        subGame: getImg("wildwalker.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25WILDSPELLS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildspells.webp"),
        subGame: getImg("wildspells.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25GLADIATOR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildgladiator.webp"),
        subGame: getImg("wildgladiator.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20WILDBOOST",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildbooster.webp"),
        subGame: getImg("wildbooster.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25VEGAS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("vegasnights.webp"),
        subGame: getImg("vegasnights.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20VEGASMAGIC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("vegasmagic.webp"),
        subGame: getImg("vegasmagic.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS5ULTRA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ultraholdandspin.webp"),
        subGame: getImg("ultraholdandspin.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS5ULTRAB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ultraburn.webp"),
        subGame: getImg("ultraburn.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS5TRDRAGONS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tripledragons.webp"),
        subGame: getImg("tripledragons.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS40VOODOO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("voodoomagic.webp"),
        subGame: getImg("voodoomagic.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10VAMPWOLF",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("vampiresvswolves.webp"),
        subGame: getImg("vampiresvswolves.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS1TIGERS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tripletigers.webp"),
        subGame: getImg("tripletigers.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS1FORTUNETREE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("treeofriches.webp"),
        subGame: getImg("treeofriches.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS40WILDWEST",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildwestgold.webp"),
        subGame: getImg("wildwestgold.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20WILDPIX",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildpixies.webp"),
        subGame: getImg("wildpixies.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20TRSBOX",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("treasurewild.webp"),
        subGame: getImg("treasurewild.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10THREESTAR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("threestarfortune.webp"),
        subGame: getImg("threestarfortune.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20GORILLA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("junglegorilla.webp"),
        subGame: getImg("junglegorilla.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS50JUICYFR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("juicyfruits.webp"),
        subGame: getImg("juicyfruits.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS18MASHANG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("treasurehorse.webp"),
        subGame: getImg("treasurehorse.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20OLYMPGATE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gatesofolympus.webp"),
        subGame: getImg("gatesofolympus.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS40FRRAINBOW",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fruitrainbow.webp"),
        subGame: getImg("fruitrainbow.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20FPARTY2",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fruitparty2.webp"),
        subGame: getImg("fruitparty2.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20FRUITPARTY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fruitparty.webp"),
        subGame: getImg("fruitparty.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20AMULETEG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fortuneofgiza.webp"),
        subGame: getImg("fortuneofgiza.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10FLOATDRG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("floatingdragon.webp"),
        subGame: getImg("floatingdragon.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10GOLDFISH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fishinreels.webp"),
        subGame: getImg("fishinreels.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10FIRESTRIKE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("firestrike.webp"),
        subGame: getImg("firestrike.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS7FIRE88",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fire88.webp"),
        subGame: getImg("fire88.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS15FAIRYTALE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fairytalefortune.webp"),
        subGame: getImg("fairytalefortune.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10EYESTORM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("eyeofthestorm.webp"),
        subGame: getImg("eyeofthestorm.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10FRUITY2",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("extrajuicy.webp"),
        subGame: getImg("extrajuicy.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20EMPTYBANK",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("emptythebank.webp"),
        subGame: getImg("emptythebank.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20EKINGRR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("emeraldkingrainbowroad.webp"),
        subGame: getImg("emeraldkingrainbowroad.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20EKING",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("emeraldking.webp"),
        subGame: getImg("emeraldking.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS40SPARTAKING",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("spartanking.webp"),
        subGame: getImg("spartanking.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20SANTAWONDER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("santaswonderland.webp"),
        subGame: getImg("santaswonderland.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS50SAFARIKING",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("safariking.webp"),
        subGame: getImg("safariking.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10NUDGEIT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("riseofgizapowernudge.webp"),
        subGame: getImg("riseofgizapowernudge.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10RETURNDEAD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("returnofthedead.webp"),
        subGame: getImg("returnofthedead.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25QUEENOFGOLD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("queenofgold.webp"),
        subGame: getImg("queenofgold.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25PYRAMID",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pyramidking.webp"),
        subGame: getImg("pyramidking.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS50PIXIE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pixiewings.webp"),
        subGame: getImg("pixiewings.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS40PIRGOLD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pirategolddeluxe.webp"),
        subGame: getImg("pirategolddeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS9PIGGYBANK",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("piggybankbills.webp"),
        subGame: getImg("piggybankbills.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20PHOENIXF",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("phoenixforge.webp"),
        subGame: getImg("phoenixforge.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25PANDAGOLD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pandasfortune.webp"),
        subGame: getImg("pandasfortune.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSHIVE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("starbounty.webp"),
        subGame: getImg("starbounty.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20SANTA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("santa.webp"),
        subGame: getImg("santa.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20KRAKEN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("releasethekraken.webp"),
        subGame: getImg("releasethekraken.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSHAMMTHOR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("powerofthormegaways.webp"),
        subGame: getImg("powerofthormegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS40PIRATE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pirategold.webp"),
        subGame: getImg("pirategold.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25PEKING",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pekingluck.webp"),
        subGame: getImg("pekingluck.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25PANDATEMPLE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pandafortune2.webp"),
        subGame: getImg("pandafortune2.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS4096MYSTERY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mysterious.webp"),
        subGame: getImg("mysterious.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS9MADMONKEY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("monkeymadness.webp"),
        subGame: getImg("monkeymadness.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS50KINGKONG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mightykong.webp"),
        subGame: getImg("mightykong.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS8MAGICJOURN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("magicjourney.webp"),
        subGame: getImg("magicjourney.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS4096MAGICIAN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("magicianssecrets.webp"),
        subGame: getImg("magicianssecrets.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSWEST",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mysticchief.webp"),
        subGame: getImg("mysticchief.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10WILDTUT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mysteriousegypt.webp"),
        subGame: getImg("mysteriousegypt.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25MUSTANG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mustanggold.webp"),
        subGame: getImg("mustanggold.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS243MWARRIOR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("monkeywarrior.webp"),
        subGame: getImg("monkeywarrior.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25MMOUSE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("moneymouse.webp"),
        subGame: getImg("moneymouse.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS1MASTERJOKER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("masterjoker.webp"),
        subGame: getImg("masterjoker.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS9CHEN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("masterchensfortune.webp"),
        subGame: getImg("masterchensfortune.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20SMUGCOVE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("smugglerscove.webp"),
        subGame: getImg("smugglerscove.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSCRYSCAV",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("crystalcavernsmegaways.webp"),
        subGame: getImg("crystalcavernsmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10MADAME",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("madamedestiny.webp"),
        subGame: getImg("madamedestiny.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25GOLDPARTY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldparty.webp"),
        subGame: getImg("goldparty.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10LUCKCHARM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("luckygracecharm.webp"),
        subGame: getImg("luckygracecharm.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSLIGHT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("luckylightning.webp"),
        subGame: getImg("luckylightning.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS50CHINESECHARMS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("luckydragons.webp"),
        subGame: getImg("luckydragons.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20LEPRECHAUN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("leprechaunsong.webp"),
        subGame: getImg("leprechaunsong.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20LEPREXMAS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("leprechauncarol.webp"),
        subGame: getImg("leprechauncarol.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20GODIVA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ladygodiva.webp"),
        subGame: getImg("ladygodiva.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25JOURNEY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("journeytothewest.webp"),
        subGame: getImg("journeytothewest.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSMADAME",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("madamedestinymegaways.webp"),
        subGame: getImg("madamedestinymegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10RUNES",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gatesofvalhalla.webp"),
        subGame: getImg("gatesofvalhalla.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS5HOTBURN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hottoburn.webp"),
        subGame: getImg("hottoburn.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25NEWYEAR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("luckynewyear.webp"),
        subGame: getImg("luckynewyear.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS5JOKER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jokersjewels.webp"),
        subGame: getImg("jokersjewels.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10MAYANGODS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("johnhunterandthemayangods.webp"),
        subGame: getImg("johnhunterandthemayangods.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS9HOTROLL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hotchilli.webp"),
        subGame: getImg("hotchilli.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25JOKERKING",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jokerking.webp"),
        subGame: getImg("jokerking.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25SCARABQUEEN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("johnhunterandthetombofthescarabqueen.webp"),
        subGame: getImg("johnhunterandthetombofthescarabqueen.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS1024BUTTERFLY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jadebutterfly.webp"),
        subGame: getImg("jadebutterfly.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20HBURNHS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hottoburnholdandspin.webp"),
        subGame: getImg("hottoburnholdandspin.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25SAFARI",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hotsafari.webp"),
        subGame: getImg("hotsafari.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25HOTFIESTA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hotfiesta.webp"),
        subGame: getImg("hotfiesta.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20HONEY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("honeyhoneyhoney.webp"),
        subGame: getImg("honeyhoneyhoney.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20HERCPEG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("herculesandpegasus.webp"),
        subGame: getImg("herculesandpegasus.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS50HERCULES",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("herculessonofzeus.webp"),
        subGame: getImg("herculessonofzeus.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS243FORTSEREN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("greekgods.webp"),
        subGame: getImg("greekgods.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSRHINO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("greatrhinomegaways.webp"),
        subGame: getImg("greatrhinomegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20RHINO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("greatrhino.webp"),
        subGame: getImg("greatrhino.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25SEA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("greatreef.webp"),
        subGame: getImg("greatreef.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS75EMPRESS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldenbeauty.webp"),
        subGame: getImg("goldenbeauty.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25GOLDRUSH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldrush.webp"),
        subGame: getImg("goldrush.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20EGYPTTRS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("egyptianfortunes.webp"),
        subGame: getImg("egyptianfortunes.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25DWARVES_NEW",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dwarvengolddeluxe.webp"),
        subGame: getImg("dwarvengolddeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS5DRMYSTERY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragonkingdomeyesoffire.webp"),
        subGame: getImg("dragonkingdomeyesoffire.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25DRAGONKINGDOM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragonkingdom.webp"),
        subGame: getImg("dragonkingdom.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS1600DRAGO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragojewelsoffortune.webp"),
        subGame: getImg("dragojewelsoffortune.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS15DIAMOND",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("diamondstrike.webp"),
        subGame: getImg("diamondstrike.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20DAYDEAD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dayofdead.webp"),
        subGame: getImg("dayofdead.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS243DANCINGPAR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("danceparty.webp"),
        subGame: getImg("danceparty.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSWEREWOLF",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("curseofthewerewolfmegaways.webp"),
        subGame: getImg("curseofthewerewolfmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10COWGOLD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("cowboysgold.webp"),
        subGame: getImg("cowboysgold.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20XMASCAROL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("christmascarolmegaways.webp"),
        subGame: getImg("christmascarolmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10BXMASBNZA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("christmasbigbassbonanza.webp"),
        subGame: getImg("christmasbigbassbonanza.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25CHILLI",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("chilliheat.webp"),
        subGame: getImg("chilliheat.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20CHICKDROP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("chickendrop.webp"),
        subGame: getImg("chickendrop.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSBANKBONZ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("cashbonanza.webp"),
        subGame: getImg("cashbonanza.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS243CAISHIEN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("caishenscash.webp"),
        subGame: getImg("caishenscash.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS4096BUFKING",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("buffaloking.webp"),
        subGame: getImg("buffaloking.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10BOOKFALLEN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bookoffallen.webp"),
        subGame: getImg("bookoffallen.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25BKOFKNGDM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bookofkingdoms.webp"),
        subGame: getImg("bookofkingdoms.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS12BBB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("biggerbassbonanza.webp"),
        subGame: getImg("biggerbassbonanza.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSBBB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bigbassbonanzamegaways.webp"),
        subGame: getImg("bigbassbonanzamegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10BBBONANZA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bigbassbonanza.webp"),
        subGame: getImg("bigbassbonanza.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25RIO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("heartofrio.webp"),
        subGame: getImg("heartofrio.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20RHINOLUXE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("greatrhinodeluxe.webp"),
        subGame: getImg("greatrhinodeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS3TRAIN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldtrain.webp"),
        subGame: getImg("goldtrain.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20GOLDFEVER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gemsbonanza.webp"),
        subGame: getImg("gemsbonanza.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS5DRHS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragonhotholdspin.webp"),
        subGame: getImg("dragonhotholdspin.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25DAVINCI",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("davincistreasure.webp"),
        subGame: getImg("davincistreasure.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS432CONGOCASH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("congocash.webp"),
        subGame: getImg("congocash.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSCHILHEAT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("chilliheatmegaways.webp"),
        subGame: getImg("chilliheatmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20TERRORV",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("cashelevator.webp"),
        subGame: getImg("cashelevator.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS243FORTUNE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("caishensgold.webp"),
        subGame: getImg("caishensgold.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSBUFKING",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("buffalokingmegaways.webp"),
        subGame: getImg("buffalokingmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS40BIGJUAN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bigjuan.webp"),
        subGame: getImg("bigjuan.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS5AZTECGEMS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("aztecgems.webp"),
        subGame: getImg("aztecgems.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10EGYPTCLS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ancientegyptclassic.webp"),
        subGame: getImg("ancientegyptclassic.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS9AZTECGEMSDX",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("aztecgemsdeluxe.webp"),
        subGame: getImg("aztecgemsdeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS7776AZTEC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("aztecbonanza.webp"),
        subGame: getImg("aztecbonanza.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25ASGARD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("asgard.webp"),
        subGame: getImg("asgard.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS10EGYPT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ancientegypt.webp"),
        subGame: getImg("ancientegypt.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20ALADDINSORC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("aladdinandthesorcerer.webp"),
        subGame: getImg("aladdinandthesorcerer.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS1DRAGON8",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("888dragons.webp"),
        subGame: getImg("888dragons.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS7MONKEYS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("7monkeys.webp"),
        subGame: getImg("7monkeys.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS1024LIONSD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("5lionsdance.webp"),
        subGame: getImg("5lionsdance.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS243LIONS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("5lions.webp"),
        subGame: getImg("5lions.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25KINGDOMS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("3kingdomsbattleofredcliffs.webp"),
        subGame: getImg("3kingdomsbattleofredcliffs.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20EIGHTDRAGONS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("8dragons.webp"),
        subGame: getImg("8dragons.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS7PIGS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("7piggies.webp"),
        subGame: getImg("7piggies.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSLIONS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("5lionsmegaways.webp"),
        subGame: getImg("5lionsmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS243LIONSGOLD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("5lionsgold.webp"),
        subGame: getImg("5lionsgold.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_CS3IRISHCHARMS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("irishcharms.webp"),
        subGame: getImg("irishcharms.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_CS3W",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("diamondsareforever3lines.webp"),
        subGame: getImg("diamondsareforever3lines.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS25BTYGOLD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bountygold.webp"),
        subGame: getImg("bountygold.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_CS5TRIPLE8GOLD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("888gold.webp"),
        subGame: getImg("888gold.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS50ALADDIN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("3geniewishes.webp"),
        subGame: getImg("3geniewishes.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS75BRONCO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("broncospirit.webp"),
        subGame: getImg("broncospirit.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20SUPERX",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("superx.webp"),
        subGame: getImg("superx.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS40MADWHEEL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thewildmachine.webp"),
        subGame: getImg("thewildmachine.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20MAGICPOT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("themagiccauldron.webp"),
        subGame: getImg("themagiccauldron.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20MIDAS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thehandofmidas.webp"),
        subGame: getImg("thehandofmidas.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20CHICKEN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thegreatchickenescape.webp"),
        subGame: getImg("thegreatchickenescape.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS1024DTIGER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thedragontiger.webp"),
        subGame: getImg("thedragontiger.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VSWAYSDOGS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thedoghousemegaways.webp"),
        subGame: getImg("thedoghousemegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20DOGHOUSE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thedoghouse.webp"),
        subGame: getImg("thedoghouse.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS1024TEMUJ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("temujintreasures.webp"),
        subGame: getImg("temujintreasures.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20SBXMAS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sweetbonanzaxmas.webp"),
        subGame: getImg("sweetbonanzaxmas.webp"),
      },
    },
    {
      subCode: "GTAG_PRAGMATICPLAY_VS20FRUITSW",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sweetbonanza.webp"),
        subGame: getImg("sweetbonanza.webp"),
      },
    },
  ],
} as LIBRARY_GAME;
