import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('AMEBA', fileName)
};

const TW = [
  {
    subCode: "AMEBA_2009",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_2009_zhTW.webp"),
      subGame: getImg("AMEBA_2009_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_2003",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_2003_zhTW.webp"),
      subGame: getImg("AMEBA_2003_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_55",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_55_zhTW.webp"),
      subGame: getImg("AMEBA_55_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_53",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_53_zhTW.webp"),
      subGame: getImg("AMEBA_53_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_51",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_51_zhTW.webp"),
      subGame: getImg("AMEBA_51_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_3",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_3_zhTW.webp"),
      subGame: getImg("AMEBA_3_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_49",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_49_zhTW.webp"),
      subGame: getImg("AMEBA_49_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_47",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_47_zhTW.webp"),
      subGame: getImg("AMEBA_47_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_25",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_25_zhTW.webp"),
      subGame: getImg("AMEBA_25_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_45",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_45_zhTW.webp"),
      subGame: getImg("AMEBA_45_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_117",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_117_zhTW.webp"),
      subGame: getImg("AMEBA_117_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_36",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_36_zhTW.webp"),
      subGame: getImg("AMEBA_36_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_43",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_43_zhTW.webp"),
      subGame: getImg("AMEBA_43_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_132",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_132_zhTW.webp"),
      subGame: getImg("AMEBA_132_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_5",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_5_zhTW.webp"),
      subGame: getImg("AMEBA_5_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_41",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_41_zhTW.webp"),
      subGame: getImg("AMEBA_41_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_42",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_42_zhTW.webp"),
      subGame: getImg("AMEBA_42_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_6",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_6_zhTW.webp"),
      subGame: getImg("AMEBA_6_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_40",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_40_zhTW.webp"),
      subGame: getImg("AMEBA_40_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_38",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_38_zhTW.webp"),
      subGame: getImg("AMEBA_38_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_39",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_39_zhTW.webp"),
      subGame: getImg("AMEBA_39_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_31",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_31_zhTW.webp"),
      subGame: getImg("AMEBA_31_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_29",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_29_zhTW.webp"),
      subGame: getImg("AMEBA_29_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_37",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_37_zhTW.webp"),
      subGame: getImg("AMEBA_37_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_26",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_26_zhTW.webp"),
      subGame: getImg("AMEBA_26_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_32",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_32_zhTW.webp"),
      subGame: getImg("AMEBA_32_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_28",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_28_zhTW.webp"),
      subGame: getImg("AMEBA_28_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_27",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_27_zhTW.webp"),
      subGame: getImg("AMEBA_27_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_24",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_24_zhTW.webp"),
      subGame: getImg("AMEBA_24_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_17",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_17_zhTW.webp"),
      subGame: getImg("AMEBA_17_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_20",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_20_zhTW.webp"),
      subGame: getImg("AMEBA_20_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_10",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_10_zhTW.webp"),
      subGame: getImg("AMEBA_10_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_22",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_22_zhTW.webp"),
      subGame: getImg("AMEBA_22_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_23",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_23_zhTW.webp"),
      subGame: getImg("AMEBA_23_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_19",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_19_zhTW.webp"),
      subGame: getImg("AMEBA_19_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_18",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_18_zhTW.webp"),
      subGame: getImg("AMEBA_18_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_11",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_11_zhTW.webp"),
      subGame: getImg("AMEBA_11_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_7",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_7_zhTW.webp"),
      subGame: getImg("AMEBA_7_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_3",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_3_zhTW.webp"),
      subGame: getImg("AMEBA_3_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_13",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_13_zhTW.webp"),
      subGame: getImg("AMEBA_13_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_16",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_16_zhTW.webp"),
      subGame: getImg("AMEBA_16_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_12",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_12_zhTW.webp"),
      subGame: getImg("AMEBA_12_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_15",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_15_zhTW.webp"),
      subGame: getImg("AMEBA_15_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_4",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_4_zhTW.webp"),
      subGame: getImg("AMEBA_4_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_8",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_8_zhTW.webp"),
      subGame: getImg("AMEBA_8_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_2",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_2_zhTW.webp"),
      subGame: getImg("AMEBA_2_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_106",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_106_zhTW.webp"),
      subGame: getImg("AMEBA_106_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_9",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_9_zhTW.webp"),
      subGame: getImg("AMEBA_9_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_1",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_1_zhTW.webp"),
      subGame: getImg("AMEBA_1_zhTW.webp"),
    },
  },
  {
    subCode: "AMEBA_14",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_14_zhTW.webp"),
      subGame: getImg("AMEBA_14_zhTW.webp"),
    },
  },
];

const CN = [
  {
    subCode: "AMEBA_2009",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_2009_zhCN.webp"),
      subGame: getImg("AMEBA_2009_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_2003",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_2003_zhCN.webp"),
      subGame: getImg("AMEBA_2003_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_55",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_55_zhCN.webp"),
      subGame: getImg("AMEBA_55_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_53",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_53_zhCN.webp"),
      subGame: getImg("AMEBA_53_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_51",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_51_zhCN.webp"),
      subGame: getImg("AMEBA_51_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_3",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_3_zhCN.webp"),
      subGame: getImg("AMEBA_3_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_49",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_49_zhCN.webp"),
      subGame: getImg("AMEBA_49_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_47",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_47_zhCN.webp"),
      subGame: getImg("AMEBA_47_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_25",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_25_zhCN.webp"),
      subGame: getImg("AMEBA_25_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_45",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_45_zhCN.webp"),
      subGame: getImg("AMEBA_45_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_117",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_117_zhCN.webp"),
      subGame: getImg("AMEBA_117_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_36",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_36_zhCN.webp"),
      subGame: getImg("AMEBA_36_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_43",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_43_zhCN.webp"),
      subGame: getImg("AMEBA_43_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_132",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_132_zhCN.webp"),
      subGame: getImg("AMEBA_132_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_5",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_5_zhCN.webp"),
      subGame: getImg("AMEBA_5_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_41",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_41_zhCN.webp"),
      subGame: getImg("AMEBA_41_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_42",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_42_zhCN.webp"),
      subGame: getImg("AMEBA_42_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_6",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_6_zhCN.webp"),
      subGame: getImg("AMEBA_6_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_40",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_40_zhCN.webp"),
      subGame: getImg("AMEBA_40_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_38",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_38_zhCN.webp"),
      subGame: getImg("AMEBA_38_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_39",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_39_zhCN.webp"),
      subGame: getImg("AMEBA_39_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_31",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_31_zhCN.webp"),
      subGame: getImg("AMEBA_31_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_29",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_29_zhCN.webp"),
      subGame: getImg("AMEBA_29_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_37",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_37_zhCN.webp"),
      subGame: getImg("AMEBA_37_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_26",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_26_zhCN.webp"),
      subGame: getImg("AMEBA_26_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_32",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_32_zhCN.webp"),
      subGame: getImg("AMEBA_32_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_28",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_28_zhCN.webp"),
      subGame: getImg("AMEBA_28_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_27",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_27_zhCN.webp"),
      subGame: getImg("AMEBA_27_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_24",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_24_zhCN.webp"),
      subGame: getImg("AMEBA_24_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_17",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_17_zhCN.webp"),
      subGame: getImg("AMEBA_17_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_20",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_20_zhCN.webp"),
      subGame: getImg("AMEBA_20_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_10",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_10_zhCN.webp"),
      subGame: getImg("AMEBA_10_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_22",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_22_zhCN.webp"),
      subGame: getImg("AMEBA_22_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_23",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_23_zhCN.webp"),
      subGame: getImg("AMEBA_23_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_19",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_19_zhCN.webp"),
      subGame: getImg("AMEBA_19_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_18",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_18_zhCN.webp"),
      subGame: getImg("AMEBA_18_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_11",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_11_zhCN.webp"),
      subGame: getImg("AMEBA_11_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_7",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_7_zhCN.webp"),
      subGame: getImg("AMEBA_7_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_3",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_3_zhCN.webp"),
      subGame: getImg("AMEBA_3_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_13",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_13_zhCN.webp"),
      subGame: getImg("AMEBA_13_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_16",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_16_zhCN.webp"),
      subGame: getImg("AMEBA_16_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_12",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_12_zhCN.webp"),
      subGame: getImg("AMEBA_12_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_15",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_15_zhCN.webp"),
      subGame: getImg("AMEBA_15_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_4",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_4_zhCN.webp"),
      subGame: getImg("AMEBA_4_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_8",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_8_zhCN.webp"),
      subGame: getImg("AMEBA_8_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_2",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_2_zhCN.webp"),
      subGame: getImg("AMEBA_2_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_106",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_106_zhCN.webp"),
      subGame: getImg("AMEBA_106_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_9",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_9_zhCN.webp"),
      subGame: getImg("AMEBA_9_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_1",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_1_zhCN.webp"),
      subGame: getImg("AMEBA_1_zhCN.webp"),
    },
  },
  {
    subCode: "AMEBA_14",
    type: ["EGAMING"],
    thumb: {
      img: getImg("AMEBA_14_zhCN.webp"),
      subGame: getImg("AMEBA_14_zhCN.webp"),
    },
  },
];

export default {
  code: "AMEBA",
  type: ["EGAMING"],
  play_method: "URL",
  thumb: {
    img: getImg("logo-square.webp"), // 方形
    // img: getImg("logo-whiteLayer.webp"), // 橫條帶白邊
    // img: getImg("logo-normal.webp"), // 橫條
  },
  subGames: [
    {
      subCode: "AMEBA_2009",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_2009.webp"),
        subGame: getImg("AMEBA_2009.webp"),
      },
    },
    {
      subCode: "AMEBA_2003",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_2003.webp"),
        subGame: getImg("AMEBA_2003.webp"),
      },
    },
    {
      subCode: "AMEBA_55",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_55.webp"),
        subGame: getImg("AMEBA_55.webp"),
      },
    },
    {
      subCode: "AMEBA_53",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_53.webp"),
        subGame: getImg("AMEBA_53.webp"),
      },
    },
    {
      subCode: "AMEBA_51",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_51.webp"),
        subGame: getImg("AMEBA_51.webp"),
      },
    },
    {
      subCode: "AMEBA_3",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_3.webp"),
        subGame: getImg("AMEBA_3.webp"),
      },
    },
    {
      subCode: "AMEBA_49",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_49.webp"),
        subGame: getImg("AMEBA_49.webp"),
      },
    },
    {
      subCode: "AMEBA_47",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_47.webp"),
        subGame: getImg("AMEBA_47.webp"),
      },
    },
    {
      subCode: "AMEBA_25",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_25.webp"),
        subGame: getImg("AMEBA_25.webp"),
      },
    },
    {
      subCode: "AMEBA_45",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_45.webp"),
        subGame: getImg("AMEBA_45.webp"),
      },
    },
    {
      subCode: "AMEBA_117",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_117.webp"),
        subGame: getImg("AMEBA_117.webp"),
      },
    },
    {
      subCode: "AMEBA_36",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_36.webp"),
        subGame: getImg("AMEBA_36.webp"),
      },
    },
    {
      subCode: "AMEBA_43",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_43.webp"),
        subGame: getImg("AMEBA_43.webp"),
      },
    },
    {
      subCode: "AMEBA_132",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_132.webp"),
        subGame: getImg("AMEBA_132.webp"),
      },
    },
    {
      subCode: "AMEBA_5",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_5.webp"),
        subGame: getImg("AMEBA_5.webp"),
      },
    },
    {
      subCode: "AMEBA_41",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_41.webp"),
        subGame: getImg("AMEBA_41.webp"),
      },
    },
    {
      subCode: "AMEBA_42",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_42.webp"),
        subGame: getImg("AMEBA_42.webp"),
      },
    },
    {
      subCode: "AMEBA_6",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_6.webp"),
        subGame: getImg("AMEBA_6.webp"),
      },
    },
    {
      subCode: "AMEBA_40",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_40.webp"),
        subGame: getImg("AMEBA_40.webp"),
      },
    },
    {
      subCode: "AMEBA_38",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_38.webp"),
        subGame: getImg("AMEBA_38.webp"),
      },
    },
    {
      subCode: "AMEBA_39",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_39.webp"),
        subGame: getImg("AMEBA_39.webp"),
      },
    },
    {
      subCode: "AMEBA_31",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_31.webp"),
        subGame: getImg("AMEBA_31.webp"),
      },
    },
    {
      subCode: "AMEBA_29",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_29.webp"),
        subGame: getImg("AMEBA_29.webp"),
      },
    },
    {
      subCode: "AMEBA_37",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_37.webp"),
        subGame: getImg("AMEBA_37.webp"),
      },
    },
    {
      subCode: "AMEBA_26",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_26.webp"),
        subGame: getImg("AMEBA_26.webp"),
      },
    },
    {
      subCode: "AMEBA_32",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_32.webp"),
        subGame: getImg("AMEBA_32.webp"),
      },
    },
    {
      subCode: "AMEBA_28",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_28.webp"),
        subGame: getImg("AMEBA_28.webp"),
      },
    },
    {
      subCode: "AMEBA_27",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_27.webp"),
        subGame: getImg("AMEBA_27.webp"),
      },
    },
    {
      subCode: "AMEBA_24",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_24.webp"),
        subGame: getImg("AMEBA_24.webp"),
      },
    },
    {
      subCode: "AMEBA_17",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_17.webp"),
        subGame: getImg("AMEBA_17.webp"),
      },
    },
    {
      subCode: "AMEBA_20",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_20.webp"),
        subGame: getImg("AMEBA_20.webp"),
      },
    },
    {
      subCode: "AMEBA_10",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_10.webp"),
        subGame: getImg("AMEBA_10.webp"),
      },
    },
    {
      subCode: "AMEBA_22",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_22.webp"),
        subGame: getImg("AMEBA_22.webp"),
      },
    },
    {
      subCode: "AMEBA_23",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_23.webp"),
        subGame: getImg("AMEBA_23.webp"),
      },
    },
    {
      subCode: "AMEBA_19",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_19.webp"),
        subGame: getImg("AMEBA_19.webp"),
      },
    },
    {
      subCode: "AMEBA_18",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_18.webp"),
        subGame: getImg("AMEBA_18.webp"),
      },
    },
    {
      subCode: "AMEBA_11",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_11.webp"),
        subGame: getImg("AMEBA_11.webp"),
      },
    },
    {
      subCode: "AMEBA_7",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_7.webp"),
        subGame: getImg("AMEBA_7.webp"),
      },
    },
    {
      subCode: "AMEBA_3",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_3.webp"),
        subGame: getImg("AMEBA_3.webp"),
      },
    },
    {
      subCode: "AMEBA_13",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_13.webp"),
        subGame: getImg("AMEBA_13.webp"),
      },
    },
    {
      subCode: "AMEBA_16",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_16.webp"),
        subGame: getImg("AMEBA_16.webp"),
      },
    },
    {
      subCode: "AMEBA_12",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_12.webp"),
        subGame: getImg("AMEBA_12.webp"),
      },
    },
    {
      subCode: "AMEBA_15",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_15.webp"),
        subGame: getImg("AMEBA_15.webp"),
      },
    },
    {
      subCode: "AMEBA_4",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_4.webp"),
        subGame: getImg("AMEBA_4.webp"),
      },
    },
    {
      subCode: "AMEBA_8",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_8.webp"),
        subGame: getImg("AMEBA_8.webp"),
      },
    },
    {
      subCode: "AMEBA_2",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_2.webp"),
        subGame: getImg("AMEBA_2.webp"),
      },
    },
    {
      subCode: "AMEBA_106",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_106.webp"),
        subGame: getImg("AMEBA_106.webp"),
      },
    },
    {
      subCode: "AMEBA_9",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_9.webp"),
        subGame: getImg("AMEBA_9.webp"),
      },
    },
    {
      subCode: "AMEBA_1",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_1.webp"),
        subGame: getImg("AMEBA_1.webp"),
      },
    },
    {
      subCode: "AMEBA_14",
      type: ["EGAMING"],
      thumb: {
        img: getImg("AMEBA_14.webp"),
        subGame: getImg("AMEBA_14.webp"),
      },
    },
  ],
  localeSetting: {
    zh_CN: { subGames: CN },
    zh_TW: { subGames: TW },
  },
} as LIBRARY_GAME;
