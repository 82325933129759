import { AxiosResponse } from "axios";
import $http from "../../../../FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "casinoWallet/front-wallet/transfer";
// TODO: 回傳 (不同遊戲會有不同回傳與使用方式)

export namespace typeDoModify {
  export interface request {
    /** 來源	必填,字串,max:32; 可帶入WALLET,其餘參考{p26_ges_code}	 */
    from: string;
    /** 目的	必填,字串,max:32; 可帶入WALLET,其餘參考{p26_ges_code}	 */
    to: string;
    /** 額度	必填,數值; min:0.01 */
    amount: string;
  }
  export interface AxiosResponse extends _AxiosResponse {}
}
interface _AxiosResponse extends AxiosResponse<responseData> {}

interface responseData extends BackendResponse {
  response: any;
}

/**
 * 轉點
 * http://redmine.mirfak.tw/projects/pkg-250000-casinowallet/repository/48/revisions/master/entry/_md/api/Front/Wallet/%E8%BD%89%E7%A7%BB.md
 */
export default async (request: typeDoModify.request) => {
  const getter = await $http.put(API, preformat(request));
  if (getter.data.code !== 0) throw getter;
  return getter;
};

/** 送出前的強制格式化，去除額外的參數 */
function preformat(request: typeDoModify.request) {
  return {
    from: request.from,
    to: request.to,
    amount: request.amount,
  };
}
