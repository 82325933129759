import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('GTAG_SPRIBE', fileName)
};

export default {
  code: "GTAG_SPRIBE",
  type: ["CASINO", "EGAMING", "LOTTERY"],
  play_method: "URL",
  thumb: {
    img: getImg("logo.webp"),
    logo: getImg("logo.webp"),
  },
  subGames: [
    {
      subCode: "GTAG_SPRIBE_SPB_HOTLINE",
      type: ["CASINO", "EGAMING"],
      thumb: {
        img: getImg("GTAG_SPRIBE_SPB_HOTLINE.webp"),
        subGame: getImg("GTAG_SPRIBE_SPB_HOTLINE.webp"),
      },
    },
    {
      subCode: "GTAG_SPRIBE_SPB_MINIROULETTE",
      type: ["CASINO", "EGAMING"],
      thumb: {
        img: getImg("GTAG_SPRIBE_SPB_MINIROULETTE.webp"),
        subGame: getImg("GTAG_SPRIBE_SPB_MINIROULETTE.webp"),
      },
    },
    {
      subCode: "GTAG_SPRIBE_SPB_KENO",
      type: ["LOTTERY", "EGAMING"],
      thumb: {
        img: getImg("GTAG_SPRIBE_SPB_KENO.webp"),
        subGame: getImg("GTAG_SPRIBE_SPB_KENO.webp"),
      },
    },
    {
      subCode: "GTAG_SPRIBE_SPB_HILO",
      type: ["CASINO", "EGAMING"],
      thumb: {
        img: getImg("GTAG_SPRIBE_SPB_HILO.webp"),
        subGame: getImg("GTAG_SPRIBE_SPB_HILO.webp"),
      },
    },
    {
      subCode: "GTAG_SPRIBE_SPB_MINES",
      type: ["EGAMING"],
      thumb: {
        img: getImg("GTAG_SPRIBE_SPB_MINES.webp"),
        subGame: getImg("GTAG_SPRIBE_SPB_MINES.webp"),
      },
    },
    {
      subCode: "GTAG_SPRIBE_SPB_PLINKO",
      type: ["EGAMING"],
      thumb: {
        img: getImg("GTAG_SPRIBE_SPB_PLINKO.webp"),
        subGame: getImg("GTAG_SPRIBE_SPB_PLINKO.webp"),
      },
    },
    {
      subCode: "GTAG_SPRIBE_SPB_GOAL",
      type: ["EGAMING"],
      thumb: {
        img: getImg("GTAG_SPRIBE_SPB_GOAL.webp"),
        subGame: getImg("GTAG_SPRIBE_SPB_GOAL.webp"),
      },
    },
    {
      subCode: "GTAG_SPRIBE_SPB_DICE",
      type: ["CASINO", "EGAMING"],
      thumb: {
        img: getImg("GTAG_SPRIBE_SPB_DICE.webp"),
        subGame: getImg("GTAG_SPRIBE_SPB_DICE.webp"),
      },
    },
    {
      subCode: "GTAG_SPRIBE_SPB_AVIATOR",
      type: ["EGAMING"],
      thumb: {
        img: getImg("GTAG_SPRIBE_SPB_AVIATOR.webp"),
        subGame: getImg("GTAG_SPRIBE_SPB_AVIATOR.webp"),
      },
    },
  ],
} as LIBRARY_GAME;
