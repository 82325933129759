import { AxiosResponse } from "axios";
import $http from "../../../FPKG-220000-CasinoCore/axios"; // 後端溝通
import isMobile from "@/modules/FPKG-20000-Util/library/mobileDetective";
// import _devPrintName from "./_devPrintName";
const API = "casinoGames/member/lists";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace typeLists {
  // export interface request {}
  export type response = listItem[];
  export type listItem = {
    /** 遊戲ID */
    p26_ges_id: number;
    /** 遊戲名稱 */
    p26_ges_name: string;
    /** 遊戲代碼 */
    p26_ges_code: string;
    /** 子遊戲 */
    sub_games?: sub_game[];
    /** 轉點錢包 0:轉點 1:免轉 */
    p26_gaio_transfer_type: 0 | 1;
    /** 維護中 */
    maintenance: boolean;
  };
  export type sub_game = {
    /** 子遊戲代碼 */
    p26_sgs_code: string;
    p26_sgs_name: string;
  };

  export type AxiosResponse = _AxiosResponse;
}
type _AxiosResponse = AxiosResponse<responseData>;

interface responseData extends BackendResponse {
  response: any;
}

/** 取得遊戲列表 */
export default async () => {
  const getter = await $http.get(API);
  if (getter.data.code !== 0) throw getter;
  const response = preformate(getter.data.response);
  // _devPrintName(response, "GTAG_PLAYSTAR");
  getter.data.response = response;
  return getter;
};

function preformate(res: typeLists.response) {
  if (!Array.isArray(res) || res.length === 0) return [];
  return (
    res
      /** remove fake Game */
      .filter(obj => !/FAKE_/.test(obj.p26_ges_code))
      .map(filtingSubGames)
  );

  /** 子遊戲依照裝置類型過濾 */
  function filtingSubGames(obj: typeLists.listItem) {
    switch (obj.p26_ges_code) {
      case "GOLDEN_F_PM_SPORTS":
      case "GOLDEN_F_PM_E_SPORTS":
        return SET_PM_SPORT();
      case "GOLDEN_F_PM_KENO":
        return SET_PM_KENO();
      case "GOLDEN_F_PM_LIVE":
        return SET_PM_LIVE();
      case "GOLDEN_F_IM_SPORT":
        return SET_IM_SPORT();
      default:
        return obj;
    }

    function SET_PM_SPORT() {
      const DEVICE = isMobile ? "APP" : "PC";
      return {
        ...obj,
        sub_games: (obj.sub_games || [])?.filter(subGame =>
          new RegExp(DEVICE, "i").test(subGame.p26_sgs_code),
        ),
      };
    }
    function SET_PM_KENO() {
      const DEVICE = isMobile ? "H5" : "PC";
      return {
        ...obj,
        sub_games: (obj.sub_games || [])?.filter(subGame =>
          new RegExp(DEVICE, "i").test(subGame.p26_sgs_code),
        ),
      };
    }

    function SET_PM_LIVE() {
      const DEVICE = isMobile ? "PC" : "H5";

      return {
        ...obj,
        sub_games: (obj.sub_games || [])?.filter(
          subGame => !new RegExp(DEVICE, "i").test(subGame.p26_sgs_name),
        ),
      };
    }

    function SET_IM_SPORT() {
      const DEVICE = isMobile ? "PC" : "APP";

      return {
        ...obj,
        sub_games: (obj.sub_games || [])?.filter(
          subGame => !new RegExp(DEVICE, "i").test(subGame.p26_sgs_code),
        ),
      };
    }
  }
}
