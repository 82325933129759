import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('GOLDEN_F_AE_SEXY', fileName)
};
export default {
  code: "GOLDEN_F_AE_SEXY",
  type: ["CASINO"],
  play_method: "URL",
  thumb: {
    img: getImg("logo.webp"),
  },
} as LIBRARY_GAME;
