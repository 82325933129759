/* eslint-disable no-param-reassign */
import Info, {
  typeInfo,
  setMethods,
} from "../service/casinoPaymentFlow/prepaySequence/info";
import Create, {
  typeCreate,
} from "../service/casinoPaymentFlow/auction/create";
import Lists, { typeLists } from "../service/casinoPaymentFlow/auction/lists";
import showMethodComponent from "../library/showMethodComponent";

type payForm = {
  request: typeCreate.request;
  response: typeCreate.response;
  component: string;
};

type state = {
  /** 能不能領錢看這個 */
  ratio_demand: typeInfo.p32_ratio_demand;
  /** 原始資訊 */
  rawData: typeInfo.p27_prepay_sequence[];
  /** 結帳資訊 */
  payForm: payForm | {};
  /** 拍賣紀錄 */
  record: typeLists.response;
};

const state: state = {
  ratio_demand: {
    base_setting_ratio: 0,
    grant_point: 0,
    valid_amount: "0",
  },
  rawData: [],
  payForm: {},
  record: {
    list: [],
    paginator: {
      sort_type: "ASC",
      count: 0,
      page: 0,
      page_num: 0,
      perpage: 0,
    },
  },
};

export default {
  namespaced: true,
  state,
  mutations: {
    setSell: (st: state, data: typeInfo.response) => {
      st.ratio_demand = data.p32_ratio_demand;
    },
    setList: (st: state, data: typeInfo.response) => {
      const list = data.p27_prepay_sequence;
      st.rawData = list;
    },
    setPayForm: (
      st: state,
      data: { request: typeCreate.request; response: typeCreate.response },
    ) => {
      const component = showMethodComponent(data);
      st.payForm = { ...data, component };
    },
    setRecord: (st: state, data: typeLists.response) => {
      st.record = data;
    },
    async cleanRecord(st: state) {
      st.record = {
        list: [],
        paginator: {
          sort_type: "ASC",
          count: 0,
          page: 0,
          page_num: 0,
          perpage: 0,
        },
      };
    },
  },
  actions: {
    /** 取款資訊 */
    async getInfo({ commit }: any) {
      const res = (await Info()).data.response;
      commit("setList", res);
      commit("setSell", res);
    },
    /** 進行拍賣 */
    async create({ commit }: any, request: typeCreate.request) {
      const response = await (await Create(request)).data.response;
      commit("setPayForm", { request, response });
    },
    /** 取得紀錄 */
    async getRecord({ commit }: any, request: typeLists.request) {
      const response = await (await Lists(request)).data.response;
      commit("setRecord", response);
    },
  },
  getters: {
    /** 決定用戶是否能取款 */
    canAuction(st: state) {
      const { valid_amount, grant_point, base_setting_ratio } = st.ratio_demand;
      const list = setMethods(st.rawData);
      /** 必須要有列表項目 */
      const listPass = Array.isArray(list) && list.length > 0;
      /** 無限制取款 */
      const unlimit = base_setting_ratio === 0 || base_setting_ratio === null;
      /** 一般取款條件 */
      const normalPass =
        parseFloat(valid_amount) >= grant_point * base_setting_ratio;

      return listPass && (unlimit || normalPass);
    },
    list(st: state) {
      return setMethods(st.rawData);
    },
    payForm(st: state) {
      return st.payForm;
    },
    record(st: state) {
      return st.record;
    },
  },
};
