import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('ONETOUCH', fileName)
};

// TAG_EXPANSE_
export default {
  code: "GTAG_ONETOUCH",
  type: ["EGAMING"],
  play_method: "URL",
  subGames: [
    {
      subCode: "GTAG_ONETOUCH_OT_ANDARBAHAR",
      thumb: {
        img: getImg("andarbahar.webp"),
        subGame: getImg("andarbahar.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_GRANDHEISTFEATUREBUY94",
      thumb: {
        img: getImg("grandheistfeaturebuy94.webp"),
        subGame: getImg("grandheistfeaturebuy94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_GIFTSOFHORUS",
      thumb: {
        img: getImg("giftsofhorus.webp"),
        subGame: getImg("giftsofhorus.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_DRAGONTIGER",
      thumb: {
        img: getImg("dragontiger.webp"),
        subGame: getImg("dragontiger.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_BLACKJACKCLASSICPP",
      thumb: {
        img: getImg("blackjackclassicpp.webp"),
        subGame: getImg("blackjackclassicpp.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_ANGELSANDDEMONS",
      thumb: {
        img: getImg("angelsanddemons.webp"),
        subGame: getImg("angelsanddemons.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_JUICY7-3REELS",
      thumb: {
        img: getImg("juicy73reels.webp"),
        subGame: getImg("juicy73reels.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_FORTUNEMINER-3REELS",
      thumb: {
        img: getImg("fortuneminer3reels.webp"),
        subGame: getImg("fortuneminer3reels.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_WILDCOYOTEMEGAWAYS94",
      thumb: {
        img: getImg("wildcoyotemegaways94.webp"),
        subGame: getImg("wildcoyotemegaways94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_WILDCOYOTEMEGAWAYS",
      thumb: {
        img: getImg("wildcoyotemegaways.webp"),
        subGame: getImg("wildcoyotemegaways.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_WHEELOFFORTUNE",
      thumb: {
        img: getImg("wheeloffortune.webp"),
        subGame: getImg("wheeloffortune.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_WATFORDSLOT94",
      thumb: {
        img: getImg("watfordslot94.webp"),
        subGame: getImg("watfordslot94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_WATFORDSLOT",
      thumb: {
        img: getImg("watfordslot.webp"),
        subGame: getImg("watfordslot.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_WACKYWILDLIFE",
      thumb: {
        img: getImg("wackywildlife.webp"),
        subGame: getImg("wackywildlife.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_TRAVELINGTREASURESINDIA94",
      thumb: {
        img: getImg("travelingtreasuresindia94.webp"),
        subGame: getImg("travelingtreasuresindia94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_TRAVELINGTREASURESINDIA",
      thumb: {
        img: getImg("travelingtreasuresindia.webp"),
        subGame: getImg("travelingtreasuresindia.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_TRAVELINGTREASURESBRAZIL94",
      thumb: {
        img: getImg("travelingtreasuresbrazil94.webp"),
        subGame: getImg("travelingtreasuresbrazil94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_TRAVELINGTREASURESBRAZIL",
      thumb: {
        img: getImg("travelingtreasuresbrazil.webp"),
        subGame: getImg("travelingtreasuresbrazil.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_TRAVELINGTREASURESAFRICA94",
      thumb: {
        img: getImg("travelingtreasuresafrica94.webp"),
        subGame: getImg("travelingtreasuresafrica94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_TRAVELINGTREASURESAFRICA",
      thumb: {
        img: getImg("travelingtreasuresafrica.webp"),
        subGame: getImg("travelingtreasuresafrica.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_TIKITERROR",
      thumb: {
        img: getImg("tikiterror.webp"),
        subGame: getImg("tikiterror.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_THEMAIDENANDTHESWORDMAN",
      thumb: {
        img: getImg("themaidenandtheswordman.webp"),
        subGame: getImg("themaidenandtheswordman.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_TEXASHOLDEM",
      thumb: {
        img: getImg("texasholdem.webp"),
        subGame: getImg("texasholdem.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_TEENPATTI",
      thumb: {
        img: getImg("teenpatti.webp"),
        subGame: getImg("teenpatti.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_SUMOSHOWDOWN",
      thumb: {
        img: getImg("sumoshowdown.webp"),
        subGame: getImg("sumoshowdown.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_STONEGAZE94",
      thumb: {
        img: getImg("stonegaze94.webp"),
        subGame: getImg("stonegaze94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_STONEGAZE",
      thumb: {
        img: getImg("stonegaze.webp"),
        subGame: getImg("stonegaze.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_STEAMVAULT",
      thumb: {
        img: getImg("steamvault.webp"),
        subGame: getImg("steamvault.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_SNACKBLAST94",
      thumb: {
        img: getImg("snackblast94.webp"),
        subGame: getImg("snackblast94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_SNACKBLAST",
      thumb: {
        img: getImg("snackblast.webp"),
        subGame: getImg("snackblast.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_SICBO",
      thumb: {
        img: getImg("sicbo.webp"),
        subGame: getImg("sicbo.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_SEATREASUREDEEPDIVE",
      thumb: {
        img: getImg("seatreasuredeepdive.webp"),
        subGame: getImg("seatreasuredeepdive.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_SEATREASURE94",
      thumb: {
        img: getImg("seatreasure94.webp"),
        subGame: getImg("seatreasure94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_SEATREASURE",
      thumb: {
        img: getImg("seatreasure.webp"),
        subGame: getImg("seatreasure.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_RYSEOFTHEMIGHTYGODS",
      thumb: {
        img: getImg("ryseofthemightygods.webp"),
        subGame: getImg("ryseofthemightygods.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_RYSEOFROME94",
      thumb: {
        img: getImg("ryseofrome94.webp"),
        subGame: getImg("ryseofrome94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_RYSEOFROME",
      thumb: {
        img: getImg("ryseofrome.webp"),
        subGame: getImg("ryseofrome.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_RUSSIANPOKER2",
      thumb: {
        img: getImg("russianpoker2.webp"),
        subGame: getImg("russianpoker2.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_RUSSIANPOKER",
      thumb: {
        img: getImg("russianpoker.webp"),
        subGame: getImg("russianpoker.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_ROULETTE",
      thumb: {
        img: getImg("roulette.webp"),
        subGame: getImg("roulette.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_REELFRUITZFRENZY94",
      thumb: {
        img: getImg("reelfruitzfrenzy94.webp"),
        subGame: getImg("reelfruitzfrenzy94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_REELFRUITZFRENZY",
      thumb: {
        img: getImg("reelfruitzfrenzy.webp"),
        subGame: getImg("reelfruitzfrenzy.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_RAIDENMEDALBURST94",
      thumb: {
        img: getImg("raidenmedalburst94.webp"),
        subGame: getImg("raidenmedalburst94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_WILDSORCERY",
      thumb: {
        img: getImg("wildsorcery.webp"),
        subGame: getImg("wildsorcery.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_WILDSORCERY94",
      thumb: {
        img: getImg("wildsorcery.webp"),
        subGame: getImg("wildsorcery.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_WILDWILDWEST212094",
      thumb: {
        img: getImg("wildwildwest2120.webp"),
        subGame: getImg("wildwildwest2120.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_WILDWILDWEST2120",
      thumb: {
        img: getImg("wildwildwest2120.webp"),
        subGame: getImg("wildwildwest2120.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_RAIDENMEDALBURST",
      thumb: {
        img: getImg("raidenmedalburst.webp"),
        subGame: getImg("raidenmedalburst.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_QUEENSOFGLORYLEGACY",
      thumb: {
        img: getImg("queensofglorylegacy.webp"),
        subGame: getImg("queensofglorylegacy.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_QUEENSOFGLORY",
      thumb: {
        img: getImg("queensofglory.webp"),
        subGame: getImg("queensofglory.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_PIPSQUEST94",
      thumb: {
        img: getImg("pipsquest94.webp"),
        subGame: getImg("pipsquest94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_PIPSQUEST",
      thumb: {
        img: getImg("pipsquest.webp"),
        subGame: getImg("pipsquest.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_NEON2077",
      thumb: {
        img: getImg("neon2077.webp"),
        subGame: getImg("neon2077.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_MVPHOOPS94",
      thumb: {
        img: getImg("mvphoops94.webp"),
        subGame: getImg("mvphoops94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_MVPHOOPS",
      thumb: {
        img: getImg("mvphoops.webp"),
        subGame: getImg("mvphoops.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_MIKOFESTIVAL94",
      thumb: {
        img: getImg("mikofestival94.webp"),
        subGame: getImg("mikofestival94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_MIKOFESTIVAL92",
      thumb: {
        img: getImg("mikofestival92.webp"),
        subGame: getImg("mikofestival92.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_MIKOFESTIVAL",
      thumb: {
        img: getImg("mikofestival.webp"),
        subGame: getImg("mikofestival.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_MAIDENANDSWORDMAN",
      thumb: {
        img: getImg("maidenandswordman.webp"),
        subGame: getImg("maidenandswordman.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_MAIDENANDSWORDMAN94",
      thumb: {
        img: getImg("maidenandswordman94.webp"),
        subGame: getImg("maidenandswordman94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_LUCKYLION94",
      thumb: {
        img: getImg("luckylion94.webp"),
        subGame: getImg("luckylion94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_LUCKYLION",
      thumb: {
        img: getImg("luckylion.webp"),
        subGame: getImg("luckylion.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_LOOTORBOOT93",
      thumb: {
        img: getImg("lootorboot93.webp"),
        subGame: getImg("lootorboot93.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_LOOTORBOOT",
      thumb: {
        img: getImg("lootorboot.webp"),
        subGame: getImg("lootorboot.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_LADYFORTUNA",
      thumb: {
        img: getImg("ladyfortuna.webp"),
        subGame: getImg("ladyfortuna.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_LADYFORTUNA94",
      thumb: {
        img: getImg("ladyfortuna.webp"),
        subGame: getImg("ladyfortuna.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_KINGDRAGONTIGER",
      thumb: {
        img: getImg("kingdragontiger.webp"),
        subGame: getImg("kingdragontiger.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_KENORUSH",
      thumb: {
        img: getImg("kenorush.webp"),
        subGame: getImg("kenorush.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_JUICY7",
      thumb: {
        img: getImg("juicy7.webp"),
        subGame: getImg("juicy7.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_JEWELRYCATS94",
      thumb: {
        img: getImg("jewelrycats94.webp"),
        subGame: getImg("jewelrycats94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_JEWELRYCATS",
      thumb: {
        img: getImg("jewelrycats.webp"),
        subGame: getImg("jewelrycats.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_INFINITYTOWER94",
      thumb: {
        img: getImg("infinitytower94.webp"),
        subGame: getImg("infinitytower94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_INFINITYTOWER",
      thumb: {
        img: getImg("infinitytower.webp"),
        subGame: getImg("infinitytower.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_INBETWEENPOKER",
      thumb: {
        img: getImg("inbetweenpoker.webp"),
        subGame: getImg("inbetweenpoker.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_HIGHHANDHOLDEMPOKER",
      thumb: {
        img: getImg("highhandholdempoker.webp"),
        subGame: getImg("highhandholdempoker.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_GRANDHEISTFEATUREBUY",
      thumb: {
        img: getImg("grandheistfeaturebuy.webp"),
        subGame: getImg("grandheistfeaturebuy.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_GRANDHEIST94",
      thumb: {
        img: getImg("grandheist94.webp"),
        subGame: getImg("grandheist94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_GRANDHEIST",
      thumb: {
        img: getImg("grandheist.webp"),
        subGame: getImg("grandheist.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_GOLDENSTRIPE",
      thumb: {
        img: getImg("goldenstripe.webp"),
        subGame: getImg("goldenstripe.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_GOLDENSHISA",
      thumb: {
        img: getImg("goldenshisa.webp"),
        subGame: getImg("goldenshisa.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_GODHANDFEATUREBUY94",
      thumb: {
        img: getImg("godhandfeaturebuy94.webp"),
        subGame: getImg("godhandfeaturebuy94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_GODHANDFEATUREBUY92",
      thumb: {
        img: getImg("godhandfeaturebuy92.webp"),
        subGame: getImg("godhandfeaturebuy92.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_GODHANDFEATUREBUY",
      thumb: {
        img: getImg("godhandfeaturebuy.webp"),
        subGame: getImg("godhandfeaturebuy.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_GODHAND94",
      thumb: {
        img: getImg("godhand94.webp"),
        subGame: getImg("godhand94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_GODHAND",
      thumb: {
        img: getImg("godhand.webp"),
        subGame: getImg("godhand.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_GENIESSECRETFEATUREBUY",
      thumb: {
        img: getImg("geniessecretfeaturebuy.webp"),
        subGame: getImg("geniessecretfeaturebuy.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_GENIESSECRET94",
      thumb: {
        img: getImg("geniessecret94.webp"),
        subGame: getImg("geniessecret94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_GENIESSECRET",
      thumb: {
        img: getImg("geniessecret.webp"),
        subGame: getImg("geniessecret.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_GANBARUTOBATTLE",
      thumb: {
        img: getImg("ganbarutobattle.webp"),
        subGame: getImg("ganbarutobattle.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_GANBARUTOBATTLE94",
      thumb: {
        img: getImg("ganbarutobattle94.webp"),
        subGame: getImg("ganbarutobattle94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_FORTUNEMINER",
      thumb: {
        img: getImg("fortuneminer.webp"),
        subGame: getImg("fortuneminer.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_FORGOTTENPHARAOH",
      thumb: {
        img: getImg("forgottenpharaoh.webp"),
        subGame: getImg("forgottenpharaoh.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_FORGOTTENPHARAOH94",
      thumb: {
        img: getImg("forgottenpharaoh94.webp"),
        subGame: getImg("forgottenpharaoh94.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_FLEXINGDRAGONS",
      thumb: {
        img: getImg("flexingdragons.webp"),
        subGame: getImg("flexingdragons.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_FLAMENGOFCSLOT",
      thumb: {
        img: getImg("flamengofcslot.webp"),
        subGame: getImg("flamengofcslot.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_FIRECOMBO",
      thumb: {
        img: getImg("firecombo.webp"),
        subGame: getImg("firecombo.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_CASHGALAXY",
      thumb: {
        img: getImg("cashgalaxy.webp"),
        subGame: getImg("cashgalaxy.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_BUBBLESBONANZA",
      thumb: {
        img: getImg("bubblesbonanza.webp"),
        subGame: getImg("bubblesbonanza.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_BONUSTRACK",
      thumb: {
        img: getImg("bonustrack.webp"),
        subGame: getImg("bonustrack.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_BOMBAYROULETTE",
      thumb: {
        img: getImg("bombayroulette.webp"),
        subGame: getImg("bombayroulette.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_BOMBAYBLACKJACK",
      thumb: {
        img: getImg("bombayblackjack.webp"),
        subGame: getImg("bombayblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_BLACKJACKSUPREMESHPP",
      thumb: {
        img: getImg("blackjacksupremeshpp.webp"),
        subGame: getImg("blackjacksupremeshpp.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_BLACKJACKSUPREMEMHPP",
      thumb: {
        img: getImg("blackjacksuprememhpp.webp"),
        subGame: getImg("blackjacksuprememhpp.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_BLACKJACKCLASSIC",
      thumb: {
        img: getImg("blackjackclassic.webp"),
        subGame: getImg("blackjackclassic.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_BEATSEX",
      thumb: {
        img: getImg("beatsex.webp"),
        subGame: getImg("beatsex.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_BACCARATSUPREMENOCOMMISSION",
      thumb: {
        img: getImg("baccaratsupremenocommission.webp"),
        subGame: getImg("baccaratsupremenocommission.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_BACCARATSUPREME",
      thumb: {
        img: getImg("baccaratsupreme.webp"),
        subGame: getImg("baccaratsupreme.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_BACCARATNOCOMMISSION",
      thumb: {
        img: getImg("baccaratnocommission.webp"),
        subGame: getImg("baccaratnocommission.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_BACCARAT",
      thumb: {
        img: getImg("baccarat.webp"),
        subGame: getImg("baccarat.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_TEENPATTI20-20",
      thumb: {
        img: getImg("teenpatti2020.webp"),
        subGame: getImg("teenpatti2020.webp"),
      },
    },
    {
      subCode: "GTAG_ONETOUCH_OT_SUMOSHOWDOWN-4REELS",
      thumb: {
        img: getImg("sumoshowdown4reels.webp"),
        subGame: getImg("sumoshowdown4reels.webp"),
      },
    },
  ],
} as LIBRARY_GAME;
