import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('HC', fileName)
};
export default {
  code: "HC",
  type: ["EGAMING"],
  play_method: "URL",
  thumb: { img: getImg("img.jpg") },
  // subGames: [],
} as LIBRARY_GAME;
