import { AxiosResponse } from "axios";
import $http from "@/modules/FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "promotion/member-promotion/lists";

export namespace typeLists {
  export type request = pageinator & {};

  export type response = listItem[];

  export type listItem = {
    p47_pon_id: number;
    p47_pon_name: string;
    p47_pon_offer_start: 1704902400;
    p47_pon_offer_end: 1706025600;
    p47_pon_application_deadline: 1705766400;
    p47_pon_maximum_point: "1000.0000";
    p47_pon_header_image_p7_file_id: number;
    p47_pon_image_p7_file_id: number;
    title_file: {
      p7_file_id: 92;
      p7_file_guid: "441a457f-96df-4b5b-8cd2-0bb212c749d4";
      p7_file_add_date: 1704943317;
      p7_file_status: "3";
      p7_file_folder: "files/2024/01/11";
      p7_file_name: "1e26f4700d6cf85164f1b0150ecac8f9.jpg";
      p7_file_mine: "image/jpeg";
      p7_file_source_name: "Cat03.jpg";
      p7_file_driver: "local";
    };
    contentfile: {
      p7_file_id: 93;
      p7_file_guid: "95e4d44a-6818-4d54-9221-6caff4b36216";
      p7_file_add_date: 1704943319;
      p7_file_status: "3";
      p7_file_folder: "files/2024/01/11";
      p7_file_name: "3f2ecec013ca5b8e47efa745a762e16d.jpg";
      p7_file_mine: "image/jpeg";
      p7_file_source_name: "Cat03.jpg";
      p7_file_driver: "local";
    };
    title_file_f?: img | null;
    contentfile_f?: img | null;
  };

  export type AxiosResponse = _AxiosResponse;
}
type _AxiosResponse = AxiosResponse<responseData>;

interface responseData extends BackendResponse {
  response: any;
}

/** 優惠列表 */
export default async (params?: typeLists.request) => {
  const getter = await $http.get(API, { params });
  if (getter.data.code !== 0) throw getter;
  return getter;
};
