import { AxiosResponse } from "axios";
import $http from "@/modules/FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "zubo/member-redEnvelope/lists";

export namespace typeLists {
  export type request = pageinator & {
    search_cp10_awrd_created_at_start?: string;
    search_cp10_awrd_created_at_end?: string;
  };

  export type response = {
    account_profile: {
      p3_account_profile: {
        p3_ape_name: string;
        p3_ape_p3_ant_id: number;
      }[];
    };
    list: listItem[];
    paginator: pageinator;
  };

  export type listItem = {
    /** ID  */
    // "11727.8000"
    cp10_atwt_after_point: string;
    // "11726.8000"
    cp10_atwt_before_point: string;
    cp10_atwt_operator_p3_ant_id: number;
    /** 點數 */
    cp10_atwt_operator_point: number;
    cp10_atwt_target_p3_ant_id: number;
    /** 1697211185 */
    cp10_awrd_created_at: number;
    cp10_awrd_id: number;
    cp10_awrd_memo?: string;
    cp10_awrd_target_node_is_red_envelope: number;
    operator_account: { p3_ant_account: string };
    target_account: { p3_ant_account: string };
  };

  export interface AxiosResponse extends _AxiosResponse {}
}
interface _AxiosResponse extends AxiosResponse<responseData> {}

interface responseData extends BackendResponse {
  response: any;
}

/** 明細 */
export default async (params?: typeLists.request) => {
  const getter = await $http.get(API, { params });
  if (getter.data.code !== 0) throw getter;
  return getter;
};
