import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('DG', fileName)
};
export default {
  code: "DREAM_GAME",
  type: ["CASINO"],
  play_method: "URL",
  thumb: { img: getImg("logo.webp") },
} as LIBRARY_GAME;
