import { AxiosResponse } from "axios";
import $http from "../../../FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "casinoAccount/front/register";

export namespace typeDoModify {
  /** 通訊軟體 */
  export type p17_communication_software = {
    p17_cse_software: "LINE_ID";
    p17_cse_code: string;
  };

  export interface request {
    p3_ant_account: string;
    p3_ant_password: string;
    p3_ant_password_confirm: string;
    p3_ape_tel?: string;
    captcha: string;
    domain: string;
    otp:string,
    /** 通訊軟體 */
    p17_communication_software?: p17_communication_software[];
    /** 姓名 */
    p3_ape_name?: string;
  }
  export interface AxiosResponse extends _AxiosResponse {}
}
interface _AxiosResponse extends AxiosResponse<responseData> {}

interface responseData extends BackendResponse {
  response: any;
}

/** 註冊 */
export default async (request: typeDoModify.request) => {
  const getter = await $http.post(API, preformat(request));
  if (getter.data.code !== 0) throw getter;
  return getter;
};

/** 送出前的強制格式化，去除額外的參數 */
function preformat(request: typeDoModify.request): typeDoModify.request {
  return {
    p3_ant_account: request.p3_ant_account,
    p3_ant_password: request.p3_ant_password,
    p3_ant_password_confirm: request.p3_ant_password_confirm,
    p3_ape_tel: request.p3_ape_tel,
    p3_ape_name: request.p3_ape_name,
    captcha: request.captcha,
    domain: window.location.host,
    otp:request.otp,
    p17_communication_software: request.p17_communication_software,
  };
}
