import { onUnmounted} from "vue";
import { useStore } from "vuex";

/**
 * 瀏覽器或裝置的初始化
 */
export default () => {
  const store = useStore();

  /** 取得裝置寬度 */
  const getWidth = () => {
    store.commit("device/getScreenWidth");
  };

  /** 取得裝置名稱 */
  store.commit("device/setDeviceState");

  getWidth();
  window.addEventListener("resize", getWidth);

  onUnmounted(() => {
    window.removeEventListener("resize", getWidth);
  });
};
