import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('RK5', fileName)
};
export default {
  code: "RK5_GAME",
  type: ["EGAMING"],
  play_method: "URL",
  thumb: {
    img: getImg("logo.jpg"),
  },
  subGames: [
    {
      subCode: "RK5_GAME_RKS-H5-00001_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00001_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00001_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00002_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00002_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00002_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00003_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00003_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00003_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00004_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00004_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00004_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00005_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00005_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00005_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00006_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00006_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00006_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00007_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00007_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00007_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00008_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00008_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00008_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00009_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00009_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00009_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00010_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00010_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00010_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00011_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00011_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00011_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00012_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00012_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00012_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00013_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00013_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00013_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00014_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00014_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00014_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00015_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00015_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00015_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00016_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00016_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00016_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00017_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00017_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00017_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00018_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00018_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00018_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00019_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00019_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00019_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00020_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00020_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00020_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00021_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00021_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00021_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00022_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00022_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00022_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00023_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00023_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00023_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00024_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00024_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00024_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00025_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00025_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00025_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00026_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00026_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00026_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKM-H5-00001_0",
      thumb: {
        img: getImg("RK5_GAME_RKM-H5-00001_0.png"),
        subGame: getImg("RK5_GAME_RKM-H5-00001_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKM-H5-00002_0",
      thumb: {
        img: getImg("RK5_GAME_RKM-H5-00002_0.png"),
        subGame: getImg("RK5_GAME_RKM-H5-00002_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00027_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00027_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00027_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00028_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00028_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00028_0.png"),
      },
    },
    {
      subCode: "RK5_GAME_RKS-H5-00029_0",
      thumb: {
        img: getImg("RK5_GAME_RKS-H5-00029_0.png"),
        subGame: getImg("RK5_GAME_RKS-H5-00029_0.png"),
      },
    },
  ],
} as LIBRARY_GAME;
