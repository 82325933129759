import { AxiosResponse } from "axios";
import $http from "@/modules/FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "casinoPaymentFlow/front-auction/lists";
// https://redmine.mirfak.tw/projects/pkg-270000-casinopaymentflow/repository/55/revisions/master/entry/_md/api/Front/Auction/%E5%88%97%E8%A1%A8.md

export namespace typeLists {
  export type request = pageinator & {
    /** 開始時間 */
    search_p27_aon_created_at_start?: string;
    /** 結束時間 */
    search_p27_aon_created_at_end?: string;
  };

  export type response = {
    list: listItem[];
    paginator: pageinator;
  };

  export type listItem = {
    /** 拍賣單號 */
    p27_aon_order_no: string;
    /** 建立時間 */
    p27_aon_created_at: number;
    /** 到帳時間 */
    p27_aon_deal_at: number;
    /** 金額 */
    p27_aon_amount: string;
    /** 當下的手續費設定(單筆金額) */
    p27_aon_current_p25_fcg_single_amount: string;
    /** 當下的手續費設定(單筆抽成 (%)) */
    p27_aon_current_p25_fcg_single_percentage: string;
    /** 是否免手續費; 1:是, 0:否 */
    p27_aon_fee_is_free: "1" | "0";
    /** 狀態: 3.已付款、2.待付款、-1.取消 */
    p27_aon_status: "3" | "2" | "-1";
    /** 備註 */
    p27_aon_memo: string | null;
    /** 通道 */
    p23_bmd_method: string;
  };

  export interface AxiosResponse extends _AxiosResponse {}
}
interface _AxiosResponse extends AxiosResponse<responseData> {}

interface responseData extends BackendResponse {
  response: any;
}

/** 明細 */
export default async (params?: typeLists.request) => {
  const getter = await $http.get(API, { params });
  if (getter.data.code !== 0) throw getter;
  return getter;
};
