import { AxiosResponse } from "axios";
import $http from "../../../FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "casinoAccount/env/info";
// https://redmine.mirfak.tw/projects/pkg-170000-casinoaccount/repository/37/revisions/master/entry/_md/api/Env/%E8%B3%87%E8%A8%8A.md

export namespace typeInfo {
  // export interface request {  }
  export interface AxiosResponse extends _AxiosResponse {}
  export type response = {
    /** 電話是否必填 */
    phone_required: boolean;
    /* 電話是否認證 */
    phone_verify: boolean;
  };
}
interface _AxiosResponse extends AxiosResponse<responseData> {}

interface responseData extends BackendResponse {
  response: typeInfo.response;
}

export default async () => {
  const getter = await $http(API);
  if (getter.data.code !== 0) throw getter;
  return getter;
};
