/* eslint-disable no-param-reassign */
import FileUpload, { IMG } from "../../service/casinoAccount/upload";

type state = {
  createState: number;
  files: IMG[];
};

const state: state = {
  createState: 0,
  files: [],
};

const store = {
  namespaced: true,
  state,
  mutations: {
    setFiles(st: state, files: Array<IMG>) {
      st.files = files;
    },
    clean(st: state) {
      st.files = [];
    },
    remove(st: state, index: number) {
      st.files.splice(index);
    },
  },
  actions: {
    async fileUpload({ commit }: any, files: Array<File>) {
      const fileUploadService = new FileUpload(files);
      return await fileUploadService
        .action()
        .then(res => {
          commit("setFiles", res);
          return res;
        })
        .catch((e: number) => {
          /** 因為錯誤分流 這裡其實不會有錯誤被拋出 */
          throw e;
        });
    },
  },
  getters: {
    files(st: state) {
      return st.files;
    },
  },
};

export default store;
