/** TODO: 製作 key value 的儲存機制與getter */
type state = {};

/**
 * 客製化項目用的儲存空間
 */
export default {
  namespaced: true,
  state: {} as state,
  mutations: {},
};
