import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('GTAG_HABANERO', fileName)
};

export default {
  code: "GTAG_HABANERO",
  type: ["EGAMING"],
  play_method: "URL",
  subGames: [
    {
      subCode: "GTAG_HABANERO_TENSORBETTER100HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tensorbetter100hand.webp"),
        subGame: getImg("tensorbetter100hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_TENSORBETTER50HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tensorbetter50hand.webp"),
        subGame: getImg("tensorbetter50hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_TENSORBETTER10HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tensorbetter10hand.webp"),
        subGame: getImg("tensorbetter10hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_TENSORBETTER5HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tensorbetter5hand.webp"),
        subGame: getImg("tensorbetter5hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_TENSORBETTER1HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tensorbetter1hand.webp"),
        subGame: getImg("tensorbetter1hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_JOKERPOKER100HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jokerpoker100hand.webp"),
        subGame: getImg("jokerpoker100hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_JOKERPOKER50HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jokerpoker50hand.webp"),
        subGame: getImg("jokerpoker50hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_JOKERPOKER10HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jokerpoker10hand.webp"),
        subGame: getImg("jokerpoker10hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_JOKERPOKER5HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jokerpoker5hand.webp"),
        subGame: getImg("jokerpoker5hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_JOKERPOKER1HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jokerpoker1hand.webp"),
        subGame: getImg("jokerpoker1hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_JACKSORBETTER100HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jacksorbetter100hand.webp"),
        subGame: getImg("jacksorbetter100hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_JACKSORBETTER50HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jacksorbetter50hand.webp"),
        subGame: getImg("jacksorbetter50hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_JACKSORBETTER10HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jacksorbetter10hand.webp"),
        subGame: getImg("jacksorbetter10hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_JACKSORBETTER5HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jacksorbetter5hand.webp"),
        subGame: getImg("jacksorbetter5hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_JACKSORBETTER1HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jacksorbetter1hand.webp"),
        subGame: getImg("jacksorbetter1hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_DOUBLEDOUBLEBONUSPOKER100HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("doubledoublebonuspoker100hand.webp"),
        subGame: getImg("doubledoublebonuspoker100hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_DOUBLEDOUBLEBONUSPOKER50HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("doubledoublebonuspoker50hand.webp"),
        subGame: getImg("doubledoublebonuspoker50hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_DOUBLEDOUBLEBONUSPOKER10HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("doubledoublebonuspoker10hand.webp"),
        subGame: getImg("doubledoublebonuspoker10hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_DOUBLEDOUBLEBONUSPOKER5HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("doubledoublebonuspoker5hand.webp"),
        subGame: getImg("doubledoublebonuspoker5hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_DOUBLEDOUBLEBONUSPOKER1HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("doubledoublebonuspoker1hand.webp"),
        subGame: getImg("doubledoublebonuspoker1hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_DOUBLEBONUSPOKER100HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("doublebonuspoker100hand.webp"),
        subGame: getImg("doublebonuspoker100hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_DOUBLEBONUSPOKER50HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("doublebonuspoker50hand.webp"),
        subGame: getImg("doublebonuspoker50hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_DOUBLEBONUSPOKER10HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("doublebonuspoker10hand.webp"),
        subGame: getImg("doublebonuspoker10hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_DOUBLEBONUSPOKER5HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("doublebonuspoker5hand.webp"),
        subGame: getImg("doublebonuspoker5hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_DOUBLEBONUSPOKER1HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("doublebonuspoker1hand.webp"),
        subGame: getImg("doublebonuspoker1hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_DUECESWILD100HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("deuceswild100hand.webp"),
        subGame: getImg("deuceswild100hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_DUECESWILD50HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("deuceswild50hand.webp"),
        subGame: getImg("deuceswild50hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_ACESANDEIGHTS100HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("acesandeights100hand.webp"),
        subGame: getImg("acesandeights100hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_ACESANDEIGHTS50HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("acesandeights50hand.webp"),
        subGame: getImg("acesandeights50hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_ACESANDEIGHTS10HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("acesandeights10hand.webp"),
        subGame: getImg("acesandeights10hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_ACESANDEIGHTS5HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("acesandeights5hand.webp"),
        subGame: getImg("acesandeights5hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_ACESANDEIGHTS1HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("acesandeights1hand.webp"),
        subGame: getImg("acesandeights1hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_DUECESWILD10HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("deuceswild10hand.webp"),
        subGame: getImg("deuceswild10hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_DUECESWILD5HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("deuceswild5hand.webp"),
        subGame: getImg("deuceswild5hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_DUECESWILD1HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("deuceswild1hand.webp"),
        subGame: getImg("deuceswild1hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_BONUSPOKER100HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bonuspoker100hand.webp"),
        subGame: getImg("bonuspoker100hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_BONUSPOKER50HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bonuspoker50hand.webp"),
        subGame: getImg("bonuspoker50hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_BONUSPOKER10HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bonuspoker10hand.webp"),
        subGame: getImg("bonuspoker10hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_BONUSPOKER5HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bonuspoker5hand.webp"),
        subGame: getImg("bonuspoker5hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_BONUSPOKER1HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bonuspoker1hand.webp"),
        subGame: getImg("bonuspoker1hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_BONUSDUECESWILD100HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bonusdeuceswild100hand.webp"),
        subGame: getImg("bonusdeuceswild100hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_BONUSDUECESWILD50HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bonusdeuceswild50hand.webp"),
        subGame: getImg("bonusdeuceswild50hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_BONUSDUECESWILD10HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bonusdeuceswild10hand.webp"),
        subGame: getImg("bonusdeuceswild10hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_BONUSDUECESWILD5HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bonusdeuceswild5hand.webp"),
        subGame: getImg("bonusdeuceswild5hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_BONUSDUECESWILD1HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bonusdeuceswild1hand.webp"),
        subGame: getImg("bonusdeuceswild1hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_ALLAMERICANPOKER100HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("allamericanpoker100hand.webp"),
        subGame: getImg("allamericanpoker100hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_ALLAMERICANPOKER50HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("allamericanpoker50hand.webp"),
        subGame: getImg("allamericanpoker50hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_ALLAMERICANPOKER10HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("allamericanpoker10hand.webp"),
        subGame: getImg("allamericanpoker10hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_ALLAMERICANPOKER5HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("allamericanpoker5hand.webp"),
        subGame: getImg("allamericanpoker5hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_ALLAMERICANPOKER1HAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("allamericanpoker1hand.webp"),
        subGame: getImg("allamericanpoker1hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGDRAGONTIGERGATE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragontigergate.webp"),
        subGame: getImg("dragontigergate.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGRAINBOWMANIA",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("rainbowmania.webp"),
        subGame: getImg("rainbowmania.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGLEGENDARYBEASTS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("legendarybeasts.webp"),
        subGame: getImg("legendarybeasts.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGNAUGHTYWUKONG",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("naughtywukong.webp"),
        subGame: getImg("naughtywukong.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGPROST",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("prost.webp"),
        subGame: getImg("prost.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGNEWYEARSBASH",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("newyearsbash.webp"),
        subGame: getImg("newyearsbash.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGLUCKYDURIAN",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("luckydurian.webp"),
        subGame: getImg("luckydurian.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGLANTERNLUCK",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("lanternluck.webp"),
        subGame: getImg("lanternluck.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGMIGHTYMEDUSA",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mightymedusa.webp"),
        subGame: getImg("mightymedusa.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGGOLDENUNICORNDELUXE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldenunicorndeluxe.webp"),
        subGame: getImg("goldenunicorndeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGSPACEGOONZ",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("spacegoonz.webp"),
        subGame: getImg("spacegoonz.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGLAUGHINGBUDDHA",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("laughingbuddha.webp"),
        subGame: getImg("laughingbuddha.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGTAIKOBEATS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("taikobeats.webp"),
        subGame: getImg("taikobeats.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGTUKTUKTHAILAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tuktukthailand.webp"),
        subGame: getImg("tuktukthailand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGSOJUBOMB",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sojubomb.webp"),
        subGame: getImg("sojubomb.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGDISCOBEATS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("discobeats.webp"),
        subGame: getImg("discobeats.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGBOMBRUNNER",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bombrunner.webp"),
        subGame: getImg("bombrunner.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGWEIRDSCIENCE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("weirdscience.webp"),
        subGame: getImg("weirdscience.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGVIKINGSPLUNDER",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("vikingsplunder.webp"),
        subGame: getImg("vikingsplunder.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGTREASURETOMB",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("treasuretomb.webp"),
        subGame: getImg("treasuretomb.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGTREASUREDIVER",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("treasurediver.webp"),
        subGame: getImg("treasurediver.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGTOWEROFPIZZA",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("towerofpizza.webp"),
        subGame: getImg("towerofpizza.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGTHEDRAGONCASTLE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragoncastle.webp"),
        subGame: getImg("dragoncastle.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGTHEBIGDEAL",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thebigdeal.webp"),
        subGame: getImg("thebigdeal.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGSUPERSTRIKE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("superstrike.webp"),
        subGame: getImg("superstrike.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGSPACEFORTUNE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("spacefortune.webp"),
        subGame: getImg("spacefortune.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGSOS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sos.webp"),
        subGame: getImg("sos.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGSKYSTHELIMIT",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("skysthelimit.webp"),
        subGame: getImg("skysthelimit.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGSIRBLINGALOT",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sirblingalot.webp"),
        subGame: getImg("sirblingalot.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGSHOGUNSLAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("shogunsland.webp"),
        subGame: getImg("shogunsland.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGSHAOLINFORTUNES243",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("shaolinfortunes.webp"),
        subGame: getImg("shaolinfortunes.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGSHAOLINFORTUNES100",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("shaolinfortunes100.webp"),
        subGame: getImg("shaolinfortunes100.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGRODEODRIVE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("rodeodrive.webp"),
        subGame: getImg("rodeodrive.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGRIDEEMCOWBOY",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("rideemcowboy.webp"),
        subGame: getImg("rideemcowboy.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGQUEENOFQUEENS243",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("queenofqueens.webp"),
        subGame: getImg("queenofqueens.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGQUEENOFQUEENS1024",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("queenofqueensii.webp"),
        subGame: getImg("queenofqueensii.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGPUCKERUPPRINCE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("puckerupprince.webp"),
        subGame: getImg("puckerupprince.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGPOOLSHARK",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("poolshark.webp"),
        subGame: getImg("poolshark.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGPIRATESPLUNDER",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("piratesplunder.webp"),
        subGame: getImg("piratesplunder.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGPAMPERME",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pamperme.webp"),
        subGame: getImg("pamperme.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGMYSTICFORTUNE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mysticfortune.webp"),
        subGame: getImg("mysticfortune.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGMUMMYMONEY",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mummymoney.webp"),
        subGame: getImg("mummymoney.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGMRBLING",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mrbling.webp"),
        subGame: getImg("mrbling.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGMONSTERMASHCASH",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("monstermashcash.webp"),
        subGame: getImg("monstermashcash.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGLITTLEGREENMONEY",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("littlegreenmoney.webp"),
        subGame: getImg("littlegreenmoney.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGKINGTUTSTOMB",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("kingtutstomb.webp"),
        subGame: getImg("kingtutstomb.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGJUNGLERUMBLE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("junglerumble.webp"),
        subGame: getImg("junglerumble.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGINDIANCASHCATCHER",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("indiancashcatcher.webp"),
        subGame: getImg("indiancashcatcher.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGHAUNTEDHOUSE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hauntedhouse.webp"),
        subGame: getImg("hauntedhouse.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGGOLDENUNICORN",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldenunicorn.webp"),
        subGame: getImg("goldenunicorn.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGGLAMROCK",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("glamrock.webp"),
        subGame: getImg("glamrock.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGFRONTIERFORTUNES",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("frontierfortunes.webp"),
        subGame: getImg("frontierfortunes.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGFLYINGHIGH",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("flyinghigh.webp"),
        subGame: getImg("flyinghigh.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGEGYPTIANDREAMS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("egyptiandreams.webp"),
        subGame: getImg("egyptiandreams.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGDRFEELGOOD",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("drfeelgood.webp"),
        subGame: getImg("drfeelgood.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGDRAGONSREALM",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragonsrealm.webp"),
        subGame: getImg("dragonsrealm.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGDOUBLEODOLLARS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("doubleodollars.webp"),
        subGame: getImg("doubleodollars.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGDISCOFUNK",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("discofunk.webp"),
        subGame: getImg("discofunk.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGCASHREEF",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("cashreef.webp"),
        subGame: getImg("cashreef.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGCASHOSAURUS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("cashosaurus.webp"),
        subGame: getImg("cashosaurus.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGCARNIVALCASH",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("carnivalcash.webp"),
        subGame: getImg("carnivalcash.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGBLACKBEARDSBOUNTY",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackbeardsbounty.webp"),
        subGame: getImg("blackbeardsbounty.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGBIKINIISLAND",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bikiniisland.webp"),
        subGame: getImg("bikiniisland.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGBARNSTORMERBUCKS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("barnstormerbucks.webp"),
        subGame: getImg("barnstormerbucks.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGAZLANDSGOLD",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("aztlansgold.webp"),
        subGame: getImg("aztlansgold.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGARCTICWONDERS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("arcticwonders.webp"),
        subGame: getImg("arcticwonders.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGALLFORONE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("allforone.webp"),
        subGame: getImg("allforone.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGKANESINFERNO",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("kanesinferno.webp"),
        subGame: getImg("kanesinferno.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGBUGGYBONUS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("buggybonus.webp"),
        subGame: getImg("buggybonus.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGGALACTICCASH",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("galacticcash.webp"),
        subGame: getImg("galacticcash.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGWICKEDWITCH",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wickedwitch.webp"),
        subGame: getImg("wickedwitch.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGGOLDRUSH",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldrush.webp"),
        subGame: getImg("goldrush.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGBOMBSAWAY",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bombsaway.webp"),
        subGame: getImg("bombsaway.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGFACAISHEN",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("facaishen.webp"),
        subGame: getImg("facaishen.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGRUFFLEDUP",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ruffledup.webp"),
        subGame: getImg("ruffledup.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGROMANEMPIRE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("romanempire.webp"),
        subGame: getImg("romanempire.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGCOYOTECRASH",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("coyotecrash.webp"),
        subGame: getImg("coyotecrash.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGDRAGONSTHRONE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragonsthrone.webp"),
        subGame: getImg("dragonsthrone.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGARCANEELEMENTS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("arcaneelements.webp"),
        subGame: getImg("arcaneelements.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SG12ZODIACS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("12zodiacs.webp"),
        subGame: getImg("12zodiacs.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGSUPERTWISTER",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("supertwister.webp"),
        subGame: getImg("supertwister.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGSPARTA",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sparta.webp"),
        subGame: getImg("sparta.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGGANGSTERS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gangsters.webp"),
        subGame: getImg("gangsters.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGOCEANSCALL",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("oceanscall.webp"),
        subGame: getImg("oceanscall.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGJUGGLENAUT",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jugglenaut.webp"),
        subGame: getImg("jugglenaut.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGFENGHUANG",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fenghuang.webp"),
        subGame: getImg("fenghuang.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGFIREROOSTER",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("firerooster.webp"),
        subGame: getImg("firerooster.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGTHEKOIGATE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("koigate.webp"),
        subGame: getImg("koigate.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGBIRDOFTHUNDER",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("birdofthunder.webp"),
        subGame: getImg("birdofthunder.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGPANDAPANDA",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pandapanda.webp"),
        subGame: getImg("pandapanda.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGTHEDEADESCAPE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thedeadescape.webp"),
        subGame: getImg("thedeadescape.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGSCRUFFYSCALLYWAGS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("scruffyscallywags.webp"),
        subGame: getImg("scruffyscallywags.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGCAKEVALLEY",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("cakevalley.webp"),
        subGame: getImg("cakevalley.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGROLLINGROGER",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("rollingroger.webp"),
        subGame: getImg("rollingroger.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGSANTASVILLAGE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("santasvillage.webp"),
        subGame: getImg("santasvillage.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SG5MARIACHIS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("5mariachis.webp"),
        subGame: getImg("5mariachis.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGFOURDIVINEBEASTS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fourdivinebeasts.webp"),
        subGame: getImg("fourdivinebeasts.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGLONDONHUNTER",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("londonhunter.webp"),
        subGame: getImg("londonhunter.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGPRESTO",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("presto.webp"),
        subGame: getImg("presto.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGFORTUNEDOGS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fortunedogs.webp"),
        subGame: getImg("fortunedogs.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGKNOCKOUTFOOTBALL",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("knockoutfootball.webp"),
        subGame: getImg("knockoutfootball.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGWAYSOFFORTUNE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("waysoffortune.webp"),
        subGame: getImg("waysoffortune.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGEGYPTIANDREAMSDELUXE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("egyptiandreamsdeluxe.webp"),
        subGame: getImg("egyptiandreamsdeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGPUMPKINPATCH",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pumpkinpatch.webp"),
        subGame: getImg("pumpkinpatch.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGHAPPIESTCHRISTMASTREE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("happiestchristmastree.webp"),
        subGame: getImg("happiestchristmastree.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGHOTHOTFRUIT",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hothotfruit.webp"),
        subGame: getImg("hothotfruit.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SG5LUCKYLIONS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("5luckylions.webp"),
        subGame: getImg("5luckylions.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGMOUNTMAZUMA",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mountmazuma.webp"),
        subGame: getImg("mountmazuma.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGMAGICOAK",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("magicoak.webp"),
        subGame: getImg("magicoak.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGLUCKYLUCKY",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("luckylucky.webp"),
        subGame: getImg("luckylucky.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGWILDTRUCKS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildtrucks.webp"),
        subGame: getImg("wildtrucks.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGNUWA",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("nuwa.webp"),
        subGame: getImg("nuwa.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGCOLOSSALGEMS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("colossalgems.webp"),
        subGame: getImg("colossalgems.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGWIZARDSWANTWAR",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wizardswantwar.webp"),
        subGame: getImg("wizardswantwar.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGHOTHOTHALLOWEEN",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hothothalloween.webp"),
        subGame: getImg("hothothalloween.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGNAUGHTYSANTA",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("naughtysanta.webp"),
        subGame: getImg("naughtysanta.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGFACAISHENDELUXE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("facaishendeluxe.webp"),
        subGame: getImg("facaishendeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGLOONYBLOX",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("loonyblox.webp"),
        subGame: getImg("loonyblox.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGKNOCKOUTFOOTBALLRUSH",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("knockoutfootballrush.webp"),
        subGame: getImg("knockoutfootballrush.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGLUCKYFORTUNECAT",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("luckyfortunecat.webp"),
        subGame: getImg("luckyfortunecat.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGTECHNOTUMBLE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("technotumble.webp"),
        subGame: getImg("technotumble.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGSCOPA",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("scopa.webp"),
        subGame: getImg("scopa.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGHEYSUSHI",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("heysushi.webp"),
        subGame: getImg("heysushi.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGWEALTHINN",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wealthinn.webp"),
        subGame: getImg("wealthinn.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGJELLYFISHFLOWULTRA",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jellyfishflowultra.webp"),
        subGame: getImg("jellyfishflowultra.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGJELLYFISHFLOW",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jellyfishflow.webp"),
        subGame: getImg("jellyfishflow.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGHAPPYAPE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("happyape.webp"),
        subGame: getImg("happyape.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGTABERNADELOSMUERTOSULTRA",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tabernadelosmuertosultra.webp"),
        subGame: getImg("tabernadelosmuertosultra.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGTABERNADELOSMUERTOS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tabernadelosmuertos.webp"),
        subGame: getImg("tabernadelosmuertos.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGCHRISTMASGIFTRUSH",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("christmasgiftrush.webp"),
        subGame: getImg("christmasgiftrush.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGTOTEMTOWERS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("totemtowers.webp"),
        subGame: getImg("totemtowers.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGORBSOFATLANTIS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("orbsofatlantis.webp"),
        subGame: getImg("orbsofatlantis.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGBEFORETIMERUNSOUT",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("beforetimerunsout.webp"),
        subGame: getImg("beforetimerunsout.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGRETURNTOTHEFEATURE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("returntothefeature.webp"),
        subGame: getImg("returntothefeature.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGGRAPEESCAPE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thegrapeescape.webp"),
        subGame: getImg("thegrapeescape.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGJUMP",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jump.webp"),
        subGame: getImg("jump.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGCANDYTOWER",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("candytower.webp"),
        subGame: getImg("candytower.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGMARVELOUSFURLONGS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("marvelousfurlongs.webp"),
        subGame: getImg("marvelousfurlongs.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGCALAVERASEXPLOSIVAS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("calaverasexplosivas.webp"),
        subGame: getImg("calaverasexplosivas.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGMYSTICFORTUNEDELUXE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mysticfortunedeluxe.webp"),
        subGame: getImg("mysticfortunedeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGFLY",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fly.webp"),
        subGame: getImg("fly.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGNINETAILS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ninetails.webp"),
        subGame: getImg("ninetails.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGZEUS2",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("zeus2.webp"),
        subGame: getImg("zeus2.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SGZEUS",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("zeus.webp"),
        subGame: getImg("zeus.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_TGDRAGONTIGER",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragontiger.webp"),
        subGame: getImg("dragontiger.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_TGWAR",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("war.webp"),
        subGame: getImg("war.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_TGTHREECARDPOKERDELUXE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("threecardpokerdeluxe.webp"),
        subGame: getImg("threecardpokerdeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_TGTHREECARDPOKER",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("threecardpoker.webp"),
        subGame: getImg("threecardpoker.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_SICBO",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sicbo.webp"),
        subGame: getImg("sicbo.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_AMERICANBACCARAT",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("americanbaccarat.webp"),
        subGame: getImg("americanbaccarat.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_EUROULETTE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("europeanroulette.webp"),
        subGame: getImg("europeanroulette.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_TGBLACKJACKAMERICAN",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("americanblackjack.webp"),
        subGame: getImg("americanblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_BLACKJACK3H",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack3hand.webp"),
        subGame: getImg("blackjack3hand.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_CARIBBEANSTUD",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("caribbeanstud.webp"),
        subGame: getImg("caribbeanstud.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_CARIBBEANHOLDEM",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("caribbeanholdem.webp"),
        subGame: getImg("caribbeanholdem.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_BACCARAT3HZC",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("baccaratzerocommission.webp"),
        subGame: getImg("baccaratzerocommission.webp"),
      },
    },
    {
      subCode: "GTAG_HABANERO_BLACKJACK3HDOUBLEEXPOSURE",
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("doubleexposure3hand.webp"),
        subGame: getImg("doubleexposure3hand.webp"),
      },
    },
  ],
} as LIBRARY_GAME;
