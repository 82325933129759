import { getGameImg } from "../../../util/gameImg";
const getImg = (fileName: string) => {
  return getGameImg("RSG_ELECTRONIC", fileName);
};
/** TODO: 包含三款本地端遊戲圖片，待S3正常後上傳
 * RSG_ELECTRONIC_114
 * RSG_ELECTRONIC_117
 * RSG_ELECTRONIC_119 */
export default {
  code: "RSG_ELECTRONIC",
  type: ["EGAMING"],
  play_method: "URL",
  subGames: [
    {
      subCode: "RSG_ELECTRONIC_1",
      thumb: {
        img: getImg("game-FortuneThai.webp"),
        subGame: getImg("game-FortuneThai.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_2",
      thumb: {
        img: getImg("game-MagicGem.webp"),
        subGame: getImg("game-MagicGem.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_3",
      thumb: {
        img: getImg("game-Royal777.webp"),
        subGame: getImg("game-Royal777.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_4",
      thumb: {
        img: getImg("game-LoveCity.webp"),
        subGame: getImg("game-LoveCity.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_5",
      thumb: {
        img: getImg("game-GoldChicken.webp"),
        subGame: getImg("game-GoldChicken.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_6",
      thumb: {
        img: getImg("game-Pharaoh.webp"),
        subGame: getImg("game-Pharaoh.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_7",
      thumb: {
        img: getImg("game-Alibaba.webp"),
        subGame: getImg("game-Alibaba.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_8",
      thumb: {
        img: getImg("game-LuckyFruits.webp"),
        subGame: getImg("game-LuckyFruits.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_10",
      thumb: {
        img: getImg("game-Jungle.webp"),
        subGame: getImg("game-Jungle.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_11",
      thumb: {
        img: getImg("game-CaptainHook.webp"),
        subGame: getImg("game-CaptainHook.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_12",
      thumb: {
        img: getImg("game-HUCA.webp"),
        subGame: getImg("game-HUCA.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_14",
      thumb: {
        img: getImg("game-SweetCandy.webp"),
        subGame: getImg("game-SweetCandy.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_15",
      thumb: {
        img: getImg("game-FireSpin.webp"),
        subGame: getImg("game-FireSpin.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_16",
      thumb: {
        img: getImg("game-Popeye.webp"),
        subGame: getImg("game-Popeye.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_17",
      thumb: {
        img: getImg("game-CrazyDoctor.webp"),
        subGame: getImg("game-CrazyDoctor.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_18",
      thumb: {
        img: getImg("game-Nonstop.webp"),
        subGame: getImg("game-Nonstop.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_19",
      thumb: {
        img: getImg("game-5Dragons.webp"),
        subGame: getImg("game-5Dragons.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_21",
      thumb: {
        img: getImg("game-72Changes.webp"),
        subGame: getImg("game-72Changes.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_23",
      thumb: {
        img: getImg("game-Mermaid.webp"),
        subGame: getImg("game-Mermaid.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_24",
      thumb: {
        img: getImg("game-Buffalo.webp"),
        subGame: getImg("game-Buffalo.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_25",
      thumb: {
        img: getImg("game-WildPanda.webp"),
        subGame: getImg("game-WildPanda.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_26",
      thumb: {
        img: getImg("game-LuckyThailand.webp"),
        subGame: getImg("game-LuckyThailand.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_27",
      thumb: {
        img: getImg("game-GodofWealth.webp"),
        subGame: getImg("game-GodofWealth.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_28",
      thumb: {
        img: getImg("game-LuckyDragon.webp"),
        subGame: getImg("game-LuckyDragon.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_29",
      thumb: {
        img: getImg("game-HUSA.webp"),
        subGame: getImg("game-HUSA.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_30",
      thumb: {
        img: getImg("game-DragonKing.webp"),
        subGame: getImg("game-DragonKing.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_31",
      thumb: {
        img: getImg("game-TiKiParty.webp"),
        subGame: getImg("game-TiKiParty.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_32",
      thumb: {
        img: getImg("game-GoblinMiner.webp"),
        subGame: getImg("game-GoblinMiner.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_33",
      thumb: {
        img: getImg("game-LuckyBar.webp"),
        subGame: getImg("game-LuckyBar.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_34",
      thumb: {
        img: getImg("game-Africa.webp"),
        subGame: getImg("game-Africa.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_35",
      thumb: {
        img: getImg("game-WizardStore.webp"),
        subGame: getImg("game-WizardStore.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_36",
      thumb: {
        img: getImg("game-Mr.Doggy.webp"),
        subGame: getImg("game-Mr.Doggy.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_37",
      thumb: {
        img: getImg("game-DiscoNight.webp"),
        subGame: getImg("game-DiscoNight.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_38",
      thumb: {
        img: getImg("game-HorrorNights.webp"),
        subGame: getImg("game-HorrorNights.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_39",
      thumb: {
        img: getImg("game-ChinaEmpress.webp"),
        subGame: getImg("game-ChinaEmpress.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_40",
      thumb: {
        img: getImg("game-FuWaFaFa.webp"),
        subGame: getImg("game-FuWaFaFa.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_41",
      thumb: {
        img: getImg("game-TarZan.webp"),
        subGame: getImg("game-TarZan.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_42",
      thumb: {
        img: getImg("game-Jalapeno.webp"),
        subGame: getImg("game-Jalapeno.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_43",
      thumb: {
        img: getImg("game-PiggyPunch.webp"),
        subGame: getImg("game-PiggyPunch.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_44",
      thumb: {
        img: getImg("game-SevensHigh.webp"),
        subGame: getImg("game-SevensHigh.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_45",
      thumb: {
        img: getImg("game-Kunoichi.webp"),
        subGame: getImg("game-Kunoichi.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_46",
      thumb: {
        img: getImg("game-Ninja.webp"),
        subGame: getImg("game-Ninja.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_47",
      thumb: {
        img: getImg("game-Jelly27.webp"),
        subGame: getImg("game-Jelly27.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_48",
      thumb: {
        img: getImg("game-AngryBear.webp"),
        subGame: getImg("game-AngryBear.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_49",
      thumb: {
        img: getImg("game-Poseidon.webp"),
        subGame: getImg("game-Poseidon.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_50",
      thumb: {
        img: getImg("game-DancingLion.webp"),
        subGame: getImg("game-DancingLion.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_51",
      thumb: {
        img: getImg("game-Medusa.webp"),
        subGame: getImg("game-Medusa.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_52",
      thumb: {
        img: getImg("game-Medea.webp"),
        subGame: getImg("game-Medea.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_53",
      thumb: {
        img: getImg("game-NeonCircle.webp"),
        subGame: getImg("game-NeonCircle.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_55",
      thumb: {
        img: getImg("game-GetHigh.webp"),
        subGame: getImg("game-GetHigh.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_56",
      thumb: {
        img: getImg("game-Cowboy.webp"),
        subGame: getImg("game-Cowboy.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_58",
      thumb: {
        img: getImg("game-TheLittleMatchGirl.webp"),
        subGame: getImg("game-TheLittleMatchGirl.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_59",
      thumb: {
        img: getImg("game-MysteryPanda.webp"),
        subGame: getImg("game-MysteryPanda.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_60",
      thumb: {
        img: getImg("game-HipHopMonkey.webp"),
        subGame: getImg("game-HipHopMonkey.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_61",
      thumb: {
        img: getImg("game-BookofGold.webp"),
        subGame: getImg("game-BookofGold.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_65",
      thumb: {
        img: getImg("game-TaiChi.webp"),
        subGame: getImg("game-TaiChi.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_66",
      thumb: {
        img: getImg("game-GoldenLeafClover.webp"),
        subGame: getImg("game-GoldenLeafClover.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_68",
      thumb: {
        img: getImg("game-WizardStoreGold.webp"),
        subGame: getImg("game-WizardStoreGold.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_70",
      thumb: {
        img: getImg("game-RatsMoney.webp"),
        subGame: getImg("game-RatsMoney.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_72",
      thumb: {
        img: getImg("game-Songkran.webp"),
        subGame: getImg("game-Songkran.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_73",
      thumb: {
        img: getImg("game-ElfArcher.webp"),
        subGame: getImg("game-ElfArcher.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_76",
      thumb: {
        img: getImg("game-BearKingdom.webp"),
        subGame: getImg("game-BearKingdom.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_78",
      thumb: {
        img: getImg("game-Royal7777.webp"),
        subGame: getImg("game-Royal7777.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_81",
      thumb: {
        img: getImg("game-DragonKing2.webp"),
        subGame: getImg("game-DragonKing2.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_82",
      thumb: {
        img: getImg("game-PharaohII.webp"),
        subGame: getImg("game-PharaohII.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_90",
      thumb: {
        img: getImg("game-DragonFight.webp"),
        subGame: getImg("game-DragonFight.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_100",
      thumb: {
        img: getImg("game-Roma.webp"),
        subGame: getImg("game-Roma.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_111",
      thumb: {
        img: getImg("game-HappyFarm.webp"),
        subGame: getImg("game-HappyFarm.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_112",
      thumb: {
        img: getImg("game-PowerOfThor.webp"),
        subGame: getImg("game-PowerOfThor.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_113",
      thumb: {
        img: getImg("game-ChinShiHuang.webp"),
        subGame: getImg("game-ChinShiHuang.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_2001",
      thumb: {
        img: getImg("game-EnergyCombo.webp"),
        subGame: getImg("game-EnergyCombo.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_3001",
      thumb: {
        img: getImg("game-OceanEmperor.webp"),
        subGame: getImg("game-OceanEmperor.webp"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_114",
      thumb: {
        img: getImg("RSG_ELECTRONIC_114.png"),
        subGame: getImg("RSG_ELECTRONIC_114.png"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_117",
      thumb: {
        img: getImg("RSG_ELECTRONIC_117.png"),
        subGame: getImg("RSG_ELECTRONIC_117.png"),
      },
    },
    {
      subCode: "RSG_ELECTRONIC_119",
      thumb: {
        img: getImg("RSG_ELECTRONIC_119.png"),
        subGame: getImg("RSG_ELECTRONIC_119.png"),
      },
    },
  ],
  localeSetting: {
    zh_CN: {
      subGames: [
        {
          subCode: "RSG_ELECTRONIC_1",
          thumb: {
            img: getImg("game-FortuneThai_cn.webp"),
            subGame: getImg("game-FortuneThai_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_2",
          thumb: {
            img: getImg("game-MagicGem_cn.webp"),
            subGame: getImg("game-MagicGem_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_3",
          thumb: {
            img: getImg("game-Royal777_cn.webp"),
            subGame: getImg("game-Royal777_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_4",
          thumb: {
            img: getImg("game-LoveCity_cn.webp"),
            subGame: getImg("game-LoveCity_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_5",
          thumb: {
            img: getImg("game-GoldChicken_cn.webp"),
            subGame: getImg("game-GoldChicken_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_6",
          thumb: {
            img: getImg("game-Pharaoh_cn.webp"),
            subGame: getImg("game-Pharaoh_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_7",
          thumb: {
            img: getImg("game-Alibaba_cn.webp"),
            subGame: getImg("game-Alibaba_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_8",
          thumb: {
            img: getImg("game-LuckyFruits_cn.webp"),
            subGame: getImg("game-LuckyFruits_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_10",
          thumb: {
            img: getImg("game-Jungle_cn.webp"),
            subGame: getImg("game-Jungle_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_11",
          thumb: {
            img: getImg("game-CaptainHook_cn.webp"),
            subGame: getImg("game-CaptainHook_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_12",
          thumb: {
            img: getImg("game-HUCA_cn.webp"),
            subGame: getImg("game-HUCA_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_14",
          thumb: {
            img: getImg("game-SweetCandy_cn.webp"),
            subGame: getImg("game-SweetCandy_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_15",
          thumb: {
            img: getImg("game-FireSpin_cn.webp"),
            subGame: getImg("game-FireSpin_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_16",
          thumb: {
            img: getImg("game-Popeye_cn.webp"),
            subGame: getImg("game-Popeye_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_17",
          thumb: {
            img: getImg("game-CrazyDoctor_cn.webp"),
            subGame: getImg("game-CrazyDoctor_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_18",
          thumb: {
            img: getImg("game-Nonstop_cn.webp"),
            subGame: getImg("game-Nonstop_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_19",
          thumb: {
            img: getImg("game-5Dragons_cn.webp"),
            subGame: getImg("game-5Dragons_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_21",
          thumb: {
            img: getImg("game-72Changes_cn.webp"),
            subGame: getImg("game-72Changes_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_23",
          thumb: {
            img: getImg("game-Mermaid_cn.webp"),
            subGame: getImg("game-Mermaid_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_24",
          thumb: {
            img: getImg("game-Buffalo_cn.webp"),
            subGame: getImg("game-Buffalo_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_25",
          thumb: {
            img: getImg("game-WildPanda_cn.webp"),
            subGame: getImg("game-WildPanda_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_26",
          thumb: {
            img: getImg("game-LuckyThailand_cn.webp"),
            subGame: getImg("game-LuckyThailand_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_27",
          thumb: {
            img: getImg("game-GodofWealth_cn.webp"),
            subGame: getImg("game-GodofWealth_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_28",
          thumb: {
            img: getImg("game-LuckyDragon_cn.webp"),
            subGame: getImg("game-LuckyDragon_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_29",
          thumb: {
            img: getImg("game-HUSA_cn.webp"),
            subGame: getImg("game-HUSA_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_30",
          thumb: {
            img: getImg("game-DragonKing_cn.webp"),
            subGame: getImg("game-DragonKing_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_31",
          thumb: {
            img: getImg("game-TiKiParty_cn.webp"),
            subGame: getImg("game-TiKiParty_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_32",
          thumb: {
            img: getImg("game-GoblinMiner_cn.webp"),
            subGame: getImg("game-GoblinMiner_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_33",
          thumb: {
            img: getImg("game-LuckyBar_cn.webp"),
            subGame: getImg("game-LuckyBar_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_34",
          thumb: {
            img: getImg("game-Africa_cn.webp"),
            subGame: getImg("game-Africa_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_35",
          thumb: {
            img: getImg("game-WizardStore_cn.webp"),
            subGame: getImg("game-WizardStore_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_36",
          thumb: {
            img: getImg("game-Mr.Doggy_cn.webp"),
            subGame: getImg("game-Mr.Doggy_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_37",
          thumb: {
            img: getImg("game-DiscoNight_cn.webp"),
            subGame: getImg("game-DiscoNight_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_38",
          thumb: {
            img: getImg("game-HorrorNights_cn.webp"),
            subGame: getImg("game-HorrorNights_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_39",
          thumb: {
            img: getImg("game-ChinaEmpress_cn.webp"),
            subGame: getImg("game-ChinaEmpress_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_40",
          thumb: {
            img: getImg("game-FuWaFaFa_cn.webp"),
            subGame: getImg("game-FuWaFaFa_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_41",
          thumb: {
            img: getImg("game-TarZan_cn.webp"),
            subGame: getImg("game-TarZan_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_42",
          thumb: {
            img: getImg("game-Jalapeno_cn.webp"),
            subGame: getImg("game-Jalapeno_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_43",
          thumb: {
            img: getImg("game-PiggyPunch_cn.webp"),
            subGame: getImg("game-PiggyPunch_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_44",
          thumb: {
            img: getImg("game-SevensHigh_cn.webp"),
            subGame: getImg("game-SevensHigh_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_45",
          thumb: {
            img: getImg("game-Kunoichi_cn.webp"),
            subGame: getImg("game-Kunoichi_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_46",
          thumb: {
            img: getImg("game-Ninja_cn.webp"),
            subGame: getImg("game-Ninja_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_47",
          thumb: {
            img: getImg("game-Jelly27_cn.webp"),
            subGame: getImg("game-Jelly27_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_48",
          thumb: {
            img: getImg("game-AngryBear_cn.webp"),
            subGame: getImg("game-AngryBear_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_49",
          thumb: {
            img: getImg("game-Poseidon_cn.webp"),
            subGame: getImg("game-Poseidon_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_50",
          thumb: {
            img: getImg("game-DancingLion_cn.webp"),
            subGame: getImg("game-DancingLion_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_51",
          thumb: {
            img: getImg("game-Medusa_cn.webp"),
            subGame: getImg("game-Medusa_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_52",
          thumb: {
            img: getImg("game-Medea_cn.webp"),
            subGame: getImg("game-Medea_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_53",
          thumb: {
            img: getImg("game-NeonCircle_cn.webp"),
            subGame: getImg("game-NeonCircle_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_55",
          thumb: {
            img: getImg("game-GetHigh_cn.webp"),
            subGame: getImg("game-GetHigh_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_56",
          thumb: {
            img: getImg("game-Cowboy_cn.webp"),
            subGame: getImg("game-Cowboy_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_58",
          thumb: {
            img: getImg("game-TheLittleMatchGirl_cn.webp"),
            subGame: getImg("game-TheLittleMatchGirl_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_59",
          thumb: {
            img: getImg("game-MysteryPanda_cn.webp"),
            subGame: getImg("game-MysteryPanda_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_60",
          thumb: {
            img: getImg("game-HipHopMonkey_cn.webp"),
            subGame: getImg("game-HipHopMonkey_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_61",
          thumb: {
            img: getImg("game-BookofGold_cn.webp"),
            subGame: getImg("game-BookofGold_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_65",
          thumb: {
            img: getImg("game-TaiChi_cn.webp"),
            subGame: getImg("game-TaiChi_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_66",
          thumb: {
            img: getImg("game-GoldenLeafClover_cn.webp"),
            subGame: getImg("game-GoldenLeafClover_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_68",
          thumb: {
            img: getImg("game-WizardStoreGold_cn.webp"),
            subGame: getImg("game-WizardStoreGold_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_70",
          thumb: {
            img: getImg("game-RatsMoney_cn.webp"),
            subGame: getImg("game-RatsMoney_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_72",
          thumb: {
            img: getImg("game-Songkran_cn.webp"),
            subGame: getImg("game-Songkran_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_73",
          thumb: {
            img: getImg("game-ElfArcher_cn.webp"),
            subGame: getImg("game-ElfArcher_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_76",
          thumb: {
            img: getImg("game-BearKingdom_cn.webp"),
            subGame: getImg("game-BearKingdom_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_78",
          thumb: {
            img: getImg("game-Royal7777_cn.webp"),
            subGame: getImg("game-Royal7777_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_81",
          thumb: {
            img: getImg("game-DragonKing2_cn.webp"),
            subGame: getImg("game-DragonKing2_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_82",
          thumb: {
            img: getImg("game-PharaohII_cn.webp"),
            subGame: getImg("game-PharaohII_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_90",
          thumb: {
            img: getImg("game-DragonFight_cn.webp"),
            subGame: getImg("game-DragonFight_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_100",
          thumb: {
            img: getImg("game-Roma_cn.webp"),
            subGame: getImg("game-Roma_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_111",
          thumb: {
            img: getImg("game-HappyFarm_cn.webp"),
            subGame: getImg("game-HappyFarm_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_112",
          thumb: {
            img: getImg("game-PowerOfThor_cn.webp"),
            subGame: getImg("game-PowerOfThor_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_113",
          thumb: {
            img: getImg("game-ChinShiHuang_cn.webp"),
            subGame: getImg("game-ChinShiHuang_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_2001",
          thumb: {
            img: getImg("game-EnergyCombo_cn.webp"),
            subGame: getImg("game-EnergyCombo_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_3001",
          thumb: {
            img: getImg("game-OceanEmperor_cn.webp"),
            subGame: getImg("game-OceanEmperor_cn.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_114",
          thumb: {
            img: getImg("RSG_ELECTRONIC_114_cn.png"),
            subGame: getImg("RSG_ELECTRONIC_114_cn.png"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_117",
          thumb: {
            img: getImg("RSG_ELECTRONIC_117_cn.png"),
            subGame: getImg("RSG_ELECTRONIC_117_cn.png"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_119",
          thumb: {
            img: getImg("RSG_ELECTRONIC_119.png"),
            subGame: getImg("RSG_ELECTRONIC_119.png"),
          },
        },
      ],
    },
    zh_TW: {
      subGames: [
        {
          subCode: "RSG_ELECTRONIC_1",
          thumb: {
            img: getImg("game-FortuneThai_tw.webp"),
            subGame: getImg("game-FortuneThai_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_2",
          thumb: {
            img: getImg("game-MagicGem_tw.webp"),
            subGame: getImg("game-MagicGem_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_3",
          thumb: {
            img: getImg("game-Royal777_tw.webp"),
            subGame: getImg("game-Royal777_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_4",
          thumb: {
            img: getImg("game-LoveCity_tw.webp"),
            subGame: getImg("game-LoveCity_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_5",
          thumb: {
            img: getImg("game-GoldChicken_tw.webp"),
            subGame: getImg("game-GoldChicken_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_6",
          thumb: {
            img: getImg("game-Pharaoh_tw.webp"),
            subGame: getImg("game-Pharaoh_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_7",
          thumb: {
            img: getImg("game-Alibaba_tw.webp"),
            subGame: getImg("game-Alibaba_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_8",
          thumb: {
            img: getImg("game-LuckyFruits_tw.webp"),
            subGame: getImg("game-LuckyFruits_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_10",
          thumb: {
            img: getImg("game-Jungle_tw.webp"),
            subGame: getImg("game-Jungle_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_11",
          thumb: {
            img: getImg("game-CaptainHook_tw.webp"),
            subGame: getImg("game-CaptainHook_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_12",
          thumb: {
            img: getImg("game-HUCA_tw.webp"),
            subGame: getImg("game-HUCA_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_14",
          thumb: {
            img: getImg("game-SweetCandy_tw.webp"),
            subGame: getImg("game-SweetCandy_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_15",
          thumb: {
            img: getImg("game-FireSpin_tw.webp"),
            subGame: getImg("game-FireSpin_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_16",
          thumb: {
            img: getImg("game-Popeye_tw.webp"),
            subGame: getImg("game-Popeye_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_17",
          thumb: {
            img: getImg("game-CrazyDoctor_tw.webp"),
            subGame: getImg("game-CrazyDoctor_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_18",
          thumb: {
            img: getImg("game-Nonstop_tw.webp"),
            subGame: getImg("game-Nonstop_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_19",
          thumb: {
            img: getImg("game-5Dragons_tw.webp"),
            subGame: getImg("game-5Dragons_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_21",
          thumb: {
            img: getImg("game-72Changes_tw.webp"),
            subGame: getImg("game-72Changes_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_23",
          thumb: {
            img: getImg("game-Mermaid_tw.webp"),
            subGame: getImg("game-Mermaid_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_24",
          thumb: {
            img: getImg("game-Buffalo_tw.webp"),
            subGame: getImg("game-Buffalo_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_25",
          thumb: {
            img: getImg("game-WildPanda_tw.webp"),
            subGame: getImg("game-WildPanda_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_26",
          thumb: {
            img: getImg("game-LuckyThailand_tw.webp"),
            subGame: getImg("game-LuckyThailand_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_27",
          thumb: {
            img: getImg("game-GodofWealth_tw.webp"),
            subGame: getImg("game-GodofWealth_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_28",
          thumb: {
            img: getImg("game-LuckyDragon_tw.webp"),
            subGame: getImg("game-LuckyDragon_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_29",
          thumb: {
            img: getImg("game-HUSA_tw.webp"),
            subGame: getImg("game-HUSA_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_30",
          thumb: {
            img: getImg("game-DragonKing_tw.webp"),
            subGame: getImg("game-DragonKing_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_31",
          thumb: {
            img: getImg("game-TiKiParty_tw.webp"),
            subGame: getImg("game-TiKiParty_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_32",
          thumb: {
            img: getImg("game-GoblinMiner_tw.webp"),
            subGame: getImg("game-GoblinMiner_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_33",
          thumb: {
            img: getImg("game-LuckyBar_tw.webp"),
            subGame: getImg("game-LuckyBar_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_34",
          thumb: {
            img: getImg("game-Africa_tw.webp"),
            subGame: getImg("game-Africa_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_35",
          thumb: {
            img: getImg("game-WizardStore_tw.webp"),
            subGame: getImg("game-WizardStore_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_36",
          thumb: {
            img: getImg("game-Mr.Doggy_tw.webp"),
            subGame: getImg("game-Mr.Doggy_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_37",
          thumb: {
            img: getImg("game-DiscoNight_tw.webp"),
            subGame: getImg("game-DiscoNight_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_38",
          thumb: {
            img: getImg("game-HorrorNights_tw.webp"),
            subGame: getImg("game-HorrorNights_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_39",
          thumb: {
            img: getImg("game-ChinaEmpress_tw.webp"),
            subGame: getImg("game-ChinaEmpress_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_40",
          thumb: {
            img: getImg("game-FuWaFaFa_tw.webp"),
            subGame: getImg("game-FuWaFaFa_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_41",
          thumb: {
            img: getImg("game-TarZan_tw.webp"),
            subGame: getImg("game-TarZan_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_42",
          thumb: {
            img: getImg("game-Jalapeno_tw.webp"),
            subGame: getImg("game-Jalapeno_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_43",
          thumb: {
            img: getImg("game-PiggyPunch_tw.webp"),
            subGame: getImg("game-PiggyPunch_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_44",
          thumb: {
            img: getImg("game-SevensHigh_tw.webp"),
            subGame: getImg("game-SevensHigh_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_45",
          thumb: {
            img: getImg("game-Kunoichi_tw.webp"),
            subGame: getImg("game-Kunoichi_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_46",
          thumb: {
            img: getImg("game-Ninja_tw.webp"),
            subGame: getImg("game-Ninja_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_47",
          thumb: {
            img: getImg("game-Jelly27_tw.webp"),
            subGame: getImg("game-Jelly27_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_48",
          thumb: {
            img: getImg("game-AngryBear_tw.webp"),
            subGame: getImg("game-AngryBear_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_49",
          thumb: {
            img: getImg("game-Poseidon_tw.webp"),
            subGame: getImg("game-Poseidon_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_50",
          thumb: {
            img: getImg("game-DancingLion_tw.webp"),
            subGame: getImg("game-DancingLion_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_51",
          thumb: {
            img: getImg("game-Medusa_tw.webp"),
            subGame: getImg("game-Medusa_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_52",
          thumb: {
            img: getImg("game-Medea_tw.webp"),
            subGame: getImg("game-Medea_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_53",
          thumb: {
            img: getImg("game-NeonCircle_tw.webp"),
            subGame: getImg("game-NeonCircle_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_55",
          thumb: {
            img: getImg("game-GetHigh_tw.webp"),
            subGame: getImg("game-GetHigh_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_56",
          thumb: {
            img: getImg("game-Cowboy_tw.webp"),
            subGame: getImg("game-Cowboy_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_58",
          thumb: {
            img: getImg("game-TheLittleMatchGirl_tw.webp"),
            subGame: getImg("game-TheLittleMatchGirl_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_59",
          thumb: {
            img: getImg("game-MysteryPanda_tw.webp"),
            subGame: getImg("game-MysteryPanda_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_60",
          thumb: {
            img: getImg("game-HipHopMonkey_tw.webp"),
            subGame: getImg("game-HipHopMonkey_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_61",
          thumb: {
            img: getImg("game-BookofGold_tw.webp"),
            subGame: getImg("game-BookofGold_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_65",
          thumb: {
            img: getImg("game-TaiChi_tw.webp"),
            subGame: getImg("game-TaiChi_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_66",
          thumb: {
            img: getImg("game-GoldenLeafClover_tw.webp"),
            subGame: getImg("game-GoldenLeafClover_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_68",
          thumb: {
            img: getImg("game-WizardStoreGold_tw.webp"),
            subGame: getImg("game-WizardStoreGold_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_70",
          thumb: {
            img: getImg("game-RatsMoney_tw.webp"),
            subGame: getImg("game-RatsMoney_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_72",
          thumb: {
            img: getImg("game-Songkran_tw.webp"),
            subGame: getImg("game-Songkran_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_73",
          thumb: {
            img: getImg("game-ElfArcher_tw.webp"),
            subGame: getImg("game-ElfArcher_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_76",
          thumb: {
            img: getImg("game-BearKingdom_tw.webp"),
            subGame: getImg("game-BearKingdom_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_78",
          thumb: {
            img: getImg("game-Royal7777_tw.webp"),
            subGame: getImg("game-Royal7777_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_81",
          thumb: {
            img: getImg("game-DragonKing2_tw.webp"),
            subGame: getImg("game-DragonKing2_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_82",
          thumb: {
            img: getImg("game-PharaohII_tw.webp"),
            subGame: getImg("game-PharaohII_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_90",
          thumb: {
            img: getImg("game-DragonFight_tw.webp"),
            subGame: getImg("game-DragonFight_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_100",
          thumb: {
            img: getImg("game-Roma_tw.webp"),
            subGame: getImg("game-Roma_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_111",
          thumb: {
            img: getImg("game-HappyFarm_tw.webp"),
            subGame: getImg("game-HappyFarm_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_112",
          thumb: {
            img: getImg("game-PowerOfThor_tw.webp"),
            subGame: getImg("game-PowerOfThor_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_113",
          thumb: {
            img: getImg("game-ChinShiHuang_tw.webp"),
            subGame: getImg("game-ChinShiHuang_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_2001",
          thumb: {
            img: getImg("game-EnergyCombo_tw.webp"),
            subGame: getImg("game-EnergyCombo_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_3001",
          thumb: {
            img: getImg("game-OceanEmperor_tw.webp"),
            subGame: getImg("game-OceanEmperor_tw.webp"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_114",
          thumb: {
            img: getImg("RSG_ELECTRONIC_114_tw.png"),
            subGame: getImg("RSG_ELECTRONIC_114_tw.png"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_117",
          thumb: {
            img: getImg("RSG_ELECTRONIC_117_tw.png"),
            subGame: getImg("RSG_ELECTRONIC_117_tw.png"),
          },
        },
        {
          subCode: "RSG_ELECTRONIC_119",
          thumb: {
            img: getImg("RSG_ELECTRONIC_119.png"),
            subGame: getImg("RSG_ELECTRONIC_119.png"),
          },
        },
      ],
    },
  },
} as LIBRARY_GAME;
