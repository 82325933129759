/* eslint-disable @typescript-eslint/no-var-requires */
import { createRouter, createWebHistory } from "vue-router";
import { getEnv } from '@/modules/FPKG-20000-Util/env'
import BRANCH from '@branch';  
import loadRouter from '@/modules/THEMES/THEME-120000-CASINO8/router'
const THEME_NAME = BRANCH.p8_theme;   
// // 确保 THEME_NAME 正确赋值  
// const loadRouter = async () => {
//   return await import(`@/modules/THEMES/${THEME_NAME}/router.ts`);
// };

// eslint-disable-next-line @typescript-eslint/no-var-requires

const error404 = {
  path: "/:pathMatch(.*)*",
  // path: "/error404",
  // redirect: '/login',
  component: () =>
    import(/* webpackChunkName: "error-handle" */ "./components/error404.vue"),
  name: "error404",
};
const THEME_ROUTER = loadRouter;

const DEFAULT_PATH = [...THEME_ROUTER, error404];

const router = createRouter({
  history: createWebHistory(getEnv('BASE_URL')),
  routes: DEFAULT_PATH,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { top: savedPosition.top, behavior: "auto" };
    }
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    return { top: 0 };
  },
});

export default router;
