import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('BEWIN', fileName)
};
export default {
  code: "BEWIN",
  type: ["EGAMING"],
  play_method: "URL",
  subGames: [
    {
      subCode: "BEWIN_coo0049",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0048",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0047",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0046",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0045",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0044",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0043",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0042",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0041",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0040",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0039",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0038",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0037",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0036",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0035",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0034",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0033",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0032",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0031",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0030",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0029",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0028",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0027",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0026",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0025",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0024",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0023",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0022",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0021",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0020",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0019",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0018",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0017",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0016",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0015",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0014",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0013",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0012",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0011",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0010",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0009",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0008",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0007",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0006",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0005",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0004",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0003",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0002",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_coo0001",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      subCode: "BEWIN_cmc0002",
      thumb: { img: getImg("lobby/740x480.png") },
    },
    {
      thumb: { img: getImg("cmc0001/zh740x480.jpg") },
      subCode: "BEWIN_cmc0001",
    },
    {
      thumb: { img: getImg("cm00074/zh740x480.jpg") },
      subCode: "BEWIN_cm00074",
    },
    {
      thumb: { img: getImg("cm00076/zh740x480.jpg") },
      subCode: "BEWIN_cm00076",
    },
    {
      thumb: { img: getImg("cm00083/zh740x480.jpg") },
      subCode: "BEWIN_cm00083",
    },
    {
      thumb: { img: getImg("cm00046/zh740x480.jpg") },
      subCode: "BEWIN_cm00046",
    },
    {
      thumb: { img: getImg("cm00075/zh740x480.jpg") },
      subCode: "BEWIN_cm00075",
    },
    {
      thumb: { img: getImg("cmf0006/zh740x480.jpg") },
      subCode: "BEWIN_cmf0006",
    },
    {
      thumb: { img: getImg("cm00026/zh740x480.jpg") },
      subCode: "BEWIN_cm00026",
    },
    {
      thumb: { img: getImg("cm00023/zh740x480.jpg") },
      subCode: "BEWIN_cm00023",
    },
    {
      thumb: { img: getImg("cmf0001/zh740x480.jpg") },
      subCode: "BEWIN_cmf0001",
    },
    {
      thumb: { img: getImg("cmf0002/zh740x480.jpg") },
      subCode: "BEWIN_cmf0002",
    },
    {
      thumb: { img: getImg("cm00067/zh740x480.jpg") },
      subCode: "BEWIN_cm00067",
    },
    {
      thumb: { img: getImg("cm00022/zh740x480.jpg") },
      subCode: "BEWIN_cm00022",
    },
    {
      thumb: { img: getImg("cm00030/zh740x480.jpg") },
      subCode: "BEWIN_cm00030",
    },
    {
      thumb: { img: getImg("cm00063/zh740x480.jpg") },
      subCode: "BEWIN_cm00063",
    },
    {
      thumb: { img: getImg("cm00040/zh740x480.jpg") },
      subCode: "BEWIN_cm00040",
    },
    {
      thumb: { img: getImg("cm00021/zh740x480.jpg") },
      subCode: "BEWIN_cm00021",
    },
    {
      thumb: { img: getImg("cm00032/zh740x480.jpg") },
      subCode: "BEWIN_cm00032",
    },
    {
      thumb: { img: getImg("cm00055/zh740x480.jpg") },
      subCode: "BEWIN_cm00055",
    },
    {
      thumb: { img: getImg("cm00042/zh740x480.jpg") },
      subCode: "BEWIN_cm00042",
    },
    {
      thumb: { img: getImg("cm00038/zh740x480.jpg") },
      subCode: "BEWIN_cm00038",
    },
    {
      thumb: { img: getImg("cm00035/zh740x480.jpg") },
      subCode: "BEWIN_cm00035",
    },
    {
      thumb: { img: getImg("cm00020/zh740x480.jpg") },
      subCode: "BEWIN_cm00020",
    },
    {
      thumb: { img: getImg("cm00031/zh740x480.jpg") },
      subCode: "BEWIN_cm00031",
    },
    {
      thumb: { img: getImg("cm00034/zh740x480.jpg") },
      subCode: "BEWIN_cm00034",
    },
    {
      thumb: { img: getImg("cm00033/zh740x480.jpg") },
      subCode: "BEWIN_cm00033",
    },
    {
      thumb: { img: getImg("cm00019/zh740x480.jpg") },
      subCode: "BEWIN_cm00019",
    },
    {
      thumb: { img: getImg("cm00056/zh740x480.jpg") },
      subCode: "BEWIN_cm00056",
    },
    {
      thumb: { img: getImg("cm00018/zh740x480.jpg") },
      subCode: "BEWIN_cm00018",
    },
    {
      thumb: { img: getImg("cm00017/zh740x480.jpg") },
      subCode: "BEWIN_cm00017",
    },
    {
      thumb: { img: getImg("cm00016/zh740x480.jpg") },
      subCode: "BEWIN_cm00016",
    },
    {
      thumb: { img: getImg("cm00015/zh740x480.jpg") },
      subCode: "BEWIN_cm00015",
    },
    {
      thumb: { img: getImg("cm00014/zh740x480.jpg") },
      subCode: "BEWIN_cm00014",
    },
    {
      thumb: { img: getImg("cm00013/zh740x480.jpg") },
      subCode: "BEWIN_cm00013",
    },
    {
      thumb: { img: getImg("cm00012/zh740x480.jpg") },
      subCode: "BEWIN_cm00012",
    },
    {
      thumb: { img: getImg("cm00011/zh740x480.jpg") },
      subCode: "BEWIN_cm00011",
    },
    {
      thumb: { img: getImg("cm00010/zh740x480.jpg") },
      subCode: "BEWIN_cm00010",
    },
    {
      thumb: { img: getImg("cm00009/zh740x480.jpg") },
      subCode: "BEWIN_cm00009",
    },
    {
      thumb: { img: getImg("cm00008/zh740x480.jpg") },
      subCode: "BEWIN_cm00008",
    },
    {
      thumb: { img: getImg("cm00007/zh740x480.jpg") },
      subCode: "BEWIN_cm00007",
    },
    {
      thumb: { img: getImg("cm00006/zh740x480.jpg") },
      subCode: "BEWIN_cm00006",
    },
    {
      thumb: { img: getImg("cm00005/zh740x480.jpg") },
      subCode: "BEWIN_cm00005",
    },
    {
      thumb: { img: getImg("cm00004/zh740x480.jpg") },
      subCode: "BEWIN_cm00004",
    },
    {
      thumb: { img: getImg("cm00003/zh740x480.jpg") },
      subCode: "BEWIN_cm00003",
    },
    {
      thumb: { img: getImg("cm00002/zh740x480.jpg") },
      subCode: "BEWIN_cm00002",
    },
    {
      thumb: { img: getImg("cm00001/zh740x480.jpg") },
      subCode: "BEWIN_cm00001",
    },
  ],
} as LIBRARY_GAME;
