import { AxiosResponse } from "axios";
import $http from "../../../FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "account/auth/logout";

export namespace typeDoModify {
  // export interface request {}
  export interface AxiosResponse extends _AxiosResponse {}
}
interface _AxiosResponse extends AxiosResponse<responseData> {}

interface responseData extends BackendResponse {
  response: any;
}

/** 登入 */
export default async () => {
  const getter = await $http.put(API);
  if (getter.data.code !== 0) throw getter;
  return getter;
};
