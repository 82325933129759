import { AxiosResponse } from "axios";
import $http from "../../../FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "casinoAccount/front-member/info";
// https://redmine.mirfak.tw/projects/pkg-170000-casinoaccount/repository/37/revisions/master/entry/_md/api/Front/Member/%E8%B3%87%E8%A8%8A.md

export namespace typeInfo {
  // export interface request {  }
  export type AxiosResponse = _AxiosResponse;
  export type response = {
    datum: datum;
    p17_communication_software: p17_communication_software[];
  };
  export type datum = {
    p3_account: {};
    p17_mer_auction_free_times: 15;
    p17_mer_id_name: null;
    p17_mer_id_number: null;
    p17_mer_p3_ant_id: 146;
    p17_mer_p18_ull_id: 13;
    p17_mer_phone_verify_status: "y";
    p17_mer_review_memo: null;
    /** 帳號審核狀態 3:通過 ,2:待審 ,-3:不通過  */
    p17_mer_review_status: "3" | "2" | "-3";
    p17_mer_reviewed_at: 1636451986;
    p17_mer_reviewer_p3_ant_id: 48;
    p17_mer_stored_free_times: 183;
    p18_user_level: p18_user_level;
  };
  export type p18_user_level = {
    p18_ull_count: 31;
    p18_ull_created_at: 1615631392;
    p18_ull_default: "y";
    p18_ull_id: 13;
    p18_ull_memo: null;
    /** 使用者等級 */
    p18_ull_name: string;
    p18_ull_status: "3";
    p18_ull_updated_at: 1634308395;
  };
  export type p17_communication_software = {
    p17_cse_code: "3RG3rg3RG";
    // p17_cse_id: 22;
    // p17_cse_p3_ant_id: 146;
    p17_cse_software: "LINE_ID";
  };
}
type _AxiosResponse = AxiosResponse<ResponseData>;

interface ResponseData extends BackendResponse {
  response: typeInfo.response;
}

/** 使用者資訊 */
export default async (): Promise<_AxiosResponse> => {
  const getter = await $http(API);
  if (getter.data.code !== 0) throw getter;
  return getter;
};
