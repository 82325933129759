import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('GTAG_SKYWIND', fileName)
};

export default {
  code: "GTAG_SKYWIND",
  type: ["EGAMING", "CASINO"],
  play_method: "URL",
  subGames: [
    {
      subCode: "GTAG_SKYWIND_SW_RO_YEL_RO_ATOM",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("yellowatomroulette.webp"),
        subGame: getImg("yellowatomroulette.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_RO_BLU_RO_ATOM",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blueatomroulette.webp"),
        subGame: getImg("blueatomroulette.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_RO_GRE_RO_ATOM",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("greenatomroulette.webp"),
        subGame: getImg("greenatomroulette.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_RO_RED_RO_ATOM",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("redatomroulette.webp"),
        subGame: getImg("redatomroulette.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_RO_YEL_RO",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("yellowatomroulette.webp"),
        subGame: getImg("yellowatomroulette.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_RO_BLU_RO",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blueatomroulette.webp"),
        subGame: getImg("blueatomroulette.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_RO_GRE_RO",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("greenatomroulette.webp"),
        subGame: getImg("greenatomroulette.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_RO_RED_RO",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("redatomroulette.webp"),
        subGame: getImg("redatomroulette.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_RO_A01BAC_NC",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("baccarata01nocommission.webp"),
        subGame: getImg("baccarata01nocommission.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_LIVEGAME_ROULETTE",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("roulettea03.webp"),
        subGame: getImg("roulettea03.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_LIVEGAME_BACCARAT",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("livegameslobby.webp"),
        subGame: getImg("livegameslobby.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_LIVEGAME_ALL_LIVE",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("livegameslobby.webp"),
        subGame: getImg("livegameslobby.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_KS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("knight’ssaga.webp"),
        subGame: getImg("knight’ssaga.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_DOSC7S",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("doublescatter7’s.webp"),
        subGame: getImg("doublescatter7’s.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_CBE_965",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("celestialbeauty.webp"),
        subGame: getImg("celestialbeauty.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_IMN_CRASH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("imooncrashroyale.webp"),
        subGame: getImg("imooncrashroyale.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SUBO_965",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sugarbonanzadeluxe.webp"),
        subGame: getImg("sugarbonanzadeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_PSI_965",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("piatasmash.webp"),
        subGame: getImg("piatasmash.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_TM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("triplemonkey.webp"),
        subGame: getImg("triplemonkey.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_MCATSS_965",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("maxchanceandthesafarisecrets.webp"),
        subGame: getImg("maxchanceandthesafarisecrets.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_ST",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("startrekthenextgeneration.webp"),
        subGame: getImg("startrekthenextgeneration.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_ITG_BIGIRISHBUCKS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bigirishbucks.webp"),
        subGame: getImg("bigirishbucks.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_HGE_965",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("heavenlygold.webp"),
        subGame: getImg("heavenlygold.webp"),
      },
    },

    {
      subCode: "GTAG_SKYWIND_SW_GE1XAS-TE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("caishenlaile.webp"),
        subGame: getImg("caishenlaile.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_ALME",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("alivemegaways.webp"),
        subGame: getImg("alivemegaways.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_JQW_AB_JP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jinqianwajackpot.webp"),
        subGame: getImg("jinqianwajackpot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_DMZC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("damaozhaocaimoneycat.webp"),
        subGame: getImg("damaozhaocaimoneycat.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_RM_AB_JP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("respinmaniawushijackpot.webp"),
        subGame: getImg("respinmaniawushijackpot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_WIWICAOU",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildwildcashout.webp"),
        subGame: getImg("wildwildcashout.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SULIDE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("superlionnonjp.webp"),
        subGame: getImg("superlionnonjp.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_WIDI",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wickeddice.webp"),
        subGame: getImg("wickeddice.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_JOLUDE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jokersluckdeluxe.webp"),
        subGame: getImg("jokersluckdeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_TPGSOP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thepearlgame.webp"),
        subGame: getImg("thepearlgame.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SEOFPE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("seaofpearls.webp"),
        subGame: getImg("seaofpearls.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_JOLU",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jokersluck.webp"),
        subGame: getImg("jokersluck.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SSM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("stickysevensmegaways.webp"),
        subGame: getImg("stickysevensmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_BAGO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("elpasogold.webp"),
        subGame: getImg("elpasogold.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_LOMATHTT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("lotharmatthaus.beawinner.webp"),
        subGame: getImg("lotharmatthaus.beawinner.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_CSI",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("csi:crimesceneinvestigation.webp"),
        subGame: getImg("csi:crimesceneinvestigation.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_CSY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("caishenye.webp"),
        subGame: getImg("caishenye.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_LFS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("liufushou.webp"),
        subGame: getImg("liufushou.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_HR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("heavenlyruler.webp"),
        subGame: getImg("heavenlyruler.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("savannahcash.webp"),
        subGame: getImg("savannahcash.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_JXL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jixianglong.webp"),
        subGame: getImg("jixianglong.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_GO8D",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goddessof8directions.webp"),
        subGame: getImg("goddessof8directions.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_RA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("rainballs.webp"),
        subGame: getImg("rainballs.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_COMA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("combatmasters.webp"),
        subGame: getImg("combatmasters.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_BL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("biglion.webp"),
        subGame: getImg("biglion.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_PE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pirateempress.webp"),
        subGame: getImg("pirateempress.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_KXCS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("kuxuancaishen.webp"),
        subGame: getImg("kuxuancaishen.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_FR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("firereel.webp"),
        subGame: getImg("firereel.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_WRL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("waterreel.webp"),
        subGame: getImg("waterreel.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_LCC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("luckychanchu.webp"),
        subGame: getImg("luckychanchu.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SCTZ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("songcaitongzi.webp"),
        subGame: getImg("songcaitongzi.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_FD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("shenqijiulong.webp"),
        subGame: getImg("shenqijiulong.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_XW",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("xuanwu.webp"),
        subGame: getImg("xuanwu.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_8TR1QU",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("8treasures1queen.webp"),
        subGame: getImg("8treasures1queen.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SULI",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("superlion.webp"),
        subGame: getImg("superlion.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_WG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("warriorsgold.webp"),
        subGame: getImg("warriorsgold.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_WF",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildfive.webp"),
        subGame: getImg("wildfive.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_AR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("aztecreel.webp"),
        subGame: getImg("aztecreel.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_GS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldshot.webp"),
        subGame: getImg("goldshot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_FB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("simei.webp"),
        subGame: getImg("simei.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_PS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("potshot.webp"),
        subGame: getImg("potshot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_RR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("riverboatreel.webp"),
        subGame: getImg("riverboatreel.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_XYBL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("xingyunbianlian.webp"),
        subGame: getImg("xingyunbianlian.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_GES",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("genieshot.webp"),
        subGame: getImg("genieshot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_HD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hearts&dragons.webp"),
        subGame: getImg("hearts&dragons.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_RMAC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thereelmacau.webp"),
        subGame: getImg("thereelmacau.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SGCF_AB_JP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("shuiguocaifujackpot.webp"),
        subGame: getImg("shuiguocaifujackpot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_WS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("westshot.webp"),
        subGame: getImg("westshot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_BUL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("buffalolightning.webp"),
        subGame: getImg("buffalolightning.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_MPAYS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("monkeypays.webp"),
        subGame: getImg("monkeypays.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_MDLS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("middleshot.webp"),
        subGame: getImg("middleshot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_MR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("metalreel.webp"),
        subGame: getImg("metalreel.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_FF",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("firefestival.webp"),
        subGame: getImg("firefestival.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_ES",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("egyptspin.webp"),
        subGame: getImg("egyptspin.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_BB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bigbuffalo.webp"),
        subGame: getImg("bigbuffalo.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_GEMERENIGAEL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("geniemegareels.webp"),
        subGame: getImg("geniemegareels.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_VFV",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("vivafruitvegas.webp"),
        subGame: getImg("vivafruitvegas.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_CASHDAHA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("caishendahai.webp"),
        subGame: getImg("caishendahai.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_XWK",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("xiaowukong.webp"),
        subGame: getImg("xiaowukong.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SLBS_JP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("shenlongbaoshijackpot.webp"),
        subGame: getImg("shenlongbaoshijackpot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SLAND",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sugarland.webp"),
        subGame: getImg("sugarland.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_BM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("butterflymoon.webp"),
        subGame: getImg("butterflymoon.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_FG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fruitygirl.webp"),
        subGame: getImg("fruitygirl.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_FSQT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fushouqitian.webp"),
        subGame: getImg("fushouqitian.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_EC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("egyptcash.webp"),
        subGame: getImg("egyptcash.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_CF",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("chillifestival.webp"),
        subGame: getImg("chillifestival.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_2PD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("2powerfuldragons.webp"),
        subGame: getImg("2powerfuldragons.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_WFWW",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wufuwawa.webp"),
        subGame: getImg("wufuwawa.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_GQ_AB_JP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gemqueenjackpot.webp"),
        subGame: getImg("gemqueenjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_EX",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("explosion.webp"),
        subGame: getImg("explosion.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_RORIYANG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("royalrings.webp"),
        subGame: getImg("royalrings.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_TAOFTWDRJAED",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("taleoftwodragonsjackpotedition.webp"),
        subGame: getImg("taleoftwodragonsjackpotedition.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SILIJAAB_JP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("silingjackpot.webp"),
        subGame: getImg("silingjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_DALADEJAAB_JP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dalandeluxejackpot.webp"),
        subGame: getImg("dalandeluxejackpot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SIMEJAAB_JP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("simeijackpot.webp"),
        subGame: getImg("simeijackpot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_YXLB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("yingxionglubu.webp"),
        subGame: getImg("yingxionglubu.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SHCTZ_AB_JP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("zhaocaitongzijackpot.webp"),
        subGame: getImg("zhaocaitongzijackpot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_WI0",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wicked777.webp"),
        subGame: getImg("wicked777.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_PRLI",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jaguargold.webp"),
        subGame: getImg("jaguargold.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SUEL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("superelephant.webp"),
        subGame: getImg("superelephant.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_WIFR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wickedhot.webp"),
        subGame: getImg("wickedhot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_FIREDE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("firereeldeluxe.webp"),
        subGame: getImg("firereeldeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_WAREDE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("waterreeldeluxe.webp"),
        subGame: getImg("waterreeldeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_MISHDE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("middleshotdeluxe.webp"),
        subGame: getImg("middleshotdeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_BOOFGE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bookofgemsmegaways.webp"),
        subGame: getImg("bookofgemsmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SU",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("superwolf.webp"),
        subGame: getImg("superwolf.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_GEJO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jokermultiwin.webp"),
        subGame: getImg("jokermultiwin.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_RUPE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("rubypearl.webp"),
        subGame: getImg("rubypearl.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_BIBUME",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bigbuffalomegaways.webp"),
        subGame: getImg("bigbuffalomegaways.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_MYJP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mayajackpot.webp"),
        subGame: getImg("mayajackpot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_MM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mayamillions.webp"),
        subGame: getImg("mayamillions.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_FISH_PRAWN_CRAB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fishprawncrab.webp"),
        subGame: getImg("fishprawncrab.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_ER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("europeanroulette.webp"),
        subGame: getImg("europeanroulette.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_DRAGON_DOZER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragondozer.webp"),
        subGame: getImg("dragondozer.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_OR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("oceanruler.webp"),
        subGame: getImg("oceanruler.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_BAC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("baccarat.webp"),
        subGame: getImg("baccarat.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_TCB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("3cardbrag.webp"),
        subGame: getImg("3cardbrag.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_BJC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjack.webp"),
        subGame: getImg("blackjack.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_FJ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("flyjet.webp"),
        subGame: getImg("flyjet.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SCCA2D",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("snatchxcash.webp"),
        subGame: getImg("snatchxcash.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_LUCKY_OMQ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("luckyomq.webp"),
        subGame: getImg("luckyomq.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_LUCKYFIM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("luckyfisherman.webp"),
        subGame: getImg("luckyfisherman.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_TWFR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("twinfruits.webp"),
        subGame: getImg("twinfruits.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_TOTIATP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("theorcatheicebergandthepenguin.webp"),
        subGame: getImg("theorcatheicebergandthepenguin.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_DR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragonriches.webp"),
        subGame: getImg("dragonriches.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_DLD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dalandeluxe.webp"),
        subGame: getImg("dalandeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_REMAMERE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("respinmaniamegareels.webp"),
        subGame: getImg("respinmaniamegareels.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_GT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gemtemple.webp"),
        subGame: getImg("gemtemple.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_HLCS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("huanlecaishen.webp"),
        subGame: getImg("huanlecaishen.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_REKI",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("respinking.webp"),
        subGame: getImg("respinking.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_CTS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("choi'stravellingshow.webp"),
        subGame: getImg("choi'stravellingshow.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_88SF",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("88shifu.webp"),
        subGame: getImg("88shifu.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SHCTZ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("zhaocaitongzi.webp"),
        subGame: getImg("zhaocaitongzi.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_HCS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("haoshichengshuang.webp"),
        subGame: getImg("haoshichengshuang.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_LLL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("longlonglong.webp"),
        subGame: getImg("longlonglong.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_QOW",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("queenofwands.webp"),
        subGame: getImg("queenofwands.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_GQ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gemqueen.webp"),
        subGame: getImg("gemqueen.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_IJP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("incajackpot.webp"),
        subGame: getImg("incajackpot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_JQW",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jinqianwa.webp"),
        subGame: getImg("jinqianwa.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_AF",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("asianfantasy.webp"),
        subGame: getImg("asianfantasy.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_FL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fortunelions.webp"),
        subGame: getImg("fortunelions.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_CH8",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("chaoji888.webp"),
        subGame: getImg("chaoji888.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_WHMJ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fivetigergenerals.webp"),
        subGame: getImg("fivetigergenerals.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_FKMJ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fengkuangmajiang.webp"),
        subGame: getImg("fengkuangmajiang.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("siling.webp"),
        subGame: getImg("siling.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_GG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gogold.webp"),
        subGame: getImg("gogold.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_T2D",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("taleoftwodragons.webp"),
        subGame: getImg("taleoftwodragons.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_FUQSG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fuqishuiguo.webp"),
        subGame: getImg("fuqishuiguo.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_FUFARM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fufarm.webp"),
        subGame: getImg("fufarm.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_FUFARM_JP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fufarmjackpot.webp"),
        subGame: getImg("fufarmjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_9S1K",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("9sons1king.webp"),
        subGame: getImg("9sons1king.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_LD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("legendarydragons.webp"),
        subGame: getImg("legendarydragons.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_GGDN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldengarden.webp"),
        subGame: getImg("goldengarden.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_HP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("heavenlyphoenix.webp"),
        subGame: getImg("heavenlyphoenix.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_H2H",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("heart2heart.webp"),
        subGame: getImg("heart2heart.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SGCF",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("shuiguocaifu.webp"),
        subGame: getImg("shuiguocaifu.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SCYD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("shengcaiyoudao.webp"),
        subGame: getImg("shengcaiyoudao.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SLBS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("shenlongbaoshi.webp"),
        subGame: getImg("shenlongbaoshi.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_SF",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sanfu.webp"),
        subGame: getImg("sanfu.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_RM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("respinmania.webp"),
        subGame: getImg("respinmania.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_RF",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ramessesfortune.webp"),
        subGame: getImg("ramessesfortune.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_OMQJP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("oldmasterq.webp"),
        subGame: getImg("oldmasterq.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_FBB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fubaobao.webp"),
        subGame: getImg("fubaobao.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_MER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mermaidbeauty.webp"),
        subGame: getImg("mermaidbeauty.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_FP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("flamingphoenix.webp"),
        subGame: getImg("flamingphoenix.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_DJ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("doublejungle.webp"),
        subGame: getImg("doublejungle.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_DB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("doublebonus.webp"),
        subGame: getImg("doublebonus.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_GOL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("godoflightning.webp"),
        subGame: getImg("godoflightning.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_DHCF",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("daheicifu.webp"),
        subGame: getImg("daheicifu.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_FUFISH-JP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fufishjackpot.webp"),
        subGame: getImg("fufishjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_AL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("amazonlady.webp"),
        subGame: getImg("amazonlady.webp"),
      },
    },
    {
      subCode: "GTAG_SKYWIND_SW_FUFISH_INTW",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fufish.webp"),
        subGame: getImg("fufish.webp"),
      },
    },
  ],
} as LIBRARY_GAME;
