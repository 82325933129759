import { AxiosResponse } from "axios";
import $http from "@/modules/FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "casinoBonus/bonus-member/lists";
// https://redmine.mirfak.tw/projects/pkg-400000-casinobonus/repository/109/revisions/master/entry/_md/%E7%B4%85%E5%88%A9%E6%93%8D%E4%BD%9C/Member%20%E6%9C%83%E5%93%A1%E7%AB%AF/%E5%88%97%E8%A1%A8.md

export namespace typeLists {
  export type request = pageinator & {
    /** 建立開始 */
    search_p40_bus_created_at_start?: string;
    /** 建立結束 */
    search_p40_bus_created_at_end?: string;
  };

  export type response = {
    list: listItem[];
    /** 總計 */
    sum: string;
    profile: profile;
    paginator: pageinator;
  };

  export type listItem = {
    /** 帳號  */
    p3_ant_account: string;
    /** 建立時間 timestamp */
    p40_bus_created_at: number;
    /** 點數  */
    p40_bus_point: string;
    /** 類型 參考 PKG-400000-CasinoBonus/src/Constant/Type.php */
    /** 一般:GENERAL  */
    p40_bus_type: "GENERAL";
    /** 備註  */
    p40_bus_memo?: string;
    /** 操作者 */
    creator: {
      p3_ant_account: string;
      p3_ape_name: string;
    };
  };

  type profile = {
    /** 基本資料 */
    p3_account_profile: {
      /** 	姓名	 */
      p3_ape_name: string;
      /** 	生日	 */
      p3_ape_birthday: string;
      /** 	性別	1.男 2.女 3.其他 */
      p3_ape_gender: string;
      /** 	電話	 */
      p3_ape_tel: string;
      /** 	email */
      p3_ape_email: string;
    }[];
  };

  export interface AxiosResponse extends _AxiosResponse {}
}
interface _AxiosResponse extends AxiosResponse<responseData> {}

interface responseData extends BackendResponse {
  response: any;
}

/** 明細 */
export default async (params?: typeLists.request) => {
  const getter = await $http.get(API, { params });
  if (getter.data.code !== 0) throw getter;
  return getter;
};
