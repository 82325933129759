import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('MC_BINGO', fileName)
};
export default {
  code: "MC_BINGO",
  type: ["EGAMING"],
  thumb: {
    img: getImg("ED-fin02.png"),
  },
  play_method: "URL",
  // subGames: [],
} as LIBRARY_GAME;
