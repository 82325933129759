import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('GOLDEN_F_IM_SPORT', fileName)
};

/** 雙類型 */
export default {
  code: "GOLDEN_F_IM_SPORT",
  type: ["ESPORT", "SPORT"],
  play_method: "URL",
  typeSetting: {
    ESPORT: { name: "CasinoFrontGames.GOLDEN_F_IM_E_SPORT.name" },
    SPORT: { name: "CasinoFrontGames.GOLDEN_F_IM_SPORT.name" },
  },
  subGames: [
    {
      subCode: "GOLDEN_F_IM_SPORT_IM_ESPORT_PK10",
      type: ["ESPORT"],
    },
    {
      subCode: "GOLDEN_F_IM_SPORT_IM_ESPORT_VIRTUAL",
      type: ["ESPORT"],
      thumb: {
        img: getImg("ESPORT2.webp"),
        subGame: getImg("ESPORT2.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_IM_SPORT_IM_ESPORT",
      type: ["ESPORT"],
      thumb: {
        img: getImg("ESPORT.webp"),
        subGame: getImg("ESPORT.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_IM_SPORT_IM_ESPORT_APP",
      type: ["ESPORT"],
      thumb: {
        img: getImg("ESPORT.webp"),
        subGame: getImg("ESPORT.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_IM_SPORT_IM_ESPORT_PC",
      type: ["ESPORT"],
      thumb: {
        img: getImg("ESPORT.webp"),
        subGame: getImg("ESPORT.webp"),
      },
    },

    {
      subCode: "GOLDEN_F_IM_SPORT_IM_SPORT",
      type: ["SPORT"],
    },
    {
      subCode: "GOLDEN_F_IM_SPORT_IM_SPORT_APP",
      type: ["SPORT"],
    },
    {
      subCode: "GOLDEN_F_IM_SPORT_IM_SPORT_PC",
      type: ["SPORT"],
    },
  ],
} as LIBRARY_GAME;
