import { AxiosResponse } from "axios";
import $http from "../../../FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "casinoGames/member-detail/info";

export namespace typeInfo {
  export interface request {
    p28_glr_vendor_id: string;
  }
  export type AxiosResponse = _AxiosResponse;
  export interface response {}
}
type _AxiosResponse = AxiosResponse<responseData>;

interface responseData extends BackendResponse {
  response: {};
}

/**
 * 下注資訊
 * https://redmine.mirfak.tw/projects/pkg-280000-casinogames/repository/57/revisions/master/entry/_md/api/Member%20%E6%9C%83%E5%93%A1%E5%85%A5%E5%8F%A3/Detail%20%E6%98%8E%E7%B4%B0/%E8%B3%87%E8%A8%8A.md
 */
export default async (params: typeInfo.request) => {
  const getter = await $http(API, { params });
  if (getter.data.code !== 0) throw getter;
  return getter;
};
