import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('OBE', fileName)
};
export default {
  code: "OBE",
  type: ["EGAMING"],
  play_method: "URL",
  subGames: [
    { subCode: "OBE_1", thumb: { img: getImg("1.png") } },
    { subCode: "OBE_2", thumb: { img: getImg("2.png") } },
    { subCode: "OBE_3", thumb: { img: getImg("3.png") } },
    { subCode: "OBE_4", thumb: { img: getImg("4.png") } },
    { subCode: "OBE_5", thumb: { img: getImg("5.png") } },
    { subCode: "OBE_15", thumb: { img: getImg("15.png") } },
    { subCode: "OBE_8", thumb: { img: getImg("8.png") } },
    { subCode: "OBE_11", thumb: { img: getImg("11.png") } },
    { subCode: "OBE_12", thumb: { img: getImg("12.png") } },
    { subCode: "OBE_6", thumb: { img: getImg("6.png") } },
    { subCode: "OBE_7", thumb: { img: getImg("7.png") } },
    { subCode: "OBE_9", thumb: { img: getImg("9.png") } },
    { subCode: "OBE_10", thumb: { img: getImg("10.png") } },
    { subCode: "OBE_13", thumb: { img: getImg("13.png") } },
    { subCode: "OBE_14", thumb: { img: getImg("14.png") } },
    { subCode: "OBE_16", thumb: { img: getImg("16.png") } },
    { subCode: "OBE_17", thumb: { img: getImg("17.png") } },
    { subCode: "OBE_18", thumb: { img: getImg("18.png") } },
    { subCode: "OBE_19", thumb: { img: getImg("19.png") } },
    { subCode: "OBE_20", thumb: { img: getImg("20.png") } },
    { subCode: "OBE_21", thumb: { img: getImg("21.png") } },
    { subCode: "OBE_23", thumb: { img: getImg("23.png") } },
    { subCode: "OBE_22", thumb: { img: getImg("22.png") } },
    { subCode: "OBE_24", thumb: { img: getImg("24.png") } },
    { subCode: "OBE_25", thumb: { img: getImg("25.png") } },
    { subCode: "OBE_26", thumb: { img: getImg("26.png") } },
    { subCode: "OBE_28", thumb: { img: getImg("28.png") } },
    { subCode: "OBE_27", thumb: { img: getImg("27.png") } },
    { subCode: "OBE_31", thumb: { img: getImg("31.png") } },
    { subCode: "OBE_37", thumb: { img: getImg("37.png") } },
    { subCode: "OBE_38", thumb: { img: getImg("38.png") } },
    { subCode: "OBE_39", thumb: { img: getImg("39.png") } },
    { subCode: "OBE_40", thumb: { img: getImg("40.png") } },
    { subCode: "OBE_41", thumb: { img: getImg("41.png") } },
    { subCode: "OBE_36", thumb: { img: getImg("36.png") } },
    { subCode: "OBE_35", thumb: { img: getImg("35.png") } },
    { subCode: "OBE_32", thumb: { img: getImg("32.png") } },
    { subCode: "OBE_33", thumb: { img: getImg("33.png") } },
    { subCode: "OBE_29", thumb: { img: getImg("29.png") } },
    { subCode: "OBE_34", thumb: { img: getImg("34.png") } },
    { subCode: "OBE_30", thumb: { img: getImg("30.png") } },
  ],
} as LIBRARY_GAME;
