import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('PLAYSTAR', fileName)
};

// TAG_EXPANSE_
export default {
  code: "GTAG_PLAYSTAR",
  type: ["EGAMING"],
  play_method: "URL",
  subGames: [
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_FEATUREBUY・SUPERPOWERFUL",
      thumb: {
        img: getImg("featurebuysuperpowerful.webp"),
        subGame: getImg("featurebuysuperpowerful.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_FBE",
      thumb: {
        img: getImg("featurebuychristmasexpress.webp"),
        subGame: getImg("featurebuychristmasexpress.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_FEATUREBUY・GOLDENPIG",
      thumb: {
        img: getImg("featurebuygoldenpig.webp"),
        subGame: getImg("featurebuygoldenpig.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_CHALLENGE・GOLDENPIG",
      thumb: {
        img: getImg("challengegoldenpig.webp"),
        subGame: getImg("challengegoldenpig.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_CHALLENGE・FULUSHOUXI",
      thumb: {
        img: getImg("challengefulushouxi.webp"),
        subGame: getImg("challengefulushouxi.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_CHALLENGE・MAYANCALENDAR",
      thumb: {
        img: getImg("challengemayancalendar.webp"),
        subGame: getImg("challengemayancalendar.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_RATOFWEALTH",
      thumb: {
        img: getImg("ratofwealth.webp"),
        subGame: getImg("ratofwealth.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_OCEANKINGSTREASURE",
      thumb: {
        img: getImg("oceankingstreasure.webp"),
        subGame: getImg("oceankingstreasure.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_FISHINGFAFAFA",
      thumb: {
        img: getImg("fishingfafafa.webp"),
        subGame: getImg("fishingfafafa.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_FISHINGINTHAILAND",
      thumb: {
        img: getImg("fishinginthailand.webp"),
        subGame: getImg("fishinginthailand.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_FISHINGFOODIE",
      thumb: {
        img: getImg("fishingfoodie.webp"),
        subGame: getImg("fishingfoodie.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_ZOMBIEBONUS",
      thumb: {
        img: getImg("zombiebonus.webp"),
        subGame: getImg("zombiebonus.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_SPICYFISHING",
      thumb: {
        img: getImg("spicyfishing.webp"),
        subGame: getImg("spicyfishing.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_HAIDILAO",
      thumb: {
        img: getImg("haidilao.webp"),
        subGame: getImg("haidilao.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_BILLIONAIRE",
      thumb: {
        img: getImg("billionaire.webp"),
        subGame: getImg("billionaire.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_GIANTSHARK",
      thumb: {
        img: getImg("giantshark.webp"),
        subGame: getImg("giantshark.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_RUNNINGBUFFALO",
      thumb: {
        img: getImg("runningbuffalo.webp"),
        subGame: getImg("runningbuffalo.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_GOLDLEOPARD",
      thumb: {
        img: getImg("goldleopard.webp"),
        subGame: getImg("goldleopard.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_CAISHENDAO",
      thumb: {
        img: getImg("caishendao.webp"),
        subGame: getImg("caishendao.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_COLORGAME",
      thumb: {
        img: getImg("colorgame.webp"),
        subGame: getImg("colorgame.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_TONGITS",
      thumb: {
        img: getImg("tongits.webp"),
        subGame: getImg("tongits.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_U4",
      thumb: {
        img: getImg("u4.webp"),
        subGame: getImg("u4.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_DUMMYKING",
      thumb: {
        img: getImg("dummyking.webp"),
        subGame: getImg("dummyking.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_PAIKANG",
      thumb: {
        img: getImg("paikang.webp"),
        subGame: getImg("paikang.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_DUMMY",
      thumb: {
        img: getImg("dummy.webp"),
        subGame: getImg("dummy.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_LUCKYCRUSH",
      thumb: {
        img: getImg("luckycrush.webp"),
        subGame: getImg("luckycrush.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_LUCKYKOIN",
      thumb: {
        img: getImg("luckykoin.webp"),
        subGame: getImg("luckykoin.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_MAHJONGWAYS3+",
      thumb: {
        img: getImg("mahjongways3+.webp"),
        subGame: getImg("mahjongways3+.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_MAHJONGWAYS3",
      thumb: {
        img: getImg("mahjongways3.webp"),
        subGame: getImg("mahjongways3.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_CHEFHUSKY",
      thumb: {
        img: getImg("chefhusky.webp"),
        subGame: getImg("chefhusky.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_COINMANIAC",
      thumb: {
        img: getImg("coinmaniac.webp"),
        subGame: getImg("coinmaniac.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_CRAZY777",
      thumb: {
        img: getImg("crazy777.webp"),
        subGame: getImg("crazy777.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_FANTASYSOUTHEASTASIA",
      thumb: {
        img: getImg("fantasysoutheastasia.webp"),
        subGame: getImg("fantasysoutheastasia.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_GOLDENJADE",
      thumb: {
        img: getImg("goldenjade.webp"),
        subGame: getImg("goldenjade.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_SUPERAWESOME",
      thumb: {
        img: getImg("superawesome.webp"),
        subGame: getImg("superawesome.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_DIAOCHANFADACAI",
      thumb: {
        img: getImg("diaochanfadacai.webp"),
        subGame: getImg("diaochanfadacai.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_GOLDENZONGZI",
      thumb: {
        img: getImg("goldenzongzi.webp"),
        subGame: getImg("goldenzongzi.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_LUCKYPOKER2",
      thumb: {
        img: getImg("luckypoker2.webp"),
        subGame: getImg("luckypoker2.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_BURLESQUE2",
      thumb: {
        img: getImg("burlesque2.webp"),
        subGame: getImg("burlesque2.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_WHATTHEFA",
      thumb: {
        img: getImg("whatthefa.webp"),
        subGame: getImg("whatthefa.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_FISHPRAWNCRAB",
      thumb: {
        img: getImg("fishprawncrab.webp"),
        subGame: getImg("fishprawncrab.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_SUPERWIN",
      thumb: {
        img: getImg("superwin.webp"),
        subGame: getImg("superwin.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_DAFUGUI",
      thumb: {
        img: getImg("dafugui.webp"),
        subGame: getImg("dafugui.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_MASTERHAHA",
      thumb: {
        img: getImg("masterhaha.webp"),
        subGame: getImg("masterhaha.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_SUPERBOOM",
      thumb: {
        img: getImg("superboom.webp"),
        subGame: getImg("superboom.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_WUXIA",
      thumb: {
        img: getImg("wuxia.webp"),
        subGame: getImg("wuxia.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_KUNGFU",
      thumb: {
        img: getImg("kungfu.webp"),
        subGame: getImg("kungfu.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_GOLDENWEEK",
      thumb: {
        img: getImg("goldenweek.webp"),
        subGame: getImg("goldenweek.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_ZUMA",
      thumb: {
        img: getImg("zuma.webp"),
        subGame: getImg("zuma.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_FORTUNEBULL",
      thumb: {
        img: getImg("fortunebull.webp"),
        subGame: getImg("fortunebull.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_HANDOFGOD",
      thumb: {
        img: getImg("handofgod.webp"),
        subGame: getImg("handofgod.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_PSCLUB",
      thumb: {
        img: getImg("psclub.webp"),
        subGame: getImg("psclub.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_LOVE",
      thumb: {
        img: getImg("love.webp"),
        subGame: getImg("love.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_TOMBTREASURE",
      thumb: {
        img: getImg("tombtreasure.webp"),
        subGame: getImg("tombtreasure.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_SUPERPOWERFUL",
      thumb: {
        img: getImg("superpowerful.webp"),
        subGame: getImg("superpowerful.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_SANTACLAUS",
      thumb: {
        img: getImg("santaclaus.webp"),
        subGame: getImg("santaclaus.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_AURORAWOLF",
      thumb: {
        img: getImg("aurorawolf.webp"),
        subGame: getImg("aurorawolf.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_MYLORD",
      thumb: {
        img: getImg("mylord.webp"),
        subGame: getImg("mylord.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_MONEYMEOW",
      thumb: {
        img: getImg("moneymeow.webp"),
        subGame: getImg("moneymeow.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_MOONFESTIVAL",
      thumb: {
        img: getImg("moonfestival.webp"),
        subGame: getImg("moonfestival.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_ALCHEMY",
      thumb: {
        img: getImg("alchemy.webp"),
        subGame: getImg("alchemy.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_DRAGONBOATFESTIVAL",
      thumb: {
        img: getImg("dragonboatfestival.webp"),
        subGame: getImg("dragonboatfestival.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_SUPERRICH",
      thumb: {
        img: getImg("superrich.webp"),
        subGame: getImg("superrich.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_VAMPIRE",
      thumb: {
        img: getImg("vampire.webp"),
        subGame: getImg("vampire.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_RICH&HAPPY",
      thumb: {
        img: getImg("richandhappy.webp"),
        subGame: getImg("richandhappy.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_HALLOWEEN",
      thumb: {
        img: getImg("halloween.webp"),
        subGame: getImg("halloween.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_ELEPHANT",
      thumb: {
        img: getImg("elephant.webp"),
        subGame: getImg("elephant.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_FORTUNETELLER",
      thumb: {
        img: getImg("fortuneteller.webp"),
        subGame: getImg("fortuneteller.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_FACEOFF",
      thumb: {
        img: getImg("faceoff.webp"),
        subGame: getImg("faceoff.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_WEREWOLF",
      thumb: {
        img: getImg("werewolf.webp"),
        subGame: getImg("werewolf.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_FORTUNEGOD",
      thumb: {
        img: getImg("fortunegod.webp"),
        subGame: getImg("fortunegod.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_GLORIOUSKINGDOM",
      thumb: {
        img: getImg("gloriouskingdom.webp"),
        subGame: getImg("gloriouskingdom.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_ATHENA",
      thumb: {
        img: getImg("athena.webp"),
        subGame: getImg("athena.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_DOUBLEHAPPINESS",
      thumb: {
        img: getImg("doublehappiness.webp"),
        subGame: getImg("doublehappiness.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_777.0",
      thumb: {
        img: getImg("7770.webp"),
        subGame: getImg("7770.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_FAFAMONKEY",
      thumb: {
        img: getImg("fafamonkey.webp"),
        subGame: getImg("fafamonkey.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_THEEMPIRE",
      thumb: {
        img: getImg("theempire.webp"),
        subGame: getImg("theempire.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_KUNGHEI",
      thumb: {
        img: getImg("kunghei.webp"),
        subGame: getImg("kunghei.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_WHOSTHEBOSS",
      thumb: {
        img: getImg("whostheboss.webp"),
        subGame: getImg("whostheboss.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_LUCKYPANDA",
      thumb: {
        img: getImg("luckypanda.webp"),
        subGame: getImg("luckypanda.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_MADAMECAROLINE",
      thumb: {
        img: getImg("madamecaroline.webp"),
        subGame: getImg("madamecaroline.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_INDIATREASURE",
      thumb: {
        img: getImg("indiatreasure.webp"),
        subGame: getImg("indiatreasure.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYSTAR_PSTAR_CHINAEMPRESS",
      thumb: {
        img: getImg("chinaempress.webp"),
        subGame: getImg("chinaempress.webp"),
      },
    },
  ],
} as LIBRARY_GAME;
