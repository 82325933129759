import { AxiosResponse } from "axios";
import $http from "@/modules/FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "account/auth/refresh";

export namespace typeInfo {
  // export interface request {  }
  export type AxiosResponse = _AxiosResponse;
  export type response = boolean;
}
type _AxiosResponse = AxiosResponse<responseData>;

interface responseData extends BackendResponse {
  response: boolean;
}

/**
 * 重新驗證 token
 */
export default async () => {
  const getter = await $http(API);
  if (getter.data.code !== 0) throw getter;
  return getter;
};
