import { reactive, toRefs } from "vue";
import { options } from "../axios"; // 後端溝通
import router from "../vueRouter";
import isCilentLogined from "./isCilentLogined";

/**
 * copy from FPKG-8
 */
export default function() {
  const data = reactive({
    websock: {} as any,
    reconnectData: {} as any,
    lockReconnect: false,
    timeout: 25000,
    timeoutObj: {} as any,
    serverTimeoutObj: {} as any,
  });

  const initWebSocket = () => {
    // 舉例的URL  ://notfi.hackfbg.net
    const wsURL = options.baseURL.substring(4);
    const authorisation = localStorage.getItem("x-authorisation");
    const wsuri = `ws${wsURL}?_t=${authorisation}`;
    data.websock = new WebSocket(wsuri);
    data.websock.onopen = websocketonopen; //連結
    data.websock.onmessage = websocketonmessage; //廣波
    data.websock.onerror = websocketonerror; //段開
    data.websock.onclose = websocketclose; //關閉
  };

  const websocketonopen = () => {
    heatBeat();
  };

  const websocketonmessage = (e: any) => {
    heatBeat();
    // 42 回傳的資料
    // if (e.data.indexOf("42") > -1) {
    // const first = e.data.indexOf("[");
    // const data = e.data.slice(first);
    // const redata = JSON.parse(data);
    // // 路徑
    // const path = redata[0];
    // // 參數資料
    // const params = redata[1];
    // try {
    // const modules = require(`@/modules/${path}.ts`).default;
    // modules.socket(params);
    // } catch (e) {
    // do nothing
    // }
    // }
  };

  const websocketonerror = () => {
    reconnect();
  };

  const websocketsend = (seandData: any) => {
    data.websock.send(seandData);
  };

  const websocketclose = () => {
    reconnect();
  };

  const reconnect = () => {
    if (!isCilentLogined()) return;
    if (data.lockReconnect) {
      return;
    }
    data.lockReconnect = true;
    data.reconnectData && clearTimeout(data.reconnectData);
    data.reconnectData = setTimeout(() => {
      initWebSocket();
      data.lockReconnect = false;
    }, 3000);
  };

  const heatBeat = () => {
    // 不在登入頁時執行websocketsend
    if (router.currentRoute.value.path !== "/login" && isCilentLogined()) {
      data.timeoutObj && clearTimeout(data.timeoutObj);
      data.serverTimeoutObj && clearTimeout(data.serverTimeoutObj);
      data.timeoutObj = setTimeout(() => {
        websocketsend('["heartbeat","ping"]');
        data.serverTimeoutObj = setTimeout(() => {
          data.websock.close(); //沒有收到資料後 段開重連
        }, 3000);
      }, data.timeout);
    } else {
      data.websock.close();
    }
  };
  const refData = toRefs(data);

  return {
    ...refData,
    initWebSocket,
  };
}
