import { AxiosResponse } from "axios";
import $http from "../../../FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "casinoAccount/front/sendVerifySms";
// https://redmine.mirfak.tw/projects/pkg-170000-casinoaccount/repository/37/revisions/master/entry/_md/api/Front/%E7%99%BC%E9%80%81%E8%AA%8D%E8%AD%89%E7%B0%A1%E8%A8%8A.md

export namespace typeDoModify {
  export interface request {
    p3_ant_account: string;
    p3_ape_tel: string;
  }
  export interface AxiosResponse extends _AxiosResponse {}
}
interface _AxiosResponse extends AxiosResponse<responseData> {}

interface responseData extends BackendResponse {
  response: any;
}

/** 發送驗證簡訊 */
export default async (request: typeDoModify.request) => {
  const getter = await $http.put(API, preformat(request));
  if (getter.data.code !== 0) throw getter;
  return getter;
};

/** 送出前的強制格式化，去除額外的參數 */
function preformat(request: typeDoModify.request) {
  return {
    p3_ant_account: request.p3_ant_account,
    p3_ape_tel: request.p3_ape_tel,
  };
}
