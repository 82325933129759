import { AxiosResponse } from "axios";
import $http from "@/modules/FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "promotion/member-order/lists";

export namespace typeLists {
  export type request = pageinator & {};

  export type response = {
    list: listItem[];
    paginator: pageinator;
  };
  // TODO: FORM AND LIST
  export type listItem = {
    p47_por_audit_p3_ant_id: 122;
    // 審核時間
    p47_por_audited_at: 1704987463;
    /** 申請時間 */
    p47_por_created_at: 1704984318;
    // 優惠贈與金額
    p47_por_give_bonus: "0";
    p47_por_id: 5;
    // 備註
    p47_por_memo: "123";
    p47_por_p3_ant_id: 77;
    p47_por_p47_pon_id: 5;
    // 狀態 2:申請中 3 通過 4 駁回
    p47_por_status: 4;
    // 儲值額度
    p47_por_stored_amount: "100000.00";
    promotion: {
      /** 優惠名稱 */
      p47_pon_name: "test1";
      p47_pon_application_deadline: 1705766400;
      p47_pon_bouns: "50.0000";
      p47_pon_bouns_option: "2";
      p47_pon_deposit: "500.0000";
      p47_pon_header_image_p7_file_id: 92;
      p47_pon_id: 5;
      p47_pon_image_p7_file_id: 93;
      p47_pon_maximum_point: "1000.0000";
      p47_pon_memo: null;
      p47_pon_offer_end: 1706025600;
      p47_pon_offer_start: 1704902400;
      p47_pon_register_full_moon: 1;
      p47_pon_valid_amount: "10.0000";
      p47_pon_valid_amount_option: "2";
    };
  };

  export type AxiosResponse = _AxiosResponse;
}
type _AxiosResponse = AxiosResponse<responseData>;

interface responseData extends BackendResponse {
  response: any;
}

/** 申請的優惠列表 */
export default async (params?: typeLists.request) => {
  const getter = await $http.get(API, { params });
  if (getter.data.code !== 0) throw getter;
  return getter;
};
