import { AxiosResponse } from "axios";
import $http from "../../../FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "casinoAccount/front/phoneVerify";
// https://redmine.mirfak.tw/projects/pkg-170000-casinoaccount/repository/37/revisions/master/entry/_md/api/Front/%E6%89%8B%E6%A9%9F%E8%AA%8D%E8%AD%89.md

export namespace typeDoModify {
  export interface request {
    p3_ant_account: string;
    p17_pvy_code: string;
  }
  export interface AxiosResponse extends _AxiosResponse {}
}
interface _AxiosResponse extends AxiosResponse<responseData> {}

interface responseData extends BackendResponse {
  response: any;
}

/** 進行簡訊認證 */
export default async (request: typeDoModify.request) => {
  const getter = await $http.put(API, preformat(request));
  if (getter.data.code !== 0) throw getter;
  return getter;
};

/** 送出前的強制格式化，去除額外的參數 */
function preformat(request: typeDoModify.request) {
  return {
    p3_ant_account: request.p3_ant_account,
    p17_pvy_code: request.p17_pvy_code,
  };
}
