import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('GTAG_PLAYTECH', fileName)
};

export default {
  code: "GTAG_PLAYTECH",
  type: ["EGAMING", "CASINO"],
  play_method: "URL",
  subGames: [
    {
      subCode: "GTAG_PLAYTECH_PO",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jacksorbetter.webp"),
        subGame: getImg("jacksorbetter.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_QROL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("livequantumroulette.webp"),
        subGame: getImg("livequantumroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FBBL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("megafireblazeluckyballbrasileiro.webp"),
        subGame: getImg("megafireblazeluckyballbrasileiro.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_JSRRL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jetsetracingroulettelive.webp"),
        subGame: getImg("jetsetracingroulettelive.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FBBJL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("megafireblazeblackjacklive.webp"),
        subGame: getImg("megafireblazeblackjacklive.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_CML",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("footballcardshowdownlive.webp"),
        subGame: getImg("footballcardshowdownlive.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_TGCSL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thegreatestcardsshow.webp"),
        subGame: getImg("thegreatestcardsshow.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SWLE",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("spinawinwildlive.webp"),
        subGame: getImg("spinawinwildlive.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_TPL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("teenpattilive.webp"),
        subGame: getImg("teenpattilive.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_EJL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("everybodysjackpotlive.webp"),
        subGame: getImg("everybodysjackpotlive.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_UBAL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("livebaccarat.webp"),
        subGame: getImg("livebaccarat.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FBROL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("megafireblazeroulettelive.webp"),
        subGame: getImg("megafireblazeroulettelive.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BS_POKL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("betonpokerlive.webp"),
        subGame: getImg("betonpokerlive.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BS_BAL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("betonbaccaratlive.webp"),
        subGame: getImg("betonbaccaratlive.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_CBJL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("italiancashbackblackjack.webp"),
        subGame: getImg("italiancashbackblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_CHEL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("livecasinoholdem.webp"),
        subGame: getImg("livecasinoholdem.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_NC_BAL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("livenocommissionbaccarat.webp"),
        subGame: getImg("livenocommissionbaccarat.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BAL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("livebaccarat.webp"),
        subGame: getImg("livebaccarat.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SPROL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("livespreadbetroulette.webp"),
        subGame: getImg("livespreadbetroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FROL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("footballroulette.webp"),
        subGame: getImg("footballroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FROFL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("footballfrenchroulette.webp"),
        subGame: getImg("footballfrenchroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_RODZL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("liveamericanroulette.webp"),
        subGame: getImg("liveamericanroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_AOGJBROL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofgodsbonusroulette.webp"),
        subGame: getImg("ageofgodsbonusroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_CSPLJPT",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("livecasinostudpoker.webp"),
        subGame: getImg("livecasinostudpoker.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_3BRGL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("live3cardbrag.webp"),
        subGame: getImg("live3cardbrag.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SWL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("livespinawin.webp"),
        subGame: getImg("livespinawin.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_HILOL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("livehilo.webp"),
        subGame: getImg("livehilo.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BFBL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("liveslotsbuffaloblitz.webp"),
        subGame: getImg("liveslotsbuffaloblitz.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ABWL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("adventuresbeyondwonderlandlive.webp"),
        subGame: getImg("adventuresbeyondwonderlandlive.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_UBJL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("liveunlimitedblackjack.webp"),
        subGame: getImg("liveunlimitedblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_7EML",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("setteemezzo.webp"),
        subGame: getImg("setteemezzo.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_MSBJL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("majorityrulesspeedblackjack.webp"),
        subGame: getImg("majorityrulesspeedblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ABL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("andarbaharlive.webp"),
        subGame: getImg("andarbaharlive.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SBDL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sicbodeluxe.webp"),
        subGame: getImg("sicbodeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_DTL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("livedragontiger.webp"),
        subGame: getImg("livedragontiger.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_DEVWILDS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("devilwilds.webp"),
        subGame: getImg("devilwilds.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AZBOLIPP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("aztecabonuslinespowerplayjackpot.webp"),
        subGame: getImg("aztecabonuslinespowerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_HONGEM_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("honeygems.webp"),
        subGame: getImg("honeygems.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_POP_BBW_QSP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bbw.webp"),
        subGame: getImg("bbw.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_POP_DRAGONSHRINE_QSP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ds.webp"),
        subGame: getImg("ds.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AOGGOSUN_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodshelios.webp"),
        subGame: getImg("ageofthegodshelios.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_GOLDSTF_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldsplashtootsfroots.webp"),
        subGame: getImg("goldsplashtootsfroots.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_QCTOBLO_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fireblazetoltecblocks.webp"),
        subGame: getImg("fireblazetoltecblocks.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_MOUNTEXP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("grandjunctionmountainexpress.webp"),
        subGame: getImg("grandjunctionmountainexpress.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_KGOMOONPP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("megafireblazekhonsugodofmoonpowerplayjackpot.webp"),
        subGame: getImg("megafireblazekhonsugodofmoonpowerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_F4CCD_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fire4cashcollectquattro.webp"),
        subGame: getImg("fire4cashcollectquattro.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_EOFROME_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("megafireblazeemperorofrome.webp"),
        subGame: getImg("megafireblazeemperorofrome.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_CXTREMEPP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("chillixtremepowerplayjackpot.webp"),
        subGame: getImg("chillixtremepowerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AZBOLI_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("aztecabonuslines.webp"),
        subGame: getImg("aztecabonuslines.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_FOCASHCO_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("footballcashcollect.webp"),
        subGame: getImg("footballcashcollect.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_RABBITCASH_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("yutujincaicashcollect.webp"),
        subGame: getImg("yutujincaicashcollect.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_CIRC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("circuslaunch.webp"),
        subGame: getImg("circuslaunch.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_GGUN_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("grandjunctiongoldenguns.webp"),
        subGame: getImg("grandjunctiongoldenguns.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_MGCCSAHARA_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sahararichesmegawayscashcollect.webp"),
        subGame: getImg("sahararichesmegawayscashcollect.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_MFOREST_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mightyhatmystictales.webp"),
        subGame: getImg("mightyhatmystictales.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_CCHFORTUNE_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("witchescashcollect.webp"),
        subGame: getImg("witchescashcollect.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SHARKS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sharkblitz.webp"),
        subGame: getImg("sharkblitz.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BBMWAYS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("buffaloblitzmegaways.webp"),
        subGame: getImg("buffaloblitzmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_WCRUSADE_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildcrusadeempiretreasures.webp"),
        subGame: getImg("wildcrusadeempiretreasures.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BCASH_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldhitoreillysriches.webp"),
        subGame: getImg("goldhitoreillysriches.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_VEMPTR_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("vikingsempiretreasures.webp"),
        subGame: getImg("vikingsempiretreasures.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_ETPOP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("prideofpersiaempiretreasures.webp"),
        subGame: getImg("prideofpersiaempiretreasures.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_GRUSH_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mightyhatmineomine.webp"),
        subGame: getImg("mightyhatmineomine.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_QCCHARM_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fireblazequattrocelticcharm.webp"),
        subGame: getImg("fireblazequattrocelticcharm.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_GWIZARD_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fireblazegreenwizard.webp"),
        subGame: getImg("fireblazegreenwizard.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_FMHITBARPP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hitbarpp.webp"),
        subGame: getImg("hitbarpp.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SKING_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("monstermultipliers.webp"),
        subGame: getImg("monstermultipliers.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_AZTEC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("incajackpot.webp"),
        subGame: getImg("incajackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_JRUSH_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jokerrush.webp"),
        subGame: getImg("jokerrush.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_CGUARD_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("qinsempirecelestialguardians.webp"),
        subGame: getImg("qinsempirecelestialguardians.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_GWISH_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mightyhatlampofgold.webp"),
        subGame: getImg("mightyhatlampofgold.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_KOIHAR_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("koiharmony.webp"),
        subGame: getImg("koiharmony.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SBULLET_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("silverbulletbanditcashcollect.webp"),
        subGame: getImg("silverbulletbanditcashcollect.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_DRISE_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("diamondrise.webp"),
        subGame: getImg("diamondrise.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_FMHITBAR_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hitbar.webp"),
        subGame: getImg("hitbar.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GFAL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("rulersoftheworldempiretreasures.webp"),
        subGame: getImg("rulersoftheworldempiretreasures.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_GSTORM2_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsgodofstorms2.webp"),
        subGame: getImg("ageofthegodsgodofstorms2.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_MKEEPER_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsmazekeeper.webp"),
        subGame: getImg("ageofthegodsmazekeeper.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_CXTREME_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("chillixtreme.webp"),
        subGame: getImg("chillixtreme.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AUNCOIL_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("anacondauncoiled.webp"),
        subGame: getImg("anacondauncoiled.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_MBLOCKS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mayanblocks.webp"),
        subGame: getImg("mayanblocks.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_TQCEMPT_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thequeenscurseempiretreasures.webp"),
        subGame: getImg("thequeenscurseempiretreasures.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_WPISTO_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("megafireblazewildpistolero.webp"),
        subGame: getImg("megafireblazewildpistolero.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_CCLUCK_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("leprechaunsluckcashcollect.webp"),
        subGame: getImg("leprechaunsluckcashcollect.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_FFEVER_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fishinbonanza.webp"),
        subGame: getImg("fishinbonanza.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AOGWFOT_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodswheelsofolympus.webp"),
        subGame: getImg("ageofthegodswheelsofolympus.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_TBIRDS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("powerzonesthunderbirds.webp"),
        subGame: getImg("powerzonesthunderbirds.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BCIRCUS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("megafireblazebigcircus.webp"),
        subGame: getImg("megafireblazebigcircus.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FISHSHR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fishshootforcash.webp"),
        subGame: getImg("fishshootforcash.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AOGWW_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodswonderwarriors.webp"),
        subGame: getImg("ageofthegodswonderwarriors.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_JISLAND2_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jurassicisland2.webp"),
        subGame: getImg("jurassicisland2.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_HARROW_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("heroesarrow.webp"),
        subGame: getImg("heroesarrow.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_MGBWIZARD_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fireblazebluewizardmegaways.webp"),
        subGame: getImg("fireblazebluewizardmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_RSLOT_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("rhinoblitz.webp"),
        subGame: getImg("rhinoblitz.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SLION",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("superlion.webp"),
        subGame: getImg("superlion.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BETWILDSPP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("betterwildspowerplayjackpot.webp"),
        subGame: getImg("betterwildspowerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_CCSKINGOFA_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("atlantiscashcollect.webp"),
        subGame: getImg("atlantiscashcollect.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_DOSTORMSPP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("djinnofstormspowerplayjackpot.webp"),
        subGame: getImg("djinnofstormspowerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_CCSAHARA_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sahararichescashcollect.webp"),
        subGame: getImg("sahararichescashcollect.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_CERUPTION_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("chilieruptionthundershots.webp"),
        subGame: getImg("chilieruptionthundershots.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_PSTRIKE_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pyramidvalleypowerzones.webp"),
        subGame: getImg("pyramidvalleypowerzones.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_GGUARDIANS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gloriousguardians.webp"),
        subGame: getImg("gloriousguardians.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_RO101",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("101roulette.webp"),
        subGame: getImg("101roulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SUPRO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("superroulette.webp"),
        subGame: getImg("superroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_CAR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("caribbeanstudpoker.webp"),
        subGame: getImg("caribbeanstudpoker.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ABBJ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("allbetsblackjack.webp"),
        subGame: getImg("allbetsblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_3CB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("3cardbrag.webp"),
        subGame: getImg("3cardbrag.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_HLF2",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("halloweenfortune2.webp"),
        subGame: getImg("halloweenfortune2.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GTSJXB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jixiang8.webp"),
        subGame: getImg("jixiang8.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GTSJE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jadeemperoryuhuangdadi.webp"),
        subGame: getImg("jadeemperoryuhuangdadi.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHJAH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jekyllandhyde.webp"),
        subGame: getImg("jekyllandhyde.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHJID",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jurassicisland.webp"),
        subGame: getImg("jurassicisland.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHJUT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jungletrouble.webp"),
        subGame: getImg("jungletrouble.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHLCL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("leprechaunsluck.webp"),
        subGame: getImg("leprechaunsluck.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHWNOZ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("winningsofoz.webp"),
        subGame: getImg("winningsofoz.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_KFP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sacredstones.webp"),
        subGame: getImg("sacredstones.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SCRDSTNS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sacredstones.webp"),
        subGame: getImg("sacredstones.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_STRSAWK",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("starsawakening.webp"),
        subGame: getImg("starsawakening.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_CM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("chinesekitchen.webp"),
        subGame: getImg("chinesekitchen.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ZCJB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("zhaocaijinbao.webp"),
        subGame: getImg("zhaocaijinbao.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("beachlife.webp"),
        subGame: getImg("beachlife.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FBR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("footballrules.webp"),
        subGame: getImg("footballrules.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHSBD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sinbadsgoldenvoyage.webp"),
        subGame: getImg("sinbadsgoldenvoyage.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_HB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("anightout.webp"),
        subGame: getImg("anightout.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GTSPOR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("plentyofortune.webp"),
        subGame: getImg("plentyofortune.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_CATQC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("catqueen.webp"),
        subGame: getImg("catqueen.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_RNG2",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("romeandglory.webp"),
        subGame: getImg("romeandglory.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_MGSTK",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("magicalstacks.webp"),
        subGame: getImg("magicalstacks.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_HLF",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("halloweenfortune.webp"),
        subGame: getImg("halloweenfortune.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_IRL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("irishluck.webp"),
        subGame: getImg("irishluck.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ARC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("archer.webp"),
        subGame: getImg("archer.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_MFRT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("missfortune.webp"),
        subGame: getImg("missfortune.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHICV",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("icecave.webp"),
        subGame: getImg("icecave.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SSP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("santasurprise.webp"),
        subGame: getImg("santasurprise.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BIB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("greatblue.webp"),
        subGame: getImg("greatblue.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_CT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("captaintreasure.webp"),
        subGame: getImg("captaintreasure.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_WHK",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("whiteking.webp"),
        subGame: getImg("whiteking.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GTSWG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildgambler.webp"),
        subGame: getImg("wildgambler.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BOB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bonusbears.webp"),
        subGame: getImg("bonusbears.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_LEGWLD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("legacyofthewild.webp"),
        subGame: getImg("legacyofthewild.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_TPD2",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thaiparadise.webp"),
        subGame: getImg("thaiparadise.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_DONQ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("therichesofdonquixote.webp"),
        subGame: getImg("therichesofdonquixote.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SAMZ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("secretsoftheamazon.webp"),
        subGame: getImg("secretsoftheamazon.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHHOF",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("heartofthefrontier.webp"),
        subGame: getImg("heartofthefrontier.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GTSATQ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("atlantisqueen.webp"),
        subGame: getImg("atlantisqueen.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_LM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("lottomadness.webp"),
        subGame: getImg("lottomadness.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHCPL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("chestsofplenty.webp"),
        subGame: getImg("chestsofplenty.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SFH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("safariheat.webp"),
        subGame: getImg("safariheat.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHHOTJ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("heartofthejungle.webp"),
        subGame: getImg("heartofthejungle.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_EAS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("eastersurprise.webp"),
        subGame: getImg("eastersurprise.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_MCB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mrcashback.webp"),
        subGame: getImg("mrcashback.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FXF",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fortunesofthefox.webp"),
        subGame: getImg("fortunesofthefox.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_PISA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pixelsamurai.webp"),
        subGame: getImg("pixelsamurai.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_PHOT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("purplehot.webp"),
        subGame: getImg("purplehot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GOS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldentour.webp"),
        subGame: getImg("goldentour.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_HK",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("highwaykings.webp"),
        subGame: getImg("highwaykings.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GREL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldrally.webp"),
        subGame: getImg("goldrally.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_CTIV",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("catinvegas.webp"),
        subGame: getImg("catinvegas.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_MOBDT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("deserttreasure.webp"),
        subGame: getImg("deserttreasure.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GES",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("geishastory.webp"),
        subGame: getImg("geishastory.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_PMN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("panthermoon.webp"),
        subGame: getImg("panthermoon.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHGLSS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("theglassslipper.webp"),
        subGame: getImg("theglassslipper.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GTS50",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hotgems.webp"),
        subGame: getImg("hotgems.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHAMW",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("amazonwild.webp"),
        subGame: getImg("amazonwild.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_CRIT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("cashit.webp"),
        subGame: getImg("cashit.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SHSFC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("spacehuntershootforcash.webp"),
        subGame: getImg("spacehuntershootforcash.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SBL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("livebaccarat.webp"),
        subGame: getImg("livebaccarat.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_EJPL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("everybodysjackpotlive.webp"),
        subGame: getImg("everybodysjackpotlive.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_QRODZL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("1000quantumroulette.webp"),
        subGame: getImg("1000quantumroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_QABJLP",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("quantumblackjackplus.webp"),
        subGame: getImg("quantumblackjackplus.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GOSL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("godofstormslive.webp"),
        subGame: getImg("godofstormslive.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BJL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("liveblackjack.webp"),
        subGame: getImg("liveblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ROFL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("livefrenchroulette.webp"),
        subGame: getImg("livefrenchroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ROL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("liveroulette.webp"),
        subGame: getImg("liveroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_QABJL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("livequantumallbetsblackjack.webp"),
        subGame: getImg("livequantumallbetsblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ABJL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("liveallbetsblackjack.webp"),
        subGame: getImg("liveallbetsblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BS_DTL",
      type: ["CASINO"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("betondragontigerlive.webp"),
        subGame: getImg("betondragontigerlive.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_DW_MH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("deuceswildmultihand.webp"),
        subGame: getImg("deuceswildmultihand.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_JB_MH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jacksorbettermultihand.webp"),
        subGame: getImg("jacksorbettermultihand.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_JP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jokerpoker.webp"),
        subGame: getImg("jokerpoker.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_AF_MH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("acesandfacesmultihand.webp"),
        subGame: getImg("acesandfacesmultihand.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_MRO_G",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("miniroulette.webp"),
        subGame: getImg("miniroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_RETOBLOW_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("readytoblowthundershots.webp"),
        subGame: getImg("readytoblowthundershots.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BBLITZMM_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("buffaloblitzmegamerge.webp"),
        subGame: getImg("buffaloblitzmegamerge.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_GOLDHIT2_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldhitshrineofanubis.webp"),
        subGame: getImg("goldhitshrineofanubis.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_JRUSHPP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jokerrushpowerplayjackpot.webp"),
        subGame: getImg("jokerrushpowerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_POP_93FDB01A_QSP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("cashtruck.webp"),
        subGame: getImg("cashtruck.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_POP_B55BA1D4_QSP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hammerofvulcan.webp"),
        subGame: getImg("hammerofvulcan.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_POP_EASTRNEMRLDS_QSP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("easternemeralds.webp"),
        subGame: getImg("easternemeralds.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_POP_4512E199_QSP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ravenrising.webp"),
        subGame: getImg("ravenrising.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_POP_85462C30_QSP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bigbadwolfmegaways.webp"),
        subGame: getImg("bigbadwolfmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_POP_518163C8_QSP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sakurafortune2.webp"),
        subGame: getImg("sakurafortune2.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_POP_68F6F2E6_QSP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("easternemeraldsmegaways.webp"),
        subGame: getImg("easternemeraldsmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_POP_WINSFORTUNE_QSP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("winsoffortune.webp"),
        subGame: getImg("winsoffortune.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_MGAOGKOL_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodskingofolympusmegaways.webp"),
        subGame: getImg("ageofthegodskingofolympusmegaways.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_MERFIND_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("triplestopmermaidsfind.webp"),
        subGame: getImg("triplestopmermaidsfind.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AWILD2PP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("anacondawild2powerplayjackpot.webp"),
        subGame: getImg("anacondawild2powerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_WOFIRE1_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wheelsofflame.webp"),
        subGame: getImg("wheelsofflame.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SFORTUNE_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sakurafortune.webp"),
        subGame: getImg("sakurafortune.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_HGEXTREMEPP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hotgemsxtremepowerplayjackpot.webp"),
        subGame: getImg("hotgemsxtremepowerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AMAMMOTHPP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("absolutelymammothpowerplayjackpot.webp"),
        subGame: getImg("absolutelymammothpowerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_PIGEONFS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pigeonsfromspace.webp"),
        subGame: getImg("pigeonsfromspace.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_WLINXPP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildlinxpowerplayjackpot.webp"),
        subGame: getImg("wildlinxpowerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BPROG_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("kingblitz.webp"),
        subGame: getImg("kingblitz.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GEMQ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gemqueen.webp"),
        subGame: getImg("gemqueen.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SSTRIKEPP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("stallionstrikepowerplayjackpot.webp"),
        subGame: getImg("stallionstrikepowerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_CTEMPLE_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("qinsempirecaishenstemple.webp"),
        subGame: getImg("qinsempirecaishenstemple.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_FFIGHTER_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fireblazefirefighter.webp"),
        subGame: getImg("fireblazefirefighter.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_HCDELUX_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hotcrowndeluxe.webp"),
        subGame: getImg("hotcrowndeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_WPUNCH_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wushupunch.webp"),
        subGame: getImg("wushupunch.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SOICEPP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("stormsoficepowerplayjackpot.webp"),
        subGame: getImg("stormsoficepowerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AEXPEDITION_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("aztecexpeditionthundershots.webp"),
        subGame: getImg("aztecexpeditionthundershots.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SOANUBIS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("curseofanubis.webp"),
        subGame: getImg("curseofanubis.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_MARAT_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("monkeyandrat.webp"),
        subGame: getImg("monkeyandrat.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_FBALLIWPP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("footballscratchpowerplayjackpot.webp"),
        subGame: getImg("footballscratchpowerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AOGNNL_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsnorsenorselegends.webp"),
        subGame: getImg("ageofthegodsnorsenorselegends.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_TTTOTEMSPP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tiptoptotemspowerplayjackpot.webp"),
        subGame: getImg("tiptoptotemspowerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_ZCHARMS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("zodiaccharms.webp"),
        subGame: getImg("zodiaccharms.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_ETSCORE_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("theperfectheist.webp"),
        subGame: getImg("theperfectheist.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_FBGBB_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fireblazegoldenbuccaneerbells.webp"),
        subGame: getImg("fireblazegoldenbuccaneerbells.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_HGEXTREMEIW_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hotgemsxtremescratch.webp"),
        subGame: getImg("hotgemsxtremescratch.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_WLAVAIW_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildlavascratch.webp"),
        subGame: getImg("wildlavascratch.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_EEMERALDSPP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("egyptianemeraldspowerplayjackpot.webp"),
        subGame: getImg("egyptianemeraldspowerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_RWIZARD_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fireblazeredwizard.webp"),
        subGame: getImg("fireblazeredwizard.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_GPORANG_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldpileorangutan.webp"),
        subGame: getImg("goldpileorangutan.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BOKINGPP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bookofkingspowerplayjackpot.webp"),
        subGame: getImg("bookofkingspowerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BBOB_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("breakoutbob.webp"),
        subGame: getImg("breakoutbob.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SGIFT_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fireblazesistersgift.webp"),
        subGame: getImg("fireblazesistersgift.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_REMPRESS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fireblazeriverempress.webp"),
        subGame: getImg("fireblazeriverempress.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_NALIGHT_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("janejonesinbookofking2.webp"),
        subGame: getImg("janejonesinbookofking2.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AOGBOORACLE_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("aogbookoforacle.webp"),
        subGame: getImg("aogbookoforacle.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_TFORTUNE_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldpiletigerspride.webp"),
        subGame: getImg("goldpiletigerspride.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_PPPEARLS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pearlspearlspearls.webp"),
        subGame: getImg("pearlspearlspearls.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_TGIFT_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("toadsgift.webp"),
        subGame: getImg("toadsgift.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SCUPID_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("lovefool.webp"),
        subGame: getImg("lovefool.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_RRICHES_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("recordriches.webp"),
        subGame: getImg("recordriches.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_INFDRG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("infinitydragon.webp"),
        subGame: getImg("infinitydragon.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BBELLSPP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blazingbellspowerplayjackpot.webp"),
        subGame: getImg("blazingbellspowerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AWILD2_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("anacondawildii.webp"),
        subGame: getImg("anacondawildii.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_ALUCK_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("arowanasluck.webp"),
        subGame: getImg("arowanasluck.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BFRENZY_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("beefrenzy.webp"),
        subGame: getImg("beefrenzy.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AFACTORY_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fireblazegoldenamazingfactory.webp"),
        subGame: getImg("fireblazegoldenamazingfactory.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_ZCJBAO2_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("zhaocaijinbao2.webp"),
        subGame: getImg("zhaocaijinbao2.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_GPNYG_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldpilenewyearsgold.webp"),
        subGame: getImg("goldpilenewyearsgold.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_WLAVA_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildlava.webp"),
        subGame: getImg("wildlava.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_TTREASURE_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldpiletoltectreasure.webp"),
        subGame: getImg("goldpiletoltectreasure.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AOGROTU_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsrulerofthedead.webp"),
        subGame: getImg("ageofthegodsrulerofthedead.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_GOGOLD_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsnorsebookofdwarves.webp"),
        subGame: getImg("ageofthegodsnorsebookofdwarves.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_28DMAN_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("28mansions.webp"),
        subGame: getImg("28mansions.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BUDDHAWAYS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldenways.webp"),
        subGame: getImg("goldenways.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_CWAYS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("caishenways.webp"),
        subGame: getImg("caishenways.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_LOTWILD2_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("legacyofthewilds2.webp"),
        subGame: getImg("legacyofthewilds2.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_FGIFT_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("frogsgift.webp"),
        subGame: getImg("frogsgift.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BBLITZ2_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("buffaloblitzii.webp"),
        subGame: getImg("buffaloblitzii.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_HYDRA_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("legendofhydra.webp"),
        subGame: getImg("legendofhydra.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_STRONGOX_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("oxriches.webp"),
        subGame: getImg("oxriches.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_MQUEST_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("questwest.webp"),
        subGame: getImg("questwest.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_DRAGONCHI_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragonchi.webp"),
        subGame: getImg("dragonchi.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_LOTTIGER_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("legacyofthetiger.webp"),
        subGame: getImg("legacyofthetiger.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_JHREELS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jokerhotreels.webp"),
        subGame: getImg("jokerhotreels.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_RGIFT_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gemsplashrainbowsgift.webp"),
        subGame: getImg("gemsplashrainbowsgift.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BETWILDS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("betterwilds.webp"),
        subGame: getImg("betterwilds.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_DSPARKS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragonspark.webp"),
        subGame: getImg("dragonspark.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_JMOON_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jinnsmoon.webp"),
        subGame: getImg("jinnsmoon.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AOGGAGIANTS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsnorsegodsandgiants.webp"),
        subGame: getImg("ageofthegodsnorsegodsandgiants.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_PLUCK_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pandaluck.webp"),
        subGame: getImg("pandaluck.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BBELLS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blazingbells.webp"),
        subGame: getImg("blazingbells.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_ATRAIL_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("adventuretrail.webp"),
        subGame: getImg("adventuretrail.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AOGGGRIFFIN_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsgloriousgriffin.webp"),
        subGame: getImg("ageofthegodsgloriousgriffin.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_JQW",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jinqianwa.webp"),
        subGame: getImg("jinqianwa.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_QNW",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("queenofwands.webp"),
        subGame: getImg("queenofwands.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SX",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sixiang.webp"),
        subGame: getImg("sixiang.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ZCTZ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("zhaocaitongzi.webp"),
        subGame: getImg("zhaocaitongzi.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_RIRIJC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ririjincai.webp"),
        subGame: getImg("ririjincai.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_LONGLONG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("longlonglong.webp"),
        subGame: getImg("longlonglong.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FDAY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fortuneday.webp"),
        subGame: getImg("fortuneday.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_CHAO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("chaoji888.webp"),
        subGame: getImg("chaoji888.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FKMJ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fengkuangmajiang.webp"),
        subGame: getImg("fengkuangmajiang.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_HEAVRU",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("heavenlyruler.webp"),
        subGame: getImg("heavenlyruler.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_HOTKTV",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hotktv.webp"),
        subGame: getImg("hotktv.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FRTLN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fortunelions.webp"),
        subGame: getImg("fortunelions.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASFA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("asianfantasy.webp"),
        subGame: getImg("asianfantasy.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_TRPMNK",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("triplemonkey.webp"),
        subGame: getImg("triplemonkey.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FTG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fivetigergenerals.webp"),
        subGame: getImg("fivetigergenerals.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_WLCSH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wulucaishen.webp"),
        subGame: getImg("wulucaishen.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_RIRISHC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ririshengcai.webp"),
        subGame: getImg("ririshengcai.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SSTRIKE_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("stallionstrike.webp"),
        subGame: getImg("stallionstrike.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AOGNWOT_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsnorsewaysofthunder.webp"),
        subGame: getImg("ageofthegodsnorsewaysofthunder.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_WWD_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildwestwilds.webp"),
        subGame: getImg("wildwestwilds.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BOKING_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bookofkings.webp"),
        subGame: getImg("bookofkings.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_EEMERALDS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("egyptianemeralds.webp"),
        subGame: getImg("egyptianemeralds.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_MWILDS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("midnightwilds.webp"),
        subGame: getImg("midnightwilds.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AOGKASGARD_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsnorsekingofasgard.webp"),
        subGame: getImg("ageofthegodsnorsekingofasgard.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GTSFLZT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("feilongzaitian.webp"),
        subGame: getImg("feilongzaitian.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GTSSWK",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sunwukong.webp"),
        subGame: getImg("sunwukong.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GHT_A",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("lieyanzuanshi.webp"),
        subGame: getImg("lieyanzuanshi.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GTSGME",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thegreatmingempire.webp"),
        subGame: getImg("thegreatmingempire.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_NIAN_K",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("niannianyouyuasia.webp"),
        subGame: getImg("niannianyouyuasia.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_AGOEG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofegypt.webp"),
        subGame: getImg("ageofegypt.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHFGF",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fairgroundfortunesghosttrain.webp"),
        subGame: getImg("fairgroundfortunesghosttrain.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BBBO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("berryberrybonanza.webp"),
        subGame: getImg("berryberrybonanza.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BIGSH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bigshots.webp"),
        subGame: getImg("bigshots.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BUP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("buckleup.webp"),
        subGame: getImg("buckleup.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_DVNCV",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("davincisvault.webp"),
        subGame: getImg("davincisvault.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GALST",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("galacticstreak.webp"),
        subGame: getImg("galacticstreak.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GEMHEAT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gemheat.webp"),
        subGame: getImg("gemheat.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_HWILDS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hologramwilds.webp"),
        subGame: getImg("hologramwilds.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_JDLO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("juegodelaoca.webp"),
        subGame: getImg("juegodelaoca.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_LUMLI",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("luminouslife.webp"),
        subGame: getImg("luminouslife.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_MURDER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("murdermystery.webp"),
        subGame: getImg("murdermystery.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_MWILDS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("majiwilds.webp"),
        subGame: getImg("majiwilds.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_RRD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("royalrespindeluxe.webp"),
        subGame: getImg("royalrespindeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_TOTL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("treasuresofthelamps.webp"),
        subGame: getImg("treasuresofthelamps.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_WILDB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildbeats.webp"),
        subGame: getImg("wildbeats.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_WWW",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wolveswolveswolves.webp"),
        subGame: getImg("wolveswolveswolves.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_PST",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pharaohssecrets.webp"),
        subGame: getImg("pharaohssecrets.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_POPC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gaelicluck.webp"),
        subGame: getImg("gaelicluck.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_WHK2",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("whitekingii.webp"),
        subGame: getImg("whitekingii.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_C7",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("crazy7.webp"),
        subGame: getImg("crazy7.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_WLG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wulong.webp"),
        subGame: getImg("wulong.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_DNR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dolphinreef.webp"),
        subGame: getImg("dolphinreef.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_HH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hauntedhouse.webp"),
        subGame: getImg("hauntedhouse.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_PAW",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("piggiesandthewolf.webp"),
        subGame: getImg("piggiesandthewolf.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_UB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("uggabugga.webp"),
        subGame: getImg("uggabugga.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_TRL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("truelove.webp"),
        subGame: getImg("truelove.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("baishi.webp"),
        subGame: getImg("baishi.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SIS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("silentsamurai.webp"),
        subGame: getImg("silentsamurai.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_PGV",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("penguinvacation.webp"),
        subGame: getImg("penguinvacation.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GTSFC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("footballcarnival.webp"),
        subGame: getImg("footballcarnival.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FCGZ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("feicuigongzhu.webp"),
        subGame: getImg("feicuigongzhu.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ANWILD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("anacondawild.webp"),
        subGame: getImg("anacondawild.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_NK",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("neptuneskingdom.webp"),
        subGame: getImg("neptuneskingdom.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_EPA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("epicape.webp"),
        subGame: getImg("epicape.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_DRGCH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragonchampions.webp"),
        subGame: getImg("dragonchampions.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SIB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("silverbullet.webp"),
        subGame: getImg("silverbullet.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("funkymonkey.webp"),
        subGame: getImg("funkymonkey.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_YCLONG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("yunconglong.webp"),
        subGame: getImg("yunconglong.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bermudatriangle.webp"),
        subGame: getImg("bermudatriangle.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BFB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("buffaloblitz.webp"),
        subGame: getImg("buffaloblitz.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ER",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("vacationstation.webp"),
        subGame: getImg("vacationstation.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_JNGLG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("junglegiants.webp"),
        subGame: getImg("junglegiants.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SHMST",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sherlockmystery.webp"),
        subGame: getImg("sherlockmystery.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_VCSTD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("vacationstationdeluxe.webp"),
        subGame: getImg("vacationstationdeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GTSSMBR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sambabrazil.webp"),
        subGame: getImg("sambabrazil.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_TIGC",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tigerclaw.webp"),
        subGame: getImg("tigerclaw.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_WOTP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("waysofthephoenix.webp"),
        subGame: getImg("waysofthephoenix.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_AOGMM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsmedusa&monsters.webp"),
        subGame: getImg("ageofthegodsmedusa&monsters.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_AOGMT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsmightymidas.webp"),
        subGame: getImg("ageofthegodsmightymidas.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_AOGROO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsrulersofolympus.webp"),
        subGame: getImg("ageofthegodsrulersofolympus.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_AOGROTS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsrulerofthesky.webp"),
        subGame: getImg("ageofthegodsrulerofthesky.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHABW",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("adventuresbeyondwonderland.webp"),
        subGame: getImg("adventuresbeyondwonderland.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHJCB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("juicybooty.webp"),
        subGame: getImg("juicybooty.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_CBELLS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("christmasjackpotbells.webp"),
        subGame: getImg("christmasjackpotbells.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_JBELLS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jackpotbells.webp"),
        subGame: getImg("jackpotbells.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_PYRR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pyramidoframesses.webp"),
        subGame: getImg("pyramidoframesses.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ZEUS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodskingofolympus.webp"),
        subGame: getImg("ageofthegodskingofolympus.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_XUFE",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("xuanpulianhuan.webp"),
        subGame: getImg("xuanpulianhuan.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_AOGS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegods.webp"),
        subGame: getImg("ageofthegods.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FURF",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsfuriousfour.webp"),
        subGame: getImg("ageofthegodsfuriousfour.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FTSIS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsfatesisters.webp"),
        subGame: getImg("ageofthegodsfatesisters.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ESM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("esmeralda.webp"),
        subGame: getImg("esmeralda.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_LJXY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("longjiaxiangyun.webp"),
        subGame: getImg("longjiaxiangyun.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_MNKMN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("monkeymania.webp"),
        subGame: getImg("monkeymania.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHADV",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("adventuresinwonderlanddeluxe.webp"),
        subGame: getImg("adventuresinwonderlanddeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_LNDG",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("landofgold.webp"),
        subGame: getImg("landofgold.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FMN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fruitmania.webp"),
        subGame: getImg("fruitmania.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_AEOLUS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsgodofstorms.webp"),
        subGame: getImg("ageofthegodsgodofstorms.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GRBJP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("greatbluejackpot.webp"),
        subGame: getImg("greatbluejackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_CNPR",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sweetparty.webp"),
        subGame: getImg("sweetparty.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHFTA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fairestofthemall.webp"),
        subGame: getImg("fairestofthemall.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FMJP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("funkymonkeyjackpot.webp"),
        subGame: getImg("funkymonkeyjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_NIAN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("niannianyouyu.webp"),
        subGame: getImg("niannianyouyu.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHBOB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bountyofthebeanstalk.webp"),
        subGame: getImg("bountyofthebeanstalk.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ATHN",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsgoddessofwisdom.webp"),
        subGame: getImg("ageofthegodsgoddessofwisdom.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_CCCCNY",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("coincoincoincny.webp"),
        subGame: getImg("coincoincoincny.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SOL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("streakofluck.webp"),
        subGame: getImg("streakofluck.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_JPGT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jackpotgiant.webp"),
        subGame: getImg("jackpotgiant.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FNFRJ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("funkyfruits.webp"),
        subGame: getImg("funkyfruits.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_HRCLS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsprinceofolympus.webp"),
        subGame: getImg("ageofthegodsprinceofolympus.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ASHTMD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("timeforadeal.webp"),
        subGame: getImg("timeforadeal.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_EVJ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("everybodysjackpot.webp"),
        subGame: getImg("everybodysjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_ZCJBJP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("zhaocaijinbaojackpot.webp"),
        subGame: getImg("zhaocaijinbaojackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_THT",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thaitemple.webp"),
        subGame: getImg("thaitemple.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_PHTD",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pharaohstreasuredeluxe.webp"),
        subGame: getImg("pharaohstreasuredeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_LEMERALDS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("luckyemeralds.webp"),
        subGame: getImg("luckyemeralds.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_RRIDER_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("retrorush.webp"),
        subGame: getImg("retrorush.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_GMACAQUE_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("goldenmacaque.webp"),
        subGame: getImg("goldenmacaque.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_APPOWER_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsapollopower.webp"),
        subGame: getImg("ageofthegodsapollopower.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_NYSLOT_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("newyearsbonanza.webp"),
        subGame: getImg("newyearsbonanza.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_PANTHPAYS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pantherpays.webp"),
        subGame: getImg("pantherpays.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_FPRINCE_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("forestprince.webp"),
        subGame: getImg("forestprince.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SCQUEEN_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pharaohsdaughter.webp"),
        subGame: getImg("pharaohsdaughter.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BWIZARD_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bluewizard.webp"),
        subGame: getImg("bluewizard.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AOGETROY_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsepictroy.webp"),
        subGame: getImg("ageofthegodsepictroy.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_HICE_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hainanice.webp"),
        subGame: getImg("hainanice.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_TSTACKS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tigerstacks.webp"),
        subGame: getImg("tigerstacks.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_PBLAST_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("buccaneerblast.webp"),
        subGame: getImg("buccaneerblast.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_TTTOTEMS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tiptoptotems.webp"),
        subGame: getImg("tiptoptotems.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AOGROTSEA_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsruleroftheseas.webp"),
        subGame: getImg("ageofthegodsruleroftheseas.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_CCAVE_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("cascadingcave.webp"),
        subGame: getImg("cascadingcave.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SEXILES_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("starmadaexiles.webp"),
        subGame: getImg("starmadaexiles.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_CASCHARMS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("casinocharms.webp"),
        subGame: getImg("casinocharms.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BOMAWAY_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bombs.webp"),
        subGame: getImg("bombs.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SECAGENT_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("spyrise.webp"),
        subGame: getImg("spyrise.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_ELADY_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("eternallady.webp"),
        subGame: getImg("eternallady.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SELFELF_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("selfieelfie.webp"),
        subGame: getImg("selfieelfie.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_PUMPKINB_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pumpkinbonanza.webp"),
        subGame: getImg("pumpkinbonanza.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_ELIMINATORS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("eliminators.webp"),
        subGame: getImg("eliminators.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_VAMPIREP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("vampireprincessofdarkness.webp"),
        subGame: getImg("vampireprincessofdarkness.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_NSSHEN_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("nishushenme.webp"),
        subGame: getImg("nishushenme.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_XJINFU_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jinfuxingyun.webp"),
        subGame: getImg("jinfuxingyun.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_JFLONG_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("jinfulong.webp"),
        subGame: getImg("jinfulong.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_FATCHOY_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fatchoychoysun.webp"),
        subGame: getImg("fatchoychoysun.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_DBOND_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragonbond.webp"),
        subGame: getImg("dragonbond.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_STARSABLAZE_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("starsablaze.webp"),
        subGame: getImg("starsablaze.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_TIGERTDP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tigerturtledragonphoenix.webp"),
        subGame: getImg("tigerturtledragonphoenix.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_TSGIFT_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("tsaishensgift.webp"),
        subGame: getImg("tsaishensgift.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SJUNGLE_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("savagejungle.webp"),
        subGame: getImg("savagejungle.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SQUEEN_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("skyqueen.webp"),
        subGame: getImg("skyqueen.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_GTREVEAL_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("thegreatreveal.webp"),
        subGame: getImg("thegreatreveal.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SOROME_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("shieldsofrome.webp"),
        subGame: getImg("shieldsofrome.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BTBANDIT_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bonustrainbandits.webp"),
        subGame: getImg("bonustrainbandits.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_HGEXTREME_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("waysboosthotgemsxtreme.webp"),
        subGame: getImg("waysboosthotgemsxtreme.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_KGOMOON_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("megafireblazekhonsugodofmoon.webp"),
        subGame: getImg("megafireblazekhonsugodofmoon.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_BOKINGS2PP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("bookofkings2powerplayjackpot.webp"),
        subGame: getImg("bookofkings2powerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SSCARAB_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("eyeofanubis.webp"),
        subGame: getImg("eyeofanubis.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_WTRACK_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fullmoonwildtrack.webp"),
        subGame: getImg("fullmoonwildtrack.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_WPANDA_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fullmoonwhitepanda.webp"),
        subGame: getImg("fullmoonwhitepanda.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_EAPE2_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("epicapeiijackpotblitz.webp"),
        subGame: getImg("epicapeiijackpotblitz.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_WLINX_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("wildlinx.webp"),
        subGame: getImg("wildlinx.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_DHALL_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragonshallthundershots.webp"),
        subGame: getImg("dragonshallthundershots.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_TWOLF_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("fireblazegoldentundrawolf.webp"),
        subGame: getImg("fireblazegoldentundrawolf.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_FBARS_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("flamingbars.webp"),
        subGame: getImg("flamingbars.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_PPAYSPP_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pantherpayspowerplayjackpot.webp"),
        subGame: getImg("pantherpayspowerplayjackpot.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_ISHTAR_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ishtar.webp"),
        subGame: getImg("ishtar.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_AMAMMOTH_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("absolutelymammoth.webp"),
        subGame: getImg("absolutelymammoth.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_SGOMAGIC_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("sorcerersguildofmagic.webp"),
        subGame: getImg("sorcerersguildofmagic.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_GPAS_GSKC_POP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("gemsplashkingscourt.webp"),
        subGame: getImg("gemsplashkingscourt.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_WOP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsspinawin.webp"),
        subGame: getImg("ageofthegodsspinawin.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_PREMBA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("premiumbaccarat.webp"),
        subGame: getImg("premiumbaccarat.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_MFBRO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("megafireblazeroulette.webp"),
        subGame: getImg("megafireblazeroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BJ65",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("vegasblackjack.webp"),
        subGame: getImg("vegasblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_DB1000RO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("1000diamondbetroulette.webp"),
        subGame: getImg("1000diamondbetroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_MBJ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("miniblackjack.webp"),
        subGame: getImg("miniblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FRR_G",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("premiumfrenchroulette.webp"),
        subGame: getImg("premiumfrenchroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_PRESROL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("roulettedeluxe.webp"),
        subGame: getImg("roulettedeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_PROL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pennyroulette.webp"),
        subGame: getImg("pennyroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_QRO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("quantumrouletteinstantplay.webp"),
        subGame: getImg("quantumrouletteinstantplay.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_DBRO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("diamondbetroulette.webp"),
        subGame: getImg("diamondbetroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_AOGRO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("ageofthegodsroulette.webp"),
        subGame: getImg("ageofthegodsroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_RODZ_G",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("premiumamericanroulette.webp"),
        subGame: getImg("premiumamericanroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_RO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("classicroulette.webp"),
        subGame: getImg("classicroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_DRGRO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("dragonjackpotroulette.webp"),
        subGame: getImg("dragonjackpotroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_MOBRO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mobileroulette.webp"),
        subGame: getImg("mobileroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_RO_G",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("premiumeuropeanroulette.webp"),
        subGame: getImg("premiumeuropeanroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SBRO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("spreadbetroulette.webp"),
        subGame: getImg("spreadbetroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_LWH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("spinawin.webp"),
        subGame: getImg("spinawin.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_PBRO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("pinballroulette.webp"),
        subGame: getImg("pinballroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_MRO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("miniroulette.webp"),
        subGame: getImg("miniroulette.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BJBU",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("busterblackjack.webp"),
        subGame: getImg("busterblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BJLL",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("luckyluckyblackjack.webp"),
        subGame: getImg("luckyluckyblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BJS",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjackswitch.webp"),
        subGame: getImg("blackjackswitch.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BJTO_SH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("premiumblackjacksinglehand.webp"),
        subGame: getImg("premiumblackjacksinglehand.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_HILOP",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("hilopremium.webp"),
        subGame: getImg("hilopremium.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_SEM",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("7emezzo.webp"),
        subGame: getImg("7emezzo.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("baccarat.webp"),
        subGame: getImg("baccarat.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BJSD2",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("blackjacksurrender.webp"),
        subGame: getImg("blackjacksurrender.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_MOBBJ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("mobileblackjack.webp"),
        subGame: getImg("mobileblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_HUH",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("headsupholdem.webp"),
        subGame: getImg("headsupholdem.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_FCBJ",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("freechipblackjack.webp"),
        subGame: getImg("freechipblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BJTO",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("premiumblackjack.webp"),
        subGame: getImg("premiumblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_BJCB",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("cashbackblackjack.webp"),
        subGame: getImg("cashbackblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_CHEAA",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("casinoholdem.webp"),
        subGame: getImg("casinoholdem.webp"),
      },
    },
    {
      subCode: "GTAG_PLAYTECH_PFBJ_MH5",
      type: ["EGAMING"],
      custImgClass: "img-ratio-3x2",
      thumb: {
        img: getImg("perfectblackjackmultihand5.webp"),
        subGame: getImg("perfectblackjackmultihand5.webp"),
      },
    },
  ],
} as LIBRARY_GAME;
