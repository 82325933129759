import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('GR', fileName)
};
const getLogo = () => {
  return getGameImg('GR_CHESS', 'logo.png')
};
export default {
  code: "GR_ELECTRONIC",
  type: ["EGAMING", "CHESS"],
  play_method: "URL",
  typeSetting: {
    CHESS: {
      name: "CasinoFrontGames.GR_CHESS.name",
      thumb: {
        img: getLogo(),
      },
    },
    EGAMING: {
      name: "CasinoFrontGames.GR_ELECTRONIC.name",
      thumb: {
        img: getImg("logo.png"),
      },
    },
  },
  thumb: {
    img: getImg("logo.png"),
  },
  subGames: [
    {
      subCode: "GR_ELECTRONIC_101",
      type: ["CHESS"],
      thumb: {
        img: getImg("101.png"),
        subGame: getImg("101.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_102",
      type: ["EGAMING"],
      thumb: {
        img: getImg("102.png"),
        subGame: getImg("102.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_103",
      type: ["EGAMING"],
      thumb: {
        img: getImg("103.png"),
        subGame: getImg("103.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_104",
      type: ["EGAMING"],
      thumb: {
        img: getImg("104.png"),
        subGame: getImg("104.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_105",
      type: ["CHESS"],
      thumb: {
        img: getImg("105.png"),
        subGame: getImg("105.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_106",
      type: ["CHESS"],
      thumb: {
        img: getImg("106.png"),
        subGame: getImg("106.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_107",
      type: ["CHESS"],
      thumb: {
        img: getImg("107.png"),
        subGame: getImg("107.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_108",
      type: ["EGAMING"],
      thumb: {
        img: getImg("108.png"),
        subGame: getImg("108.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_109",
      type: ["EGAMING"],
      thumb: {
        img: getImg("109.png"),
        subGame: getImg("109.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_110",
      type: ["CHESS"],
      thumb: {
        img: getImg("110.png"),
        subGame: getImg("110.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_111",
      type: ["CHESS"],
      thumb: {
        img: getImg("111.png"),
        subGame: getImg("111.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_112",
      type: ["CHESS"],
      thumb: {
        img: getImg("112.png"),
        subGame: getImg("112.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_113",
      type: ["CHESS"],
      thumb: {
        img: getImg("113.png"),
        subGame: getImg("113.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_114",
      type: ["EGAMING"],
      thumb: {
        img: getImg("114.png"),
        subGame: getImg("114.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_115",
      type: ["EGAMING"],
      thumb: {
        img: getImg("115.png"),
        subGame: getImg("115.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_116",
      type: ["CHESS"],
      thumb: {
        img: getImg("116.png"),
        subGame: getImg("116.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_119",
      type: ["EGAMING"],
      thumb: {
        img: getImg("119.png"),
        subGame: getImg("119.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_120",
      type: ["CHESS"],
      thumb: {
        img: getImg("120.png"),
        subGame: getImg("120.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_121",
      type: ["CHESS"],
      thumb: {
        img: getImg("121.png"),
        subGame: getImg("121.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_122",
      type: ["CHESS"],
      thumb: {
        img: getImg("122.png"),
        subGame: getImg("122.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_124",
      type: ["CHESS"],
      thumb: {
        img: getImg("124.png"),
        subGame: getImg("124.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_125",
      type: ["CHESS"],
      thumb: {
        img: getImg("125.png"),
        subGame: getImg("125.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_126",
      type: ["CHESS"],
      thumb: {
        img: getImg("126.png"),
        subGame: getImg("126.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_301",
      type: ["CHESS"],
      thumb: {
        img: getImg("301.png"),
        subGame: getImg("301.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_302",
      type: ["CHESS"],
      thumb: {
        img: getImg("302.png"),
        subGame: getImg("302.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_303",
      type: ["CHESS"],
      thumb: {
        img: getImg("303.png"),
        subGame: getImg("303.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_304",
      type: ["CHESS"],
      thumb: {
        img: getImg("304.png"),
        subGame: getImg("304.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_305",
      type: ["CHESS"],
      thumb: {
        img: getImg("305.png"),
        subGame: getImg("305.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_306",
      type: ["CHESS"],
      thumb: {
        img: getImg("306.png"),
        subGame: getImg("306.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_307",
      type: ["CHESS"],
      thumb: {
        img: getImg("307.png"),
        subGame: getImg("307.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_308",
      type: ["CHESS"],
      thumb: {
        img: getImg("308.png"),
        subGame: getImg("308.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_309",
      type: ["CHESS"],
      thumb: {
        img: getImg("309.png"),
        subGame: getImg("309.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_310",
      type: ["CHESS"],
      thumb: {
        img: getImg("310.png"),
        subGame: getImg("310.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_311",
      type: ["CHESS"],
      thumb: {
        img: getImg("311.png"),
        subGame: getImg("311.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_312",
      type: ["CHESS"],
      thumb: {
        img: getImg("312.png"),
        subGame: getImg("312.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_313",
      type: ["CHESS"],
      thumb: {
        img: getImg("313.png"),
        subGame: getImg("313.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_314",
      type: ["CHESS"],
      thumb: {
        img: getImg("314.png"),
        subGame: getImg("314.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_501",
      type: ["EGAMING"],
      thumb: {
        img: getImg("501.png"),
        subGame: getImg("501.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_502",
      type: ["EGAMING"],
      thumb: {
        img: getImg("502.png"),
        subGame: getImg("502.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10001",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10001.png"),
        subGame: getImg("10001.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10002",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10002.png"),
        subGame: getImg("10002.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10003",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10003.png"),
        subGame: getImg("10003.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10004",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10004.png"),
        subGame: getImg("10004.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10005",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10005.png"),
        subGame: getImg("10005.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10006",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10006.png"),
        subGame: getImg("10006.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10007",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10007.png"),
        subGame: getImg("10007.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10008",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10008.png"),
        subGame: getImg("10008.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10010",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10010.png"),
        subGame: getImg("10010.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10012",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10012.png"),
        subGame: getImg("10012.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10013",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10013.png"),
        subGame: getImg("10013.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10014",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10014.png"),
        subGame: getImg("10014.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10015",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10015.png"),
        subGame: getImg("10015.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10016",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10016.png"),
        subGame: getImg("10016.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10017",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10017.png"),
        subGame: getImg("10017.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10018",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10018.png"),
        subGame: getImg("10018.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_20002",
      type: ["EGAMING"],
      thumb: {
        img: getImg("20002.png"),
        subGame: getImg("20002.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10019",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10019.jpg"),
        subGame: getImg("10019.jpg"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10020",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10020.png"),
        subGame: getImg("10020.png"),
      },
    },
    {
      subCode: "GR_ELECTRONIC_10021",
      type: ["EGAMING"],
      thumb: {
        img: getImg("10021.png"),
        subGame: getImg("10021.png"),
      },
    },
  ],
} as LIBRARY_GAME;
