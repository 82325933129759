import { AxiosResponse } from "axios";
import $http from "../../../FPKG-220000-CasinoCore/axios"; // 後端溝通
const API = "casinoGames/member-ledger/lists";
// http://redmine.mirfak.tw/projects/pkg-280000-casinogames/repository/57/revisions/master/entry/_md/api/Member%20%E6%9C%83%E5%93%A1%E5%85%A5%E5%8F%A3/Ledger%20%E5%A0%B1%E8%A1%A8/%E5%88%97%E8%A1%A8.md

/** 報表 */
export namespace typeLists {
  export interface request {
    /** 遊戲ids 陣列 */
    search_p26_ges_ids?: number[];
    /** 開始時間 整數 */
    search_start_time?: string;
    /** 結束時間 整數 */
    search_end_time?: string;
    /** 查詢時間類型 BET_TIME:下注時間 SETTLEMENT_TIME:結算時間 */
    search_time_type?: "BET_TIME" | "SETTLEMENT_TIME";
    page?: number;
    perpage?: number;
    sort?: string;
    sort_type?: "DESC" | "ASC";
  }

  export type response = {
    account: account[];
    list: listItem[];
    paginator: pageinator;
  };

  export type listItem = {
    /** 總筆數 */
    p28_glcbte_count: number;
    /** 遊戲ID */
    p28_glcbte_p26_ges_id: number;
    /** 下注額度 */
    p28_glcbte_bet_amount: string;
    /** 有效下注額度 */
    p28_glcbte_valid_amount: string;
    /** 輸贏 */
    p28_glcbte_win_lose_result: string;
    /** 退水 */
    p28_glcbte_rebate: string;
    /** 輸贏結果 算完佔成後的值 */
    p28_glcbte_layer_percentage_result: string;
    /** 退水結果 算完佔成後的值 */
    p28_glcbte_layer_rebate_result: string;
    /** 帳號ID */
    p28_glcbte_layer_ant_id: number;
  };

  export type account = {
    /** 帳號ID */
    p3_ant_id: number;
    /** 帳號 */
    p3_ant_account: string;
  };

  export interface AxiosResponse extends _AxiosResponse {}
}
interface _AxiosResponse extends AxiosResponse<responseData> {}

interface responseData extends BackendResponse {
  response: any;
}

/** 報表 */
export default async (params?: typeLists.request) => {
  const getter = await $http.get(API, { params });
  if (getter.data.code !== 0) throw getter;
  return getter;
};
