import { AxiosResponse } from "axios";
import $http from "../../../FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "casinoGames/member/login";

export namespace typeInfo {
  export interface request {
    p26_ges_code: string;
    p26_sgs_code?: string;
    domain?: string;
  }
  export type response = type_1;

  /** 遊戲啟動類型1 */
  export type type_1 = {
    url: string;
    payload: string;
  };

  export interface AxiosResponse extends _AxiosResponse {}
}
interface _AxiosResponse extends AxiosResponse<responseData> {}

interface responseData extends BackendResponse {
  response: any;
}

/** 遊戲啟動 */
export default async (request: typeInfo.request) => {
  const params = preformater(request);
  const getter = await $http.get(API, { params });
  /** 針對 DG 的客製化，再給予五次機會 */
  if (getter.data.code === 260004 && request.p26_ges_code === "DREAM_GAME") {
    const getter = await fiveTimes(params);
    return getter;
  }
  if (getter.data.code !== 0) throw getter;
  return getter;
};

function preformater(req: typeInfo.request) {
  const domain = req.p26_sgs_code
    ? window.location.href
    : window.location.origin;
  return {
    p26_ges_code: req.p26_ges_code,
    p26_sgs_code: req.p26_sgs_code,
    domain,
  };
}

async function fiveTimes(params: typeInfo.request) {
  let err;
  for (let index = 0; index < 5; index++) {
    const getter = await $http.get(API, { params });
    if (index === 4) err = getter;
    if (getter.data.code === 0) {
      return getter;
    }
    index + 1;
  }
  throw err;
}
