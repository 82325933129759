/* eslint-disable no-param-reassign */
import DoModify, { typeDoModify } from "../../service/ID/doModify";

type state = {};

const state: state = {};

const store = {
  namespaced: true,
  state,
  mutations: {},
  actions: {
    async send({ commit }: any, req: typeDoModify.request) {
      await DoModify(req);
    },
  },
  getters: {},
};

export default store;
