/* eslint-disable func-names */
/* eslint-disable no-var */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */

/** Tawk */
// setTawk('https://embed.tawk.to/5d9c3ac96c1dde20ed0588d3/default');

/**
 * 根據客服連結不同，決定為連結類型或是其他的顯示模式
 * 若為客服程式連結，就回傳空字串
 */
function setService(url: string | null): string | "" {
  const tawk = RegExp("https://embed.tawk.to/*");
  if (!url) return "";
  /** 當客服連結為tawk, 進行tawk視窗初始化 */
  if (tawk.test(url)) {
    setTawk(url);
    return "";
  }

  /** 沒有符合條件，回傳原有連結 */
  return url;
}

var Tawk_API: any = Tawk_API || {};
const Tawk_LoadStart = new Date();

function setTawk(url: string) {
  (function() {
    const s1 = document.createElement("script");
    const s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = url;
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    if (s0.parentNode) {
      s0.parentNode.insertBefore(s1, s0);
    }
  })();
}

export default setService;
