import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('EXPANSE', fileName)
};

// TAG_EXPANSE_
export default {
  code: "GTAG_EXPANSE",
  type: ["EGAMING"],
  play_method: "URL",
  subGames: [
    {
      subCode: "GTAG_EXPANSE_EXPANSE_TITANROULETTE",
      thumb: {
        img: getImg("titanroulette.webp"),
        subGame: getImg("titanroulette.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_DIGITS",
      thumb: {
        img: getImg("digits.webp"),
        subGame: getImg("digits.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_DICEMINI",
      thumb: {
        img: getImg("dicemini.webp"),
        subGame: getImg("dicemini.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_DOTSRACING",
      thumb: {
        img: getImg("dotsracing.webp"),
        subGame: getImg("dotsracing.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_HILOMINI",
      thumb: {
        img: getImg("hilomini.webp"),
        subGame: getImg("hilomini.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_WHEEL",
      thumb: {
        img: getImg("wheel.webp"),
        subGame: getImg("wheel.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_FORTUNEFARM",
      thumb: {
        img: getImg("fortunefarm.webp"),
        subGame: getImg("fortunefarm.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_HILO",
      thumb: {
        img: getImg("hilo.webp"),
        subGame: getImg("hilo.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_WILDICYFRUITS",
      thumb: {
        img: getImg("wildicyfruits.webp"),
        subGame: getImg("wildicyfruits.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_KENO",
      thumb: {
        img: getImg("keno.webp"),
        subGame: getImg("keno.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_LUCKY‘SBETTINGSHOP",
      thumb: {
        img: getImg("luckybettingshop.webp"),
        subGame: getImg("luckybettingshop.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_MAGICWHEEL",
      thumb: {
        img: getImg("magicwheel.webp"),
        subGame: getImg("magicwheel.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_GEMMINER",
      thumb: {
        img: getImg("gemminer.webp"),
        subGame: getImg("gemminer.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_MAYA‘STREASURE",
      thumb: {
        img: getImg("mayastreasure.webp"),
        subGame: getImg("mayastreasure.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_MINIROULETTE",
      thumb: {
        img: getImg("miniroulette.webp"),
        subGame: getImg("miniroulette.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_ODDONEOUT",
      thumb: {
        img: getImg("oddoneout.webp"),
        subGame: getImg("oddoneout.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_PIRATESPOWER",
      thumb: {
        img: getImg("piratespower.webp"),
        subGame: getImg("piratespower.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_PIXELPOKER",
      thumb: {
        img: getImg("pixelpoker.webp"),
        subGame: getImg("pixelpoker.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_PLANETSPOWER",
      thumb: {
        img: getImg("planetspower.webp"),
        subGame: getImg("planetspower.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_JOKERPOKER",
      thumb: {
        img: getImg("jokerpoker.webp"),
        subGame: getImg("jokerpoker.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_SICBO",
      thumb: {
        img: getImg("sicbo.webp"),
        subGame: getImg("sicbo.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_TITANROULETTEDELUXE",
      thumb: {
        img: getImg("titanroulettedeluxe.webp"),
        subGame: getImg("titanroulettedeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_SPINNINGBUDDHA",
      thumb: {
        img: getImg("spinningbuddha.webp"),
        subGame: getImg("spinningbuddha.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_STICKY777",
      thumb: {
        img: getImg("sticky777.webp"),
        subGame: getImg("sticky777.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_SWIPE",
      thumb: {
        img: getImg("swipe.webp"),
        subGame: getImg("swipe.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_WILDCORRIDA",
      thumb: {
        img: getImg("wildcorrida.webp"),
        subGame: getImg("wildcorrida.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_THEBOOKOFESKIMO",
      thumb: {
        img: getImg("bookofeskimo.webp"),
        subGame: getImg("bookofeskimo.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_CIRCUSFEVER",
      thumb: {
        img: getImg("clownfever.webp"),
        subGame: getImg("clownfever.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_CIRCUSFEVERDELUXE",
      thumb: {
        img: getImg("clownfeverdeluxe.webp"),
        subGame: getImg("clownfeverdeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_TITANDICE",
      thumb: {
        img: getImg("titandice.webp"),
        subGame: getImg("titandice.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_BOOKOFEGYPT",
      thumb: {
        img: getImg("bookofegypt.webp"),
        subGame: getImg("bookofegypt.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_FORESTROCK",
      thumb: {
        img: getImg("forestrock.webp"),
        subGame: getImg("forestrock.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_FAIRYINWONDERLAND",
      thumb: {
        img: getImg("fairyinwonderland.webp"),
        subGame: getImg("fairyinwonderland.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_EVOJI",
      thumb: {
        img: getImg("evoji.webp"),
        subGame: getImg("evoji.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_DEUCESWILD",
      thumb: {
        img: getImg("deuceswild.webp"),
        subGame: getImg("deuceswild.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_CRAZYHORSES",
      thumb: {
        img: getImg("crazyhorses.webp"),
        subGame: getImg("crazyhorses.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_CASINOHEIST",
      thumb: {
        img: getImg("casinoheist.webp"),
        subGame: getImg("casinoheist.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_BOUNTYHUNTERS",
      thumb: {
        img: getImg("bountyhunters.webp"),
        subGame: getImg("bountyhunters.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_CAPITALCITYDERBY",
      thumb: {
        img: getImg("capitalcityderby.webp"),
        subGame: getImg("capitalcityderby.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_DIAMONDBLACKJACK",
      thumb: {
        img: getImg("diamondblackjack.webp"),
        subGame: getImg("diamondblackjack.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_ZOMBIEAPOCALYPSE",
      thumb: {
        img: getImg("zombieapocalypse.webp"),
        subGame: getImg("zombieapocalypse.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_BATTLEFORTHETHRONE",
      thumb: {
        img: getImg("battleforthethrone.webp"),
        subGame: getImg("battleforthethrone.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_GODOFCOINS",
      thumb: {
        img: getImg("godofcoins.webp"),
        subGame: getImg("godofcoins.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_PIGGYPARTY",
      thumb: {
        img: getImg("piggyparty.webp"),
        subGame: getImg("piggyparty.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_VENIVIDIVICI",
      thumb: {
        img: getImg("venividivici.webp"),
        subGame: getImg("venividivici.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_WILDWHITEWHALE",
      thumb: {
        img: getImg("wildwhitewhale.webp"),
        subGame: getImg("wildwhitewhale.webp"),
      },
    },
    {
      subCode: "GTAG_EXPANSE_EXPANSE_PIA",
      thumb: {
        img: getImg("pia.webp"),
        subGame: getImg("pia.webp"),
      },
    },
  ],
} as LIBRARY_GAME;
