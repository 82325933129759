import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('SIMPLE_PLAY', fileName)
};
export default {
  code: "SIMPLE_PLAY",
  type: ["EGAMING"],
  play_method: "URL",
  subGames: [
    {
      subCode: "SIMPLE_PLAY_EG-FISHING-001",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-FISHING-001.jpg"),
        subGame: getImg("SIMPLE_PLAY_EG-FISHING-001.jpg"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-FISHING-002",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-FISHING-002.jpg"),
        subGame: getImg("SIMPLE_PLAY_EG-FISHING-002.jpg"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A051",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A051.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A051.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A050",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A050.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A050.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A049",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A049.jpeg"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A049.jpeg"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A048",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A048.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A048.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A047",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A047.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A047.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A046",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A046.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A046.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A045",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A045.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A045.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A044",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A044.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A044.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A043",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A043.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A043.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A042",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A042.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A042.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A041",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A041.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A041.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A040",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A040.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A040.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A039",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A039.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A039.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A038",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A038.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A038.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A037",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A037.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A037.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A036",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A036.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A036.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A035",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A035.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A035.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A034",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A034.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A034.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A033",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A033.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A033.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A032",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A032.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A032.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A031",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A031.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A031.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A030",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A030.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A030.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A029",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A029.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A029.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A028",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A028.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A028.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A027",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A027.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A027.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A026",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A026.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A026.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A025",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A025.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A025.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A024",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A024.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A024.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A023",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A023.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A023.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A022",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A022.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A022.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A021",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A021.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A021.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A020",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A020.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A020.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A018",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A018.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A018.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A017",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A017.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A017.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A016",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A016.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A016.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A015",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A015.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A015.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A014",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A014.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A014.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A013",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A013.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A013.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A012",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A012.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A012.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A011",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A011.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A011.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A010",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A010.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A010.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A009",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A009.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A009.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A008",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A008.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A008.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A006",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A006.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A006.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A005",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A005.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A005.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A004",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A004.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A004.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A003",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A003.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A003.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A002",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A002.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A002.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-A001",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-A001.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-A001.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-S008",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-S008.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-S008.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-S007",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-S007.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-S007.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-S006",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-S006.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-S006.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-S005",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-S005.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-S005.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-C003",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-C003.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-C003.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-C004",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-C004.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-C004.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-C002",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-C002.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-C002.png"),
      },
    },
    {
      subCode: "SIMPLE_PLAY_EG-SLOT-C001",
      thumb: {
        img: getImg("SIMPLE_PLAY_EG-SLOT-C001.png"),
        subGame: getImg("SIMPLE_PLAY_EG-SLOT-C001.png"),
      },
    },
  ],
} as LIBRARY_GAME;
