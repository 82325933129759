import Locale from "../../service/site/locale";
import { Commit } from "vuex";

type state = {};
const state = {};

export default {
  namespaced: true,
  state,
  actions: {
    async setLocale({ commit }: { commit: Commit }, locale = "zh_CN") {
      (await Locale({ locale })).data.response;
    },
  },
};
