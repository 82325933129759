import { AxiosResponse } from "axios";
import $http from "../../../../FPKG-220000-CasinoCore/axios"; // 後端溝通

const API = "casinoPaymentFlow/front-stored/create";

/** 錯誤碼與對應的金流component名稱 */
export const ERROR_CODES = [
  {
    code: 270010,
    component: "SZFU",
  },
  {
    code: 270020,
    component: "RichPay",
  },
  {
    code: 270032,
    component: "Cob",
  },
  {
    code: 270033,
    component: "Monolith",
  },
];

export namespace typeCreate {
  /** 在送出前需要 code 來定義回傳網址 */
  export type formInput = request & { code: string };
  export interface request {
    /** 通道方法 必填,參考 */
    p23_bmd_method: number;
    /** 金額 必填,數值,min:0.01 */
    p27_sed_amount: string;
    /** 額外的付費資訊 */
    choose?: any;
    forward_url?: string;
    p23_pfw_code?: string;
    name?: string;
  }
  export type AxiosResponse = _AxiosResponse;
  export type response = type_form;

  export type type_form = {
    code: "MyCash";
    result: {
      form_data: Record<string, string | number>;
      url: string;
    };
  };
}
type _AxiosResponse = AxiosResponse<responseData>;

interface responseData extends BackendResponse {
  response: typeCreate.response;
}

/**
 * 送出儲值單
 * http://redmine.mirfak.tw/projects/pkg-270000-casinopaymentflow/repository/55/revisions/master/entry/_md/api/Front/Stored/%E6%96%B0%E5%A2%9E.md
 */
export default async (request: typeCreate.formInput) => {
  const getter = await $http.post(API, preformater(request));
  if (getter.data.code === 0) return getter;
  /** 會出現以error code 回傳資料的形式，因此會將code與res一起回傳 */
  if (ERROR_CODES.some(E => E.code === getter.data.code)) return getter;
  throw getter;
};

function preformater(req: typeCreate.formInput): typeCreate.request {
  return {
    p23_bmd_method: req.p23_bmd_method,
    p27_sed_amount: req.p27_sed_amount,
    name: req.name,
    choose: req.choose,
    forward_url: `${window.location.origin}/myCenter/depositResult`,
    p23_pfw_code: req.p23_pfw_code,
  };
}
