import { AxiosResponse } from "axios";
import $http from "../../../../FPKG-220000-CasinoCore/axios"; // 後端溝通
import isDefined from "@/modules/FPKG-20000-Util/library/isDefined";

const API = "casinoPaymentFlow/front-prepaySequence/info";

export namespace typeInfo {
  // export interface request {  }
  export type AxiosResponse = _AxiosResponse;
  export interface response {
    p27_prepay_sequence: p27_prepay_sequence[];
    p32_ratio_demand: typeInfo.p32_ratio_demand;
  }

  /** 整理過後的物件 */
  export type filtedItem = {
    id: number;
    method: string;
    label: string;
    sort: number;
  };

  export type p27_prepay_sequence = {
    /** 金流商號通道ID */
    p27_pse_p23_bmd_id: number;
    /** 順序 */
    p27_pse_sequence: number;
    /** 商號通道 string number 1 2 4 8 16 32 */
    p23_bmd_method: "1" | "2" | "4" | "8" | "16" | "32";
    p23_bmd_singular_lower_limit: number;
    p23_bmd_singular_upper_limit: number;
    p23_bmd_day_accumulation: 0;
    p23_bmd_day_upper_limit: 10000;
    p23_bmd_total_accumulation: 0;
  };
  export type p32_ratio_demand = {
    /** 0 */
    base_setting_ratio: number;
    /** 0.8 */
    grant_point: number;
    /** "3015.500000" */
    valid_amount: string;
  };
}
type _AxiosResponse = AxiosResponse<responseData>;

interface responseData extends BackendResponse {
  response: {
    p27_prepay_sequence: typeInfo.p27_prepay_sequence[];
    p32_ratio_demand: typeInfo.p32_ratio_demand;
  };
}

/**
 * 取得取款資訊
 * https://redmine.mirfak.tw/projects/pkg-270000-casinopaymentflow/repository/55/revisions/master/entry/_md/api/Front/PrepaySequence/%E8%B3%87%E8%A8%8A.md
 */
export default async () => {
  const getter = await $http(API);
  if (getter.data.code !== 0) throw getter;
  return getter;
};

export function sorting(
  A: typeInfo.p27_prepay_sequence,
  B: typeInfo.p27_prepay_sequence,
) {
  return A.p27_pse_sequence - B.p27_pse_sequence;
}

/**
 * 整理出可用的唯一通道做輸出
 */
export function setMethods(items: typeInfo.p27_prepay_sequence[]) {
  const sorted = items.sort(sorting);
  const PAY_METHODS = [
    "1",
    "2",
    "4",
    "8",
    "16",
    "32",
    "64",
    "128",
    "256",
    "512",
    "1024",
    "2048",
    "4096",
    "8192",
    "65536",
    "131072",
  ];
  const result = PAY_METHODS.map(M =>
    sorted.filter(S => S.p23_bmd_method === M),
  )
    .filter(arr => arr.length > 0)
    .map(arr => {
      const tar = arr[0];
      const { min, max } = getMinMax(arr);
      return {
        label: `CasinoCore.diposit_method_${tar.p23_bmd_method}`,
        method: tar.p23_bmd_method,
        max,
        min,
        /** id sort 僅是取得排序第一的通道，為避免舊規則崩潰而保留 */
        id: tar.p27_pse_p23_bmd_id,
        sort: tar.p27_pse_sequence,
      };
    });
  return result;

  function getMinMax(
    arr: typeInfo.p27_prepay_sequence[],
  ): { min: number; max: number } {
    let min = arr[0].p23_bmd_singular_lower_limit || 0;
    let max = arr[0].p23_bmd_singular_upper_limit;

    for (let i = 1; i < arr.length; i++) {
      if ((arr[i].p23_bmd_singular_lower_limit || 0) < min) {
        min = arr[i].p23_bmd_singular_lower_limit;
      }

      if (arr[i].p23_bmd_singular_upper_limit > max) {
        max = arr[i].p23_bmd_singular_upper_limit;
      }
    }

    return { min, max };
  }
}
