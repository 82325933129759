import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('I8', fileName)
};
export default {
  code: "I8_ELECTRONIC",
  type: ["EGAMING", "FISHING"],
  play_method: "URL",
  thumb: { img: getImg("img.jpg") },
  subGames: [
    { subCode: "I8_ELECTRONIC_200531", type: ["FISHING"] },
    { subCode: "I8_ELECTRONIC_200532", type: ["FISHING"] },
    { subCode: "I8_ELECTRONIC_200533", type: ["FISHING"] },
    { subCode: "I8_ELECTRONIC_200534", type: ["FISHING"] },
    { subCode: "I8_ELECTRONIC_200535", type: ["FISHING"] },
    { subCode: "I8_ELECTRONIC_200536", type: ["FISHING"] },
    { subCode: "I8_ELECTRONIC_171201", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_180206", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_180207", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_180302", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_180906", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_180907", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200508", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200509", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200510", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200511", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200512", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200516", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200517", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200518", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200519", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200520", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200521", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200522", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200523", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200524", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200525", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200526", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200527", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200528", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200529", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200530", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200537", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200538", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200539", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200540", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200541", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200542", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200543", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200544", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200545", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_200546", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_201204", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_201207", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_201209", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_201210", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_201211", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_201212", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_201213", type: ["EGAMING"] },
    { subCode: "I8_ELECTRONIC_210102", type: ["EGAMING"] },
  ],
} as LIBRARY_GAME;
