import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('GTAG_MICROGAMING', fileName)
};

const ZH_SUBGAMES = [
  {
    subCode: "GTAG_MICROGAMING_8445_1001_1002",
    thumb: {
      img: getImg("BTN_CairoLink&Win_ZH.webp"),
      subGame: getImg("BTN_CairoLink&Win_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8288_1001_1002",
    thumb: {
      img: getImg("BTN_AztecFalls_ZH.webp"),
      subGame: getImg("BTN_AztecFalls_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7939_1001_1002",
    thumb: {
      img: getImg("BTN_BreakAwayShootout_ZH.webp"),
      subGame: getImg("BTN_BreakAwayShootout_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7935_1001_1002",
    thumb: {
      img: getImg("BTN_BusttheMansion_ZH.webp"),
      subGame: getImg("BTN_BusttheMansion_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8284_1001_1002",
    thumb: {
      img: getImg("BTN_CashNRichesMegaways_ZH.webp"),
      subGame: getImg("BTN_CashNRichesMegaways_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8532_1001_1002",
    thumb: {
      img: getImg("BTN_CatsoftheCaribbean_ZH.webp"),
      subGame: getImg("BTN_CatsoftheCaribbean_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8322_1001_1002",
    thumb: {
      img: getImg("BTN_CircusJugglersJackpots_ZH.webp"),
      subGame: getImg("BTN_CircusJugglersJackpots_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8323_1001_1002",
    thumb: {
      img: getImg("BTN_DíadelMariachiMegaways_ZH.webp"),
      subGame: getImg("BTN_DíadelMariachiMegaways_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8393_1001_1002",
    thumb: {
      img: getImg("BTN_DivineRichesHelios_ZH.webp"),
      subGame: getImg("BTN_DivineRichesHelios_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8422_1001_1002",
    thumb: {
      img: getImg("BTN_ArkofRa_ZH.webp"),
      subGame: getImg("BTN_ArkofRa_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8326_1001_1002",
    thumb: {
      img: getImg("BTN_AbraCatDabra_ZH.webp"),
      subGame: getImg("BTN_AbraCatDabra_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8389_1001_1002",
    thumb: {
      img: getImg("BTN_777Surge_ZH.webp"),
      subGame: getImg("BTN_777Surge_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8357_1001_1002",
    thumb: {
      img: getImg("BTN_BoltXUP_ZH.webp"),
      subGame: getImg("BTN_BoltXUP_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8483_1001_1002",
    thumb: {
      img: getImg("BTN_AmazonKingdom_ZH.webp"),
      subGame: getImg("BTN_AmazonKingdom_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8503_1001_1002",
    thumb: {
      img: getImg("BTN_6RubiesofTribute_ZH.webp"),
      subGame: getImg("BTN_6RubiesofTribute_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8286_1001_1002",
    thumb: {
      img: getImg("BTN_DungeonsandDiamonds_ZH.webp"),
      subGame: getImg("BTN_DungeonsandDiamonds_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8017_1001_1002",
    thumb: {
      img: getImg("BTN_Fishin'PotsOfGold_ZH.webp"),
      subGame: getImg("BTN_Fishin'PotsOfGold_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8446_1001_1002",
    thumb: {
      img: getImg("BTN_GoldenDragons_ZH.webp"),
      subGame: getImg("BTN_GoldenDragons_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7938_1001_1002",
    thumb: {
      img: getImg("BTN_HyperStrikeHyperSpins_ZH.webp"),
      subGame: getImg("BTN_HyperStrikeHyperSpins_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8502_1001_1002",
    thumb: {
      img: getImg("BTN_JadeShuriken_ZH.webp"),
      subGame: getImg("BTN_JadeShuriken_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8020_1001_1002",
    thumb: {
      img: getImg("BTN_KingsofCrystals_ZH.webp"),
      subGame: getImg("BTN_KingsofCrystals_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7936_1001_1002",
    thumb: {
      img: getImg("BTN_5StarKnockout_ZH.webp"),
      subGame: getImg("BTN_5StarKnockout_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8283_1001_1002",
    thumb: {
      img: getImg("BTN_25000Talons_ZH.webp"),
      subGame: getImg("BTN_25000Talons_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8484_1001_1002",
    thumb: {
      img: getImg("BTN_KitsuneAdventure_ZH.webp"),
      subGame: getImg("BTN_KitsuneAdventure_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8016_1001_1002",
    thumb: {
      img: getImg("BTN_OniHunterNightSakura_ZH.webp"),
      subGame: getImg("BTN_OniHunterNightSakura_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8534_1001_1002",
    thumb: {
      img: getImg("BTN_StarliteFruits_ZH.webp"),
      subGame: getImg("BTN_StarliteFruits_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8018_1001_1002",
    thumb: {
      img: getImg("BTN_MegaMoneyWheel_ZH.webp"),
      subGame: getImg("BTN_MegaMoneyWheel_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8019_1001_1002",
    thumb: {
      img: getImg("BTN_WheelofWinners_ZH.webp"),
      subGame: getImg("BTN_WheelofWinners_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8501_1001_1002",
    thumb: {
      img: getImg("BTN_TreasuresofKilauea_ZH.webp"),
      subGame: getImg("BTN_TreasuresofKilauea_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7937_1001_1002",
    thumb: {
      img: getImg("BTN_JurassicParkGold_ZH.webp"),
      subGame: getImg("BTN_JurassicParkGold_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8328_1001_1002",
    thumb: {
      img: getImg("BTN_LightningFortunes_ZH.webp"),
      subGame: getImg("BTN_LightningFortunes_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8390_1001_1002",
    thumb: {
      img: getImg("BTN_LuckyLeprechaunClusters_ZH.webp"),
      subGame: getImg("BTN_LuckyLeprechaunClusters_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8289_1001_1002",
    thumb: {
      img: getImg("BTN_MastersofValhalla_ZH.webp"),
      subGame: getImg("BTN_MastersofValhalla_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8324_1001_1002",
    thumb: {
      img: getImg("BTN_MatchDay_ZH.webp"),
      subGame: getImg("BTN_MatchDay_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8392_1001_1002",
    thumb: {
      img: getImg("BTN_MaskofAmun_ZH.webp"),
      subGame: getImg("BTN_MaskofAmun_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8285_1001_1002",
    thumb: {
      img: getImg("BTN_15Tridents_ZH.webp"),
      subGame: getImg("BTN_15Tridents_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8391_1001_1002",
    thumb: {
      img: getImg("BTN_ImmortalRomanceVideoBingo_ZH.webp"),
      subGame: getImg("BTN_ImmortalRomanceVideoBingo_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8421_1001_1002",
    thumb: {
      img: getImg("BTN_Pile'EmUp_ZH.webp"),
      subGame: getImg("BTN_Pile'EmUp_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8287_1001_1002",
    thumb: {
      img: getImg("BTN_TheGreatAlbini2_ZH.webp"),
      subGame: getImg("BTN_TheGreatAlbini2_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8424_1001_1002",
    thumb: {
      img: getImg("BTN_Timelines_ZH.webp"),
      subGame: getImg("BTN_Timelines_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8325_1001_1002",
    thumb: {
      img: getImg("BTN_WesternGold2_ZH.webp"),
      subGame: getImg("BTN_WesternGold2_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8327_1001_1002",
    thumb: {
      img: getImg("BTN_WildWildRomance_ZH.webp"),
      subGame: getImg("BTN_WildWildRomance_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8321_1001_1002",
    thumb: {
      img: getImg("BTN_WildfireWins_ZH.webp"),
      subGame: getImg("BTN_WildfireWins_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1100_1001_1002",
    thumb: {
      img: getImg("BTN_RRDiamondGlitz1.webp"),
      subGame: getImg("BTN_RRDiamondGlitz1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7681_1001_1002",
    thumb: {
      img: getImg("BTN_LuckyTwinsWilds_ZH.webp"),
      subGame: getImg("BTN_LuckyTwinsWilds_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2068_1001_1002",
    thumb: {
      img: getImg("BTN_RomanovRiches_ZH.webp"),
      subGame: getImg("BTN_RomanovRiches_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1359_1001_1002",
    thumb: {
      img: getImg("BTN_RivieraRiches1.webp"),
      subGame: getImg("BTN_RivieraRiches1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1009_1001_1002",
    thumb: {
      img: getImg("BTN_RRHearts&Tarts1.webp"),
      subGame: getImg("BTN_RRHearts&Tarts1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1782_1001_1002",
    thumb: {
      img: getImg("BTN_RhymingReelsGeorgiePorgie.webp"),
      subGame: getImg("BTN_RhymingReelsGeorgiePorgie.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1189_1001_1002",
    thumb: {
      img: getImg("BTN_RRExtreme1.webp"),
      subGame: getImg("BTN_RRExtreme1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1110_1001_1002",
    thumb: {
      img: getImg("BTN_RetroReels1.webp"),
      subGame: getImg("BTN_RetroReels1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4109_1001_1002",
    thumb: {
      img: getImg("BTN_RelicSeekers_ZH.webp"),
      subGame: getImg("BTN_RelicSeekers_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1293_1001_1002",
    thumb: {
      img: getImg("BTN_ReelThunder2.webp"),
      subGame: getImg("BTN_ReelThunder2.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2070_1001_1002",
    thumb: {
      img: getImg("BTN_ReelTalent_ZH.webp"),
      subGame: getImg("BTN_ReelTalent_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1157_1001_1002",
    thumb: {
      img: getImg("BTN_ReelStrike1.webp"),
      subGame: getImg("BTN_ReelStrike1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1294_1001_1002",
    thumb: {
      img: getImg("BTN_ReelSpinner_en.webp"),
      subGame: getImg("BTN_ReelSpinner_en.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6069_1001_1002",
    thumb: {
      img: getImg("BTN_ReelGemsDeluxe_ZH.webp"),
      subGame: getImg("BTN_ReelGemsDeluxe_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1207_1001_1002",
    thumb: {
      img: getImg("BTN_ReelGems1.webp"),
      subGame: getImg("BTN_ReelGems1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4289_1001_1002",
    thumb: {
      img: getImg("BTN_QueenOfTheCrystalRays.webp"),
      subGame: getImg("BTN_QueenOfTheCrystalRays.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6748_1001_1002",
    thumb: {
      img: getImg("BTN_QueenofAlexandria_ZH.webp"),
      subGame: getImg("BTN_QueenofAlexandria_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1126_1001_1002",
    thumb: {
      img: getImg("BTN_LuckyFirecracker_ZH.webp"),
      subGame: getImg("BTN_LuckyFirecracker_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4339_1001_1002",
    thumb: {
      img: getImg("BTN_LuckyBachelors_ZH.webp"),
      subGame: getImg("BTN_LuckyBachelors_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2066_1001_1002",
    thumb: {
      img: getImg("BTN_LuchaLegends_ZH.webp"),
      subGame: getImg("BTN_LuchaLegends_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1420_1001_1002",
    thumb: {
      img: getImg("BTN_LostVegas.webp"),
      subGame: getImg("BTN_LostVegas.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5365_1001_1002",
    thumb: {
      img: getImg("BTN_LongMuFortunes_ZH.webp"),
      subGame: getImg("BTN_LongMuFortunes_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1245_1001_1002",
    thumb: {
      img: getImg("BTN_Loaded1.webp"),
      subGame: getImg("BTN_Loaded1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1756_1001_1002",
    thumb: {
      img: getImg("BTN_LiquidGold.webp"),
      subGame: getImg("BTN_LiquidGold.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1049_1001_1002",
    thumb: {
      img: getImg("BTN_lionsPride.webp"),
      subGame: getImg("BTN_lionsPride.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1851_1001_1002",
    thumb: {
      img: getImg("BTN_LifeOfRiches_en.webp"),
      subGame: getImg("BTN_LifeOfRiches_en.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4295_1001_1002",
    thumb: {
      img: getImg("BTN_LegendoftheMoonLovers_ZH.webp"),
      subGame: getImg("BTN_LegendoftheMoonLovers_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7072_1001_1002",
    thumb: {
      img: getImg("BTN_LegacyofOz_ZH.webp"),
      subGame: getImg("BTN_LegacyofOz_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4110_1001_1002",
    thumb: {
      img: getImg("BTN_LaraCroftTemplesandTombs_ZH.webp"),
      subGame: getImg("BTN_LaraCroftTemplesandTombs_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1124_1001_1002",
    thumb: {
      img: getImg("BTN_LadyInRed2.webp"),
      subGame: getImg("BTN_LadyInRed2.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5487_1001_1002",
    thumb: {
      img: getImg("BTN_LadiesNite2TurnWild_ZH.webp"),
      subGame: getImg("BTN_LadiesNite2TurnWild_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1389_1001_1002",
    thumb: {
      img: getImg("BTN_LadiesNite5.webp"),
      subGame: getImg("BTN_LadiesNite5.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1286_1001_1002",
    thumb: {
      img: getImg("BTN_KittyCabana_ZH.webp"),
      subGame: getImg("BTN_KittyCabana_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1400_1001_1002",
    thumb: {
      img: getImg("BTN_KingsofCash1.webp"),
      subGame: getImg("BTN_KingsofCash1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1908_1001_1002",
    thumb: {
      img: getImg("BTN_KingTusk_ZH.webp"),
      subGame: getImg("BTN_KingTusk_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5477_1001_1002",
    thumb: {
      img: getImg("BTN_KingoftheRing_ZH.webp"),
      subGame: getImg("BTN_KingoftheRing_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6214_1001_1002",
    thumb: {
      img: getImg("BTN_GoldaurGuardians_ZH.webp"),
      subGame: getImg("BTN_GoldaurGuardians_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1267_1001_1002",
    thumb: {
      img: getImg("BTN_GoldFactory.webp"),
      subGame: getImg("BTN_GoldFactory.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6333_1001_1002",
    thumb: {
      img: getImg("BTN_GoldCollector_ZH.webp"),
      subGame: getImg("BTN_GoldCollector_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1313_1001_1002",
    thumb: {
      img: getImg("BTN_GirlswithGuns1_ZH.webp"),
      subGame: getImg("BTN_GirlswithGuns1_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1945_1001_1002",
    thumb: {
      img: getImg("BTN_GemsOdyssey.webp"),
      subGame: getImg("BTN_GemsOdyssey.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6256_1001_1002",
    thumb: {
      img: getImg("BTN_Gems&Dragons_ZH.webp"),
      subGame: getImg("BTN_Gems&Dragons_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1878_1001_1002",
    thumb: {
      img: getImg("BTN_FruitVsCandy_ZH.webp"),
      subGame: getImg("BTN_FruitVsCandy_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1943_1001_1002",
    thumb: {
      img: getImg("BTN_FruitBlast_ZH.webp"),
      subGame: getImg("BTN_FruitBlast_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1993_1001_1002",
    thumb: {
      img: getImg("BTN_Fortunium_ZH.webp"),
      subGame: getImg("BTN_Fortunium_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2089_1001_1002",
    thumb: {
      img: getImg("BTN_BookieOfOdds_ZH.webp"),
      subGame: getImg("BTN_BookieOfOdds_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4329_1001_1002",
    thumb: {
      img: getImg("BTN_BookOfOzLockNSpin_ZH.webp"),
      subGame: getImg("BTN_BookOfOzLockNSpin_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2075_1001_1002",
    thumb: {
      img: getImg("BTN_BookOfOz_ZH.webp"),
      subGame: getImg("BTN_BookOfOz_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7745_1001_1002",
    thumb: {
      img: getImg("BTN_WWELegends_ZH.webp"),
      subGame: getImg("BTN_WWELegends_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7682_1001_1002",
    thumb: {
      img: getImg("BTN_JurassicWorldRaptorRiches_ZH.webp"),
      subGame: getImg("BTN_JurassicWorldRaptorRiches_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7746_1001_1002",
    thumb: {
      img: getImg("BTN_FortuneRush_ZH.webp"),
      subGame: getImg("BTN_FortuneRush_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7744_1001_1002",
    thumb: {
      img: getImg("BTN_DragonsBreath_ZH.webp"),
      subGame: getImg("BTN_DragonsBreath_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7680_1001_1002",
    thumb: {
      img: getImg("BTN_BreakDaBankAgainMEGAWAYS_ZH.webp"),
      subGame: getImg("BTN_BreakDaBankAgainMEGAWAYS_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7791_1001_1002",
    thumb: {
      img: getImg("BTN_LuckyClucks_ZH.webp"),
      subGame: getImg("BTN_LuckyClucks_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7743_1001_1002",
    thumb: {
      img: getImg("BTN_ChroniclesofOlympusXUP_ZH.webp"),
      subGame: getImg("BTN_ChroniclesofOlympusXUP_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7786_1001_1002",
    thumb: {
      img: getImg("BTN_SquealinRiches_ZH.webp"),
      subGame: getImg("BTN_SquealinRiches_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7789_1001_1002",
    thumb: {
      img: getImg("BTN_CatClans_ZH.webp"),
      subGame: getImg("BTN_CatClans_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7790_1001_1002",
    thumb: {
      img: getImg("BTN_BookOfMrsClaus_ZH.webp"),
      subGame: getImg("BTN_BookOfMrsClaus_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7716_1001_1002",
    thumb: {
      img: getImg("BTN_9MasksofFireHYPERSPINS_ZH.webp"),
      subGame: getImg("BTN_9MasksofFireHYPERSPINS_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7742_1001_1002",
    thumb: {
      img: getImg("BTN_CossacksTheWildHunt_ZH.webp"),
      subGame: getImg("BTN_CossacksTheWildHunt_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7787_1001_1002",
    thumb: {
      img: getImg("BTN_4DiamondBluesMegaways_ZH.webp"),
      subGame: getImg("BTN_4DiamondBluesMegaways_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1901_1001_1002",
    thumb: {
      img: getImg("BTN_TastyStreet_ZH.webp"),
      subGame: getImg("BTN_TastyStreet_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1847_1001_1002",
    thumb: {
      img: getImg("BTN_Tarzan_Button_en.webp"),
      subGame: getImg("BTN_Tarzan_Button_en.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1395_1001_1002",
    thumb: {
      img: getImg("BTN_TallyHo1.webp"),
      subGame: getImg("BTN_TallyHo1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1711_1001_1002",
    thumb: {
      img: getImg("BTN_SureWin.webp"),
      subGame: getImg("BTN_SureWin.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1289_1001_1002",
    thumb: {
      img: getImg("BTN_SupeItUp2.webp"),
      subGame: getImg("BTN_SupeItUp2.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1150_1001_1002",
    thumb: {
      img: getImg("BTN_SunTide_Button_ZH.webp"),
      subGame: getImg("BTN_SunTide_Button_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1127_1001_1002",
    thumb: {
      img: getImg("BTN_SunQuest3.webp"),
      subGame: getImg("BTN_SunQuest3.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1130_1001_1002",
    thumb: {
      img: getImg("BTN_summertime.webp"),
      subGame: getImg("BTN_summertime.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1784_1001_1002",
    thumb: {
      img: getImg("BTN_SummerHoliday.webp"),
      subGame: getImg("BTN_SummerHoliday.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1893_1001_1002",
    thumb: {
      img: getImg("BTN_SugarParade_ZH.webp"),
      subGame: getImg("BTN_SugarParade_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1170_1001_1002",
    thumb: {
      img: getImg("BTN_SterlingSilver3D1.webp"),
      subGame: getImg("BTN_SterlingSilver3D1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1320_1001_1002",
    thumb: {
      img: getImg("BTN_StashoftheTitans1.webp"),
      subGame: getImg("BTN_StashoftheTitans1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1167_1001_1002",
    thumb: {
      img: getImg("BTN_StarlightKiss.webp"),
      subGame: getImg("BTN_StarlightKiss.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1404_1001_1002",
    thumb: {
      img: getImg("BTN_StarDust_ZH.webp"),
      subGame: getImg("BTN_StarDust_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1002_1001_1002",
    thumb: {
      img: getImg("BTN_SpringBreak2.webp"),
      subGame: getImg("BTN_SpringBreak2.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1275_1001_1002",
    thumb: {
      img: getImg("BTN_RabbitintheHat.webp"),
      subGame: getImg("BTN_RabbitintheHat.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1312_1001_1002",
    thumb: {
      img: getImg("BTN_PurePlatinum1.webp"),
      subGame: getImg("BTN_PurePlatinum1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1045_1001_1002",
    thumb: {
      img: getImg("BTN_prettykitty_en.webp"),
      subGame: getImg("BTN_prettykitty_en.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5528_1001_1002",
    thumb: {
      img: getImg("BTN_PlayboyFortunes_ZH.webp"),
      subGame: getImg("BTN_PlayboyFortunes_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1188_1001_1002",
    thumb: {
      img: getImg("BTN_Playboy.webp"),
      subGame: getImg("BTN_Playboy.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1160_1001_1002",
    thumb: {
      img: getImg("BTN_Pistoleras_ZH.webp"),
      subGame: getImg("BTN_Pistoleras_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5144_1001_1002",
    thumb: {
      img: getImg("BTN_PingPongStar_ZH.webp"),
      subGame: getImg("BTN_PingPongStar_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5478_1001_1002",
    thumb: {
      img: getImg("BTN_PhoWin_ZH.webp"),
      subGame: getImg("BTN_PhoWin_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4298_1001_1002",
    thumb: {
      img: getImg("BTN_PetsGoWild_ZH.webp"),
      subGame: getImg("BTN_PetsGoWild_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1737_1001_1002",
    thumb: {
      img: getImg("BTN_PartyIsland.webp"),
      subGame: getImg("BTN_PartyIsland.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2073_1001_1002",
    thumb: {
      img: getImg("BTN_OurDays_ZH.webp"),
      subGame: getImg("BTN_OurDays_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6959_1001_1002",
    thumb: {
      img: getImg("BTN_OniHunterPlus_ZH.webp"),
      subGame: getImg("BTN_OniHunterPlus_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6328_1001_1002",
    thumb: {
      img: getImg("BTN_OniHunter_ZH.webp"),
      subGame: getImg("BTN_OniHunter_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1896_1001_1002",
    thumb: {
      img: getImg("BTN_OinkCountryLove_ZH.webp"),
      subGame: getImg("BTN_OinkCountryLove_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6956_1001_1002",
    thumb: {
      img: getImg("BTN_OdinsRiches_ZH.webp"),
      subGame: getImg("BTN_OdinsRiches_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6073_1001_1002",
    thumb: {
      img: getImg("BTN_NobleSky_ZH.webp"),
      subGame: getImg("BTN_NobleSky_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6217_1001_1002",
    thumb: {
      img: getImg("BTN_NeptunesRiches_ZH.webp"),
      subGame: getImg("BTN_NeptunesRiches_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1254_1001_1002",
    thumb: {
      img: getImg("BTN_MysticDreams1.webp"),
      subGame: getImg("BTN_MysticDreams1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1008_1001_1002",
    thumb: {
      img: getImg("BTN_Munchkins2.webp"),
      subGame: getImg("BTN_Munchkins2.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1903_1001_1002",
    thumb: {
      img: getImg("BTN_MonsterWheels_ZH.webp"),
      subGame: getImg("BTN_MonsterWheels_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5697_1001_1002",
    thumb: {
      img: getImg("BTN_MonsterBlast_ZH.webp"),
      subGame: getImg("BTN_MonsterBlast_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1308_1001_1002",
    thumb: {
      img: getImg("BTN_MermaidsMillions1.webp"),
      subGame: getImg("BTN_MermaidsMillions1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1942_1001_1002",
    thumb: {
      img: getImg("BTN_MegaMoneyRush_ZH.webp"),
      subGame: getImg("BTN_MegaMoneyRush_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1885_1001_1002",
    thumb: {
      img: getImg("BTN_MegaMoneyMultiplier.webp"),
      subGame: getImg("BTN_MegaMoneyMultiplier.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1343_1001_1002",
    thumb: {
      img: getImg("BTN_MayanPrincess1.webp"),
      subGame: getImg("BTN_MayanPrincess1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5479_1001_1002",
    thumb: {
      img: getImg("BTN_HiddenPalaceTreasures_ZH.webp"),
      subGame: getImg("BTN_HiddenPalaceTreasures_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1139_1001_1002",
    thumb: {
      img: getImg("BTN_Harveys1.webp"),
      subGame: getImg("BTN_Harveys1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2101_1001_1002",
    thumb: {
      img: getImg("BTN_HappyMonsterClaw_ZH.webp"),
      subGame: getImg("BTN_HappyMonsterClaw_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1072_1001_1002",
    thumb: {
      img: getImg("BTN_HappyHolidays_ZH.webp"),
      subGame: getImg("BTN_HappyHolidays_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1047_1001_1002",
    thumb: {
      img: getImg("BTN_Halloweenies1.webp"),
      subGame: getImg("BTN_Halloweenies1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1216_1001_1002",
    thumb: {
      img: getImg("BTN_GopherGold2.webp"),
      subGame: getImg("BTN_GopherGold2.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6301_1001_1002",
    thumb: {
      img: getImg("BTN_GoldenStallion_ZH.webp"),
      subGame: getImg("BTN_GoldenStallion_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1190_1001_1002",
    thumb: {
      img: getImg("BTN_GoldenPrincess.webp"),
      subGame: getImg("BTN_GoldenPrincess.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1041_1001_1002",
    thumb: {
      img: getImg("BTN_GoldenEra.webp"),
      subGame: getImg("BTN_GoldenEra.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1888_1001_1002",
    thumb: {
      img: getImg("BTN_FortuneGirl_ZH.webp"),
      subGame: getImg("BTN_FortuneGirl_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6298_1001_1002",
    thumb: {
      img: getImg("BTN_ForgottenIslandMegaways_ZH.webp"),
      subGame: getImg("BTN_ForgottenIslandMegaways_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1887_1001_1002",
    thumb: {
      img: getImg("BTN_ForbiddenThrone.webp"),
      subGame: getImg("BTN_ForbiddenThrone.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5488_1001_1002",
    thumb: {
      img: getImg("BTN_FootballStarDeluxe_ZH.webp"),
      subGame: getImg("BTN_FootballStarDeluxe_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1186_1001_1002",
    thumb: {
      img: getImg("BTN_footballstar1.webp"),
      subGame: getImg("BTN_footballstar1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6305_1001_1002",
    thumb: {
      img: getImg("BTN_FlowerFortunesMegaways_ZH.webp"),
      subGame: getImg("BTN_FlowerFortunesMegaways_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1113_1001_1002",
    thumb: {
      img: getImg("BTN_FishParty.webp"),
      subGame: getImg("BTN_FishParty.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6330_1001_1002",
    thumb: {
      img: getImg("BTN_FireForge_ZH.webp"),
      subGame: getImg("BTN_FireForge_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2060_1001_1002",
    thumb: {
      img: getImg("BTN_ExoticCats_ZH.webp"),
      subGame: getImg("BTN_ExoticCats_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6253_1001_1002",
    thumb: {
      img: getImg("BTN_EmperoroftheSeaDeluxe_ZH.webp"),
      subGame: getImg("BTN_EmperoroftheSeaDeluxe_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1882_1001_1002",
    thumb: {
      img: getImg("BTN_EmperorOfTheSea_ZH.webp"),
      subGame: getImg("BTN_EmperorOfTheSea_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1895_1001_1002",
    thumb: {
      img: getImg("BTN_EmotiCoins_ZH.webp"),
      subGame: getImg("BTN_EmotiCoins_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6627_1001_1002",
    thumb: {
      img: getImg("BTN_EmeraldGold_ZH.webp"),
      subGame: getImg("BTN_EmeraldGold_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7074_1001_1002",
    thumb: {
      img: getImg("BTN_ElvenGold_ZH.webp"),
      subGame: getImg("BTN_ElvenGold_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6417_1001_1002",
    thumb: {
      img: getImg("BTN_EgyptianTombs_ZH.webp"),
      subGame: getImg("BTN_EgyptianTombs_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1236_1001_1002",
    thumb: {
      img: getImg("BTN_EaglesWings1.webp"),
      subGame: getImg("BTN_EaglesWings1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1948_1001_1002",
    thumb: {
      img: getImg("BTN_DreamDate_ZH.webp"),
      subGame: getImg("BTN_DreamDate_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1424_1001_1002",
    thumb: {
      img: getImg("BTN_Dragonz.webp"),
      subGame: getImg("BTN_Dragonz.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4257_1001_1002",
    thumb: {
      img: getImg("BTN_DragonShard_ZH.webp"),
      subGame: getImg("BTN_DragonShard_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1037_1001_1002",
    thumb: {
      img: getImg("BTN_DragonDance_ZH.webp"),
      subGame: getImg("BTN_DragonDance_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1102_1001_1002",
    thumb: {
      img: getImg("BTN_DoubleWammy1.webp"),
      subGame: getImg("BTN_DoubleWammy1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1309_1001_1002",
    thumb: {
      img: getImg("BTN_DolphinQuest_ZH.webp"),
      subGame: getImg("BTN_DolphinQuest_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2065_1001_1002",
    thumb: {
      img: getImg("BTN_DolphinCoast_ZH.webp"),
      subGame: getImg("BTN_DolphinCoast_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6953_1001_1002",
    thumb: {
      img: getImg("BTN_DivineDiamonds_ZH.webp"),
      subGame: getImg("BTN_DivineDiamonds_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6220_1001_1002",
    thumb: {
      img: getImg("BTN_DiamondKingJackpots_ZH.webp"),
      subGame: getImg("BTN_DiamondKingJackpots_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1949_1001_1002",
    thumb: {
      img: getImg("BTN_DiamondEmpire_ZH.webp"),
      subGame: getImg("BTN_DiamondEmpire_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2047_1001_1002",
    thumb: {
      img: getImg("BTN_DecoDiamonds_ZH.webp"),
      subGame: getImg("BTN_DecoDiamonds_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1234_1001_1002",
    thumb: {
      img: getImg("BTN_DeckTheHalls1.webp"),
      subGame: getImg("BTN_DeckTheHalls1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5532_1001_1002",
    thumb: {
      img: getImg("BTN_CuoiAndTheBanyanTree_ZH.webp"),
      subGame: getImg("BTN_CuoiAndTheBanyanTree_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7076_1001_1002",
    thumb: {
      img: getImg("BTN_CricketStarScratch_ZH.webp"),
      subGame: getImg("BTN_CricketStarScratch_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1075_1001_1002",
    thumb: {
      img: getImg("BTN_CricketStar.webp"),
      subGame: getImg("BTN_CricketStar.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1202_1001_1002",
    thumb: {
      img: getImg("BTN_CrazyChameleons1.webp"),
      subGame: getImg("BTN_CrazyChameleons1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1327_1001_1002",
    thumb: {
      img: getImg("BTN_CouchPotato2.webp"),
      subGame: getImg("BTN_CouchPotato2.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1884_1001_1002",
    thumb: {
      img: getImg("BTN_CoolBuck_5Reel_ZH.webp"),
      subGame: getImg("BTN_CoolBuck_5Reel_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1879_1001_1002",
    thumb: {
      img: getImg("BTN_Classic243.webp"),
      subGame: getImg("BTN_Classic243.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6302_1001_1002",
    thumb: {
      img: getImg("BTN_ChicagoGold_ZH.webp"),
      subGame: getImg("BTN_ChicagoGold_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1023_1001_1002",
    thumb: {
      img: getImg("BTN_BreakDaBank1.webp"),
      subGame: getImg("BTN_BreakDaBank1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6251_1001_1002",
    thumb: {
      img: getImg("BTN_BreakAwayUltra_ZH.webp"),
      subGame: getImg("BTN_BreakAwayUltra_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5325_1001_1002",
    thumb: {
      img: getImg("BTN_BreakAwayLuckyWilds_ZH.webp"),
      subGame: getImg("BTN_BreakAwayLuckyWilds_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2074_1001_1002",
    thumb: {
      img: getImg("BTN_BreakAwayDeluxe_ZH.webp"),
      subGame: getImg("BTN_BreakAwayDeluxe_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6626_1001_1002",
    thumb: {
      img: getImg("BTN_BookOFKingArthur_ZH.webp"),
      subGame: getImg("BTN_BookOFKingArthur_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5491_1001_1002",
    thumb: {
      img: getImg("BTN_BoatofFortune_ZH.webp"),
      subGame: getImg("BTN_BoatofFortune_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6957_1001_1002",
    thumb: {
      img: getImg("BTN_BlazingMammoth_ZH.webp"),
      subGame: getImg("BTN_BlazingMammoth_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1290_1001_1002",
    thumb: {
      img: getImg("BTN_BikiniParty_ZH.webp"),
      subGame: getImg("BTN_BikiniParty_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1133_1001_1002",
    thumb: {
      img: getImg("BTN_BigTop1.webp"),
      subGame: getImg("BTN_BigTop1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1399_1001_1002",
    thumb: {
      img: getImg("BTN_BigKahuna1.webp"),
      subGame: getImg("BTN_BigKahuna1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1890_1001_1002",
    thumb: {
      img: getImg("BTN_BeautifulBones_ZH.webp"),
      subGame: getImg("BTN_BeautifulBones_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1995_1001_1002",
    thumb: {
      img: getImg("BTN_BattleRoyale_ZH.webp"),
      subGame: getImg("BTN_BattleRoyale_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6184_1001_1002",
    thumb: {
      img: getImg("BTN_BasketballStarOnFire_ZH.webp"),
      subGame: getImg("BTN_BasketballStarOnFire_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4256_1001_1002",
    thumb: {
      img: getImg("BTN_BasketballStarDeluxe_ZH.webp"),
      subGame: getImg("BTN_BasketballStarDeluxe_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1257_1001_1002",
    thumb: {
      img: getImg("BTN_barsandstripes.webp"),
      subGame: getImg("BTN_barsandstripes.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1788_1001_1002",
    thumb: {
      img: getImg("BTN_BarBarBlackSheep5Reel_ZH.webp"),
      subGame: getImg("BTN_BarBarBlackSheep5Reel_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4296_1001_1002",
    thumb: {
      img: getImg("BTN_BananaOdyssey.webp"),
      subGame: getImg("BTN_BananaOdyssey.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2061_1001_1002",
    thumb: {
      img: getImg("BTN_BadmintonHero_ZH.webp"),
      subGame: getImg("BTN_BadmintonHero_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1013_1001_1002",
    thumb: {
      img: getImg("BTN_AvalonHD_ZH.webp"),
      subGame: getImg("BTN_AvalonHD_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4328_1001_1002",
    thumb: {
      img: getImg("BTN_AuroraWilds_ZH.webp"),
      subGame: getImg("BTN_AuroraWilds_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6257_1001_1002",
    thumb: {
      img: getImg("BTN_Augustus_ZH.webp"),
      subGame: getImg("BTN_Augustus_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6952_1001_1002",
    thumb: {
      img: getImg("BTN_AtlantisRising_ZH.webp"),
      subGame: getImg("BTN_AtlantisRising_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5651_1001_1002",
    thumb: {
      img: getImg("BTN_AstroLegendsLyraandErion.webp"),
      subGame: getImg("BTN_AstroLegendsLyraandErion.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6254_1001_1002",
    thumb: {
      img: getImg("BTN_AssassinMoon_ZH.webp"),
      subGame: getImg("BTN_AssassinMoon_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1384_1001_1002",
    thumb: {
      img: getImg("BTN_AsianBeauty1.webp"),
      subGame: getImg("BTN_AsianBeauty1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1021_1001_1002",
    thumb: {
      img: getImg("BTN_Ariana.webp"),
      subGame: getImg("BTN_Ariana.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6372_1001_1002",
    thumb: {
      img: getImg("BTN_AndarBaharRoyale.webp"),
      subGame: getImg("BTN_AndarBaharRoyale.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4111_1001_1002",
    thumb: {
      img: getImg("BTN_AncientFortunesZeus_ZH.webp"),
      subGame: getImg("BTN_AncientFortunesZeus_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6747_1001_1002",
    thumb: {
      img: getImg("BTN_AncientFortunesPoseidonMegaways_ZH.webp"),
      subGame: getImg("BTN_AncientFortunesPoseidonMegaways_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6332_1001_1002",
    thumb: {
      img: getImg("BTN_AmazingLinkZeus_ZH.webp"),
      subGame: getImg("BTN_AmazingLinkZeus_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6219_1001_1002",
    thumb: {
      img: getImg("BTN_AlchemyFortunes_ZH.webp"),
      subGame: getImg("BTN_AlchemyFortunes_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5490_1001_1002",
    thumb: {
      img: getImg("BTN_AlchemyBlast.webp"),
      subGame: getImg("BTN_AlchemyBlast.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5926_1001_1002",
    thumb: {
      img: getImg("BTN_AlchemistStone_ZH.webp"),
      subGame: getImg("BTN_AlchemistStone_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1004_1001_1002",
    thumb: {
      img: getImg("BTN_AlaskanFishing1.webp"),
      subGame: getImg("BTN_AlaskanFishing1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2088_1001_1002",
    thumb: {
      img: getImg("BTN_AgentJaneBlondeReturns_ZH.webp"),
      subGame: getImg("BTN_AgentJaneBlondeReturns_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1155_1001_1002",
    thumb: {
      img: getImg("BTN_AgentJaneBlonde7.webp"),
      subGame: getImg("BTN_AgentJaneBlonde7.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1246_1001_1002",
    thumb: {
      img: getImg("BTN_AgeofDiscovery3.webp"),
      subGame: getImg("BTN_AgeofDiscovery3.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5618_1001_1002",
    thumb: {
      img: getImg("BTN_AgeofConquest_ZH.webp"),
      subGame: getImg("BTN_AgeofConquest_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6304_1001_1002",
    thumb: {
      img: getImg("BTN_AdventuresOfDoubloonIsland_ZH.webp"),
      subGame: getImg("BTN_AdventuresOfDoubloonIsland_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1010_1001_1002",
    thumb: {
      img: getImg("BTN_AdventurePalaceHD.webp"),
      subGame: getImg("BTN_AdventurePalaceHD.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2076_1001_1002",
    thumb: {
      img: getImg("BTN_ActionOpsSnow&Sable_ZH.webp"),
      subGame: getImg("BTN_ActionOpsSnow&Sable_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6218_1001_1002",
    thumb: {
      img: getImg("BTN_ATaleofElves_ZH.webp"),
      subGame: getImg("BTN_ATaleofElves_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5382_1001_1002",
    thumb: {
      img: getImg("BTN_ADarkMatter_ZH.webp"),
      subGame: getImg("BTN_ADarkMatter_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5619_1001_1002",
    thumb: {
      img: getImg("BTN_9PotsofGold_ZH.webp"),
      subGame: getImg("BTN_9PotsofGold_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5363_1001_1002",
    thumb: {
      img: getImg("BTN_9MasksOfFire_ZH.webp"),
      subGame: getImg("BTN_9MasksOfFire_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6399_1001_1002",
    thumb: {
      img: getImg("BTN_9BlazingDiamonds_ZH.webp"),
      subGame: getImg("BTN_9BlazingDiamonds_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6221_1001_1002",
    thumb: {
      img: getImg("BTN_777RoyalWheel_ZH.webp"),
      subGame: getImg("BTN_777RoyalWheel_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1415_1001_1002",
    thumb: {
      img: getImg("BTN_DeucesWildPowerPoker1.webp"),
      subGame: getImg("BTN_DeucesWildPowerPoker1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6329_1001_1002",
    thumb: {
      img: getImg("BTN_MauiMischief™_ZH.webp"),
      subGame: getImg("BTN_MauiMischief™_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6300_1001_1002",
    thumb: {
      img: getImg("BTN_777MegaDeluxe_ZH.webp"),
      subGame: getImg("BTN_777MegaDeluxe_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1035_1001_1002",
    thumb: {
      img: getImg("BTN_5ReelDrive1.webp"),
      subGame: getImg("BTN_5ReelDrive1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1897_1001_1002",
    thumb: {
      img: getImg("BTN_108HeroesMF_ZH.webp"),
      subGame: getImg("BTN_108HeroesMF_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1302_1001_1002",
    thumb: {
      img: getImg("BTN_108Heroes.webp"),
      subGame: getImg("BTN_108Heroes.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6742_1001_1002",
    thumb: {
      img: getImg("BTN_10000Wishes_ZH.webp"),
      subGame: getImg("BTN_10000Wishes_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7529_1001_1002",
    thumb: {
      img: getImg("BTN_AmazingLinkApollo_ZH.webp"),
      subGame: getImg("BTN_AmazingLinkApollo_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7527_1001_1002",
    thumb: {
      img: getImg("BTN_AfricaXUP_ZH.webp"),
      subGame: getImg("BTN_AfricaXUP_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4288_1001_1002",
    thumb: {
      img: getImg("BTN_MagicOfSahara_ZH.webp"),
      subGame: getImg("BTN_MagicOfSahara_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1314_1001_1002",
    thumb: {
      img: getImg("BTN_MadHatters1.webp"),
      subGame: getImg("BTN_MadHatters1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1273_1001_1002",
    thumb: {
      img: getImg("BTN_LuckyZodiac_ZH.webp"),
      subGame: getImg("BTN_LuckyZodiac_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5142_1001_1002",
    thumb: {
      img: getImg("BTN_LuckyTwinsJackpot_ZH.webp"),
      subGame: getImg("BTN_LuckyTwinsJackpot_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2085_1001_1002",
    thumb: {
      img: getImg("BTN_ZombieHoard.webp"),
      subGame: getImg("BTN_ZombieHoard.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5705_1001_1002",
    thumb: {
      img: getImg("BTN_WPForest Party_ZH.webp"),
      subGame: getImg("BTN_WPForest Party_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1345_1001_1002",
    thumb: {
      img: getImg("BTN_WinSumDimSum_en.webp"),
      subGame: getImg("BTN_WinSumDimSum_en.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1992_1001_1002",
    thumb: {
      img: getImg("BTN_WildScarabs_ZH.webp"),
      subGame: getImg("BTN_WildScarabs_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1164_1001_1002",
    thumb: {
      img: getImg("BTN_WildOrient_Button_ZH.webp"),
      subGame: getImg("BTN_WildOrient_Button_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1152_1001_1002",
    thumb: {
      img: getImg("BTN_WildCatch_ZH.webp"),
      subGame: getImg("BTN_WildCatch_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2064_1001_1002",
    thumb: {
      img: getImg("BTN_WickedTalesDarkRed.webp"),
      subGame: getImg("BTN_WickedTalesDarkRed.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1171_1001_1002",
    thumb: {
      img: getImg("BTN_WhataHoot3.webp"),
      subGame: getImg("BTN_WhataHoot3.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6072_1001_1002",
    thumb: {
      img: getImg("BTN_WesternGold_ZH.webp"),
      subGame: getImg("BTN_WesternGold_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6187_1001_1002",
    thumb: {
      img: getImg("BTN_WantedOutlaws_ZH.webp"),
      subGame: getImg("BTN_WantedOutlaws_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1911_1001_1002",
    thumb: {
      img: getImg("BTN_WackyPanda_ZH.webp"),
      subGame: getImg("BTN_WackyPanda_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1241_1001_1002",
    thumb: {
      img: getImg("BTN_voila_2.webp"),
      subGame: getImg("BTN_voila_2.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1306_1001_1002",
    thumb: {
      img: getImg("BTN_VinylCountdown4_ZH.webp"),
      subGame: getImg("BTN_VinylCountdown4_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6070_1001_1002",
    thumb: {
      img: getImg("BTN_LuckyTwinsCatcher_ZH.webp"),
      subGame: getImg("BTN_LuckyTwinsCatcher_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4286_1001_1002",
    thumb: {
      img: getImg("BTN_TreasuresOfLionCity_ZH.webp"),
      subGame: getImg("BTN_TreasuresOfLionCity_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1283_1001_1002",
    thumb: {
      img: getImg("BTN_luckyTwins_ZH.webp"),
      subGame: getImg("BTN_luckyTwins_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5698_1001_1002",
    thumb: {
      img: getImg("BTN_LuckyThaiLanterns_ZH.webp"),
      subGame: getImg("BTN_LuckyThaiLanterns_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5483_1001_1002",
    thumb: {
      img: getImg("BTN_LuckySilat_ZH.webp"),
      subGame: getImg("BTN_LuckySilat_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5620_1001_1002",
    thumb: {
      img: getImg("BTN_LuckyRichesHyperspins_ZH.webp"),
      subGame: getImg("BTN_LuckyRichesHyperspins_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1944_1001_1002",
    thumb: {
      img: getImg("BTN_LuckyLittleGods_ZH.webp"),
      subGame: getImg("BTN_LuckyLittleGods_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1212_1001_1002",
    thumb: {
      img: getImg("BTN_LuckyLeprechaun.webp"),
      subGame: getImg("BTN_LuckyLeprechaun.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1020_1001_1002",
    thumb: {
      img: getImg("BTN_TreasurePalace.webp"),
      subGame: getImg("BTN_TreasurePalace.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5143_1001_1002",
    thumb: {
      img: getImg("BTN_TreasureDash_ZH.webp"),
      subGame: getImg("BTN_TreasureDash_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1060_1001_1002",
    thumb: {
      img: getImg("BTN_LuckyKoi.webp"),
      subGame: getImg("BTN_LuckyKoi.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1383_1001_1002",
    thumb: {
      img: getImg("BTN_TombRaiderSotS1.webp"),
      subGame: getImg("BTN_TombRaiderSotS1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1122_1001_1002",
    thumb: {
      img: getImg("BTN_TombRaider2.webp"),
      subGame: getImg("BTN_TombRaider2.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1385_1001_1002",
    thumb: {
      img: getImg("BTN_TitansOfTheSunTheia_ZH.webp"),
      subGame: getImg("BTN_TitansOfTheSunTheia_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1208_1001_1002",
    thumb: {
      img: getImg("BTN_TitansOfTheSunHyperion_ZH.webp"),
      subGame: getImg("BTN_TitansOfTheSunHyperion_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4290_1001_1002",
    thumb: {
      img: getImg("BTN_TikiVikings™_ZH.webp"),
      subGame: getImg("BTN_TikiVikings™_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6216_1001_1002",
    thumb: {
      img: getImg("BTN_TikiReward_ZH.webp"),
      subGame: getImg("BTN_TikiReward_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1232_1001_1002",
    thumb: {
      img: getImg("BTN_tigersEye.webp"),
      subGame: getImg("BTN_tigersEye.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6988_1001_1002",
    thumb: {
      img: getImg("BTN_ThunderstruckWildLightning_ZH.webp"),
      subGame: getImg("BTN_ThunderstruckWildLightning_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1330_1001_1002",
    thumb: {
      img: getImg("BTN_ThunderstruckTwo1.webp"),
      subGame: getImg("BTN_ThunderstruckTwo1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1028_1001_1002",
    thumb: {
      img: getImg("BTN_Thunderstruck1_ZH.webp"),
      subGame: getImg("BTN_Thunderstruck1_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1386_1001_1002",
    thumb: {
      img: getImg("BTN_TwistedCircus.webp"),
      subGame: getImg("BTN_TwistedCircus.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1398_1001_1002",
    thumb: {
      img: getImg("BTN_RatPack1.webp"),
      subGame: getImg("BTN_RatPack1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1906_1001_1002",
    thumb: {
      img: getImg("BTN_ThePhantomOfTheOpera_ZH.webp"),
      subGame: getImg("BTN_ThePhantomOfTheOpera_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5448_1001_1002",
    thumb: {
      img: getImg("BTN_TheIncredibleBalloonMachine_ZH.webp"),
      subGame: getImg("BTN_TheIncredibleBalloonMachine_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5530_1001_1002",
    thumb: {
      img: getImg("BTN_TheImmortalSumo_ZH.webp"),
      subGame: getImg("BTN_TheImmortalSumo_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1883_1001_1002",
    thumb: {
      img: getImg("BTN_TheHeatIsOn.webp"),
      subGame: getImg("BTN_TheHeatIsOn.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2087_1001_1002",
    thumb: {
      img: getImg("BTN_TheGreatAlbini.webp"),
      subGame: getImg("BTN_TheGreatAlbini.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5699_1001_1002",
    thumb: {
      img: getImg("BTN_TheGoldenMaskDance_ZH.webp"),
      subGame: getImg("BTN_TheGoldenMaskDance_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6075_1001_1002",
    thumb: {
      img: getImg("BTN_TheGoldSwallow_ZH.webp"),
      subGame: getImg("BTN_TheGoldSwallow_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1200_1001_1002",
    thumb: {
      img: getImg("BTN_TheFinerReelsOfLife_ZH.webp"),
      subGame: getImg("BTN_TheFinerReelsOfLife_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6185_1001_1002",
    thumb: {
      img: getImg("BTN_TarzanandtheJewelsofOpar_ZH.webp"),
      subGame: getImg("BTN_TarzanandtheJewelsofOpar_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5480_1001_1002",
    thumb: {
      img: getImg("BTN_SongkranParty_ZH.webp"),
      subGame: getImg("BTN_SongkranParty_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1001_1001_1002",
    thumb: {
      img: getImg("BTN_somanymonsters.webp"),
      subGame: getImg("BTN_somanymonsters.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1892_1001_1002",
    thumb: {
      img: getImg("BTN_SixAcrobats_ZH.webp"),
      subGame: getImg("BTN_SixAcrobats_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5925_1001_1002",
    thumb: {
      img: getImg("BTN_SistersofOzJackpots_ZH.webp"),
      subGame: getImg("BTN_SistersofOzJackpots_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6255_1001_1002",
    thumb: {
      img: getImg("BTN_SilverbackMultiplierMountain_ZH.webp"),
      subGame: getImg("BTN_SilverbackMultiplierMountain_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6954_1001_1002",
    thumb: {
      img: getImg("BTN_SilverSeas_ZH.webp"),
      subGame: getImg("BTN_SilverSeas_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4112_1001_1002",
    thumb: {
      img: getImg("BTN_SilverLioness4x.webp"),
      subGame: getImg("BTN_SilverLioness4x.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1062_1001_1002",
    thumb: {
      img: getImg("BTN_SilverFang1.webp"),
      subGame: getImg("BTN_SilverFang1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2077_1001_1002",
    thumb: {
      img: getImg("BTN_ShowdownSaloon_ZH.webp"),
      subGame: getImg("BTN_ShowdownSaloon_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2086_1001_1002",
    thumb: {
      img: getImg("BTN_ShogunofTime_ZH.webp"),
      subGame: getImg("BTN_ShogunofTime_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4287_1001_1002",
    thumb: {
      img: getImg("BTN_SherlockofLondon.webp"),
      subGame: getImg("BTN_SherlockofLondon.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1421_1001_1002",
    thumb: {
      img: getImg("BTN_ShanghaiBeauty.webp"),
      subGame: getImg("BTN_ShanghaiBeauty.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6252_1001_1002",
    thumb: {
      img: getImg("BTN_ShamrockHolmes_ZH.webp"),
      subGame: getImg("BTN_ShamrockHolmes_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6628_1001_1002",
    thumb: {
      img: getImg("BTN_SerengetiGold_ZH.webp"),
      subGame: getImg("BTN_SerengetiGold_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5486_1001_1002",
    thumb: {
      img: getImg("BTN_SecretsofSengoku_ZH.webp"),
      subGame: getImg("BTN_SecretsofSengoku_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1877_1001_1002",
    thumb: {
      img: getImg("BTN_SecretRomance_1_ZH.webp"),
      subGame: getImg("BTN_SecretRomance_1_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1325_1001_1002",
    thumb: {
      img: getImg("BTN_SecretAdmirer1.webp"),
      subGame: getImg("BTN_SecretAdmirer1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1030_1001_1002",
    thumb: {
      img: getImg("BTN_Scrooge1_ZH.webp"),
      subGame: getImg("BTN_Scrooge1_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1095_1001_1002",
    thumb: {
      img: getImg("BTN_SantasWildRide1.webp"),
      subGame: getImg("BTN_SantasWildRide1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1785_1001_1002",
    thumb: {
      img: getImg("BTN_SantaPaws.webp"),
      subGame: getImg("BTN_SantaPaws.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5447_1001_1002",
    thumb: {
      img: getImg("BTN_RugbyStarDeluxe_ZH.webp"),
      subGame: getImg("BTN_RugbyStarDeluxe_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1151_1001_1002",
    thumb: {
      img: getImg("BTN_Kathmandu1.webp"),
      subGame: getImg("BTN_Kathmandu1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1053_1001_1002",
    thumb: {
      img: getImg("BTN_KaraokeParty_ZH.webp"),
      subGame: getImg("BTN_KaraokeParty_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1891_1001_1002",
    thumb: {
      img: getImg("BTN_JurassicWorld.webp"),
      subGame: getImg("BTN_JurassicWorld.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1244_1001_1002",
    thumb: {
      img: getImg("BTN_JungleJim.webp"),
      subGame: getImg("BTN_JungleJim.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4340_1001_1002",
    thumb: {
      img: getImg("BTN_JungleJimandtheLostSphinx_ZH.webp"),
      subGame: getImg("BTN_JungleJimandtheLostSphinx_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6955_1001_1002",
    thumb: {
      img: getImg("BTN_JoyfulJokerMegaways_ZH.webp"),
      subGame: getImg("BTN_JoyfulJokerMegaways_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1418_1001_1002",
    thumb: {
      img: getImg("BTN_JokerPoker1.webp"),
      subGame: getImg("BTN_JokerPoker1.webp"),
    },
  },

  {
    subCode: "GTAG_MICROGAMING_6258_1001_1002",
    thumb: {
      img: getImg("BTN_Ingotsof CaiShen_ZH.webp"),
      subGame: getImg("BTN_Ingotsof CaiShen_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5489_1001_1002",
    thumb: {
      img: getImg("BTN_IncanAdventure_ZH.webp"),
      subGame: getImg("BTN_IncanAdventure_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1103_1001_1002",
    thumb: {
      img: getImg("BTN_ImmortalRomance1.webp"),
      subGame: getImg("BTN_ImmortalRomance1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6327_1001_1002",
    thumb: {
      img: getImg("BTN_HyperStrike™_ZH.webp"),
      subGame: getImg("BTN_HyperStrike™_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7073_1001_1002",
    thumb: {
      img: getImg("BTN_HyperGold_ZH.webp"),
      subGame: getImg("BTN_HyperGold_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1849_1001_1002",
    thumb: {
      img: getImg("BTN_Huangdi_TheYellowEmperor_en.webp"),
      subGame: getImg("BTN_Huangdi_TheYellowEmperor_en.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1063_1001_1002",
    thumb: {
      img: getImg("BTN_HoundHotel_ZH.webp"),
      subGame: getImg("BTN_HoundHotel_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1910_1001_1002",
    thumb: {
      img: getImg("BTN_HollyJollyPenguins_ZH.webp"),
      subGame: getImg("BTN_HollyJollyPenguins_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1321_1001_1002",
    thumb: {
      img: getImg("BTN_Hitman1.webp"),
      subGame: getImg("BTN_Hitman1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6071_1001_1002",
    thumb: {
      img: getImg("BTN_HippieDays_ZH.webp"),
      subGame: getImg("BTN_HippieDays_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6074_1001_1002",
    thumb: {
      img: getImg("BTN_HipHopThai_ZH.webp"),
      subGame: getImg("BTN_HipHopThai_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1163_1001_1002",
    thumb: {
      img: getImg("BTN_HighSociety.webp"),
      subGame: getImg("BTN_HighSociety.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5531_1001_1002",
    thumb: {
      img: getImg("BTN_HiddenTreasuresOfRiverKwai_ZH.webp"),
      subGame: getImg("BTN_HiddenTreasuresOfRiverKwai_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7885_1001_1002",
    thumb: {
      img: getImg("BTN_DokiDokiParfait_ZH.webp"),
      subGame: getImg("BTN_DokiDokiParfait_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7854_1001_1002",
    thumb: {
      img: getImg("BTN_KodiakKingdom_ZH.webp"),
      subGame: getImg("BTN_KodiakKingdom_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7851_1001_1002",
    thumb: {
      img: getImg("BTN_HyperStar_ZH.webp"),
      subGame: getImg("BTN_HyperStar_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7855_1001_1002",
    thumb: {
      img: getImg("BTN_BigBoomRiches_ZH.webp"),
      subGame: getImg("BTN_BigBoomRiches_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7853_1001_1002",
    thumb: {
      img: getImg("BTN_ArcticEnchantress_ZH.webp"),
      subGame: getImg("BTN_ArcticEnchantress_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7852_1001_1002",
    thumb: {
      img: getImg("BTN_BasketballStarWilds_ZH.webp"),
      subGame: getImg("BTN_BasketballStarWilds_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7850_1001_1002",
    thumb: {
      img: getImg("BTN_AgentJaneBlondeMaxVolume_ZH.webp"),
      subGame: getImg("BTN_AgentJaneBlondeMaxVolume_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1287_1001_1002",
    thumb: {
      img: getImg("BTN_RugbyStar_ZH.webp"),
      subGame: getImg("BTN_RugbyStar_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1229_1001_1002",
    thumb: {
      img: getImg("BTN_BreakAway1.webp"),
      subGame: getImg("BTN_BreakAway1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5695_1001_1002",
    thumb: {
      img: getImg("BTN_BoomPirates_ZH.webp"),
      subGame: getImg("BTN_BoomPirates_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1881_1001_1002",
    thumb: {
      img: getImg("BTN_PollenParty_ZH.webp"),
      subGame: getImg("BTN_PollenParty_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1954_1001_1002",
    thumb: {
      img: getImg("BTN_PokeTheGuy_ZH.webp"),
      subGame: getImg("BTN_PokeTheGuy_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_4327_1001_1002",
    thumb: {
      img: getImg("BTN_PlayboyGoldJackpots_ZH.webp"),
      subGame: getImg("BTN_PlayboyGoldJackpots_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1946_1001_1002",
    thumb: {
      img: getImg("BTN_PlayboyGold_ZH.webp"),
      subGame: getImg("BTN_PlayboyGold_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6186_1001_1002",
    thumb: {
      img: getImg("BTN_Deadmau5.webp"),
      subGame: getImg("BTN_Deadmau5.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1084_1001_1002",
    thumb: {
      img: getImg("BTN_CoolWolf3.webp"),
      subGame: getImg("BTN_CoolWolf3.webp"),
    },
  },

  {
    subCode: "GTAG_MICROGAMING_1197_1001_1002",
    thumb: {
      img: getImg("BTN_Cashville1.webp"),
      subGame: getImg("BTN_Cashville1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1996_1001_1002",
    thumb: {
      img: getImg("BTN_CashOccino_ZH.webp"),
      subGame: getImg("BTN_CashOccino_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1366_1001_1002",
    thumb: {
      img: getImg("BTN_Cashapillar1.webp"),
      subGame: getImg("BTN_Cashapillar1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_2067_1001_1002",
    thumb: {
      img: getImg("BTN_CashofKingdoms_ZH.webp"),
      subGame: getImg("BTN_CashofKingdoms_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1393_1001_1002",
    thumb: {
      img: getImg("BTN_CashCrazy9.webp"),
      subGame: getImg("BTN_CashCrazy9.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6303_1001_1002",
    thumb: {
      img: getImg("BTN_CarnavalJackpot_ZH.webp"),
      subGame: getImg("BTN_CarnavalJackpot_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1117_1001_1002",
    thumb: {
      img: getImg("BTN_Carnaval2.webp"),
      subGame: getImg("BTN_Carnaval2.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1886_1001_1002",
    thumb: {
      img: getImg("BTN_CandyDreams_ZH.webp"),
      subGame: getImg("BTN_CandyDreams_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1204_1001_1002",
    thumb: {
      img: getImg("BTN_BustTheBank1.webp"),
      subGame: getImg("BTN_BustTheBank1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1173_1001_1002",
    thumb: {
      img: getImg("BTN_BushTelegraph1.webp"),
      subGame: getImg("BTN_BushTelegraph1.webp"),
    },
  },

  {
    subCode: "GTAG_MICROGAMING_4297_1001_1002",
    thumb: {
      img: getImg("BTN_BreakDaBankAgainRespin_ZH.webp"),
      subGame: getImg("BTN_BreakDaBankAgainRespin_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1097_1001_1002",
    thumb: {
      img: getImg("BTN_BreakDaBankAgain1.webp"),
      subGame: getImg("BTN_BreakDaBankAgain1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1159_1001_1002",
    thumb: {
      img: getImg("BTN_BasketballStar_ZH.webp"),
      subGame: getImg("BTN_BasketballStar_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6334_1001_1002",
    thumb: {
      img: getImg("BTN_8GoldenSkullsofHollyRogerMegaways™_ZH.webp"),
      subGame: getImg("BTN_8GoldenSkullsofHollyRogerMegaways™_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6188_1001_1002",
    thumb: {
      img: getImg("BTN_MakanMakan_ZH.webp"),
      subGame: getImg("BTN_MakanMakan_ZH.webp"),
    },
  },

  {
    subCode: "GTAG_MICROGAMING_6331_1001_1002",
    thumb: {
      img: getImg("BTN_SolarWilds_ZH.webp"),
      subGame: getImg("BTN_SolarWilds_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7075_1001_1002",
    thumb: {
      img: getImg("BTN_SoccerStriker_ZH.webp"),
      subGame: getImg("BTN_SoccerStriker_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1032_1001_1002",
    thumb: {
      img: getImg("BTN_somuchsushi.webp"),
      subGame: getImg("BTN_somuchsushi.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1374_1001_1002",
    thumb: {
      img: getImg("BTN_somuchcandy.webp"),
      subGame: getImg("BTN_somuchcandy.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8533_1001_1002",
    thumb: {
      img: getImg("BTN_GoldFactoryJackpotsMegaMoolah_ZH.webp"),
      subGame: getImg("BTN_GoldFactoryJackpotsMegaMoolah_ZH.webp"),
    },
  },

  {
    subCode: "GTAG_MICROGAMING_7683_1001_1002",
    thumb: {
      img: getImg("BTN_MegaMoolahTheWitchsMoon_ZH.webp"),
      subGame: getImg("BTN_MegaMoolahTheWitchsMoon_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8423_1001_1002",
    thumb: {
      img: getImg("BTN_MegaMoolahLuckyBells_ZH.webp"),
      subGame: getImg("BTN_MegaMoolahLuckyBells_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_8420_1001_1002",
    thumb: {
      img: getImg("BTN_CashNRichesWOWPOTMegaways_ZH.webp"),
      subGame: getImg("BTN_CashNRichesWOWPOTMegaways_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1900_1001_1002",
    thumb: {
      img: getImg("BTN_GnomeWood_ZH.webp"),
      subGame: getImg("BTN_GnomeWood_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1905_1001_1002",
    thumb: {
      img: getImg("BTN_MobyDick.webp"),
      subGame: getImg("BTN_MobyDick.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5383_1001_1002",
    thumb: {
      img: getImg("BTN_WheelofWishes_ZH.webp"),
      subGame: getImg("BTN_WheelofWishes_ZH.webp"),
    },
  },

  {
    subCode: "GTAG_MICROGAMING_6215_1001_1002",
    thumb: {
      img: getImg("BTN_SistersOfOzWowPot_ZH.webp"),
      subGame: getImg("BTN_SistersOfOzWowPot_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6431_1001_1002",
    thumb: {
      img: getImg("BTN_Sherlock&MoriartyWowpot_ZH.webp"),
      subGame: getImg("BTN_Sherlock&MoriartyWowpot_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6743_1001_1002",
    thumb: {
      img: getImg("BTN_QueenOfAlexandriaWOWPOT_ZH.webp"),
      subGame: getImg("BTN_QueenOfAlexandriaWOWPOT_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6746_1001_1002",
    thumb: {
      img: getImg("BTN_MegaMoolahGoddess_ZH.webp"),
      subGame: getImg("BTN_MegaMoolahGoddess_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1154_1001_1002",
    thumb: {
      img: getImg("BTN_MajorMillions.webp"),
      subGame: getImg("BTN_MajorMillions.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6299_1001_1002",
    thumb: {
      img: getImg("BTN_JuicyJokerMegaMoolah_ZH.webp"),
      subGame: getImg("BTN_JuicyJokerMegaMoolah_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6958_1001_1002",
    thumb: {
      img: getImg("BTN_ImmortalRomanceMegaMoolah_ZH.webp"),
      subGame: getImg("BTN_ImmortalRomanceMegaMoolah_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6068_1001_1002",
    thumb: {
      img: getImg("BTN_FortuniumGoldMegaMoolah_ZH.webp"),
      subGame: getImg("BTN_FortuniumGoldMegaMoolah_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1401_1001_1002",
    thumb: {
      img: getImg("BTN_CashSplash5Reel1.webp"),
      subGame: getImg("BTN_CashSplash5Reel1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5449_1001_1002",
    thumb: {
      img: getImg("BTN_AtlanteanTreasuresMegaMoolah_ZH.webp"),
      subGame: getImg("BTN_AtlanteanTreasuresMegaMoolah_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_6189_1001_1002",
    thumb: {
      img: getImg("BTN_AfricanLegends_ZH.webp"),
      subGame: getImg("BTN_AfricanLegends_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_5529_1001_1002",
    thumb: {
      img: getImg("BTN_AbsolootlyMadMegaMoolah_ZH.webp"),
      subGame: getImg("BTN_AbsolootlyMadMegaMoolah_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7077_1001_1002",
    thumb: {
      img: getImg("BTN_9BlazingDiamondsWowpot_ZH.webp"),
      subGame: getImg("BTN_9BlazingDiamondsWowpot_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_7528_1001_1002",
    thumb: {
      img: getImg("BTN_AncientFortunesPoseidonWOWPotMegaways_ZH.webp"),
      subGame: getImg("BTN_AncientFortunesPoseidonWOWPotMegaways_ZH.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1248_1001_1001",
    thumb: {
      img: getImg("BTN_USRoulette1.webp"),
      subGame: getImg("BTN_USRoulette1.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1165",
    thumb: {
      img: getImg("BTN_FrozenDiamonds.webp"),
      subGame: getImg("BTN_FrozenDiamonds.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1733",
    thumb: {
      img: getImg("BTN_BoogieMonsters.webp"),
      subGame: getImg("BTN_BoogieMonsters.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1263",
    thumb: {
      img: getImg("BTN_beachbabes.webp"),
      subGame: getImg("BTN_beachbabes.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1169",
    thumb: {
      img: getImg("BTN_thegrandjourney.webp"),
      subGame: getImg("BTN_thegrandjourney.webp"),
    },
  },
  {
    subCode: "GTAG_MICROGAMING_1931_1001_1001",
    thumb: {
      img: getImg("BTN_Baccarat.webp"),
      subGame: getImg("BTN_Baccarat.webp"),
    },
    //  "Playboy Baccarat Multi Player"
  },
  {
    subCode: "GTAG_MICROGAMING_1922_1001_1002",
    thumb: {
      img: getImg("BTN_Baccarat.webp"),
      subGame: getImg("BTN_Baccarat.webp"),
    },
    //  "Playboy Baccarat Single Player"
  },
  {
    subCode: "GTAG_MICROGAMING_1936_1001_1002",
    thumb: {
      img: getImg("BTN_PremierRoulette.webp"),
      subGame: getImg("BTN_PremierRoulette.webp"),
    },
    //  "Roulette Single Player"
  },
  {
    subCode: "GTAG_MICROGAMING_2049_1001_1002",
    thumb: {
      img: getImg("BTN_Baccarat.webp"),
      subGame: getImg("BTN_Baccarat.webp"),
    },
    //  "No Commission Baccarat Playboy"
  },
  {
    subCode: "GTAG_MICROGAMING_1921_1001_1002",
    thumb: {
      img: getImg("BTN_Baccarat.webp"),
      subGame: getImg("BTN_Baccarat.webp"),
    },
    //  "Baccarat Single Player"
  },
  {
    subCode: "GTAG_MICROGAMING_1930_1001_1001",
    thumb: {
      img: getImg("BTN_Baccarat.webp"),
      subGame: getImg("BTN_Baccarat.webp"),
    },
    //  "Baccarat Multi Player"
  },
  {
    subCode: "GTAG_MICROGAMING_2048_1001_1002",
    thumb: {
      img: getImg("BTN_Baccarat.webp"),
      subGame: getImg("BTN_Baccarat.webp"),
    },
    //  "No Commission Baccarat"
  },
];

export default {
  code: "GTAG_MICROGAMING",
  type: ["EGAMING"],
  play_method: "URL",
  subGames: [
    {
      subCode: "GTAG_MICROGAMING_8445_1001_1002",
      thumb: {
        img: getImg("BTN_CairoLink&Win.webp"),
        subGame: getImg("BTN_CairoLink&Win.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8288_1001_1002",
      thumb: {
        img: getImg("BTN_AztecFalls.webp"),
        subGame: getImg("BTN_AztecFalls.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7939_1001_1002",
      thumb: {
        img: getImg("BTN_BreakAwayShootout.webp"),
        subGame: getImg("BTN_BreakAwayShootout.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7935_1001_1002",
      thumb: {
        img: getImg("BTN_BusttheMansion.webp"),
        subGame: getImg("BTN_BusttheMansion.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8284_1001_1002",
      thumb: {
        img: getImg("BTN_CashNRichesMegaways.webp"),
        subGame: getImg("BTN_CashNRichesMegaways.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8532_1001_1002",
      thumb: {
        img: getImg("BTN_CatsoftheCaribbean.webp"),
        subGame: getImg("BTN_CatsoftheCaribbean.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8322_1001_1002",
      thumb: {
        img: getImg("BTN_CircusJugglersJackpots.webp"),
        subGame: getImg("BTN_CircusJugglersJackpots.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8323_1001_1002",
      thumb: {
        img: getImg("BTN_DíadelMariachiMegaways.webp"),
        subGame: getImg("BTN_DíadelMariachiMegaways.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8393_1001_1002",
      thumb: {
        img: getImg("BTN_DivineRichesHelios.webp"),
        subGame: getImg("BTN_DivineRichesHelios.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8422_1001_1002",
      thumb: {
        img: getImg("BTN_ArkofRa.webp"),
        subGame: getImg("BTN_ArkofRa.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8326_1001_1002",
      thumb: {
        img: getImg("BTN_AbraCatDabra.webp"),
        subGame: getImg("BTN_AbraCatDabra.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8389_1001_1002",
      thumb: {
        img: getImg("BTN_777Surge.webp"),
        subGame: getImg("BTN_777Surge.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8357_1001_1002",
      thumb: {
        img: getImg("BTN_BoltXUP.webp"),
        subGame: getImg("BTN_BoltXUP.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8483_1001_1002",
      thumb: {
        img: getImg("BTN_AmazonKingdom.webp"),
        subGame: getImg("BTN_AmazonKingdom.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8503_1001_1002",
      thumb: {
        img: getImg("BTN_6RubiesofTribute.webp"),
        subGame: getImg("BTN_6RubiesofTribute.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8286_1001_1002",
      thumb: {
        img: getImg("BTN_DungeonsandDiamonds.webp"),
        subGame: getImg("BTN_DungeonsandDiamonds.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8017_1001_1002",
      thumb: {
        img: getImg("BTN_Fishin'PotsOfGold.webp"),
        subGame: getImg("BTN_Fishin'PotsOfGold.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8446_1001_1002",
      thumb: {
        img: getImg("BTN_GoldenDragons.webp"),
        subGame: getImg("BTN_GoldenDragons.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7938_1001_1002",
      thumb: {
        img: getImg("BTN_HyperStrikeHyperSpins.webp"),
        subGame: getImg("BTN_HyperStrikeHyperSpins.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8502_1001_1002",
      thumb: {
        img: getImg("BTN_JadeShuriken.webp"),
        subGame: getImg("BTN_JadeShuriken.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8020_1001_1002",
      thumb: {
        img: getImg("BTN_KingsofCrystals.webp"),
        subGame: getImg("BTN_KingsofCrystals.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7936_1001_1002",
      thumb: {
        img: getImg("BTN_5StarKnockout.webp"),
        subGame: getImg("BTN_5StarKnockout.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8283_1001_1002",
      thumb: {
        img: getImg("BTN_25000Talons.webp"),
        subGame: getImg("BTN_25000Talons.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8484_1001_1002",
      thumb: {
        img: getImg("BTN_KitsuneAdventure.webp"),
        subGame: getImg("BTN_KitsuneAdventure.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8016_1001_1002",
      thumb: {
        img: getImg("BTN_OniHunterNightSakura.webp"),
        subGame: getImg("BTN_OniHunterNightSakura.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8534_1001_1002",
      thumb: {
        img: getImg("BTN_StarliteFruits.webp"),
        subGame: getImg("BTN_StarliteFruits.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8018_1001_1002",
      thumb: {
        img: getImg("BTN_MegaMoneyWheel.webp"),
        subGame: getImg("BTN_MegaMoneyWheel.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8019_1001_1002",
      thumb: {
        img: getImg("BTN_WheelofWinners.webp"),
        subGame: getImg("BTN_WheelofWinners.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8501_1001_1002",
      thumb: {
        img: getImg("BTN_TreasuresofKilauea.webp"),
        subGame: getImg("BTN_TreasuresofKilauea.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7937_1001_1002",
      thumb: {
        img: getImg("BTN_JurassicParkGold.webp"),
        subGame: getImg("BTN_JurassicParkGold.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8328_1001_1002",
      thumb: {
        img: getImg("BTN_LightningFortunes.webp"),
        subGame: getImg("BTN_LightningFortunes.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8390_1001_1002",
      thumb: {
        img: getImg("BTN_LuckyLeprechaunClusters.webp"),
        subGame: getImg("BTN_LuckyLeprechaunClusters.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8289_1001_1002",
      thumb: {
        img: getImg("BTN_MastersofValhalla.webp"),
        subGame: getImg("BTN_MastersofValhalla.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8324_1001_1002",
      thumb: {
        img: getImg("BTN_MatchDay.webp"),
        subGame: getImg("BTN_MatchDay.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8392_1001_1002",
      thumb: {
        img: getImg("BTN_MaskofAmun.webp"),
        subGame: getImg("BTN_MaskofAmun.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8285_1001_1002",
      thumb: {
        img: getImg("BTN_15Tridents.webp"),
        subGame: getImg("BTN_15Tridents.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8391_1001_1002",
      thumb: {
        img: getImg("BTN_ImmortalRomanceVideoBingo.webp"),
        subGame: getImg("BTN_ImmortalRomanceVideoBingo.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8421_1001_1002",
      thumb: {
        img: getImg("BTN_Pile'EmUp.webp"),
        subGame: getImg("BTN_Pile'EmUp.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8287_1001_1002",
      thumb: {
        img: getImg("BTN_TheGreatAlbini2.webp"),
        subGame: getImg("BTN_TheGreatAlbini2.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8424_1001_1002",
      thumb: {
        img: getImg("BTN_Timelines.webp"),
        subGame: getImg("BTN_Timelines.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8325_1001_1002",
      thumb: {
        img: getImg("BTN_WesternGold2.webp"),
        subGame: getImg("BTN_WesternGold2.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8327_1001_1002",
      thumb: {
        img: getImg("BTN_WildWildRomance.webp"),
        subGame: getImg("BTN_WildWildRomance.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8321_1001_1002",
      thumb: {
        img: getImg("BTN_WildfireWins.webp"),
        subGame: getImg("BTN_WildfireWins.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1100_1001_1002",
      thumb: {
        img: getImg("BTN_RRDiamondGlitz1.webp"),
        subGame: getImg("BTN_RRDiamondGlitz1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7681_1001_1002",
      thumb: {
        img: getImg("BTN_LuckyTwinsWilds.webp"),
        subGame: getImg("BTN_LuckyTwinsWilds.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2068_1001_1002",
      thumb: {
        img: getImg("BTN_RomanovRiches.webp"),
        subGame: getImg("BTN_RomanovRiches.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1359_1001_1002",
      thumb: {
        img: getImg("BTN_RivieraRiches1.webp"),
        subGame: getImg("BTN_RivieraRiches1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1009_1001_1002",
      thumb: {
        img: getImg("BTN_RRHearts&Tarts1.webp"),
        subGame: getImg("BTN_RRHearts&Tarts1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1782_1001_1002",
      thumb: {
        img: getImg("BTN_RhymingReelsGeorgiePorgie.webp"),
        subGame: getImg("BTN_RhymingReelsGeorgiePorgie.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1189_1001_1002",
      thumb: {
        img: getImg("BTN_RRExtreme1.webp"),
        subGame: getImg("BTN_RRExtreme1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1110_1001_1002",
      thumb: {
        img: getImg("BTN_RetroReels1.webp"),
        subGame: getImg("BTN_RetroReels1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4109_1001_1002",
      thumb: {
        img: getImg("BTN_RelicSeekers_ZH.webp"),
        subGame: getImg("BTN_RelicSeekers_ZH.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1293_1001_1002",
      thumb: {
        img: getImg("BTN_ReelThunder2.webp"),
        subGame: getImg("BTN_ReelThunder2.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2070_1001_1002",
      thumb: {
        img: getImg("BTN_ReelTalent.webp"),
        subGame: getImg("BTN_ReelTalent.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1157_1001_1002",
      thumb: {
        img: getImg("BTN_ReelStrike1.webp"),
        subGame: getImg("BTN_ReelStrike1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1294_1001_1002",
      thumb: {
        img: getImg("BTN_ReelSpinner_en.webp"),
        subGame: getImg("BTN_ReelSpinner_en.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6069_1001_1002",
      thumb: {
        img: getImg("BTN_ReelGemsDeluxe.webp"),
        subGame: getImg("BTN_ReelGemsDeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1207_1001_1002",
      thumb: {
        img: getImg("BTN_ReelGems1.webp"),
        subGame: getImg("BTN_ReelGems1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4289_1001_1002",
      thumb: {
        img: getImg("BTN_QueenOfTheCrystalRays.webp"),
        subGame: getImg("BTN_QueenOfTheCrystalRays.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6748_1001_1002",
      thumb: {
        img: getImg("BTN_QueenofAlexandria.webp"),
        subGame: getImg("BTN_QueenofAlexandria.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1126_1001_1002",
      thumb: {
        img: getImg("BTN_LuckyFirecracker.webp"),
        subGame: getImg("BTN_LuckyFirecracker.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4339_1001_1002",
      thumb: {
        img: getImg("BTN_LuckyBachelors.webp"),
        subGame: getImg("BTN_LuckyBachelors.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2066_1001_1002",
      thumb: {
        img: getImg("BTN_LuchaLegends.webp"),
        subGame: getImg("BTN_LuchaLegends.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1420_1001_1002",
      thumb: {
        img: getImg("BTN_LostVegas.webp"),
        subGame: getImg("BTN_LostVegas.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5365_1001_1002",
      thumb: {
        img: getImg("BTN_LongMuFortunes.webp"),
        subGame: getImg("BTN_LongMuFortunes.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1245_1001_1002",
      thumb: {
        img: getImg("BTN_Loaded1.webp"),
        subGame: getImg("BTN_Loaded1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1756_1001_1002",
      thumb: {
        img: getImg("BTN_LiquidGold.webp"),
        subGame: getImg("BTN_LiquidGold.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1049_1001_1002",
      thumb: {
        img: getImg("BTN_lionsPride.webp"),
        subGame: getImg("BTN_lionsPride.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1851_1001_1002",
      thumb: {
        img: getImg("BTN_LifeOfRiches_en.webp"),
        subGame: getImg("BTN_LifeOfRiches_en.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4295_1001_1002",
      thumb: {
        img: getImg("BTN_LegendoftheMoonLovers.webp"),
        subGame: getImg("BTN_LegendoftheMoonLovers.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7072_1001_1002",
      thumb: {
        img: getImg("BTN_LegacyofOz.webp"),
        subGame: getImg("BTN_LegacyofOz.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4110_1001_1002",
      thumb: {
        img: getImg("BTN_LaraCroftTemplesandTombs.webp"),
        subGame: getImg("BTN_LaraCroftTemplesandTombs.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1124_1001_1002",
      thumb: {
        img: getImg("BTN_LadyInRed2.webp"),
        subGame: getImg("BTN_LadyInRed2.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5487_1001_1002",
      thumb: {
        img: getImg("BTN_LadiesNite2TurnWild.webp"),
        subGame: getImg("BTN_LadiesNite2TurnWild.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1389_1001_1002",
      thumb: {
        img: getImg("BTN_LadiesNite5.webp"),
        subGame: getImg("BTN_LadiesNite5.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1286_1001_1002",
      thumb: {
        img: getImg("BTN_KittyCabana.webp"),
        subGame: getImg("BTN_KittyCabana.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1400_1001_1002",
      thumb: {
        img: getImg("BTN_KingsofCash1.webp"),
        subGame: getImg("BTN_KingsofCash1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1908_1001_1002",
      thumb: {
        img: getImg("BTN_KingTusk.webp"),
        subGame: getImg("BTN_KingTusk.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5477_1001_1002",
      thumb: {
        img: getImg("BTN_KingoftheRing.webp"),
        subGame: getImg("BTN_KingoftheRing.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6214_1001_1002",
      thumb: {
        img: getImg("BTN_GoldaurGuardians.webp"),
        subGame: getImg("BTN_GoldaurGuardians.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1267_1001_1002",
      thumb: {
        img: getImg("BTN_GoldFactory.webp"),
        subGame: getImg("BTN_GoldFactory.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6333_1001_1002",
      thumb: {
        img: getImg("BTN_GoldCollector.webp"),
        subGame: getImg("BTN_GoldCollector.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1313_1001_1002",
      thumb: {
        img: getImg("BTN_GirlswithGuns1.webp"),
        subGame: getImg("BTN_GirlswithGuns1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1945_1001_1002",
      thumb: {
        img: getImg("BTN_GemsOdyssey.webp"),
        subGame: getImg("BTN_GemsOdyssey.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6256_1001_1002",
      thumb: {
        img: getImg("BTN_Gems&Dragons.webp"),
        subGame: getImg("BTN_Gems&Dragons.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1878_1001_1002",
      thumb: {
        img: getImg("BTN_FruitVsCandy.webp"),
        subGame: getImg("BTN_FruitVsCandy.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1943_1001_1002",
      thumb: {
        img: getImg("BTN_FruitBlast.webp"),
        subGame: getImg("BTN_FruitBlast.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1993_1001_1002",
      thumb: {
        img: getImg("BTN_Fortunium.webp"),
        subGame: getImg("BTN_Fortunium.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2089_1001_1002",
      thumb: {
        img: getImg("BTN_BookieOfOdds.webp"),
        subGame: getImg("BTN_BookieOfOdds.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4329_1001_1002",
      thumb: {
        img: getImg("BTN_BookOfOzLockNSpin.webp"),
        subGame: getImg("BTN_BookOfOzLockNSpin.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2075_1001_1002",
      thumb: {
        img: getImg("BTN_BookOfOz.webp"),
        subGame: getImg("BTN_BookOfOz.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7745_1001_1002",
      thumb: {
        img: getImg("BTN_WWELegends.webp"),
        subGame: getImg("BTN_WWELegends.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7682_1001_1002",
      thumb: {
        img: getImg("BTN_JurassicWorldRaptorRiches_ZH.webp"),
        subGame: getImg("BTN_JurassicWorldRaptorRiches_ZH.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7746_1001_1002",
      thumb: {
        img: getImg("BTN_FortuneRush.webp"),
        subGame: getImg("BTN_FortuneRush.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7744_1001_1002",
      thumb: {
        img: getImg("BTN_DragonsBreath.webp"),
        subGame: getImg("BTN_DragonsBreath.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7680_1001_1002",
      thumb: {
        img: getImg("BTN_BreakDaBankAgainMEGAWAYS.webp"),
        subGame: getImg("BTN_BreakDaBankAgainMEGAWAYS.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7791_1001_1002",
      thumb: {
        img: getImg("BTN_LuckyClucks.webp"),
        subGame: getImg("BTN_LuckyClucks.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7743_1001_1002",
      thumb: {
        img: getImg("BTN_ChroniclesofOlympusXUP.webp"),
        subGame: getImg("BTN_ChroniclesofOlympusXUP.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7786_1001_1002",
      thumb: {
        img: getImg("BTN_SquealinRiches.webp"),
        subGame: getImg("BTN_SquealinRiches.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7789_1001_1002",
      thumb: {
        img: getImg("BTN_CatClans.webp"),
        subGame: getImg("BTN_CatClans.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7790_1001_1002",
      thumb: {
        img: getImg("BTN_BookOfMrsClaus.webp"),
        subGame: getImg("BTN_BookOfMrsClaus.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7716_1001_1002",
      thumb: {
        img: getImg("BTN_9MasksofFireHYPERSPINS.webp"),
        subGame: getImg("BTN_9MasksofFireHYPERSPINS.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7742_1001_1002",
      thumb: {
        img: getImg("BTN_CossacksTheWildHunt.webp"),
        subGame: getImg("BTN_CossacksTheWildHunt.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7787_1001_1002",
      thumb: {
        img: getImg("BTN_4DiamondBluesMegaways.webp"),
        subGame: getImg("BTN_4DiamondBluesMegaways.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1901_1001_1002",
      thumb: {
        img: getImg("BTN_TastyStreet.webp"),
        subGame: getImg("BTN_TastyStreet.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1847_1001_1002",
      thumb: {
        img: getImg("BTN_Tarzan_Button_en.webp"),
        subGame: getImg("BTN_Tarzan_Button_en.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1395_1001_1002",
      thumb: {
        img: getImg("BTN_TallyHo1.webp"),
        subGame: getImg("BTN_TallyHo1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1711_1001_1002",
      thumb: {
        img: getImg("BTN_SureWin.webp"),
        subGame: getImg("BTN_SureWin.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1289_1001_1002",
      thumb: {
        img: getImg("BTN_SupeItUp2.webp"),
        subGame: getImg("BTN_SupeItUp2.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1150_1001_1002",
      thumb: {
        img: getImg("BTN_SunTide_Button.webp"),
        subGame: getImg("BTN_SunTide_Button.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1127_1001_1002",
      thumb: {
        img: getImg("BTN_SunQuest3.webp"),
        subGame: getImg("BTN_SunQuest3.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1130_1001_1002",
      thumb: {
        img: getImg("BTN_summertime.webp"),
        subGame: getImg("BTN_summertime.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1784_1001_1002",
      thumb: {
        img: getImg("BTN_SummerHoliday.webp"),
        subGame: getImg("BTN_SummerHoliday.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1893_1001_1002",
      thumb: {
        img: getImg("BTN_SugarParade.webp"),
        subGame: getImg("BTN_SugarParade.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1170_1001_1002",
      thumb: {
        img: getImg("BTN_SterlingSilver3D1.webp"),
        subGame: getImg("BTN_SterlingSilver3D1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1320_1001_1002",
      thumb: {
        img: getImg("BTN_StashoftheTitans1.webp"),
        subGame: getImg("BTN_StashoftheTitans1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1167_1001_1002",
      thumb: {
        img: getImg("BTN_StarlightKiss.webp"),
        subGame: getImg("BTN_StarlightKiss.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1404_1001_1002",
      thumb: {
        img: getImg("BTN_StarDust.webp"),
        subGame: getImg("BTN_StarDust.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1002_1001_1002",
      thumb: {
        img: getImg("BTN_SpringBreak2.webp"),
        subGame: getImg("BTN_SpringBreak2.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1275_1001_1002",
      thumb: {
        img: getImg("BTN_RabbitintheHat.webp"),
        subGame: getImg("BTN_RabbitintheHat.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1312_1001_1002",
      thumb: {
        img: getImg("BTN_PurePlatinum1.webp"),
        subGame: getImg("BTN_PurePlatinum1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1045_1001_1002",
      thumb: {
        img: getImg("BTN_prettykitty_en.webp"),
        subGame: getImg("BTN_prettykitty_en.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5528_1001_1002",
      thumb: {
        img: getImg("BTN_PlayboyFortunes.webp"),
        subGame: getImg("BTN_PlayboyFortunes.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1188_1001_1002",
      thumb: {
        img: getImg("BTN_Playboy.webp"),
        subGame: getImg("BTN_Playboy.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1160_1001_1002",
      thumb: {
        img: getImg("BTN_Pistoleras.webp"),
        subGame: getImg("BTN_Pistoleras.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5144_1001_1002",
      thumb: {
        img: getImg("BTN_PingPongStar.webp"),
        subGame: getImg("BTN_PingPongStar.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5478_1001_1002",
      thumb: {
        img: getImg("BTN_PhoWin.webp"),
        subGame: getImg("BTN_PhoWin.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4298_1001_1002",
      thumb: {
        img: getImg("BTN_PetsGoWild.webp"),
        subGame: getImg("BTN_PetsGoWild.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1737_1001_1002",
      thumb: {
        img: getImg("BTN_PartyIsland.webp"),
        subGame: getImg("BTN_PartyIsland.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2073_1001_1002",
      thumb: {
        img: getImg("BTN_OurDays.webp"),
        subGame: getImg("BTN_OurDays.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6959_1001_1002",
      thumb: {
        img: getImg("BTN_OniHunterPlus.webp"),
        subGame: getImg("BTN_OniHunterPlus.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6328_1001_1002",
      thumb: {
        img: getImg("BTN_OniHunter.webp"),
        subGame: getImg("BTN_OniHunter.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1896_1001_1002",
      thumb: {
        img: getImg("BTN_OinkCountryLove.webp"),
        subGame: getImg("BTN_OinkCountryLove.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6956_1001_1002",
      thumb: {
        img: getImg("BTN_OdinsRiches.webp"),
        subGame: getImg("BTN_OdinsRiches.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6073_1001_1002",
      thumb: {
        img: getImg("BTN_NobleSky.webp"),
        subGame: getImg("BTN_NobleSky.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6217_1001_1002",
      thumb: {
        img: getImg("BTN_NeptunesRiches.webp"),
        subGame: getImg("BTN_NeptunesRiches.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1254_1001_1002",
      thumb: {
        img: getImg("BTN_MysticDreams1.webp"),
        subGame: getImg("BTN_MysticDreams1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1008_1001_1002",
      thumb: {
        img: getImg("BTN_Munchkins2.webp"),
        subGame: getImg("BTN_Munchkins2.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1903_1001_1002",
      thumb: {
        img: getImg("BTN_MonsterWheels.webp"),
        subGame: getImg("BTN_MonsterWheels.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5697_1001_1002",
      thumb: {
        img: getImg("BTN_MonsterBlast.webp"),
        subGame: getImg("BTN_MonsterBlast.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1308_1001_1002",
      thumb: {
        img: getImg("BTN_MermaidsMillions1.webp"),
        subGame: getImg("BTN_MermaidsMillions1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1942_1001_1002",
      thumb: {
        img: getImg("BTN_MegaMoneyRush.webp"),
        subGame: getImg("BTN_MegaMoneyRush.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1885_1001_1002",
      thumb: {
        img: getImg("BTN_MegaMoneyMultiplier.webp"),
        subGame: getImg("BTN_MegaMoneyMultiplier.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1343_1001_1002",
      thumb: {
        img: getImg("BTN_MayanPrincess1.webp"),
        subGame: getImg("BTN_MayanPrincess1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5479_1001_1002",
      thumb: {
        img: getImg("BTN_HiddenPalaceTreasures.webp"),
        subGame: getImg("BTN_HiddenPalaceTreasures.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1139_1001_1002",
      thumb: {
        img: getImg("BTN_Harveys1.webp"),
        subGame: getImg("BTN_Harveys1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2101_1001_1002",
      thumb: {
        img: getImg("BTN_HappyMonsterClaw.webp"),
        subGame: getImg("BTN_HappyMonsterClaw.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1072_1001_1002",
      thumb: {
        img: getImg("BTN_HappyHolidays.webp"),
        subGame: getImg("BTN_HappyHolidays.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1047_1001_1002",
      thumb: {
        img: getImg("BTN_Halloweenies1.webp"),
        subGame: getImg("BTN_Halloweenies1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1216_1001_1002",
      thumb: {
        img: getImg("BTN_GopherGold2.webp"),
        subGame: getImg("BTN_GopherGold2.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6301_1001_1002",
      thumb: {
        img: getImg("BTN_GoldenStallion.webp"),
        subGame: getImg("BTN_GoldenStallion.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1190_1001_1002",
      thumb: {
        img: getImg("BTN_GoldenPrincess.webp"),
        subGame: getImg("BTN_GoldenPrincess.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1041_1001_1002",
      thumb: {
        img: getImg("BTN_GoldenEra.webp"),
        subGame: getImg("BTN_GoldenEra.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1888_1001_1002",
      thumb: {
        img: getImg("BTN_FortuneGirl.webp"),
        subGame: getImg("BTN_FortuneGirl.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6298_1001_1002",
      thumb: {
        img: getImg("BTN_ForgottenIslandMegaways.webp"),
        subGame: getImg("BTN_ForgottenIslandMegaways.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1887_1001_1002",
      thumb: {
        img: getImg("BTN_ForbiddenThrone.webp"),
        subGame: getImg("BTN_ForbiddenThrone.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5488_1001_1002",
      thumb: {
        img: getImg("BTN_FootballStarDeluxe.webp"),
        subGame: getImg("BTN_FootballStarDeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1186_1001_1002",
      thumb: {
        img: getImg("BTN_footballstar1.webp"),
        subGame: getImg("BTN_footballstar1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6305_1001_1002",
      thumb: {
        img: getImg("BTN_FlowerFortunesMegaways.webp"),
        subGame: getImg("BTN_FlowerFortunesMegaways.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1113_1001_1002",
      thumb: {
        img: getImg("BTN_FishParty.webp"),
        subGame: getImg("BTN_FishParty.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6330_1001_1002",
      thumb: {
        img: getImg("BTN_FireForge.webp"),
        subGame: getImg("BTN_FireForge.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2060_1001_1002",
      thumb: {
        img: getImg("BTN_ExoticCats.webp"),
        subGame: getImg("BTN_ExoticCats.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6253_1001_1002",
      thumb: {
        img: getImg("BTN_EmperoroftheSeaDeluxe.webp"),
        subGame: getImg("BTN_EmperoroftheSeaDeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1882_1001_1002",
      thumb: {
        img: getImg("BTN_EmperorOfTheSea.webp"),
        subGame: getImg("BTN_EmperorOfTheSea.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1895_1001_1002",
      thumb: {
        img: getImg("BTN_EmotiCoins.webp"),
        subGame: getImg("BTN_EmotiCoins.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6627_1001_1002",
      thumb: {
        img: getImg("BTN_EmeraldGold.webp"),
        subGame: getImg("BTN_EmeraldGold.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7074_1001_1002",
      thumb: {
        img: getImg("BTN_ElvenGold.webp"),
        subGame: getImg("BTN_ElvenGold.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6417_1001_1002",
      thumb: {
        img: getImg("BTN_EgyptianTombs.webp"),
        subGame: getImg("BTN_EgyptianTombs.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1236_1001_1002",
      thumb: {
        img: getImg("BTN_EaglesWings1.webp"),
        subGame: getImg("BTN_EaglesWings1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1948_1001_1002",
      thumb: {
        img: getImg("BTN_DreamDate.webp"),
        subGame: getImg("BTN_DreamDate.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1424_1001_1002",
      thumb: {
        img: getImg("BTN_Dragonz.webp"),
        subGame: getImg("BTN_Dragonz.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4257_1001_1002",
      thumb: {
        img: getImg("BTN_DragonShard.webp"),
        subGame: getImg("BTN_DragonShard.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1037_1001_1002",
      thumb: {
        img: getImg("BTN_DragonDance.webp"),
        subGame: getImg("BTN_DragonDance.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1102_1001_1002",
      thumb: {
        img: getImg("BTN_DoubleWammy1.webp"),
        subGame: getImg("BTN_DoubleWammy1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1309_1001_1002",
      thumb: {
        img: getImg("BTN_DolphinQuest.webp"),
        subGame: getImg("BTN_DolphinQuest.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2065_1001_1002",
      thumb: {
        img: getImg("BTN_DolphinCoast.webp"),
        subGame: getImg("BTN_DolphinCoast.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6953_1001_1002",
      thumb: {
        img: getImg("BTN_DivineDiamonds.webp"),
        subGame: getImg("BTN_DivineDiamonds.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6220_1001_1002",
      thumb: {
        img: getImg("BTN_DiamondKingJackpots.webp"),
        subGame: getImg("BTN_DiamondKingJackpots.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1949_1001_1002",
      thumb: {
        img: getImg("BTN_DiamondEmpire.webp"),
        subGame: getImg("BTN_DiamondEmpire.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2047_1001_1002",
      thumb: {
        img: getImg("BTN_DecoDiamonds.webp"),
        subGame: getImg("BTN_DecoDiamonds.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1234_1001_1002",
      thumb: {
        img: getImg("BTN_DeckTheHalls1.webp"),
        subGame: getImg("BTN_DeckTheHalls1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5532_1001_1002",
      thumb: {
        img: getImg("BTN_CuoiAndTheBanyanTree.webp"),
        subGame: getImg("BTN_CuoiAndTheBanyanTree.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7076_1001_1002",
      thumb: {
        img: getImg("BTN_CricketStarScratch.webp"),
        subGame: getImg("BTN_CricketStarScratch.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1075_1001_1002",
      thumb: {
        img: getImg("BTN_CricketStar.webp"),
        subGame: getImg("BTN_CricketStar.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1202_1001_1002",
      thumb: {
        img: getImg("BTN_CrazyChameleons1.webp"),
        subGame: getImg("BTN_CrazyChameleons1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1327_1001_1002",
      thumb: {
        img: getImg("BTN_CouchPotato2.webp"),
        subGame: getImg("BTN_CouchPotato2.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1884_1001_1002",
      thumb: {
        img: getImg("BTN_CoolBuck_5Reel.webp"),
        subGame: getImg("BTN_CoolBuck_5Reel.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1879_1001_1002",
      thumb: {
        img: getImg("BTN_Classic243.webp"),
        subGame: getImg("BTN_Classic243.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6302_1001_1002",
      thumb: {
        img: getImg("BTN_ChicagoGold.webp"),
        subGame: getImg("BTN_ChicagoGold.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1023_1001_1002",
      thumb: {
        img: getImg("BTN_BreakDaBank1.webp"),
        subGame: getImg("BTN_BreakDaBank1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6251_1001_1002",
      thumb: {
        img: getImg("BTN_BreakAwayUltra.webp"),
        subGame: getImg("BTN_BreakAwayUltra.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5325_1001_1002",
      thumb: {
        img: getImg("BTN_BreakAwayLuckyWilds.webp"),
        subGame: getImg("BTN_BreakAwayLuckyWilds.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2074_1001_1002",
      thumb: {
        img: getImg("BTN_BreakAwayDeluxe.webp"),
        subGame: getImg("BTN_BreakAwayDeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6626_1001_1002",
      thumb: {
        img: getImg("BTN_BookOFKingArthur.webp"),
        subGame: getImg("BTN_BookOFKingArthur.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5491_1001_1002",
      thumb: {
        img: getImg("BTN_BoatofFortune.webp"),
        subGame: getImg("BTN_BoatofFortune.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6957_1001_1002",
      thumb: {
        img: getImg("BTN_BlazingMammoth.webp"),
        subGame: getImg("BTN_BlazingMammoth.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1290_1001_1002",
      thumb: {
        img: getImg("BTN_BikiniParty.webp"),
        subGame: getImg("BTN_BikiniParty.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1133_1001_1002",
      thumb: {
        img: getImg("BTN_BigTop1.webp"),
        subGame: getImg("BTN_BigTop1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1399_1001_1002",
      thumb: {
        img: getImg("BTN_BigKahuna1.webp"),
        subGame: getImg("BTN_BigKahuna1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1890_1001_1002",
      thumb: {
        img: getImg("BTN_BeautifulBones.webp"),
        subGame: getImg("BTN_BeautifulBones.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1995_1001_1002",
      thumb: {
        img: getImg("BTN_BattleRoyale.webp"),
        subGame: getImg("BTN_BattleRoyale.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6184_1001_1002",
      thumb: {
        img: getImg("BTN_BasketballStarOnFire.webp"),
        subGame: getImg("BTN_BasketballStarOnFire.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4256_1001_1002",
      thumb: {
        img: getImg("BTN_BasketballStarDeluxe.webp"),
        subGame: getImg("BTN_BasketballStarDeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1257_1001_1002",
      thumb: {
        img: getImg("BTN_barsandstripes.webp"),
        subGame: getImg("BTN_barsandstripes.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1788_1001_1002",
      thumb: {
        img: getImg("BTN_BarBarBlackSheep5Reel_ZH.webp"),
        subGame: getImg("BTN_BarBarBlackSheep5Reel_ZH.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4296_1001_1002",
      thumb: {
        img: getImg("BTN_BananaOdyssey.webp"),
        subGame: getImg("BTN_BananaOdyssey.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2061_1001_1002",
      thumb: {
        img: getImg("BTN_BadmintonHero.webp"),
        subGame: getImg("BTN_BadmintonHero.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1013_1001_1002",
      thumb: {
        img: getImg("BTN_AvalonHD.webp"),
        subGame: getImg("BTN_AvalonHD.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4328_1001_1002",
      thumb: {
        img: getImg("BTN_AuroraWilds.webp"),
        subGame: getImg("BTN_AuroraWilds.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6257_1001_1002",
      thumb: {
        img: getImg("BTN_Augustus.webp"),
        subGame: getImg("BTN_Augustus.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6952_1001_1002",
      thumb: {
        img: getImg("BTN_AtlantisRising.webp"),
        subGame: getImg("BTN_AtlantisRising.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5651_1001_1002",
      thumb: {
        img: getImg("BTN_AstroLegendsLyraandErion.webp"),
        subGame: getImg("BTN_AstroLegendsLyraandErion.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6254_1001_1002",
      thumb: {
        img: getImg("BTN_AssassinMoon.webp"),
        subGame: getImg("BTN_AssassinMoon.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1384_1001_1002",
      thumb: {
        img: getImg("BTN_AsianBeauty1.webp"),
        subGame: getImg("BTN_AsianBeauty1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1021_1001_1002",
      thumb: {
        img: getImg("BTN_Ariana.webp"),
        subGame: getImg("BTN_Ariana.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6372_1001_1002",
      thumb: {
        img: getImg("BTN_AndarBaharRoyale.webp"),
        subGame: getImg("BTN_AndarBaharRoyale.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4111_1001_1002",
      thumb: {
        img: getImg("BTN_AncientFortunesZeus.webp"),
        subGame: getImg("BTN_AncientFortunesZeus.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6747_1001_1002",
      thumb: {
        img: getImg("BTN_AncientFortunesPoseidonMegaways.webp"),
        subGame: getImg("BTN_AncientFortunesPoseidonMegaways.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6332_1001_1002",
      thumb: {
        img: getImg("BTN_AmazingLinkZeus.webp"),
        subGame: getImg("BTN_AmazingLinkZeus.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6219_1001_1002",
      thumb: {
        img: getImg("BTN_AlchemyFortunes.webp"),
        subGame: getImg("BTN_AlchemyFortunes.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5490_1001_1002",
      thumb: {
        img: getImg("BTN_AlchemyBlast.webp"),
        subGame: getImg("BTN_AlchemyBlast.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5926_1001_1002",
      thumb: {
        img: getImg("BTN_AlchemistStone.webp"),
        subGame: getImg("BTN_AlchemistStone.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1004_1001_1002",
      thumb: {
        img: getImg("BTN_AlaskanFishing1.webp"),
        subGame: getImg("BTN_AlaskanFishing1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2088_1001_1002",
      thumb: {
        img: getImg("BTN_AgentJaneBlondeReturns.webp"),
        subGame: getImg("BTN_AgentJaneBlondeReturns.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1155_1001_1002",
      thumb: {
        img: getImg("BTN_AgentJaneBlonde7.webp"),
        subGame: getImg("BTN_AgentJaneBlonde7.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1246_1001_1002",
      thumb: {
        img: getImg("BTN_AgeofDiscovery3.webp"),
        subGame: getImg("BTN_AgeofDiscovery3.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5618_1001_1002",
      thumb: {
        img: getImg("BTN_AgeofConquest.webp"),
        subGame: getImg("BTN_AgeofConquest.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6304_1001_1002",
      thumb: {
        img: getImg("BTN_AdventuresOfDoubloonIsland.webp"),
        subGame: getImg("BTN_AdventuresOfDoubloonIsland.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1010_1001_1002",
      thumb: {
        img: getImg("BTN_AdventurePalaceHD.webp"),
        subGame: getImg("BTN_AdventurePalaceHD.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2076_1001_1002",
      thumb: {
        img: getImg("BTN_ActionOpsSnow&Sable.webp"),
        subGame: getImg("BTN_ActionOpsSnow&Sable.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6218_1001_1002",
      thumb: {
        img: getImg("BTN_ATaleofElves.webp"),
        subGame: getImg("BTN_ATaleofElves.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5382_1001_1002",
      thumb: {
        img: getImg("BTN_ADarkMatter.webp"),
        subGame: getImg("BTN_ADarkMatter.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5619_1001_1002",
      thumb: {
        img: getImg("BTN_9PotsofGold.webp"),
        subGame: getImg("BTN_9PotsofGold.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5363_1001_1002",
      thumb: {
        img: getImg("BTN_9MasksOfFire.webp"),
        subGame: getImg("BTN_9MasksOfFire.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6399_1001_1002",
      thumb: {
        img: getImg("BTN_9BlazingDiamonds.webp"),
        subGame: getImg("BTN_9BlazingDiamonds.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6221_1001_1002",
      thumb: {
        img: getImg("BTN_777RoyalWheel.webp"),
        subGame: getImg("BTN_777RoyalWheel.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1415_1001_1002",
      thumb: {
        img: getImg("BTN_DeucesWildPowerPoker1.webp"),
        subGame: getImg("BTN_DeucesWildPowerPoker1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6329_1001_1002",
      thumb: {
        img: getImg("BTN_MauiMischief™.webp"),
        subGame: getImg("BTN_MauiMischief™.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6300_1001_1002",
      thumb: {
        img: getImg("BTN_777MegaDeluxe.webp"),
        subGame: getImg("BTN_777MegaDeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1035_1001_1002",
      thumb: {
        img: getImg("BTN_5ReelDrive1.webp"),
        subGame: getImg("BTN_5ReelDrive1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1897_1001_1002",
      thumb: {
        img: getImg("BTN_108HeroesMF.webp"),
        subGame: getImg("BTN_108HeroesMF.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1302_1001_1002",
      thumb: {
        img: getImg("BTN_108Heroes.webp"),
        subGame: getImg("BTN_108Heroes.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6742_1001_1002",
      thumb: {
        img: getImg("BTN_10000Wishes.webp"),
        subGame: getImg("BTN_10000Wishes.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7529_1001_1002",
      thumb: {
        img: getImg("BTN_AmazingLinkApollo.webp"),
        subGame: getImg("BTN_AmazingLinkApollo.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7527_1001_1002",
      thumb: {
        img: getImg("BTN_AfricaXUP.webp"),
        subGame: getImg("BTN_AfricaXUP.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4288_1001_1002",
      thumb: {
        img: getImg("BTN_MagicOfSahara.webp"),
        subGame: getImg("BTN_MagicOfSahara.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1314_1001_1002",
      thumb: {
        img: getImg("BTN_MadHatters1.webp"),
        subGame: getImg("BTN_MadHatters1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1273_1001_1002",
      thumb: {
        img: getImg("BTN_LuckyZodiac.webp"),
        subGame: getImg("BTN_LuckyZodiac.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5142_1001_1002",
      thumb: {
        img: getImg("BTN_LuckyTwinsJackpot.webp"),
        subGame: getImg("BTN_LuckyTwinsJackpot.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2085_1001_1002",
      thumb: {
        img: getImg("BTN_ZombieHoard.webp"),
        subGame: getImg("BTN_ZombieHoard.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5705_1001_1002",
      thumb: {
        img: getImg("BTN_WPForest Party.webp"),
        subGame: getImg("BTN_WPForest Party.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1345_1001_1002",
      thumb: {
        img: getImg("BTN_WinSumDimSum_en.webp"),
        subGame: getImg("BTN_WinSumDimSum_en.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1992_1001_1002",
      thumb: {
        img: getImg("BTN_WildScarabs.webp"),
        subGame: getImg("BTN_WildScarabs.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1164_1001_1002",
      thumb: {
        img: getImg("BTN_WildOrient_Button.webp"),
        subGame: getImg("BTN_WildOrient_Button.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1152_1001_1002",
      thumb: {
        img: getImg("BTN_WildCatch.webp"),
        subGame: getImg("BTN_WildCatch.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2064_1001_1002",
      thumb: {
        img: getImg("BTN_WickedTalesDarkRed.webp"),
        subGame: getImg("BTN_WickedTalesDarkRed.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1171_1001_1002",
      thumb: {
        img: getImg("BTN_WhataHoot3.webp"),
        subGame: getImg("BTN_WhataHoot3.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6072_1001_1002",
      thumb: {
        img: getImg("BTN_WesternGold.webp"),
        subGame: getImg("BTN_WesternGold.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6187_1001_1002",
      thumb: {
        img: getImg("BTN_WantedOutlaws.webp"),
        subGame: getImg("BTN_WantedOutlaws.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1911_1001_1002",
      thumb: {
        img: getImg("BTN_WackyPanda.webp"),
        subGame: getImg("BTN_WackyPanda.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1241_1001_1002",
      thumb: {
        img: getImg("BTN_voila_2.webp"),
        subGame: getImg("BTN_voila_2.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1306_1001_1002",
      thumb: {
        img: getImg("BTN_VinylCountdown4.webp"),
        subGame: getImg("BTN_VinylCountdown4.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6070_1001_1002",
      thumb: {
        img: getImg("BTN_LuckyTwinsCatcher.webp"),
        subGame: getImg("BTN_LuckyTwinsCatcher.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4286_1001_1002",
      thumb: {
        img: getImg("BTN_TreasuresOfLionCity.webp"),
        subGame: getImg("BTN_TreasuresOfLionCity.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1283_1001_1002",
      thumb: {
        img: getImg("BTN_luckyTwins.webp"),
        subGame: getImg("BTN_luckyTwins.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5698_1001_1002",
      thumb: {
        img: getImg("BTN_LuckyThaiLanterns.webp"),
        subGame: getImg("BTN_LuckyThaiLanterns.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5483_1001_1002",
      thumb: {
        img: getImg("BTN_LuckySilat.webp"),
        subGame: getImg("BTN_LuckySilat.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5620_1001_1002",
      thumb: {
        img: getImg("BTN_LuckyRichesHyperspins.webp"),
        subGame: getImg("BTN_LuckyRichesHyperspins.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1944_1001_1002",
      thumb: {
        img: getImg("BTN_LuckyLittleGods.webp"),
        subGame: getImg("BTN_LuckyLittleGods.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1212_1001_1002",
      thumb: {
        img: getImg("BTN_LuckyLeprechaun.webp"),
        subGame: getImg("BTN_LuckyLeprechaun.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1020_1001_1002",
      thumb: {
        img: getImg("BTN_TreasurePalace.webp"),
        subGame: getImg("BTN_TreasurePalace.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5143_1001_1002",
      thumb: {
        img: getImg("BTN_TreasureDash.webp"),
        subGame: getImg("BTN_TreasureDash.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1060_1001_1002",
      thumb: {
        img: getImg("BTN_LuckyKoi.webp"),
        subGame: getImg("BTN_LuckyKoi.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1383_1001_1002",
      thumb: {
        img: getImg("BTN_TombRaiderSotS1.webp"),
        subGame: getImg("BTN_TombRaiderSotS1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1122_1001_1002",
      thumb: {
        img: getImg("BTN_TombRaider2.webp"),
        subGame: getImg("BTN_TombRaider2.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1385_1001_1002",
      thumb: {
        img: getImg("BTN_TitansOfTheSunTheia.webp"),
        subGame: getImg("BTN_TitansOfTheSunTheia.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1208_1001_1002",
      thumb: {
        img: getImg("BTN_TitansOfTheSunHyperion.webp"),
        subGame: getImg("BTN_TitansOfTheSunHyperion.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4290_1001_1002",
      thumb: {
        img: getImg("BTN_TikiVikings™.webp"),
        subGame: getImg("BTN_TikiVikings™.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6216_1001_1002",
      thumb: {
        img: getImg("BTN_TikiReward.webp"),
        subGame: getImg("BTN_TikiReward.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1232_1001_1002",
      thumb: {
        img: getImg("BTN_tigersEye.webp"),
        subGame: getImg("BTN_tigersEye.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6988_1001_1002",
      thumb: {
        img: getImg("BTN_ThunderstruckWildLightning.webp"),
        subGame: getImg("BTN_ThunderstruckWildLightning.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1330_1001_1002",
      thumb: {
        img: getImg("BTN_ThunderstruckTwo1.webp"),
        subGame: getImg("BTN_ThunderstruckTwo1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1028_1001_1002",
      thumb: {
        img: getImg("BTN_Thunderstruck1.webp"),
        subGame: getImg("BTN_Thunderstruck1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1386_1001_1002",
      thumb: {
        img: getImg("BTN_TwistedCircus.webp"),
        subGame: getImg("BTN_TwistedCircus.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1398_1001_1002",
      thumb: {
        img: getImg("BTN_RatPack1.webp"),
        subGame: getImg("BTN_RatPack1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1906_1001_1002",
      thumb: {
        img: getImg("BTN_ThePhantomOfTheOpera.webp"),
        subGame: getImg("BTN_ThePhantomOfTheOpera.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5448_1001_1002",
      thumb: {
        img: getImg("BTN_TheIncredibleBalloonMachine.webp"),
        subGame: getImg("BTN_TheIncredibleBalloonMachine.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5530_1001_1002",
      thumb: {
        img: getImg("BTN_TheImmortalSumo.webp"),
        subGame: getImg("BTN_TheImmortalSumo.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1883_1001_1002",
      thumb: {
        img: getImg("BTN_TheHeatIsOn.webp"),
        subGame: getImg("BTN_TheHeatIsOn.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2087_1001_1002",
      thumb: {
        img: getImg("BTN_TheGreatAlbini.webp"),
        subGame: getImg("BTN_TheGreatAlbini.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5699_1001_1002",
      thumb: {
        img: getImg("BTN_TheGoldenMaskDance.webp"),
        subGame: getImg("BTN_TheGoldenMaskDance.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6075_1001_1002",
      thumb: {
        img: getImg("BTN_TheGoldSwallow.webp"),
        subGame: getImg("BTN_TheGoldSwallow.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1200_1001_1002",
      thumb: {
        img: getImg("BTN_TheFinerReelsOfLife.webp"),
        subGame: getImg("BTN_TheFinerReelsOfLife.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6185_1001_1002",
      thumb: {
        img: getImg("BTN_TarzanandtheJewelsofOpar.webp"),
        subGame: getImg("BTN_TarzanandtheJewelsofOpar.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5480_1001_1002",
      thumb: {
        img: getImg("BTN_SongkranParty.webp"),
        subGame: getImg("BTN_SongkranParty.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1001_1001_1002",
      thumb: {
        img: getImg("BTN_somanymonsters.webp"),
        subGame: getImg("BTN_somanymonsters.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1892_1001_1002",
      thumb: {
        img: getImg("BTN_SixAcrobats.webp"),
        subGame: getImg("BTN_SixAcrobats.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5925_1001_1002",
      thumb: {
        img: getImg("BTN_SistersofOzJackpots.webp"),
        subGame: getImg("BTN_SistersofOzJackpots.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6255_1001_1002",
      thumb: {
        img: getImg("BTN_SilverbackMultiplierMountain.webp"),
        subGame: getImg("BTN_SilverbackMultiplierMountain.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6954_1001_1002",
      thumb: {
        img: getImg("BTN_SilverSeas.webp"),
        subGame: getImg("BTN_SilverSeas.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4112_1001_1002",
      thumb: {
        img: getImg("BTN_SilverLioness4x.webp"),
        subGame: getImg("BTN_SilverLioness4x.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1062_1001_1002",
      thumb: {
        img: getImg("BTN_SilverFang1.webp"),
        subGame: getImg("BTN_SilverFang1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2077_1001_1002",
      thumb: {
        img: getImg("BTN_ShowdownSaloon.webp"),
        subGame: getImg("BTN_ShowdownSaloon.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2086_1001_1002",
      thumb: {
        img: getImg("BTN_ShogunofTime.webp"),
        subGame: getImg("BTN_ShogunofTime.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4287_1001_1002",
      thumb: {
        img: getImg("BTN_SherlockofLondon.webp"),
        subGame: getImg("BTN_SherlockofLondon.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1421_1001_1002",
      thumb: {
        img: getImg("BTN_ShanghaiBeauty.webp"),
        subGame: getImg("BTN_ShanghaiBeauty.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6252_1001_1002",
      thumb: {
        img: getImg("BTN_ShamrockHolmes.webp"),
        subGame: getImg("BTN_ShamrockHolmes.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6628_1001_1002",
      thumb: {
        img: getImg("BTN_SerengetiGold.webp"),
        subGame: getImg("BTN_SerengetiGold.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5486_1001_1002",
      thumb: {
        img: getImg("BTN_SecretsofSengoku.webp"),
        subGame: getImg("BTN_SecretsofSengoku.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1877_1001_1002",
      thumb: {
        img: getImg("BTN_SecretRomance_1_ZH.webp"),
        subGame: getImg("BTN_SecretRomance_1_ZH.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1325_1001_1002",
      thumb: {
        img: getImg("BTN_SecretAdmirer1.webp"),
        subGame: getImg("BTN_SecretAdmirer1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1030_1001_1002",
      thumb: {
        img: getImg("BTN_Scrooge1.webp"),
        subGame: getImg("BTN_Scrooge1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1095_1001_1002",
      thumb: {
        img: getImg("BTN_SantasWildRide1.webp"),
        subGame: getImg("BTN_SantasWildRide1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1785_1001_1002",
      thumb: {
        img: getImg("BTN_SantaPaws.webp"),
        subGame: getImg("BTN_SantaPaws.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5447_1001_1002",
      thumb: {
        img: getImg("BTN_RugbyStarDeluxe.webp"),
        subGame: getImg("BTN_RugbyStarDeluxe.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1151_1001_1002",
      thumb: {
        img: getImg("BTN_Kathmandu1.webp"),
        subGame: getImg("BTN_Kathmandu1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1053_1001_1002",
      thumb: {
        img: getImg("BTN_KaraokeParty.webp"),
        subGame: getImg("BTN_KaraokeParty.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1891_1001_1002",
      thumb: {
        img: getImg("BTN_JurassicWorld.webp"),
        subGame: getImg("BTN_JurassicWorld.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1244_1001_1002",
      thumb: {
        img: getImg("BTN_JungleJim.webp"),
        subGame: getImg("BTN_JungleJim.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4340_1001_1002",
      thumb: {
        img: getImg("BTN_JungleJimandtheLostSphinx.webp"),
        subGame: getImg("BTN_JungleJimandtheLostSphinx.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6955_1001_1002",
      thumb: {
        img: getImg("BTN_JoyfulJokerMegaways.webp"),
        subGame: getImg("BTN_JoyfulJokerMegaways.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1418_1001_1002",
      thumb: {
        img: getImg("BTN_JokerPoker1.webp"),
        subGame: getImg("BTN_JokerPoker1.webp"),
      },
    },

    {
      subCode: "GTAG_MICROGAMING_6258_1001_1002",
      thumb: {
        img: getImg("BTN_Ingotsof CaiShen.webp"),
        subGame: getImg("BTN_Ingotsof CaiShen.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5489_1001_1002",
      thumb: {
        img: getImg("BTN_IncanAdventure.webp"),
        subGame: getImg("BTN_IncanAdventure.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1103_1001_1002",
      thumb: {
        img: getImg("BTN_ImmortalRomance1.webp"),
        subGame: getImg("BTN_ImmortalRomance1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6327_1001_1002",
      thumb: {
        img: getImg("BTN_HyperStrike™.webp"),
        subGame: getImg("BTN_HyperStrike™.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7073_1001_1002",
      thumb: {
        img: getImg("BTN_HyperGold.webp"),
        subGame: getImg("BTN_HyperGold.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1849_1001_1002",
      thumb: {
        img: getImg("BTN_Huangdi_TheYellowEmperor_en.webp"),
        subGame: getImg("BTN_Huangdi_TheYellowEmperor_en.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1063_1001_1002",
      thumb: {
        img: getImg("BTN_HoundHotel.webp"),
        subGame: getImg("BTN_HoundHotel.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1910_1001_1002",
      thumb: {
        img: getImg("BTN_HollyJollyPenguins.webp"),
        subGame: getImg("BTN_HollyJollyPenguins.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1321_1001_1002",
      thumb: {
        img: getImg("BTN_Hitman1.webp"),
        subGame: getImg("BTN_Hitman1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6071_1001_1002",
      thumb: {
        img: getImg("BTN_HippieDays.webp"),
        subGame: getImg("BTN_HippieDays.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6074_1001_1002",
      thumb: {
        img: getImg("BTN_HipHopThai.webp"),
        subGame: getImg("BTN_HipHopThai.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1163_1001_1002",
      thumb: {
        img: getImg("BTN_HighSociety.webp"),
        subGame: getImg("BTN_HighSociety.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5531_1001_1002",
      thumb: {
        img: getImg("BTN_HiddenTreasuresOfRiverKwai.webp"),
        subGame: getImg("BTN_HiddenTreasuresOfRiverKwai.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7885_1001_1002",
      thumb: {
        img: getImg("BTN_DokiDokiParfait.webp"),
        subGame: getImg("BTN_DokiDokiParfait.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7854_1001_1002",
      thumb: {
        img: getImg("BTN_KodiakKingdom.webp"),
        subGame: getImg("BTN_KodiakKingdom.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7851_1001_1002",
      thumb: {
        img: getImg("BTN_HyperStar.webp"),
        subGame: getImg("BTN_HyperStar.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7855_1001_1002",
      thumb: {
        img: getImg("BTN_BigBoomRiches.webp"),
        subGame: getImg("BTN_BigBoomRiches.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7853_1001_1002",
      thumb: {
        img: getImg("BTN_ArcticEnchantress.webp"),
        subGame: getImg("BTN_ArcticEnchantress.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7852_1001_1002",
      thumb: {
        img: getImg("BTN_BasketballStarWilds.webp"),
        subGame: getImg("BTN_BasketballStarWilds.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7850_1001_1002",
      thumb: {
        img: getImg("BTN_AgentJaneBlondeMaxVolume.webp"),
        subGame: getImg("BTN_AgentJaneBlondeMaxVolume.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1287_1001_1002",
      thumb: {
        img: getImg("BTN_RugbyStar.webp"),
        subGame: getImg("BTN_RugbyStar.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1229_1001_1002",
      thumb: {
        img: getImg("BTN_BreakAway1.webp"),
        subGame: getImg("BTN_BreakAway1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5695_1001_1002",
      thumb: {
        img: getImg("BTN_BoomPirates.webp"),
        subGame: getImg("BTN_BoomPirates.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1881_1001_1002",
      thumb: {
        img: getImg("BTN_PollenParty.webp"),
        subGame: getImg("BTN_PollenParty.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1954_1001_1002",
      thumb: {
        img: getImg("BTN_PokeTheGuy.webp"),
        subGame: getImg("BTN_PokeTheGuy.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_4327_1001_1002",
      thumb: {
        img: getImg("BTN_PlayboyGoldJackpots.webp"),
        subGame: getImg("BTN_PlayboyGoldJackpots.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1946_1001_1002",
      thumb: {
        img: getImg("BTN_PlayboyGold.webp"),
        subGame: getImg("BTN_PlayboyGold.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6186_1001_1002",
      thumb: {
        img: getImg("BTN_Deadmau5.webp"),
        subGame: getImg("BTN_Deadmau5.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1084_1001_1002",
      thumb: {
        img: getImg("BTN_CoolWolf3.webp"),
        subGame: getImg("BTN_CoolWolf3.webp"),
      },
    },

    {
      subCode: "GTAG_MICROGAMING_1197_1001_1002",
      thumb: {
        img: getImg("BTN_Cashville1.webp"),
        subGame: getImg("BTN_Cashville1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1996_1001_1002",
      thumb: {
        img: getImg("BTN_CashOccino.webp"),
        subGame: getImg("BTN_CashOccino.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1366_1001_1002",
      thumb: {
        img: getImg("BTN_Cashapillar1.webp"),
        subGame: getImg("BTN_Cashapillar1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_2067_1001_1002",
      thumb: {
        img: getImg("BTN_CashofKingdoms.webp"),
        subGame: getImg("BTN_CashofKingdoms.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1393_1001_1002",
      thumb: {
        img: getImg("BTN_CashCrazy9.webp"),
        subGame: getImg("BTN_CashCrazy9.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6303_1001_1002",
      thumb: {
        img: getImg("BTN_CarnavalJackpot.webp"),
        subGame: getImg("BTN_CarnavalJackpot.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1117_1001_1002",
      thumb: {
        img: getImg("BTN_Carnaval2.webp"),
        subGame: getImg("BTN_Carnaval2.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1886_1001_1002",
      thumb: {
        img: getImg("BTN_CandyDreams.webp"),
        subGame: getImg("BTN_CandyDreams.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1204_1001_1002",
      thumb: {
        img: getImg("BTN_BustTheBank1.webp"),
        subGame: getImg("BTN_BustTheBank1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1173_1001_1002",
      thumb: {
        img: getImg("BTN_BushTelegraph1.webp"),
        subGame: getImg("BTN_BushTelegraph1.webp"),
      },
    },

    {
      subCode: "GTAG_MICROGAMING_4297_1001_1002",
      thumb: {
        img: getImg("BTN_BreakDaBankAgainRespin.webp"),
        subGame: getImg("BTN_BreakDaBankAgainRespin.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1097_1001_1002",
      thumb: {
        img: getImg("BTN_BreakDaBankAgain1.webp"),
        subGame: getImg("BTN_BreakDaBankAgain1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1159_1001_1002",
      thumb: {
        img: getImg("BTN_BasketballStar.webp"),
        subGame: getImg("BTN_BasketballStar.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6334_1001_1002",
      thumb: {
        img: getImg("BTN_8GoldenSkullsofHollyRogerMegaways™.webp"),
        subGame: getImg("BTN_8GoldenSkullsofHollyRogerMegaways™.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6188_1001_1002",
      thumb: {
        img: getImg("BTN_MakanMakan.webp"),
        subGame: getImg("BTN_MakanMakan.webp"),
      },
    },

    {
      subCode: "GTAG_MICROGAMING_6331_1001_1002",
      thumb: {
        img: getImg("BTN_SolarWilds.webp"),
        subGame: getImg("BTN_SolarWilds.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7075_1001_1002",
      thumb: {
        img: getImg("BTN_SoccerStriker.webp"),
        subGame: getImg("BTN_SoccerStriker.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1032_1001_1002",
      thumb: {
        img: getImg("BTN_somuchsushi.webp"),
        subGame: getImg("BTN_somuchsushi.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1374_1001_1002",
      thumb: {
        img: getImg("BTN_somuchcandy.webp"),
        subGame: getImg("BTN_somuchcandy.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8533_1001_1002",
      thumb: {
        img: getImg("BTN_GoldFactoryJackpotsMegaMoolah.webp"),
        subGame: getImg("BTN_GoldFactoryJackpotsMegaMoolah.webp"),
      },
    },

    {
      subCode: "GTAG_MICROGAMING_7683_1001_1002",
      thumb: {
        img: getImg("BTN_MegaMoolahTheWitchsMoon.webp"),
        subGame: getImg("BTN_MegaMoolahTheWitchsMoon.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8423_1001_1002",
      thumb: {
        img: getImg("BTN_MegaMoolahLuckyBells.webp"),
        subGame: getImg("BTN_MegaMoolahLuckyBells.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_8420_1001_1002",
      thumb: {
        img: getImg("BTN_CashNRichesWOWPOTMegaways.webp"),
        subGame: getImg("BTN_CashNRichesWOWPOTMegaways.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1900_1001_1002",
      thumb: {
        img: getImg("BTN_GnomeWood.webp"),
        subGame: getImg("BTN_GnomeWood.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1905_1001_1002",
      thumb: {
        img: getImg("BTN_MobyDick.webp"),
        subGame: getImg("BTN_MobyDick.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5383_1001_1002",
      thumb: {
        img: getImg("BTN_WheelofWishes.webp"),
        subGame: getImg("BTN_WheelofWishes.webp"),
      },
    },

    {
      subCode: "GTAG_MICROGAMING_6215_1001_1002",
      thumb: {
        img: getImg("BTN_SistersOfOzWowPot.webp"),
        subGame: getImg("BTN_SistersOfOzWowPot.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6431_1001_1002",
      thumb: {
        img: getImg("BTN_Sherlock&MoriartyWowpot.webp"),
        subGame: getImg("BTN_Sherlock&MoriartyWowpot.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6743_1001_1002",
      thumb: {
        img: getImg("BTN_QueenOfAlexandriaWOWPOT.webp"),
        subGame: getImg("BTN_QueenOfAlexandriaWOWPOT.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6746_1001_1002",
      thumb: {
        img: getImg("BTN_MegaMoolahGoddess.webp"),
        subGame: getImg("BTN_MegaMoolahGoddess.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1154_1001_1002",
      thumb: {
        img: getImg("BTN_MajorMillions.webp"),
        subGame: getImg("BTN_MajorMillions.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6299_1001_1002",
      thumb: {
        img: getImg("BTN_JuicyJokerMegaMoolah.webp"),
        subGame: getImg("BTN_JuicyJokerMegaMoolah.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6958_1001_1002",
      thumb: {
        img: getImg("BTN_ImmortalRomanceMegaMoolah.webp"),
        subGame: getImg("BTN_ImmortalRomanceMegaMoolah.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6068_1001_1002",
      thumb: {
        img: getImg("BTN_FortuniumGoldMegaMoolah.webp"),
        subGame: getImg("BTN_FortuniumGoldMegaMoolah.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1401_1001_1002",
      thumb: {
        img: getImg("BTN_CashSplash5Reel1.webp"),
        subGame: getImg("BTN_CashSplash5Reel1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5449_1001_1002",
      thumb: {
        img: getImg("BTN_AtlanteanTreasuresMegaMoolah.webp"),
        subGame: getImg("BTN_AtlanteanTreasuresMegaMoolah.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_6189_1001_1002",
      thumb: {
        img: getImg("BTN_AfricanLegends.webp"),
        subGame: getImg("BTN_AfricanLegends.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_5529_1001_1002",
      thumb: {
        img: getImg("BTN_AbsolootlyMadMegaMoolah.webp"),
        subGame: getImg("BTN_AbsolootlyMadMegaMoolah.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7077_1001_1002",
      thumb: {
        img: getImg("BTN_9BlazingDiamondsWowpot.webp"),
        subGame: getImg("BTN_9BlazingDiamondsWowpot.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_7528_1001_1002",
      thumb: {
        img: getImg("BTN_AncientFortunesPoseidonWOWPotMegaways.webp"),
        subGame: getImg("BTN_AncientFortunesPoseidonWOWPotMegaways.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1248_1001_1001",
      thumb: {
        img: getImg("BTN_USRoulette1.webp"),
        subGame: getImg("BTN_USRoulette1.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1165",
      thumb: {
        img: getImg("BTN_FrozenDiamonds.webp"),
        subGame: getImg("BTN_FrozenDiamonds.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1733",
      thumb: {
        img: getImg("BTN_BoogieMonsters.webp"),
        subGame: getImg("BTN_BoogieMonsters.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1263",
      thumb: {
        img: getImg("BTN_beachbabes.webp"),
        subGame: getImg("BTN_beachbabes.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1169",
      thumb: {
        img: getImg("BTN_thegrandjourney.webp"),
        subGame: getImg("BTN_thegrandjourney.webp"),
      },
    },
    {
      subCode: "GTAG_MICROGAMING_1931_1001_1001",
      thumb: {
        img: getImg("BTN_Baccarat.webp"),
        subGame: getImg("BTN_Baccarat.webp"),
      },
      //  "Playboy Baccarat Multi Player"
    },
    {
      subCode: "GTAG_MICROGAMING_1922_1001_1002",
      thumb: {
        img: getImg("BTN_Baccarat.webp"),
        subGame: getImg("BTN_Baccarat.webp"),
      },
      //  "Playboy Baccarat Single Player"
    },
    {
      subCode: "GTAG_MICROGAMING_1936_1001_1002",
      thumb: {
        img: getImg("BTN_PremierRoulette.webp"),
        subGame: getImg("BTN_PremierRoulette.webp"),
      },
      //  "Roulette Single Player"
    },
    {
      subCode: "GTAG_MICROGAMING_2049_1001_1002",
      thumb: {
        img: getImg("BTN_Baccarat.webp"),
        subGame: getImg("BTN_Baccarat.webp"),
      },
      //  "No Commission Baccarat Playboy"
    },
    {
      subCode: "GTAG_MICROGAMING_1921_1001_1002",
      thumb: {
        img: getImg("BTN_Baccarat.webp"),
        subGame: getImg("BTN_Baccarat.webp"),
      },
      //  "Baccarat Single Player"
    },
    {
      subCode: "GTAG_MICROGAMING_1930_1001_1001",
      thumb: {
        img: getImg("BTN_Baccarat.webp"),
        subGame: getImg("BTN_Baccarat.webp"),
      },
      //  "Baccarat Multi Player"
    },
    {
      subCode: "GTAG_MICROGAMING_2048_1001_1002",
      thumb: {
        img: getImg("BTN_Baccarat.webp"),
        subGame: getImg("BTN_Baccarat.webp"),
      },
      //  "No Commission Baccarat"
    },
  ],
  localeSetting: {
    zh_CN: {
      subGames: ZH_SUBGAMES,
    },
    zh_TW: {
      subGames: ZH_SUBGAMES,
    },
  },
} as LIBRARY_GAME;

// {
//   subCode: "GTAG_MICROGAMING_4271_1001_1002",
//   thumb: {
//     img: getImg("BTN_VillagePeople®MachoMoves_ZH.webp"),
//     subGame: getImg("BTN_VillagePeople®MachoMoves_ZH.webp"),
//   },
// },
// {
//   subCode: "GTAG_MICROGAMING_1931_1001_1001",
//   thumb: { img: ", subGame: " },
// },
// {
//   subCode: "GTAG_MICROGAMING_1922_1001_1002",
//   thumb: { img: ", subGame: " },
// },
// {
//   subCode: "GTAG_MICROGAMING_1936_1001_1002",
//   thumb: { img: ", subGame: " },
// },
// {
//   subCode: "GTAG_MICROGAMING_2049_1001_1002",
//   thumb: { img: ", subGame: " },
// },
// {
//   subCode: "GTAG_MICROGAMING_1912_1001_1002",
//   thumb: { img: ", subGame: " },
// },
// {
//   subCode: "GTAG_MICROGAMING_1921_1001_1002",
//   thumb: { img: ", subGame: " },
// },
// {
//   subCode: "GTAG_MICROGAMING_1930_1001_1001",
//   thumb: { img: ", subGame: " },
// },
// {
//   subCode: "GTAG_MICROGAMING_2048_1001_1002",
//   thumb: { img: ", subGame: " },
// },
// {
// subCode: "GTAG_MICROGAMING_1093_1001_1001",
// thumb: {
//   img: getImg("BTN_MegaMoolaH.webp"),
//   subGame: getImg("BTN_MegaMoolaH.webp"),
// },
// },
// {
//   subCode: "GTAG_MICROGAMING_1391_1001_1002",
//   thumb: {
//     img: getImg("BTN_treasurenile.webp"),
//     subGame: getImg("BTN_treasurenile.webp"),
//   },
// },
// {
//   subCode: "GTAG_MICROGAMING_8485_1001_1002",
//   thumb: {
//     img: getImg("BTN_ThunderstruckIIMegaMoolah_ZH.webp"),
//     subGame: getImg("BTN_ThunderstruckIIMegaMoolah_ZH.webp"),
//   },
// },
// {
//   subCode: "GTAG_MICROGAMING_1222_1001_1002",
//   thumb: {
//     img: getImg("BTN_UntamedGiantPanda_ZH.webp"),
//     subGame: getImg("BTN_UntamedGiantPanda_ZH.webp"),
//   },
// },
// {
//   subCode: "GTAG_MICROGAMING_1318_1001_1002",
//   thumb: {
//     img: getImg("BTN_BurningDesire1_ZH.webp"),
//     subGame: getImg("BTN_BurningDesire1_ZH.webp"),
//   },
// },
// {
//   subCode: "GTAG_MICROGAMING_1291_1001_1002",
//   thumb: {
//     img: getImg("BTN_CentreCourt1_ZH.webp"),
//     subGame: getImg("BTN_CentreCourt1_ZH.webp"),
//   },
// },
// {
//   subCode: "GTAG_MICROGAMING_1250_1001_1002",
//   thumb: {
//     img: getImg("BTN_isis_ZH.webp"),
//     subGame: getImg("BTN_isis_ZH.webp"),
//   },
// },
