import Lists, { typeLists } from "../../service/promotion-order/lists";

type state = {
  order: typeLists.response;
};

const state: state = {
  order: {
    list: [],
    paginator: {
      sort_type: "ASC",
      count: 0,
      page: 0,
      page_num: 0,
      perpage: 0,
    },
  },
};

export default {
  namespaced: true,
  state,
  mutations: {
    setOrder: (st: state, data: typeLists.response) => {
      st.order = data;
    },
    cleanOrder(st: state) {
      st.order = {
        list: [],
        paginator: {
          sort_type: "ASC",
          count: 0,
          page: 0,
          page_num: 0,
          perpage: 0,
        },
      };
    },
  },
  actions: {
    async getOrder({ commit }: any, request: typeLists.request) {
      const response = await (await Lists(request)).data.response;
      commit("setOrder", response);
    },
  },
  getters: {
    order(st: state) {
      return st.order;
    },
  },
};
