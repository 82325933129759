import { getGameImg } from "../../../util/gameImg"
const getImg = (fileName: string) => {
  return getGameImg('GOLDEN_F_PG_EGAME', fileName)
};
export default {
  code: "GOLDEN_F_PG_EGAME",
  type: ["EGAMING"],
  play_method: "URL",
  thumb: {
    img: getImg("logo.webp"), // 透明底黑字
    // img: getImg("logo2.webp"), // 黑底白字
  },
  subGames: [
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_60",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_60.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_60.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_65",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_65.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_65.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_74",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_74.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_74.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_87",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_87.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_87.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_89",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_89.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_89.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_48",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_48.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_48.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_53",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_53.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_53.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_54",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_54.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_54.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_71",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_71.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_71.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_75",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_75.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_75.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_79",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_79.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_79.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_84",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_84.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_84.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_98",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_98.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_98.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_104",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_104.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_104.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_106",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_106.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_106.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_57",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_57.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_57.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_73",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_73.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_73.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_82",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_82.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_82.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_83",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_83.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_83.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_92",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_92.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_92.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_94",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_94.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_94.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_103",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_103.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_103.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_1",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_1.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_1.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_3",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_3.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_3.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_24",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_24.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_24.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_6",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_6.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_6.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_26",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_26.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_26.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_7",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_7.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_7.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_25",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_25.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_25.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_17",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_17.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_17.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_2",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_2.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_2.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_18",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_18.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_18.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_28",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_28.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_28.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_29",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_29.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_29.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_35",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_35.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_35.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_34",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_34.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_34.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_36",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_36.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_36.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_33",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_33.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_33.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_37",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_37.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_37.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_38",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_38.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_38.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_39",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_39.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_39.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_41",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_41.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_41.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_44",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_44.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_44.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_42",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_42.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_42.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_43",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_43.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_43.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_40",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_40.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_40.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_50",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_50.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_50.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_61",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_61.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_61.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_59",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_59.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_59.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_64",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_64.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_64.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_63",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_63.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_63.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_68",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_68.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_68.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_20",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_20.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_20.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_62",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_62.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_62.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_67",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_67.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_67.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_70",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_70.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_70.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_69",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_69.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_69.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_85",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_85.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_85.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_80",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_80.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_80.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_90",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_90.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_90.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_58",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_58.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_58.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_88",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_88.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_88.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_97",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_97.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_97.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_86",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_86.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_86.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_91",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_91.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_91.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_93",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_93.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_93.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_95",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_95.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_95.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_100",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_100.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_100.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_105",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_105.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_105.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_101",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_101.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_101.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_109",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_109.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_109.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_110",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_110.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_110.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_102",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_102.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_102.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_111",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_111.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_111.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_113",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_113.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_113.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_115",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_115.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_115.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_108",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_108.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_108.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_107",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_107.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_107.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_119",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_119.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_119.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_116",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_116.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_116.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_114",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_114.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_114.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_117",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_117.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_117.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_118",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_118.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_118.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_112",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_112.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_112.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_126",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_126.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_126.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_122",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_122.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_122.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_121",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_121.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_121.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_125",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_125.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_125.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_123",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_123.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_123.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_120",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_120.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_120.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_124",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_124.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_124.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_129",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_129.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_129.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_130",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_130.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_130.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_127",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_127.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_127.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_128",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_128.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_128.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_132",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_132.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_132.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_135",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_135.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_135.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_1312883",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_1312883.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_1312883.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_1338274",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_1338274.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_1338274.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_1368367",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_1368367.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_1368367.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_1372643",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_1372643.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_1372643.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_1402846",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_1402846.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_1402846.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_1543462",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_1543462.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_1543462.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_1340277",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_1340277.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_1340277.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_1420892",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_1420892.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_1420892.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_1381200",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_1381200.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_1381200.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_1418544",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_1418544.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_1418544.webp"),
      },
    },
    {
      subCode: "GOLDEN_F_PG_EGAME_PG_1448762",
      thumb: {
        img: getImg("GOLDEN_F_PG_EGAME_PG_1448762.webp"),
        subGame: getImg("GOLDEN_F_PG_EGAME_PG_1448762.webp"),
      },
    },
  ],
} as LIBRARY_GAME;
